import { useHistory } from 'react-router-dom';
import { Flag } from '../../../featureFlags';
import { AppView, Permission, Role } from '../../../graphQL';
import { isProviderSuperAdmin } from '../../../modelUtils/provider';
import { isReportingDisabled } from '../../../utils';
import { useCurrentProvider } from '../../Permissions';
import { useNavbarCounts } from '../Counts';
import { allItems, ItemId, visibleSupportingClinicianItems } from './items';

export const useNavItems = () => {
  const history = useHistory();
  const {
    currentProvider,
    appView,
    featureFlags,
    hasPermission,
    providerOrgUsesDedicatedGroupModel,
  } = useCurrentProvider();

  const hideReporting = isReportingDisabled(currentProvider);

  /*
  const showSuperAdminHome =
    isProviderSuperAdmin(currentProvider) && hasPermission(Permission.Reporting) && !hideReporting;
  */

  const canShowHandbook = featureFlags.includes(Flag.CscHandbook);

  // This logic is greatly complicated by the feature flag. Once this is fully enabled, the
  // overrides for showStudents and showReferrals can be removed.
  const canShowReferrals = featureFlags.includes(Flag.CtcReferralsTab);
  let showStudents = appView === AppView.Mcp && hasPermission(Permission.PatientList);
  let showReferrals =
    (appView === AppView.Mcp || appView === AppView.Referral) &&
    hasPermission(Permission.ReferralsView);

  if (!canShowReferrals) {
    showStudents = showStudents || appView === AppView.Referral;
    showReferrals = false;
  }

  const isEnhancedCollab = featureFlags.includes(Flag.CtcEnhancedCollaboration);
  let showDashboard = appView === AppView.Mcp || appView === AppView.Referral;
  if (!isEnhancedCollab && appView === AppView.Referral) {
    // Previous logic - don't show dashboard for referral view.
    // This can be removed once the feature flag is always on.
    showDashboard = false;
  }

  const permissionMap: Record<ItemId, boolean> = {
    [ItemId.Dashboard]: showDashboard,
    [ItemId.Home]: appView === 'oz' && hasPermission(Permission.CareDashboards),
    [ItemId.Tasks]: appView === 'oz' && hasPermission(Permission.TicketView),
    [ItemId.Students]: showStudents,
    [ItemId.Referrals]: showReferrals,
    [ItemId.Users]: appView === 'oz' && hasPermission(Permission.PatientList),
    [ItemId.Staff]: appView === 'mcp' && hasPermission(Permission.ProviderList),
    [ItemId.Providers]: appView === 'oz' && hasPermission(Permission.MantraAdmin),
    [ItemId.Appointments]: appView === 'oz',
    [ItemId.Verification]: hasPermission(Permission.MantraAdmin),
    [ItemId.Activity]: hasPermission(Permission.MantraAdmin),
    [ItemId.Settings]: true,
    [ItemId.Organizations]: hasPermission(Permission.MantraAdmin),
    [ItemId.Debug]: featureFlags.includes(Flag.Seed),
    [ItemId.Messaging]:
      hasPermission(Permission.PatientMessage) || hasPermission(Permission.ProviderMessage),
    [ItemId.Config]: hasPermission(Permission.MantraAdmin),
    [ItemId.Reporting]: hasPermission(Permission.Reporting) && !hideReporting,
    [ItemId.Resources]: appView === 'mcp' && !canShowHandbook,
    [ItemId.Handbook]: appView === 'mcp' && canShowHandbook,
    [ItemId.Utilization]:
      appView === 'mcp' &&
      hasPermission(Permission.UtilizationView) &&
      providerOrgUsesDedicatedGroupModel() &&
      featureFlags.includes(Flag.MCPShowDGM),
  };

  const countMap = useNavbarCounts();

  let organizationId: number | undefined;
  if (['referral', 'mcp'].includes(appView)) {
    organizationId = currentProvider.organizations[0].id;
  }

  const filteredItems = allItems.filter(
    i =>
      permissionMap[i.itemId] &&
      (currentProvider.role !== Role.UniversitySupportingClinician ||
        visibleSupportingClinicianItems.has(i.itemId))
  );

  const navItems = filteredItems.map(i => ({
    ...i,
    title:
      i.itemId === ItemId.Dashboard && isProviderSuperAdmin(currentProvider) ? 'Home' : i.title,
    path: i.itemId.replace(':organizationId', String(organizationId)),
    Count: countMap[i.itemId],
  }));

  const activeItem = (() => {
    let path = history.location.pathname;
    // make path generic to match with nav_item pathname
    if (path.startsWith('/organizations')) {
      const divided = path.split('/');
      divided[2] = ':organizationId';
      path = `${divided.join('/')}`;
    }
    const directMatch = allItems.find(i => path === i.itemId)?.itemId;
    return (directMatch || allItems.find(i => path.startsWith(i.itemId))?.itemId) ?? '';
  })();

  return { navItems, activeItem };
};
