import { chain } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useProviderNetworkContext } from '../Components/ProviderNetwork/ProviderNetworkContext';

export const useMoveToFirstProviderNetworkAvailability = () => {
  const { startDate, jumpTo, dedicatedGroupModelActive, providers } = useProviderNetworkContext();

  // For DGM, automatically move to the first available date
  let firstOverallDgmAvailability = '';
  if (dedicatedGroupModelActive) {
    firstOverallDgmAvailability = chain(providers)
      .map(p => p.upcomingIntakeAvailability[0].start)
      .min()
      .value();
  }
  const [movedToFirstAvailability, setMovedToFirstAvailability] = useState(false);
  useEffect(() => {
    if (
      !movedToFirstAvailability &&
      dedicatedGroupModelActive &&
      firstOverallDgmAvailability &&
      moment(firstOverallDgmAvailability).isAfter(startDate)
    ) {
      setMovedToFirstAvailability(true);
      jumpTo(moment(firstOverallDgmAvailability));
    }
  }, [firstOverallDgmAvailability, dedicatedGroupModelActive, startDate, movedToFirstAvailability]);
};
