import * as Sentry from '@sentry/browser';

const isProduction = process.env.NODE_ENV === 'production';

export const initSentry = () => {
  if (isProduction) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      ignoreErrors: [
        // these errors are mostly a result of bad actors trying to brute force the passwordless endpoint
        /Non-Error promise rejection captured with value: Object Not Found Matching Id:[0-9]+/i,
        // this error this thrown when the application is attempting to use a provider's camera but the browser hasn't been given permission to access the camera.
        /NotAllowedError: The request is not allowed by the user agent or the platform/i,
        // this error is thrown when attempting to refer a patient who's email aready exists in the system. We correctly display this user to providers.
        // However, we should not capture it in sentry as it creates a lot of noise and is a part of the normal flow.
        /(That|The|This) email is already in use./i,
      ],
    });
  }
};
