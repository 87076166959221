import { Modal, ROLE } from 'baseui/modal';
import { KIND } from 'baseui/notification';
import { capitalize, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import { InputRHF, SelectRHF } from '../../../Components/Form';
import { MantraModalBodyOld } from '../../../Components/Modal';
import { CenteredNotification } from '../../../Components/Notification';
import {
  isMantraAdmin,
  isMantraProvider,
  isMcpUser,
  MantraAdminOnly,
  OzOnly,
  useCurrentProvider,
} from '../../../Components/Permissions';
import { Warning } from '../../../Components/Warning';
import { Text } from '../../../globalStyles';
import {
  CareStatus,
  CareType,
  EditPatientInfoQuery,
  PaymentSource,
  ProviderServiceBlockedReason,
  ProvidersQuery,
  StateCodes,
  useAdminEditUserMutation,
  useEditPatientInfoQuery,
  UserCareFlowInput,
} from '../../../graphQL';
import {
  useGeoStateAvailability,
  ValidateGeoStateAndCareTypes,
} from '../../../Hooks/useGeoStateAvailability';
import { Nullable } from '../../../types';
import { borderRadius, getRemainingSessions } from '../../../utils';
import { isPsychiatrist, isTherapist } from '../../Provider/util';
import { paymentSourceCopy } from '../copy';
import { useDrilldownContext } from '../helpers';
import { Modal as MantraModal } from '../styles';

const NONE_OPTION_ID = -1;
const required = 'This field is required.';
const labelSize = 'f6';

type CareFlowFields = {
  careStatus?: CareStatus;
  paymentSource?: PaymentSource;
  providerId?: number;
  remainingSessions?: number;
};

type EditCareFlowForm = Record<CareType, CareFlowFields | undefined>;

type EditPatientCareFlowsProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function EditPatientCareFlows({ isOpen, onClose }: EditPatientCareFlowsProps) {
  const { currentProvider } = useCurrentProvider();
  const [saving, setSaving] = useState(false);
  const { track } = useEvents();
  const { user, refetch } = useDrilldownContext();
  const { validateStateCareTypes } = useGeoStateAvailability(user.organization?.id || 0);

  const { data } = useEditPatientInfoQuery({ variables: { userId: user.id } });
  const careTypeToOrgFlow = (user.organization?.careFlows ?? []).reduce(
    (acc, flow) => ({ ...acc, [flow.careType]: flow }),
    {} as Record<CareType, { careType: CareType; defaultPaymentSource: PaymentSource }>
  );

  const [editUser, { error }] = useAdminEditUserMutation({
    onCompleted: editUserData => {
      track('user.info.edited', { userId: editUserData.adminEditUser.id });
    },
  });

  const psychiatryFlow = user.careFlows.find(c => c.careType === CareType.Psychiatry);
  const psychLimits = psychiatryFlow && getRemainingSessions(psychiatryFlow);
  const therapyFlow = user.careFlows.find(c => c.careType === CareType.Therapy);
  const therapyLimits = therapyFlow && getRemainingSessions(therapyFlow);

  const form = useForm<EditCareFlowForm>({
    defaultValues: {
      [CareType.Psychiatry]: psychiatryFlow
        ? {
            careStatus: psychiatryFlow.careStatus!,
            paymentSource: psychiatryFlow.paymentSource!,
            providerId: user.provider?.id,
            remainingSessions: psychLimits?.remaining,
          }
        : undefined,
      [CareType.Therapy]: therapyFlow
        ? {
            careStatus: therapyFlow.careStatus!,
            paymentSource: therapyFlow.paymentSource!,
            providerId: user.therapist?.id,
            remainingSessions: therapyLimits?.remaining,
          }
        : undefined,
    },
  });

  const validateCareTypes =
    validateStateCareTypes && user.primaryAddressState
      ? validateStateCareTypes(user.primaryAddressState)
      : undefined;

  const onSubmit = form.handleSubmit(async (values: EditCareFlowForm) => {
    setSaving(true);
    const fallbackPaymentSource =
      user.origin === 'Dtc' ? PaymentSource.SelfPay : PaymentSource.Organization;
    const updatedCareFlows: UserCareFlowInput[] = [];
    const psychiatry = values[CareType.Psychiatry];
    if (psychiatry?.careStatus) {
      const remainingPsych = psychiatry.remainingSessions && Number(psychiatry.remainingSessions);
      const paymentSource =
        psychiatry.paymentSource ?? psychiatryFlow?.paymentSource ?? fallbackPaymentSource;
      updatedCareFlows.push({
        careType: CareType.Psychiatry,
        careStatus: psychiatry.careStatus as CareStatus,
        paymentSource: isMcpUser(currentProvider)
          ? careTypeToOrgFlow[CareType.Psychiatry].defaultPaymentSource
          : paymentSource,
        sessionLimitOverride:
          remainingPsych && psychLimits && remainingPsych !== psychLimits.remaining
            ? remainingPsych + psychLimits.sessionsUsed
            : undefined,
      });
    }
    const therapy = values[CareType.Therapy];
    if (therapy?.careStatus) {
      const remainingTherapy = therapy.remainingSessions && Number(therapy.remainingSessions);
      const paymentSource =
        therapy.paymentSource ?? therapyFlow?.paymentSource ?? fallbackPaymentSource;
      updatedCareFlows.push({
        careType: CareType.Therapy,
        careStatus: therapy.careStatus as CareStatus,
        paymentSource: isMcpUser(currentProvider)
          ? careTypeToOrgFlow[CareType.Therapy].defaultPaymentSource
          : paymentSource,
        sessionLimitOverride:
          remainingTherapy && therapyLimits && remainingTherapy !== therapyLimits.remaining
            ? remainingTherapy + therapyLimits.sessionsUsed
            : undefined,
      });
    }

    let providerId: Nullable<number> = psychiatry?.providerId || undefined;
    let therapistId: Nullable<number> = therapy?.providerId || undefined;

    if (providerId === NONE_OPTION_ID) {
      providerId = null;
    }
    if (therapistId === NONE_OPTION_ID) {
      therapistId = null;
    }

    try {
      await editUser({
        variables: {
          editUser: {
            providerId,
            therapistId,
            id: user.id,
            careFlows: isEmpty(updatedCareFlows) ? null : updatedCareFlows,
          },
        },
      });
      await refetch();
      onClose();
    } finally {
      setSaving(false);
    }
  });

  const values = form.watch([CareType.Psychiatry, CareType.Therapy]);
  const providers = data?.providersForUser?.data ?? [];

  const matchesState = (
    provider: NonNullable<EditPatientInfoQuery['providersForUser']['data']>[number]
  ) => {
    const userState = user.primaryAddressState;
    return userState && provider.geoStates.includes(userState as StateCodes);
  };

  const matchesOrg = (
    provider: NonNullable<EditPatientInfoQuery['providersForUser']['data']>[number],
    careType: CareType
  ) => {
    const userOrganizationId = user.organization?.id;
    const paymentSource = values[careType]?.paymentSource;
    const showDtcProviders =
      paymentSource !== PaymentSource.Organization ||
      Boolean(user.organization?.usesDedicatedGroupModel);
    const inPatientOrg = provider.organizations.some(
      org => org.id === userOrganizationId || org.children.some(i => i.id === userOrganizationId)
    );
    return inPatientOrg || showDtcProviders;
  };

  const psychiatrists = providers.filter(
    p =>
      isMantraProvider(p) &&
      matchesState(p) &&
      matchesOrg(p, CareType.Psychiatry) &&
      isPsychiatrist(p)
  );

  const therapists = providers.filter(
    p => isMantraProvider(p) && matchesState(p) && matchesOrg(p, CareType.Therapy) && isTherapist(p)
  );

  const canUpdateTherapy = isMantraAdmin(currentProvider) || !!careTypeToOrgFlow[CareType.Therapy];
  const canUpdatePsychiatry =
    isMantraAdmin(currentProvider) || !!careTypeToOrgFlow[CareType.Psychiatry];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeable
      animate
      size="auto"
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: {
            ...borderRadius('4px'),
            paddingLeft: '4rem',
            paddingRight: '4rem',
            paddingTop: '2rem',
            paddingBottom: '2rem',
          },
        },
      }}
      unstable_ModalBackdropScroll
    >
      {error && (
        <CenteredNotification kind={KIND.negative}>
          {error.message === 'MCP cannot remove all caretypes' ? (
            <div>
              Patient must have at least 1 active care plan. <br />
              To fully deactivate a patient&apos;s Mantra care, <br />
              please reach out to Mantra Partner Success at <br />
              <Text.externalLink href="mailto:hi@mantrahealth.com" target="_blank">
                hi@mantrahealth.com
              </Text.externalLink>
            </div>
          ) : (
            error.message
          )}
        </CenteredNotification>
      )}
      <MantraModalBodyOld style={{ width: '325px' }}>
        <div className="mb4 pt2 flex-1">
          <Text.h2 className="mb2">Edit Care Types</Text.h2>
          <Text.body>
            To activate a care type for this patient, update a care type status from ‘N/A’ to
            ‘Active’.
          </Text.body>
        </div>
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <div className="flex flex-column gap-3 mb3">
              {canUpdatePsychiatry && (
                <EditPatientCareFlow
                  careType={CareType.Psychiatry}
                  providerTitle="Psychiatric Provider"
                  providers={psychiatrists}
                  hasSessionLimit={!!psychLimits}
                  providersBlockedReason={data?.providersForUser.blockedReason}
                />
              )}
              {canUpdateTherapy && (
                <EditPatientCareFlow
                  careType={CareType.Therapy}
                  providerTitle="Therapist"
                  providers={therapists}
                  hasSessionLimit={!!therapyLimits}
                  validateCareTypes={validateCareTypes}
                  providersBlockedReason={data?.providersForUser.blockedReason}
                />
              )}
            </div>
            <FinalButton
              className="w-100 mb3"
              loading={saving}
              kind="primary"
              type="submit"
              onClick={onSubmit}
            >
              Save
            </FinalButton>
            <MantraModal.closeLink className="b" onClick={() => onClose()}>
              Cancel
            </MantraModal.closeLink>
          </form>
        </FormProvider>
      </MantraModalBodyOld>
    </Modal>
  );
}

interface EditCareFlowProps {
  careType: CareType;
  providerTitle: string;
  hasSessionLimit: boolean;
  providers: ProvidersQuery['providers'];
  validateCareTypes?: ReturnType<ValidateGeoStateAndCareTypes>;
  providersBlockedReason?: Nullable<ProviderServiceBlockedReason>;
}

function EditPatientCareFlow({
  careType,
  providerTitle,
  hasSessionLimit,
  providers,
  validateCareTypes,
  providersBlockedReason,
}: EditCareFlowProps) {
  const { currentProvider } = useCurrentProvider();
  const ctx = useFormContext();
  const careStatus: CareStatus = ctx.watch(`${careType}.careStatus`);
  const paymentSource: PaymentSource = ctx.watch(`${careType}.paymentSource`);
  const providerOptions = providers.map(i => ({ id: i.id, label: i.name }));

  return (
    <div className="w-100">
      <Text.h2 className="mt0 mb3">{careType}</Text.h2>
      <SelectRHF
        name={`${careType}.careStatus`}
        controlProps={{ label: `${careType} Status`, labelSize }}
        options={careStatusOptions.filter(
          o => isMantraAdmin(currentProvider) || o.id === null || o.id === CareStatus.Active
        )}
        rules={{
          validate: {
            validateCareStatus: (value: CareStatus) => {
              if (value !== CareStatus.Active || !validateCareTypes) {
                return true;
              }

              const validationResponse = validateCareTypes([careType]);
              if (validationResponse.passed) {
                return true;
              }

              if (validationResponse.organizationFailed) {
                const { careTypes, stateName } = validationResponse.organizationFailed;
                return `Your organization is not contracted to refer students for ${careTypes} services in ${stateName}.`;
              }

              if (validationResponse.providerFailed) {
                const { careTypes, stateName } = validationResponse.providerFailed;
                return `Mantra Health is not contracted to refer students for ${careTypes} services in ${stateName}.`;
              }

              return true;
            },
          },
        }}
      />
      <OzOnly>
        {careStatus === CareStatus.Active && (
          <>
            <SelectRHF
              controlProps={{ labelSize, className: 'mb4' }}
              name={`${careType}.paymentSource`}
              rules={{ required }}
              options={paymentSourceOptions}
            />
            <MantraAdminOnly>
              {hasSessionLimit && paymentSource === PaymentSource.Organization && (
                <InputRHF
                  type="number"
                  min={0}
                  name={`${careType}.remainingSessions`}
                  controlProps={{
                    label: `Number ${capitalize(careType)} Sessions Remaining`,
                    labelSize,
                    className: 'mb4',
                  }}
                />
              )}
              {!providerOptions.length && providersBlockedReason && (
                <Warning>{providersBlockedReasonToLabel[providersBlockedReason]}</Warning>
              )}
              <SelectRHF
                controlProps={{ label: providerTitle, labelSize, className: 'mb4' }}
                name={`${careType}.providerId`}
                options={[{ id: NONE_OPTION_ID, label: 'None' }, ...providerOptions]}
                disabled={Boolean(!providerOptions.length && providersBlockedReason)}
              />
            </MantraAdminOnly>
          </>
        )}
      </OzOnly>
    </div>
  );
}

const providersBlockedReasonToLabel: Record<ProviderServiceBlockedReason, string> = {
  [ProviderServiceBlockedReason.PatientMissingGeoState]: 'Patient is missing a primary address',
};

const paymentSourceOptions: { label: string; id: PaymentSource }[] = (
  Object.keys(paymentSourceCopy) as Array<PaymentSource>
).map(k => ({
  id: k,
  label: paymentSourceCopy[k],
}));

const careStatusOptions: { label: string; id: CareStatus | null }[] = [
  { label: 'Active', id: CareStatus.Active },
  { label: 'Cancelled', id: CareStatus.Cancelled },
  { label: 'Discharged', id: CareStatus.Discharged },
  { label: 'On Hold', id: CareStatus.OnHold },
  { label: 'N/A', id: null },
];
