import moment from 'moment';
import React from 'react';
import { ListView } from '../../Components/ListView';
import { Text } from '../../globalStyles';
import { CounselorDashboardQuery } from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import * as Styles from './styles';

interface Props {
  incomplete: CounselorDashboardQuery['organization']['usersWithOnlyIncompleteReferrals'];
  missing: CounselorDashboardQuery['organization']['usersWithoutReferrals'];
}

export function IncompleteReferralNotes({ incomplete, missing }: Props) {
  const incompleteIds = new Set(incomplete.map(i => i.id));
  const users = [...incomplete, ...missing].sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt))
  );
  if (incomplete.length === 0 && missing.length === 0) return <></>;

  return (
    <div className="pb4">
      <Text.h3>Incomplete Referral Notes</Text.h3>
      {users.length > 0 && (
        <ListView
          data={users}
          getKey={user => user.id}
          columns={[
            { key: 'name', title: 'Student Name', render: user => getFullNameReversed(user) },
            {
              key: 'createdAt',
              title: 'Enrollment Date',
              render: user => moment(user.createdAt).format('M/D/YYYY'),
            },
            {
              key: 'noteStatus',
              title: 'Referral Note',
              render: user =>
                incompleteIds.has(user.id) ? (
                  <Text.bodyGrey>In Progress</Text.bodyGrey>
                ) : (
                  <Text.body kind="danger">Not Started</Text.body>
                ),
            },
          ]}
          paginate={false}
          link={user => `/users/${user.id}`}
        />
      )}
      {users.length === 0 && (
        <Styles.EmptyStateText>No incomplete referral notes</Styles.EmptyStateText>
      )}
    </div>
  );
}
