import moment, { Moment } from 'moment';
import React from 'react';
import { Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { MantraModalBodyOld, MantraModalHeaderOld } from '../Modal';
import { Modal } from '../Modal/Modal';
import * as Styles from './Styles';

type Props = {
  nextAvailabilityButtonClick: (m: Moment) => void;
  nextAvailableProviderDate?: Date;
  soonerAvailabilityModalState: boolean;
  setSoonerAvailabilityModalState: (val: boolean) => void;
};

export const ProvidersSoonerAvailability = ({
  nextAvailabilityButtonClick,
  nextAvailableProviderDate,
  soonerAvailabilityModalState,
  setSoonerAvailabilityModalState,
}: Props) => {
  const nextAvailableMoment = nextAvailableProviderDate
    ? moment(nextAvailableProviderDate)
    : undefined;

  return (
    <div>
      <MCPSoonerAvailabilityModal
        isOpen={soonerAvailabilityModalState}
        onClose={setSoonerAvailabilityModalState}
      />
      <Styles.CenteredMaxWidth>
        <InlineSVG kind="greyIcon" icon="alert-circle" size={42} />
        <Text.h3 className="mv2">No provider availability for the selected timeframe</Text.h3>
        {nextAvailableMoment && (
          <FinalButton
            kind="outline_black"
            className="w-100 mb3"
            onClick={() => nextAvailabilityButtonClick(nextAvailableMoment)}
          >
            Next availability {nextAvailableMoment.format('ddd, MMM DD')}
          </FinalButton>
        )}
        <Text.linkButton onClick={() => setSoonerAvailabilityModalState(true)} className="b">
          Looking for sooner availability?
        </Text.linkButton>
      </Styles.CenteredMaxWidth>
    </div>
  );
};

type MCPSoonerAvailabilityModalProps = {
  isOpen: boolean;
  onClose: (val: boolean) => void;
};
export const MCPSoonerAvailabilityModal = ({
  isOpen,
  onClose,
}: MCPSoonerAvailabilityModalProps) => {
  return (
    <Modal size="tiny" isOpen={isOpen} onClose={() => onClose(false)}>
      <MantraModalHeaderOld>Looking for sooner availability?</MantraModalHeaderOld>
      <MantraModalBodyOld>
        We cannot guarantee that we can accommodate seeing this patient sooner, so please schedule
        the soonest time available, even if it&apos;s further away than desired.
        <br />
        <br />
        If it&apos;s important that this student be seen sooner, you may submit a request for
        earlier accommodation by emailing{' '}
        <Text.externalLink className="b" href="mailto:partnersuccess@mantrahealth.com">
          partnersuccess@mantrahealth.com
        </Text.externalLink>
        .
        <Text.linkButton kind="grayText" className="b mt4 tc w-100" onClick={() => onClose(false)}>
          Close
        </Text.linkButton>
      </MantraModalBodyOld>
    </Modal>
  );
};
