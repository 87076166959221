import { Modal } from 'baseui/modal';
import papaparse from 'papaparse';
import React, { useRef, useState } from 'react';
import { FinalButton } from '../../Components/FinalButton';
import { Modal as MantraModal, Text } from '../../globalStyles';
import { CreateStaff, CreateStaffRole, useAdminCreateStaffMutation } from '../../graphQL';

interface Props {
  onClose: (complete: boolean) => void;
  organizationId: number;
}

const columns: Record<string, string> = {
  'First Name': 'firstName',
  'Last Name': 'lastName',
  'Post-Nominal Titles': 'postNominalTitles',
  'Job Title': 'classification',
  Email: 'email',
  Phone: 'phone',
  'Role (admin, coordinator, staff, or referrer)': 'role',
};
const required = ['firstName', 'lastName', 'email', 'role'];
const roleMap: Record<string, CreateStaffRole> = {
  admin: 'universityAdmin',
  referrer: 'universityReferrer',
  staff: 'universityStaff',
};
const validRoleInputs = Object.keys(roleMap);

export function OrganizationAddStaffModal({ onClose, organizationId }: Props) {
  const [validationError, setValidationError] = useState('');
  const [toCreate, setToCreate] = useState<Omit<CreateStaff, 'organizationId'>[]>([]);
  const [uploading, setUploading] = useState(false);
  const [createStaff] = useAdminCreateStaffMutation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const templateCsv = papaparse.unparse([Object.keys(columns)]);
  const templateLink = `data:text/csv;charset=utf-8,${templateCsv}`;

  const failValidation = (message: string) => {
    setValidationError(message);
    if (inputRef.current) inputRef.current.value = null as any;
  };

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToCreate([]);
    setValidationError('');
    const { files } = event.target;
    if (!files || !files.length) return failValidation('Please choose a file.');
    const file = files[0];
    papaparse.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: result => {
        if (!result.data.length) return failValidation('The uploaded file had no rows.');
        const staffList: any[] = [];
        for (let i = 0; i < result.data.length; i += 1) {
          const row: any = result.data[i];
          const staff: any = {};
          for (const [header, value] of Object.entries(row)) {
            const field = columns[header];
            staff[field] = value;
          }
          for (const requiredField of required) {
            if (!staff[requiredField]) {
              return failValidation(`Required field ${requiredField} is missing on row ${i + 2}.`);
            }
          }
          if (!validRoleInputs.includes(staff.role.toLowerCase())) {
            return failValidation(
              `Invalid role "${staff.role}" on row ${
                i + 2
              }. Please enter one of: ${validRoleInputs.join(', ')}`
            );
          }
          staff.role = roleMap[staff.role.toLowerCase()];
          // remove special chars and spaces from phone numbers, e.g. (555) 555-5555 => 5555555555
          staff.phone = staff.phone.replace(/[^a-zA-Z0-9]/g, '');
          staffList.push(staff);
        }
        setToCreate(staffList);
      },
    });
  };

  const handleUpload = async () => {
    setUploading(true);
    for (const staff of toCreate) {
      try {
        // eslint-disable-next-line
        await createStaff({ variables: { input: { organizationId, ...staff } } });
      } catch (error) {
        setValidationError(`Error creating ${staff.email}: ${(error as any).message}`);
        setUploading(false);
        return;
      }
    }
    onClose(true);
  };

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={() => onClose(false)}>
      <MantraModal.body>
        <h2 className="mb4">Bulk Add Staff</h2>
        <Text.body className="mb3">
          To bulk add staff, fill out{' '}
          <a href={templateLink} download="BulkStaffUploadTemplate.csv">
            the template
          </a>{' '}
          with one staff member per row, then upload it below. Make sure to save it as a CSV and not
          an Excel or Numbers file.
        </Text.body>
        <Text.bodyBold className="mb3">
          Users created here will immediately receive emails granting them access to the MCP.
        </Text.bodyBold>
        <input
          type="file"
          ref={inputRef}
          onChange={handleSelectFile}
          accept=".csv,text/csv,application/csv"
        />
        {validationError && (
          <Text.bodyBold kind="danger" className="mt3">
            {validationError}
          </Text.bodyBold>
        )}
        <div className="mt3">
          <FinalButton
            loading={uploading}
            disabled={!!(validationError || toCreate.length === 0)}
            onClick={handleUpload}
          >
            Upload
          </FinalButton>
        </div>
      </MantraModal.body>
    </Modal>
  );
}
