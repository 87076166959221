import React from 'react';
import { SectionRow, SectionRowItem } from './SectionRow';
import { Text } from '../../../globalStyles';
import { EditableSectionRowInfo, HandBookInfo } from '../Handbook';
import { EditHandbookInput } from '../EditHandbookInput';
import { EditOrganizationHandbookInput } from '../../../graphQL';

type Props = {
  handbookInfo: HandBookInfo;
  selectedField: keyof HandBookInfo | null;
  setSelectedField: (editFieldName: keyof HandBookInfo | null) => void;
  openEdit: (item: keyof HandBookInfo) => (() => void) | undefined;
  editableSectionRowInfo: EditableSectionRowInfo[] | undefined;
  submitFunction: (
    editFieldName: keyof EditOrganizationHandbookInput
  ) => (value: string) => Promise<void>;
};

export const titleContinuityOfCare = 'Continuity of Care';

export function ContinuityOfCareSection({
  handbookInfo,
  selectedField,
  setSelectedField,
  openEdit,
  editableSectionRowInfo,
  submitFunction,
}: Props) {
  const hasManualReactivation = handbookInfo.hasManualReactivationEntitlement;

  const editableSectionRows = editableSectionRowInfo?.map(row => (
    <SectionRow label={row.label} info={row.info}>
      <SectionRowItem onEditClick={openEdit(row.displayKey)}>
        {selectedField === row.displayKey ? (
          <EditHandbookInput
            handbookRowInfo={String(handbookInfo[row.displayKey])}
            selectedField={row.displayKey}
            setSelectedField={setSelectedField}
            onSave={submitFunction(row.editInputKey)}
          />
        ) : (
          <Text.bodySmall>{handbookInfo[row.displayKey]}</Text.bodySmall>
        )}
      </SectionRowItem>
    </SectionRow>
  ));

  return (
    <>
      <SectionRow label="New semester: Manual or Self reactivation?">
        <SectionRowItem>
          <Text.bodySmall>
            {hasManualReactivation ? 'Manual Reactivation' : 'Self Reactivation'}
          </Text.bodySmall>
        </SectionRowItem>
      </SectionRow>

      {editableSectionRows}
    </>
  );
}
