/**
 * A list of events/emissions.
 */
export const events = {
  videoCall: {
    joinButtonClicked: 'video-call.join.button.clicked',
    joined: 'video-call.joined',
    leaveButtonClicked: 'video-call.leave.button.clicked',
    left: 'video-call.left',
    noShowConfirmButtonClicked: 'video-call.no-show.confirm.button.clicked',
    rejoinButtonClicked: 'video-call.rejoin.button.clicked',
  },
  // the below emissions are used for data analytics
  search: {
    providerSearchResults: 'provider.search.results',
    providerSearchBookingResults: 'provider.booking.results',
  },
} as const;
