import { pick } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { ModalLink } from '../../../Components/DedicatedGroupModel/BookableHoursModal';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import { MultiSelectRHF, SelectRHF } from '../../../Components/Form';
import { Modal } from '../../../Components/Modal/Modal';
import { Notification } from '../../../Components/Notification';
import { isMantraAdmin, isMcpRole, useCurrentProvider } from '../../../Components/Permissions';
import { RemoveCampusTeamMemberModal } from '../../../Components/SearchAndAdd/RemoveCampusTeamMemberModal';
import { SearchAndAdd } from '../../../Components/SearchAndAdd/SearchAndAdd';
import { UnsavedCampusTeamMemberModal } from '../../../Components/SearchAndAdd/UnsavedCampusTeamMemberModal';
import { Text } from '../../../globalStyles';
import {
  useAdminEditUserMutation,
  useCampusTeamOptionsForUserQuery,
  useOrganizationProvidersQuery,
} from '../../../graphQL';
import * as Styles from '../../Students/styles';
import { useDrilldownContext } from '../helpers';
import { CampusTeamMember } from './CampusTeamMember';
import { pickDefaultValues } from './EditMcpUser';
import { EditModalContainer } from './editModals';

interface EditPatientInfoProps {
  closeModal: () => void;
}

export const EditCareTeamInfo = ({ closeModal }: EditPatientInfoProps) => {
  const { featureFlags } = useCurrentProvider();
  const C = featureFlags.includes('CTC_ENHANCED_COLLABORATION')
    ? EditCareTeamInfoV2
    : EditCareTeamInfoV1;
  return <C closeModal={closeModal} />;
};

export const EditCareTeamInfoV2 = ({ closeModal }: EditPatientInfoProps) => {
  const { track } = useEvents();
  const { user, refetch } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const userOrgId = user.organization?.id;
  const providerOrgId = currentProvider.organizations[0]?.id;
  const organizationId = userOrgId ?? providerOrgId;
  const { data } = useCampusTeamOptionsForUserQuery({
    variables: { organizationId, userId: user.id },
  });

  const currentProviderName = currentProvider.name;

  const existingCampusTeam: EditCampusTeamMember[] = useMemo(
    () =>
      (data?.adminUser.campusTeam ?? []).map(c => ({
        ...c.provider,
        relationshipType: c.relationshipType,
      })),
    [data]
  );
  const searchOptions: EditCampusTeamMember[] = useMemo(
    () =>
      (data?.organization.campusTeamOptions ?? []).filter(
        p => !existingCampusTeam.some(c => c.id === p.id)
      ),
    [data, existingCampusTeam]
  );

  const [campusTeam, setCampusTeam] = useState<EditCampusTeamMember[]>([]);

  useEffect(() => {
    setCampusTeam(existingCampusTeam);
  }, [existingCampusTeam, setCampusTeam]);

  const [error, setError] = useState<string>();
  const [modalOpenIdx, setModalOpenIdx] = useState<number | null>(null);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const undefinedRolesExist = campusTeam.some(teamMember => !teamMember.relationshipType);
  const campusTeamDifference = campusTeam !== existingCampusTeam;

  useEffect(() => {
    if (!campusTeamDifference || undefinedRolesExist) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [campusTeamDifference, undefinedRolesExist, setDisabledSave]);

  const [editUser] = useAdminEditUserMutation({
    onCompleted: editUserData =>
      track('user.info.edited', { userId: editUserData.adminEditUser.id, careTeamInfo: true }),
  });

  const submit = async () => {
    const missingRelationshipType = campusTeam.find(c => !c.relationshipType);

    if (missingRelationshipType) {
      setError(`Missing relationship type for: ${missingRelationshipType.name}`);
      return;
    }
    try {
      await editUser({
        variables: {
          editUser: {
            id: user.id,
            campusTeam: campusTeam.map(c => ({
              providerId: c.id,
              relationshipType: c.relationshipType!,
            })),
          },
        },
      });
      closeModal();
      refetch();
    } catch (e) {
      setError('Internal error');
    }
    closeModal();
  };

  const closeModalButton = () => {
    // check differece between existingCareTeam and people in campusTeamState
    // check if any person doesn't have a relationshipType
    if (campusTeamDifference || undefinedRolesExist) {
      setUnsavedModalOpen(true);
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen
      onClose={closeModalButton}
      size="campusTeam"
      // overflow:'visible' allows dropdown content to appear outside the modal
      style={{ content: { overflow: 'visible' } }}
    >
      {error && <Notification kind="negative">{error}</Notification>}
      <ModalContainer>
        <Text.h2 className="mb4">
          Edit Campus Team for {user.firstName} {user.lastName}
        </Text.h2>
        <SearchAndAdd
          selectedValues={campusTeam}
          searchOptions={searchOptions.map(c => ({ data: c, label: c.name }))}
          onAddValue={v => setCampusTeam(existing => [...existing, v])}
          onEditValue={v => setCampusTeam(existing => existing.map(e => (e.id === v.id ? v : e)))}
          onRemoveValue={(_, idx) => setModalOpenIdx(idx)}
          component={CampusTeamMember}
        />
        <RemoveCampusTeamMemberModal
          campusTeam={campusTeam}
          modalOpenIdx={modalOpenIdx}
          isOpen={typeof modalOpenIdx === 'number'}
          onClose={() => setModalOpenIdx(null)}
          removeCampusTeamMember={(_, idx) =>
            setCampusTeam(existing => existing.filter((__, i) => i !== idx))
          }
          currentProviderName={currentProviderName}
        />
        {unsavedModalOpen && (
          <UnsavedCampusTeamMemberModal
            onClose={() => setUnsavedModalOpen(false)}
            isOpen={unsavedModalOpen}
            removeUnsavedMembers={() => {
              setCampusTeam(existingCampusTeam);
              setUnsavedModalOpen(false);
            }}
          />
        )}
        <FinalButton
          type="submit"
          className="w-100 mt3"
          kind="primary"
          onClick={submit}
          disabled={disabledSave}
        >
          Save Changes
        </FinalButton>
        <ModalClose className="b" onClick={closeModalButton}>
          Close
        </ModalClose>
      </ModalContainer>
    </Modal>
  );
};

const ModalClose = styled(ModalLink)`
  padding-top: 20px;
`;

const ModalContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const EditCareTeamInfoV1 = ({ closeModal }: EditPatientInfoProps) => {
  const { track } = useEvents();
  const { user, refetch } = useDrilldownContext();
  const formContext = useForm({
    defaultValues: pick(
      pickDefaultValues(user),
      'universityCareTeamPrimary',
      'universityCareTeamSecondary',
      'mantraAdminCareTeam'
    ),
  });

  const [editUser, { loading: mutationLoading }] = useAdminEditUserMutation({
    onCompleted: editUserData =>
      track('user.info.edited', { userId: editUserData.adminEditUser.id, careTeamInfo: true }),
  });

  const submit = formContext.handleSubmit(async values => {
    await editUser({ variables: { editUser: { id: user.id, ...values } } });
    closeModal();
    refetch();
  });

  return (
    <Modal
      isOpen
      onClose={closeModal}
      size="tiny"
      // overflow:'visible' allows dropdown content to appear outside the modal
      style={{ content: { overflow: 'visible' } }}
    >
      <EditModalContainer>
        <Text.h2 className="mb4">Edit Collaboration Team</Text.h2>

        <FormProvider {...formContext}>
          <form onSubmit={submit}>
            <CareTeamFields />

            <FinalButton
              type="submit"
              className="w-100 mt3"
              kind="primary"
              loading={mutationLoading}
            >
              Save Changes
            </FinalButton>
          </form>
        </FormProvider>
      </EditModalContainer>
    </Modal>
  );
};

export const CareTeamFields = () => {
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const formContext = useFormContext();

  const userOrganization = Number(user.organization?.id);
  const { data: orgData, loading } = useOrganizationProvidersQuery({
    variables: { id: userOrganization, withMantraAdmins: isMantraAdmin(currentProvider) },
  });

  if (loading || !orgData) return null;
  const { organization } = orgData;

  let options = [
    ...organization.providers.map(provider => ({
      name: provider.name,
      id: provider.id,
      role: provider.role,
    })),
  ];

  if (organization.children && organization.children.length > 0) {
    const childrenProviders = organization.children
      .find(o => o.id === userOrganization)
      ?.providers.map(provider => ({
        name: provider.name,
        id: provider.id,
        role: provider.role,
      }));
    if (childrenProviders) {
      options = [...options, ...childrenProviders];
    }
  } else if (organization.parent?.providers) {
    const parentProviders = organization.parent.providers.map(provider => ({
      name: provider.name,
      id: provider.id,
      role: provider.role,
    }));
    options = [...options, ...parentProviders];
  }

  const providerOptions = options
    .filter(({ role }) => isMcpRole(role))
    .map(({ id, name }) => ({ label: name, id }));

  return (
    <>
      <SelectRHF
        name="universityCareTeamPrimary"
        clearable
        placeholder="None"
        options={providerOptions}
        controlProps={{
          className: 'mb4',
          label: (
            <>
              <Styles.FieldName htmlFor="universityCareTeamPrimary">
                Student’s Primary Collaborator
              </Styles.FieldName>
              <Styles.FieldHint>
                Therapist/counselor or main staff member responsible for this student’s care
              </Styles.FieldHint>
            </>
          ),
        }}
      />
      <MultiSelectRHF
        name="universityCareTeamSecondary"
        options={providerOptions}
        rules={{
          validate: (v?: number[]) => {
            if (!v) return true;
            if (v.includes(formContext.getValues('universityCareTeamPrimary')))
              return 'primary collaborator may not also be on the secondary collaboration team';
            return true;
          },
        }}
        controlProps={{
          label: (
            <>
              <Styles.FieldName htmlFor="universityCareTeamSecondary">
                Additional Staff
              </Styles.FieldName>
              <Styles.FieldHint>
                Other staff members at the university that work directly with this student
              </Styles.FieldHint>
            </>
          ),
        }}
      />
      {isMantraAdmin(currentProvider) && (
        <MultiSelectRHF
          name="mantraAdminCareTeam"
          options={orgData.mantraAdmins?.map(c => ({ id: c.id, label: c.name })) ?? []}
          controlProps={{
            className: isMantraAdmin(currentProvider) ? undefined : 'dn',
            label: (
              <>
                <Styles.FieldName htmlFor="mantraAdminCareTeam">
                  Other Mantra Collaborators
                </Styles.FieldName>
                <Styles.FieldHint>
                  Mantra Admin users who collaborate on this patient with other care team members.
                </Styles.FieldHint>
              </>
            ),
          }}
        />
      )}
    </>
  );
};
