import { pick } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  DetailedAvailability,
  SelectAvailabilityFn,
} from '../../Components/ProviderNetwork/Availability/DetailedAvailability';
import { SimpleAvailability } from '../../Components/ProviderNetwork/Availability/SimpleAvailability';
import { useDefaultProviderOrgParam } from '../../Components/ProviderNetwork/hooks/useDefaultParams';
import {
  ProviderNetworkContextProvider,
  useProviderNetworkContext,
} from '../../Components/ProviderNetwork/ProviderNetworkContext';
import { ProviderNetworkShell } from '../../Components/ProviderNetwork/ProviderNetworkShell';
import { ProvidersNotFound } from '../../Components/ProviderNetwork/ProvidersNotFound';
import {
  ProviderNetworkRow,
  RenderAvailability,
} from '../../Components/ProviderNetwork/ProviderNetworkRow';
import { AppointmentOption } from '../../Components/ProviderNetwork/types';
import { SelfPayEnrollModal } from './EnrollModal/SelfPayEnrollModal';
import {
  BookingSuccess,
  EnrollmentSuccess,
  SponsoredCareModal,
} from './EnrollModal/SponsoredCareModal';
import { useMoveToFirstProviderNetworkAvailability } from '../../Hooks/useMoveToFirstProviderNetworkAvailability';
import { nextAvailableIntake } from '../Booking/ProviderAvailabilityList';

export const ProviderNetwork = () => {
  const organizationId = useDefaultProviderOrgParam();

  const [enrollmentSuccess, setEnrollmentSuccess] = useState<BookingSuccess | null>(null);
  const [sponsoredEnrollModal, setSponsoredEnrollModal] = useState<AppointmentOption | null>(null);
  const [selfPayEnrollModal, setSelfPayEnrollModal] = useState(false);

  if (enrollmentSuccess) {
    return <EnrollmentSuccess {...enrollmentSuccess} />;
  }

  return (
    /*
      the provider network is used for either referrals or initial booking
      therefore all appointment types are "intake"
    */
    <ProviderNetworkContextProvider
      searchBy={{ organizationId, appointmentType: 'intake' }}
      withDateRange
    >
      {selfPayEnrollModal && <SelfPayEnrollModal onClose={() => setSelfPayEnrollModal(false)} />}
      {sponsoredEnrollModal && (
        <SponsoredCareModal
          appointment={sponsoredEnrollModal}
          onClose={() => setSponsoredEnrollModal(null)}
          onComplete={v => setEnrollmentSuccess(v)}
        />
      )}
      <ProviderNetworkShell withSearch onSelectProviders={() => setSelfPayEnrollModal(true)}>
        <Body onSelectAvailability={setSponsoredEnrollModal} />
      </ProviderNetworkShell>
    </ProviderNetworkContextProvider>
  );
};

type BodyProps = {
  onSelectAvailability?: SelectAvailabilityFn;
};

const Body = ({ onSelectAvailability }: BodyProps) => {
  const { providers, isSelfPayFlow, days, startDate, dedicatedGroupModelActive, searchVariables } =
    useProviderNetworkContext();

  useMoveToFirstProviderNetworkAvailability();

  const ProviderAvailability: RenderAvailability = useMemo(() => {
    if (isSelfPayFlow) return SimpleAvailability;
    return props => {
      return (
        <DetailedAvailability
          provider={pick(props.provider, ['id', 'name', 'careTypes'])}
          nextAvailability={
            // upcomingIntakeAvailability has the appointment times sorted on the backend.
            props.provider.upcomingIntakeAvailability.filter(a =>
              moment(a.start).isAfter(startDate)
            )[0]
          }
          patientState={searchVariables.state || undefined}
          onSelectAvailability={onSelectAvailability}
        />
      );
    };
  }, [days, isSelfPayFlow, onSelectAvailability]);

  const nextAvailableProviderDate = nextAvailableIntake(providers, dedicatedGroupModelActive, days);

  return (
    <>
      {providers.map(p => (
        <ProviderNetworkRow
          key={p.id}
          provider={p}
          renderAvailability={ProviderAvailability}
          gridSmall={isSelfPayFlow}
          canSelectProviders={isSelfPayFlow}
          // w self pay flow
        />
      ))}
      {providers.length <= 0 && (
        <ProvidersNotFound nextAvailableProviderDate={nextAvailableProviderDate} clearGeoState />
      )}
    </>
  );
};
