import { toaster } from 'baseui/toast';
import { get } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { useCurrentProvider } from '../../Components/Permissions';
import { ColorLookup, colors, Text } from '../../globalStyles';
import { AppView, CurrentProviderQuery, useForgotProviderPasswordMutation } from '../../graphQL';
import { formatPhoneNumber } from '../../utils';
import { getTerms } from '../Terms/utils';
import { OzProfile } from './OzProfile';
import { SettingsPageEditProfileModal } from './SettingsPageEditProfileModal';

export const ProfileTab = () => {
  const { currentProvider, appView } = useCurrentProvider();
  return (
    <div className="dib">
      {appView === 'mcp' && <McpProfile />}
      {appView === 'referral' && <ReferralProfile />}
      {appView === 'oz' && <OzProfile providerId={currentProvider.id} />}
    </div>
  );
};

function McpProfile() {
  const { currentProvider: provider, refetch } = useCurrentProvider();
  return (
    <>
      <BasicInfoHeader />
      <EditProfile provider={provider} refetch={refetch} />
      <Terms text="Terms of Use for University Providers" appView="mcp" />
    </>
  );
}

function ReferralProfile() {
  const { currentProvider: provider } = useCurrentProvider();
  return (
    <>
      <BasicInfoHeader />
      <ResetPasswordLink email={provider.email} />
      <Terms
        text="Terms of Use for University Referrers"
        appView="referral"
        kind="black"
        withBreak
      />
    </>
  );
}

function BasicInfoHeader() {
  const { currentProvider: provider } = useCurrentProvider();
  return (
    <>
      <PrimaryInfo>
        {provider.portrait?.url && (
          <Headshot src={provider.portrait?.url} alt="Picture of provider" />
        )}
        <div className="info">
          <Text.h3>{provider.name}</Text.h3>
          <Text.body>{provider.classification}</Text.body>
          <Text.bodyGrey>{provider.pronouns}</Text.bodyGrey>
        </div>
      </PrimaryInfo>
      {(provider.email || provider.phone) && (
        <InfoSection
          title="Contact"
          info={[provider.email, provider.phone ? formatPhoneNumber(provider.phone) : '']}
        />
      )}
    </>
  );
}

function EditProfile({
  provider,
  refetch,
}: {
  provider: CurrentProviderQuery['currentProvider'];
  refetch: () => void;
}) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <div className="mv4">
        <FinalButton
          kind="outline_black"
          iconLeft={{ icon: 'iconsBlackEditSvg', size: 20, className: 'mr2' }}
          onClick={() => setEditModalOpen(true)}
        >
          Edit Profile
        </FinalButton>
      </div>
      {editModalOpen && (
        <SettingsPageEditProfileModal
          provider={provider}
          onClose={() => setEditModalOpen(false)}
          refetch={refetch}
        />
      )}
    </>
  );
}

function ResetPasswordLink({ email }: { email: string }) {
  const [reset] = useForgotProviderPasswordMutation({ variables: { email } });
  const clickReset = async () => {
    try {
      await reset();
      toaster.positive('Reset email sent.', {});
    } catch (error) {
      toaster.negative('Unable to reset password, please try again.', {});
    }
  };
  return (
    <InfoContainer>
      <Text.label>RESET YOUR PASSWORD</Text.label>
      <Text.linkButton onClick={clickReset}>Reset password</Text.linkButton>
    </InfoContainer>
  );
}

function InfoSection({ title, info }: { title: string; info: (string | undefined)[] }) {
  return (
    <InfoContainer>
      <Text.label>{title}</Text.label>
      {info.map(i => (
        <Text.body key={i}>{i}</Text.body>
      ))}
    </InfoContainer>
  );
}

function Terms({
  text,
  appView,
  kind,
  withBreak,
}: {
  text: string;
  appView: AppView;
  kind?: ColorLookup;
  withBreak?: boolean;
}) {
  const termsLink = getTerms(appView);
  if (!termsLink) return null;
  return (
    <>
      {withBreak && <div className="bb mt5 mb4" style={{ color: colors.grey.border }} />}
      <Link href={getTerms('referral') ?? ''} target="_blank" kind={kind}>
        {text}
      </Link>
    </>
  );
}

const Link = styled.a<{ kind?: ColorLookup }>`
  border: none;
  background: none;
  font-family: ${Text.HELVETICA};
  font-size: 14px;
  padding: 0px;
  text-decoration: none;
  color: ${({ kind }) => (kind ? get(colors, kind) : colors.primary)};
`;

const InfoContainer = styled.div`
  margin-top: 40px;
`;

const Headshot = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 33px;
`;

const PrimaryInfo = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
`;
