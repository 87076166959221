import { useApolloClient } from '@apollo/client';
import { chain, lowerCase } from 'lodash';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CampusTeamActivity } from '../../Components/CampusTeam/CampusTeamActivity';
import { FinalButton } from '../../Components/FinalButton';
import { AsyncListView } from '../../Components/ListView/AsyncListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import {
  FeatureFlagged,
  isMcpUser,
  PermsOnly,
  useCurrentProvider,
} from '../../Components/Permissions';
import { PaddedPage, Text } from '../../globalStyles';
import {
  Entitlement,
  Permission,
  Role,
  SortDirection,
  StudentsDocument,
  StudentsQuery,
  StudentsQueryVariables,
  useStudentListOptionsQuery,
} from '../../graphQL';
import {
  compareLastNames,
  formatMantraProviders,
  getFullNameReversed,
} from '../../modelUtils/users';
import { taskStatusOptions } from '../UserList/Shared';
import { ReferrerList } from './ReferrerList';

export function StudentsPage() {
  const { hasPermission } = useCurrentProvider();

  if (hasPermission(Permission.PatientList)) return <DefaultView />;
  return <ReferrerList />;
}

function DefaultView() {
  const history = useHistory();
  const params = useParams() as Record<string, string>;
  const { currentProvider, featureFlags } = useCurrentProvider();
  const organizationId = Number(params.organizationId);
  const { data: optData } = useStudentListOptionsQuery({ variables: { organizationId } });
  const apolloClient = useApolloClient();

  const loadData = useCallback(
    async (uiState, prevPage) => {
      const { data } = await apolloClient.query<StudentsQuery, StudentsQueryVariables>({
        query: StudentsDocument,
        variables: {
          params: {
            dtc: false,
            cursor: prevPage?.cursor,
            search: uiState.debouncedSearchValue,
            organizationId: uiState.filterValues?.organizationId ?? organizationId,
            careTeamMemberId: uiState.filterValues?.careTeam ?? uiState.filterValues?.campusTeam,
            taskStatus: uiState.filterValues?.taskStatus,
            sortField: uiState.sortColumn,
            sortDirection:
              uiState.sortDirection === 'desc' ? SortDirection.Descending : SortDirection.Ascending,
            limit: uiState.limit,
            includeAllPatients: true,
          },
        },
      });
      if (!data) {
        throw new Error('Could not find student data');
      }
      return data.paginatedUsers;
    },
    [apolloClient]
  );

  if (!optData) {
    return <LoadingPage />;
  }

  const isSuperOrg = optData.organization.children.length > 0;
  const isUniSupportingClinician = currentProvider.role === Role.UniversitySupportingClinician;

  const showIdentification = optData.organization.entitlements.some(
    i => i.key === Entitlement.OrgIdentification
  );

  const isCTCEnhancedCollabActive = featureFlags.includes('CTC_ENHANCED_COLLABORATION');

  return (
    <StudentsContainerGrid
      className={isCTCEnhancedCollabActive ? undefined : 'feature-flag-inactive'}
    >
      <PaddedPage className="relative">
        <UniversityLogo />
        <div className="flex mb4">
          <Text.h1>Students</Text.h1>
          <PermsOnly allowed="patientCreate">
            <div className="ml3">
              <FinalButton
                pill
                iconLeft="iconsWhitePlusSvg"
                onClick={() => history.push(`/organizations/${organizationId}/students/enroll`)}
                data-cy="addStudentButton"
              >
                Add
              </FinalButton>
            </div>
          </PermsOnly>
        </div>
        <AsyncListView
          fetchData={loadData}
          hideTopControls
          analyticsName="students-page-list"
          pageSize={10}
          initialSortColumn="name"
          initialSortDirection="asc"
          search={(user, query) => {
            const q = query.toLowerCase();
            return (
              user.firstName.toLowerCase().includes(q) || user.lastName.toLowerCase().includes(q)
            );
          }}
          columns={[
            {
              key: 'name',
              title: 'Name',
              render: user => (
                <div>
                  <Text.bodyBold data-cy="name" className="ttc">
                    {getFullNameReversed(user)}
                  </Text.bodyBold>
                  {showIdentification && (
                    <Text.captionGrey>{user.organizationIdentification ?? 'n/a'}</Text.captionGrey>
                  )}
                  {!showIdentification && (
                    <Text.captionGrey>{lowerCase(user.careTypes.join(', '))}</Text.captionGrey>
                  )}
                </div>
              ),
              sort: compareLastNames,
            },
            {
              key: 'email',
              title: 'Email',
              render: user =>
                user.email ? (
                  <Text.body data-cy="email">{user.email}</Text.body>
                ) : (
                  <Text.body kind="grayText" className="i">
                    hidden
                  </Text.body>
                ),

              sort: (a, b) =>
                a.email?.toLowerCase().localeCompare(b.email?.toLowerCase() ?? '') ?? -1,
              hidden: isCTCEnhancedCollabActive,
            },
            {
              key: 'provider',
              title: 'Mantra Providers',
              render: formatMantraProviders,
              hidden: isSuperOrg || isCTCEnhancedCollabActive,
            },
            {
              key: 'campus',
              title: 'Campus',
              render: user => user.organization?.name ?? 'None',
              hidden: !isSuperOrg || isCTCEnhancedCollabActive,
            },
            {
              key: 'careTeam',
              title: 'University Collaboration Team',
              render: user =>
                user.universityCareTeam.map(provider => provider.name).join(', ') || 'None',
              hidden: isCTCEnhancedCollabActive,
            },
            {
              key: 'campusTeam',
              title: 'Campus Team',
              render: user =>
                user.campusTeam && user.campusTeam.length
                  ? user.campusTeam.map(c => c.provider.name).join(', ')
                  : 'None',
              hidden: !isCTCEnhancedCollabActive,
            },
            {
              key: 'taskStatus',
              title: 'Mantra Status',
              render: user =>
                user.taskStatus || (
                  <Text.body kind="grayText" className="i">
                    hidden
                  </Text.body>
                ),
              sort: (a, b) => (a.taskStatus ?? '').localeCompare(b.taskStatus ?? ''),
            },
          ]}
          getKey={user => user.id}
          link={user => `/users/${user.id}`}
          filters={[
            {
              key: 'organizationId',
              placeholder: 'Campus',
              options: optData.organization.children
                .map(i => ({ label: i.name, id: i.id }))
                .sort((a, b) => a.label.localeCompare(b.label)),
              hidden: !isSuperOrg,
            },
            {
              key: 'careTeam',
              placeholder: 'University Collaboration Team',
              options: optData.providers
                .filter(isMcpUser)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(i => ({ id: i.id, label: i.name })),
              hidden: isCTCEnhancedCollabActive,
            },
            {
              key: 'campusTeam',
              placeholder: 'Campus Team',
              options: chain(optData.organizationCampusTeam ?? [])
                .map(({ provider }) => provider)
                .uniqBy(provider => provider.id)
                .map(provider => ({
                  id: provider.id,
                  label: provider.name,
                }))
                .value(),
              hidden: !isCTCEnhancedCollabActive || isUniSupportingClinician,
            },
            {
              key: 'taskStatus',
              placeholder: 'Mantra Status',
              options: taskStatusOptions,
            },
          ]}
        />
      </PaddedPage>
      <FeatureFlagged flag="CTC_ENHANCED_COLLABORATION">
        <CampusTeamActivityContainer>
          <CampusTeamActivity />
        </CampusTeamActivityContainer>
      </FeatureFlagged>
    </StudentsContainerGrid>
  );
}

const StudentsContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 30rem;

  &.feature-flag-inactive {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 60rem) {
    grid-template-columns: 1fr;
    & > div:first-of-type {
      margin-bottom: 2rem;
    }
  }
`;

const CampusTeamActivityContainer = styled.div`
  background: white;
  padding: 3rem 2.5rem;
  height: 100%;
`;
