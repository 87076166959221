import moment from 'moment';
import React, { useState } from 'react';
import {
  AppointmentControls,
  useCanCancelAppointment,
} from '../../../Components/Appointment/AppointmentControls';
import { JoinAppointmentButton } from '../../../Components/Appointment/JoinAppointmentButton';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { colors, Text } from '../../../globalStyles';
import {
  AdminAppointmentsQuery,
  Permission,
  useAdminAppointmentsQuery,
  UserRecordViewStatus,
} from '../../../graphQL';
import { cx } from '../../../utils';
import { getUpcomingAppts, Restricted, useDrilldownContext } from '../helpers';
import { Styles } from '../styles';

interface Props {
  appointment: AdminAppointmentsQuery['adminAppointments'][number];
  refetch: () => void;
}

function UpcomingAppointmentWidget({ appointment, refetch }: Props) {
  const { appView } = useCurrentProvider();
  const {
    user,
    refetch: userRefetch,
    userView: { recordViewStatus },
  } = useDrilldownContext();
  const [expanded, setExpanded] = useState(false);

  const canExpand = appView !== 'mcp' || recordViewStatus !== UserRecordViewStatus.Restricted;
  const behavior = useCanCancelAppointment(appointment)
    ? {
        role: 'button',
        onClick: () => setExpanded(e => !e),
        className: cx({ pointer: canExpand }),
      }
    : {};

  return (
    <Styles.widget color={colors.primary} className="flex">
      <Icon icon="iconsBlackApptSvg" alt="Appointment" style={{ margin: '1em 0.5em auto 0' }} />
      <div style={{ flex: 1 }}>
        {/* eslint-disable-next-line */}
        <div className={behavior.className} role={behavior.role} onClick={behavior.onClick}>
          <Text.bodyBold>{moment(appointment.startTime).format('M/D/YYYY h:mm a z')}</Text.bodyBold>
          <Text.body>
            {appointment.description || 'Appointment'} with{' '}
            {appointment.provider?.name ?? 'Unknown'}
          </Text.body>
        </div>
        <Restricted>
          {behavior.role && expanded && (
            <div className="flex flex-row mt4 nl3 justify-between items-end">
              <AppointmentControls
                appointment={appointment}
                refetch={() => {
                  refetch();
                  userRefetch();
                }}
              />
              <PermsOnly allowed={Permission.PatientVideo}>
                <div className="nr2">
                  <JoinAppointmentButton
                    appointmentId={appointment.id}
                    providerId={appointment.provider.id}
                    userId={user.id}
                  >
                    Join Appointment
                  </JoinAppointmentButton>
                </div>
              </PermsOnly>
            </div>
          )}
        </Restricted>
      </div>

      {behavior.role && (
        <div>
          <FinalButton
            kind="minimal_gray"
            onClick={() => setExpanded(val => !val)}
            data-testid="expand-appointment-button"
            iconLeft={
              canExpand
                ? { icon: expanded ? 'iconsUpChevronSvg' : 'iconsDownChevronSvg', size: 18 }
                : undefined
            }
          />
        </div>
      )}
    </Styles.widget>
  );
}

export const UpcomingAppointmentWidgets = () => {
  const { user } = useDrilldownContext();
  const { data, refetch } = useAdminAppointmentsQuery({ variables: { userId: user.id } });

  const upcomingAppointments = getUpcomingAppts(data?.adminAppointments ?? []);

  return (
    <>
      {upcomingAppointments.map(appt => (
        <UpcomingAppointmentWidget key={appt.id} appointment={appt} refetch={refetch} />
      ))}
    </>
  );
};
