import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { cx } from '../../utils';

export type FormControlProps = {
  name: string;
  eyebrow?: string;
  label?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  caption?: string;
  className?: string;
  unwrap?: boolean; // return children instead of control
  required?: boolean;
  error?: boolean;
  errorMsg?: string;
  children?: React.ReactNode;
  labelSize?: 'f6' | 'f5' | 'f4';
};
export const FormControl: React.FC<FormControlProps> = ({
  children,
  name,
  className = 'mb3',
  labelSize = 'f5',
  eyebrow,
  label,
  subtitle,
  caption,
  unwrap,
  required,
  error,
  errorMsg,
}) => {
  if (unwrap) return <>{children}</>;

  const renderLabel =
    typeof label === 'string' ? (
      <ControlLabel htmlFor={name} className={cx(labelSize)}>
        {label} {required && <span style={{ color: colors.danger }}>*</span>}
      </ControlLabel>
    ) : (
      label
    );

  const renderSubtitle =
    typeof subtitle === 'string' ? (
      <Text.bodySmall className="mt2">{subtitle}</Text.bodySmall>
    ) : (
      subtitle
    );

  return (
    <div className={className}>
      {eyebrow && <Text.label className="mb2">{eyebrow}</Text.label>}
      {renderLabel}
      {subtitle && renderSubtitle}
      {children}
      {caption && (
        <Text.bodySmall className="f6 db tl mt1" kind={error ? 'danger' : 'grayText'}>
          {errorMsg ?? caption}
        </Text.bodySmall>
      )}
    </div>
  );
};

export const ControlLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: ${colors.black};
`;
export const ControlHint = styled.p`
  color: ${colors.grey.dark};
  font-size: 12px;
  margin-top: -0.5em;
  margin-bottom: 0.25rem;
`;
