import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { useIsBookingV2 } from '../../Components/ProviderNetwork/providerNetworkUtils';
import { ActiveWeek } from '../../Components/Scheduler/ActiveWeek';
import { CalendarRangePicker } from '../../Components/Scheduler/CalendarRangePicker';
import { PaddedPage } from '../../globalStyles';
import { Permission, useAdminAppointmentsQuery } from '../../graphQL';
import { getWeek, guessTz, TimeZone } from '../../utils';
import { PageTitle, UnexpectedError } from '../Shared';
import { AppointmentList } from './AppointmentList';
import * as Styles from './styles';
import { Appointment } from './types';

export function AppointmentsPage() {
  const isBookingV2 = useIsBookingV2();
  const [startDate, setStartDate] = useState(moment().startOf('week'));
  const [timezone, setTimezone] = useState<TimeZone>(guessTz());
  const history = useHistory();
  const { hasPermission } = useCurrentProvider();
  const { data, loading, refetch, error } = useAdminAppointmentsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      allProviders: hasPermission(Permission.MantraAdmin),
      startTime: startDate.clone().startOf('week').toDate(),
      endTime: startDate.clone().endOf('week').toDate(),
    },
  });

  const now = moment();
  const appointments: Appointment[] = useMemo(
    () =>
      data
        ? data.adminAppointments
            .filter(
              appointment =>
                ['upcoming', 'complete', 'no show'].includes(appointment.status) ||
                appointment.lateCancelOrReschedule
            )
            .map(i => ({
              ...i,
              startMoment: moment(i.startTime).tz(timezone),
              endMoment: moment(i.endTime).tz(timezone),
            }))
        : [],
    [data, timezone]
  );

  const datesWithAppointments = useMemo(() => appointments.map(i => i.startMoment), [appointments]);

  const handleAdvanceWeek = (delta: number) =>
    setStartDate(startDate.clone().startOf('week').add(delta, 'weeks'));

  return (
    <PaddedPage>
      <Styles.HeaderRow>
        <PageTitle>Appointments</PageTitle>
        {!isBookingV2 && (
          <FinalButton
            iconLeft="iconsWhitePlusSvg"
            kind="primary"
            pill
            onClick={() => history.push('/book')}
          >
            New Appointment
          </FinalButton>
        )}
      </Styles.HeaderRow>
      <Styles.BodyWrapper>
        <CalendarRangePicker
          startDate={startDate}
          onChangeStartDate={setStartDate}
          monthButtonAlignment="left"
          circledDate={now}
          leftMonthOffset={-1}
        />
        <ActiveWeek
          startDate={startDate.clone().startOf('week')}
          onChangeWeek={delta => handleAdvanceWeek(delta)}
          activeDays={getWeek(startDate, { timezone, startOfWeek: true })
            .filter(i => i.isSameOrAfter(now, 'date'))
            .map(i => i.day())}
          highlightedDate={now}
          dottedDates={datesWithAppointments}
          timezone={timezone}
        />
      </Styles.BodyWrapper>
      <Styles.Divider />
      <Styles.BodyWrapper>
        {loading && <LoadingPage />}
        {error && <UnexpectedError />}
        {data && !loading && (
          <AppointmentList
            appointments={appointments}
            startDate={startDate}
            endDate={startDate.clone().endOf('week')}
            onAdvanceWeek={() => handleAdvanceWeek(1)}
            timeZone={timezone}
            onChangeTimeZone={setTimezone}
            refetch={refetch}
          />
        )}
      </Styles.BodyWrapper>
    </PaddedPage>
  );
}
