import React from 'react';
import styled from 'styled-components';
import { InlineSVG } from '../../../Components/Icons';
import { colors, Text } from '../../../globalStyles';

type PaymentWarningProps = {
  title: string;
  details?: string | JSX.Element;
};

export const PaymentWarning = ({ title, details }: PaymentWarningProps) => (
  <PaymentWarningContainer className="mt4">
    <div className="flex flex-row gap-2">
      <InlineSVG
        className="flex-shrink-0 mt1"
        icon="alert-circle"
        width={20}
        height={20}
        kind="orange"
      />
      <div>
        <Text.bodyBold kind="orange">{title}</Text.bodyBold>
        {details && <Text.body>{details}</Text.body>}
      </div>
    </div>
  </PaymentWarningContainer>
);

const PaymentWarningContainer = styled.div`
  background: ${colors.orangeBg};
  border-radius: 0.25rem;
  padding: 1rem;
`;
