import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { useVideoContextV2 } from '../../Components/Video/VideoProvider';
import { Flag } from '../../featureFlags';
import { colorMap, Text } from '../../globalStyles';
import { VideoCallDataFragment } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { cx } from '../../utils';
import { ConfirmLikenessDialog } from './ConfirmLikenessDialog';
import { NoShowButton } from './NoShowButton';
import { VideoCallControls } from './VideoCallControls';

export type VideoCallPageProps = {
  appointment: VideoCallDataFragment;
  providerEndCall: () => void;
};

export function VideoCallPage({ appointment, providerEndCall }: VideoCallPageProps) {
  if (process.env.REACT_APP_OPENTOK_API_KEY === undefined) {
    throw new Error('Opentok API key is undefined.');
  }

  const { featureFlags } = useCurrentProvider();

  const context = useVideoContextV2();
  const { isSessionConnected, showingPopover, isUserConnected, isLikenessConfirmed } = context;

  // Initialize session when page loads
  useEffect(() => {
    if (isSessionConnected) context.setState({ showingPopover: false });
    else context.joinCall(appointment);
  }, []);

  // Disconnect when the page unmounts. The ref is an annoying workaround for the
  // lack of a proper "onComponentUnmount" in hooks. Without the ref, if we have
  // an empty dependency array in the function with the callback, it may use a stale
  // callback, but if we include the context in the dependency array, it will
  // disconnect us anytime the context changes, not just on unmount.
  const contextRef = React.useRef(context);
  useEffect(() => {
    contextRef.current = context;
  }, [context]);
  useEffect(() => {
    return () => {
      if (contextRef.current.showingPopover) return;
      contextRef.current.disconnect();
    };
  }, []);

  if (!isSessionConnected) return <LoadingPage />;

  return (
    <StyledVideoPage className={cx({ showingPopover, background: !isUserConnected })}>
      <WaitingText appointment={appointment} />
      <VideoCallControls appointment={appointment} endCall={providerEndCall} />
      <div className="video-call-bg" />
      {!featureFlags.includes(Flag.CscPartTwo) && !isLikenessConfirmed && (
        <ConfirmLikenessDialog
          disabled={!isUserConnected}
          setConfirmed={() => context.setState({ isLikenessConfirmed: true })}
          name={getFullName(appointment.user!)}
          selfieUrl={appointment.user.selfie?.url}
        />
      )}
    </StyledVideoPage>
  );
}

const getApptDuration = (apt: VideoCallPageProps['appointment']) =>
  moment(apt?.endTime).diff(apt?.startTime, 'minutes');

const WaitingText = ({ appointment }: Pick<VideoCallPageProps, 'appointment'>) => {
  const { isUserConnected, showingPopover } = useVideoContextV2();

  const fullName = getFullName(appointment.user);

  if (isUserConnected || showingPopover) return null;
  return (
    <div style={{ inset: 0 }} className="fixed flex flex-column items-center justify-center pb4">
      <div className="tc">
        {appointment ? (
          <div style={{ zIndex: 1 }}>
            <Text.h2 kind="primary">
              Waiting for
              <br />
              {fullName}.
            </Text.h2>
            <Text.body className="mb3">
              {`${getApptDuration(appointment)} min ${appointment.description}`}
              <br />
              {moment(appointment.startTime).format('dddd, MMMM D [at] h:mm a')}
            </Text.body>
            <NoShowButton
              appointmentId={appointment.id}
              fullName={fullName}
              providerId={appointment.provider.id}
            />
          </div>
        ) : (
          <Text.body>{fullName} has no appointment scheduled.</Text.body>
        )}
      </div>
    </div>
  );
};

const StyledVideoPage = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: none;

  &.background {
    background: radial-gradient(
      circle,
      ${colorMap.primary[5]} 0%,
      ${colorMap.primary[4]} 60%,
      ${colorMap.primary[3]} 100%
    );
  }

  &.showingPopover {
    background: ${colorMap.primary[5]};
  }
`;
