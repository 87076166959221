import styled, { css } from 'styled-components';
import { colors } from '../../globalStyles';

export const ConfirmLikenessContainerV1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 27.5%;
  background: white;
  height: 20%;
  border-radius: 0 0 4px 0;
  display: flex;
  justify-content: 'flex-grow';
  max-width: 480px;
  min-width: 360px;
`;

export const ConfirmLikenessContainerV2 = styled.div`
  position: absolute;
  width: 27.5%;
  background: white;
  height: 20%;
  border-radius: 0 0 4px 0;
  display: flex;
  justify-content: 'flex-grow';
  max-width: 480px;
  min-width: 360px;
`;

export const waitingText = styled.h1`
  color: #192e62;
  font-size: 28px;
  font-weight: normal;
  line-height: 36px;
  margin: 0 auto 30px;
  max-width: 370px;
  padding: 0 25px;
  text-align: center;
`;

export const afterCall = styled.div`
  background: rgba(200, 208, 255, 1);
  background: linear-gradient(90deg, rgba(200, 208, 255, 0.2) 0%, rgba(217, 224, 243, 0.2) 100%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 8rem;

  div {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 20px;
      width: 100%;
    }
  }
`;

const StatusText = css`
  letter-spacing: 1.2px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const apptStatusText = {
  completed: styled.div`
    ${StatusText};
    color: #07cd6f;
  `,
  noShow: styled.div`
    ${StatusText};
    color: #f32920;
  `,
  rescheduled: styled.div`
    ${StatusText};
    color: ${colors.grey.unfocusedText};
  `,
  cancelled: styled.div`
    ${StatusText};
    color: ${colors.danger};
  `,
};
