import { Moment } from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';
import { Select } from '../../../../Components/Form';
import { DatePicker } from '../../../../Components/Form/DatePicker';
import { Text } from '../../../../globalStyles';
import { FriendlyAllocation } from '../../util';
import { LocalAllocation } from './Hooks/types';
import { ScheduleType } from './types';

type RepeatsDropdownProps = {
  scheduleType: ScheduleType;
  scheduleTypeFromRepeatModal: ScheduleType;
  date: Moment;
  blockAllocations: FriendlyAllocation[];
  localAllocations: LocalAllocation[];
  repeatsUntilDate: Date | null;
  onChangeScheduleType: (type: ScheduleType) => void;
  onChangeRepeatsUntil: (date: Date | null) => void;
};

export const RepeatsDropdown = ({
  scheduleType,
  scheduleTypeFromRepeatModal,
  date,
  blockAllocations,
  localAllocations,
  repeatsUntilDate,
  onChangeScheduleType,
  onChangeRepeatsUntil,
}: RepeatsDropdownProps) => {
  const scheduleTypeOptions = [
    {
      id: 'single',
      label: 'Does not repeat',
    },
    {
      id: 'recurring',
      label: `Weekly on ${date.format('dddd')}`,
    },
  ];

  // Dropdown disabled when recurring item is being modified
  const dropdownDisabled =
    ((scheduleTypeFromRepeatModal === 'single' && blockAllocations[0]?.weekly) ||
      scheduleTypeFromRepeatModal === 'recurring') &&
    !!blockAllocations.length;

  return (
    <AvailabilityRepeatRow>
      <Text.label style={{ whiteSpace: 'nowrap' }}>Repeat Schedule?</Text.label>
      <Select
        options={scheduleTypeOptions}
        value={scheduleType}
        onChange={type => onChangeScheduleType(type as ScheduleType)}
        clearable={false}
        style={{ width: 'auto', maxWidth: 220 }}
        disabled={dropdownDisabled}
      />
      {scheduleType === 'recurring' && (
        <>
          <Text.body>until</Text.body>
          <DatePicker
            value={repeatsUntilDate || localAllocations[0]?.repeatsUntil?.toDate()}
            onChange={d => onChangeRepeatsUntil(d)}
            iconLeft="iconsCalendarSvg"
            placeholder="Select end date"
            min={date}
            style={{
              width: 'auto',
              maxWidth: repeatsUntilDate || localAllocations[0]?.repeatsUntil ? 164 : 194,
            }}
            clearable={false}
          />
        </>
      )}
    </AvailabilityRepeatRow>
  );
};

const AvailabilityRepeatRow = styled.section`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
`;
