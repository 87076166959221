import styled from 'styled-components';
import { colors, PaddedPage, Text } from '../../globalStyles';

export const Background = styled.div`
  background: white;
  min-height: 100%;
`;

export const Wrapper = styled(PaddedPage)`
  max-width: 55rem;
`;

export const HeaderContainer = styled.div`
  margin: 2em 0 2.5rem 0;

  & > p {
    line-height: 1.25em;
  }
`;

// aliased h3 for consistency
export const Header = styled(Text.h3)``;

export const FormSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em 3em;
  margin-bottom: 3em;

  @media only screen and (max-width: 26rem) {
    grid-template-columns: 1fr;
  }
`;

export const FieldName = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const FieldHint = styled.div`
  color: ${colors.grey.dark};
  font-size: 12px;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
`;

export const AddField = styled.div`
  cursor: pointer;
  color: ${colors.primary};
  margin: 0.25em 0 0.5em;
  font-weight: bold;
`;

export const FinishedWrapper = styled.div`
  margin: auto;
  max-width: 368px;
  padding-top: 4em;
  padding-bottom: 2em;
`;

export const CheckIcon = styled.img`
  margin: auto;
  display: block;
  height: 30px;
  width: 30px;
`;
