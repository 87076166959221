import React from 'react';
import { FinalButton } from '../../../Components/FinalButton';
import { Text } from '../../../globalStyles';
import { VideoCallSidebarQuery } from '../../../graphQL';
import { withHttp } from '../../../utils';

type SafeOperatingHandbookProps = {
  data: VideoCallSidebarQuery;
};

export const SafeOperatingHandbookTab = ({ data }: SafeOperatingHandbookProps) => {
  const { safeOperatingHandbookUrl } = data.user.organization ?? {};
  return (
    <section>
      {safeOperatingHandbookUrl && (
        <>
          <FinalButton
            className="w-100 tl"
            iconLeft={{ icon: 'iconsBlackHandbookSvg', size: 20 }}
            kind="minimal_black"
            onClick={() => window.open(withHttp(safeOperatingHandbookUrl), '_blank')}
          >
            View Safe Operating Handbook*
          </FinalButton>
          <Text.body className="ml3">* Opens in a new tab</Text.body>
        </>
      )}
    </section>
  );
};
