import { CurrentProviderContext } from '../../Components/Permissions';
import { CurrentProviderAvailability } from './CurrentProviderAvailability';
import { NotificationsTab } from './NotificationsTab';
import { ProfileTab } from './ProfileTab';
import { FaqTab } from './FaqTab';

type Tab = {
  key: string;
  text: string;
  component: React.FC;
  condition: (user: CurrentProviderContext) => boolean;
};

export const settingsTabs: Tab[] = [
  {
    key: 'profile',
    text: 'Profile',
    component: ProfileTab,
    condition: () => true,
  },
  {
    key: 'notifications',
    text: 'Notifications',
    component: NotificationsTab,
    condition: (user: CurrentProviderContext) =>
      user.appView === 'mcp' && !user.featureFlags.includes('CTC_ENHANCED_COLLABORATION'),
  },
  {
    key: 'availability',
    text: 'Availability',
    component: CurrentProviderAvailability,
    condition: (user: CurrentProviderContext) => user.appView === 'oz',
  },
  {
    key: 'faq',
    text: 'FAQ',
    component: FaqTab,
    condition: (user: CurrentProviderContext) =>
      user.appView === 'mcp' || user.appView === 'referral',
  },
];
