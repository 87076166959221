import { isNil, times } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { Select } from '../../../../Components/Form';
import { TimeZone } from '../../../../utils';
import { LocalAllocation } from './Hooks/types';

// time options increment by this value
const TIME_INTERVAL = 15;

export type TimeType = 'startTime' | 'endTime';

type TimeSelectProps<T extends TimeType> = {
  timeType: T;
  timezone: TimeZone;
  forDate: Moment;
  allocation: LocalAllocation;
  onChange: (timeType: T, updatedMoment: Moment) => void;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
};

// Using JS Date, destroys the timezone
export function TimeSelect<T extends TimeType>({
  timeType,
  timezone,
  forDate,
  allocation,
  onChange,
  style,
  className,
  id,
}: TimeSelectProps<T>) {
  const selectionStartMoment = hasTimeType(allocation, timeType)
    ? allocation[timeType].clone().tz(timezone)
    : null;

  const selectionOptions = useMemo(() => {
    return selectionStartMoment
      ? getSelectionOptions(selectionStartMoment)
      : getSelectionOptions(getDateInTz(forDate, timezone));
  }, [selectionStartMoment, forDate, timezone]);

  const currentSelection = selectionStartMoment
    ? selectionOptions.findIndex(s => s.isSame(selectionStartMoment))
    : undefined;

  const convertBackToAllocTimezone = useCallback(
    (idx: number) => selectionOptions[idx].clone().tz(allocation.timezone),
    [selectionOptions, allocation.timezone]
  );

  return (
    <Select
      id={id}
      placeholder="Select"
      clearable={false}
      value={currentSelection}
      options={selectionOptions.map((s, i) => ({
        id: i,
        label: s.format('h:mm a'),
      }))}
      onChange={e => onChange(timeType, convertBackToAllocTimezone(e as number))}
      style={style}
      className={className}
    />
  );
}

const getDateInTz = (m: Moment, timezone: TimeZone) => {
  return moment.tz({ date: m.date(), month: m.month(), year: m.year() }, timezone).startOf('day');
};

const getSelectionOptions = (startTime: Moment) => {
  return times((24 * 60) / 15, i =>
    startTime
      .clone()
      .startOf('day')
      .add(i * TIME_INTERVAL, 'minutes')
  );
};

export const hasTimeType = <T extends TimeType>(
  allocation: LocalAllocation,
  timeType: T
): allocation is LocalAllocation & { [t in T]: Moment } => {
  return timeType in allocation && !isNil(allocation[timeType as keyof LocalAllocation]);
};
