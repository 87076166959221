import { ChevronLeft, ChevronRight } from 'baseui/icon';
import { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { Permission } from '../../graphQL';
import { buildWeeksGrid, guessTz, TimeZone } from '../../utils';
import { LoadingOverlay } from '../LoadingOverlay';
import { useCurrentProvider } from '../Permissions';
import { TimezonePicker } from '../TimezonePicker';
import * as Styles from './styles';

interface Props {
  initialMonth: Moment;
  renderCell: (date: Moment, tz: TimeZone, isInActiveMonth: boolean) => React.ReactNode;
  loading?: boolean;
  renderWeekSummary?: (week: (Moment | undefined)[], tz: TimeZone) => React.ReactNode;
  showLeadingAndTrailingDates?: boolean;
}

export function Calendar({
  initialMonth,
  renderCell,
  loading,
  renderWeekSummary,
  showLeadingAndTrailingDates = false,
}: Props) {
  const [timezone, setTimezone] = useState<TimeZone>(guessTz());
  const [month, setMonth] = useState(initialMonth);
  const { hasPermission } = useCurrentProvider();
  const showSummaryCell = hasPermission(Permission.MantraAdmin);
  const weeks = buildWeeksGrid(month, timezone, showLeadingAndTrailingDates);
  const handleMonthChanged = (delta: number) => setMonth(month.clone().add(delta, 'months'));
  return (
    <Styles.Wrapper>
      {loading && <LoadingOverlay spinnerSize={120} />}
      <Styles.Header>
        <Styles.ChangeMonthButton onClick={() => handleMonthChanged(-1)}>
          <ChevronLeft size={30} color="#555555" />
        </Styles.ChangeMonthButton>
        <Styles.ChangeMonthButton onClick={() => handleMonthChanged(1)}>
          <ChevronRight size={30} color="#555555" />
        </Styles.ChangeMonthButton>
        <Styles.MonthName>{month.format('MMMM YYYY')}</Styles.MonthName>
        <Styles.TzPickerWrapper>
          <TimezonePicker value={timezone} onChange={setTimezone} />
        </Styles.TzPickerWrapper>
      </Styles.Header>
      <Styles.DayNames showSummaryCell={showSummaryCell}>
        {showSummaryCell && renderWeekSummary && <Styles.SummaryHeading />}
        <Styles.DateHeading>Sun</Styles.DateHeading>
        <Styles.DateHeading>Mon</Styles.DateHeading>
        <Styles.DateHeading>Tues</Styles.DateHeading>
        <Styles.DateHeading>Wed</Styles.DateHeading>
        <Styles.DateHeading>Thurs</Styles.DateHeading>
        <Styles.DateHeading>Fri</Styles.DateHeading>
        <Styles.DateHeading>Sat</Styles.DateHeading>
      </Styles.DayNames>
      <Styles.DayCells showSummaryCell={showSummaryCell}>
        {weeks.map((week, weekIndex) => (
          <React.Fragment key={weekIndex}>
            {showSummaryCell && renderWeekSummary && (
              <Styles.DayCell> {week.length && renderWeekSummary(week, timezone)}</Styles.DayCell>
            )}
            {week.map((day, dayIndex) => {
              const isInActiveMonth = month.month() === day?.month();
              return (
                <React.Fragment key={dayIndex}>
                  <Styles.DayCell>
                    {day && renderCell(day, timezone, isInActiveMonth)}
                  </Styles.DayCell>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        ))}
      </Styles.DayCells>
    </Styles.Wrapper>
  );
}
