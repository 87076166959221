import { isNumber, noop, times } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { HELVETICA } from '../../globalStyles/text';
import { cx } from '../../utils';
import { Grid } from '../Grid';
import { Icon, IconKey } from '../Icons';
import { baseInputStyles, InvisibleInput } from './Input';
import { InlineControls } from './Shared';

export type DatePickerProps = {
  value?: Date | null;
  onChange: (value: Date | null) => void;
} & PublicDatePickerProps;
export const DatePicker = ({ value, onChange, ...props }: DatePickerProps) => {
  const hook = useDatePicker();

  return (
    <LocalDatePicker
      onSelectDay={day => onChange(day.toDate())}
      hook={hook}
      textValue={value ? moment(value).format('L') : ''}
      onClear={() => onChange(null)}
      value={value ? [value] : undefined}
      {...props}
    />
  );
};

export type DateRangePickerProps = {
  value?: [Date, Date] | null;
  onChange: (d: DateRangePickerProps['value']) => void;
} & PublicDatePickerProps;

export const DateRangePicker = ({ value, onChange, ...props }: DateRangePickerProps) => {
  const hook = useDatePicker();
  const [localValue, setLocalValue] = useState<[Date?, Date?]>([]);

  const onSelectDay = (day: moment.Moment) => {
    const date = day.toDate();
    const [localLower, localUpper] = localValue;
    if (!localLower) {
      setLocalValue([date]);
    } else if (localUpper) {
      setLocalValue([date]);
      onChange(null);
    } else {
      const range: [Date, Date] = date > localLower ? [localLower, date] : [date, localLower];
      setLocalValue(range);
      onChange(range);
    }
  };

  const onClear = () => {
    onChange(null);
    setLocalValue([]);
  };

  const base = ['/', '/'];
  const textValue =
    (hook.focus
      ? base.map((s, i) => (localValue[i] ? moment(localValue[i]).format('L') : s)).join(' - ')
      : value && base.map((s, i) => (value[i] ? moment(value[i]).format('L') : s)).join(' - ')) ??
    '';

  return (
    <LocalDatePicker
      hook={hook}
      textValue={textValue}
      onClear={onClear}
      onSelectDay={onSelectDay}
      value={localValue}
      {...props}
    />
  );
};

type PublicDatePickerProps = {
  placeholder?: string;
  disabled?: boolean;
  iconLeft?: IconKey;
  min?: moment.Moment;
  clearable?: boolean;
  style?: React.CSSProperties;
};
type LocalDatePickerProps = {
  hook: ReturnType<typeof useDatePicker>;
  textValue: string;
  value?: any;
  onSelectDay: (day: moment.Moment) => void;
  onClear: () => void;
} & PublicDatePickerProps;
const LocalDatePicker = ({
  disabled,
  hook,
  iconLeft,
  placeholder,
  textValue,
  clearable,
  value,
  min,
  onClear,
  onSelectDay,
  style,
}: LocalDatePickerProps) => {
  const { controlsRef, calendarRef, dropdownRef, inputRef, focus, monthStart } = hook;

  return (
    <StyledContainer
      className={cx({ focus, disabled })}
      style={style}
      onClick={() => {
        if (disabled) return;
        inputRef.current?.focus();
      }}
    >
      {iconLeft && (
        <Icon style={{ paddingLeft: '0.5rem' }} icon={iconLeft} size={18} className="nl2" />
      )}
      <InvisibleInput
        className="flex-auto"
        ref={inputRef}
        placeholder={placeholder}
        disabled={disabled}
        value={textValue}
        onChange={noop}
        onBlur={hook.onBlur}
        onFocus={hook.onFocus}
        onKeyDown={e => {
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            (controlsRef.current?.children.item(0) as any)?.focus?.();
          }
        }}
      />
      <InlineControls
        value={value}
        onClear={clearable ? () => onClear() : undefined}
        disabled={disabled}
      />
      {focus && (
        <StyledDropdownContainer
          onFocus={hook.onFocus}
          className="appear-below"
          ref={dropdownRef}
          tabIndex={-1}
        >
          <MonthSelector
            ref={controlsRef}
            onNavUp={() => inputRef.current?.focus()}
            onNavDown={() => (calendarRef.current?.firstElementChild as HTMLElement)?.focus?.()}
            date={monthStart}
            hook={hook}
          />
          <CalendarGrid
            hook={hook}
            ref={calendarRef}
            onNavUp={() => (controlsRef.current?.firstElementChild as HTMLElement)?.focus?.()}
            onSelectDay={onSelectDay}
            min={min}
            value={value}
          />
        </StyledDropdownContainer>
      )}
    </StyledContainer>
  );
};

const useDatePicker = () => {
  const timeoutId = useRef<number>();
  const controlsRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [focus, setFocus] = useState(false);
  const [, setUpdateCount] = useState(0);
  const dateRef = useRef(moment());
  const reRender = () => setUpdateCount(i => i + 1);

  const monthStart = dateRef.current.clone().startOf('month');

  const onFocus = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    setFocus(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLElement>) => {
    const { relatedTarget, currentTarget } = e;
    timeoutId.current = setTimeout(() => {
      if (
        (relatedTarget as HTMLElement)?.contains(dropdownRef.current) ||
        !currentTarget.contains(document.activeElement)
      ) {
        setFocus(false);
      }
    }, 0);
  };

  const moveMonth = (i: number) => {
    dateRef.current.add(i, 'month');
    reRender();
  };

  return {
    controlsRef,
    calendarRef,
    dropdownRef,
    inputRef,
    monthStart,
    focus,
    moveMonth,
    onBlur,
    onFocus,
  };
};

type MonthSelectorProps = {
  date: moment.Moment;
  onNavUp: () => void;
  onNavDown: () => void;
  hook: ReturnType<typeof useDatePicker>;
};
const MonthSelector = React.forwardRef<HTMLDivElement, MonthSelectorProps>(
  ({ onNavDown, onNavUp, hook, date }, ref) => {
    const leftRef = useRef<HTMLButtonElement>(null);
    const rightRef = useRef<HTMLButtonElement>(null);

    const onArrowKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          return onNavDown();
        case 'ArrowLeft':
          e.preventDefault();
          return leftRef.current?.focus();
        case 'ArrowRight':
          e.preventDefault();
          return rightRef.current?.focus();
        case 'ArrowUp':
          e.preventDefault();
          return onNavUp();
        default:
          break;
      }
    };
    const backgroundColor = colors.grey.light;

    return (
      <>
        <div
          ref={ref}
          style={{ backgroundColor }}
          className="black bg-light-gray ph2 pv3 flex items-center"
        >
          <button
            tabIndex={0}
            type="button"
            className="tc pointer bw0 bg-transparent white dim"
            onClick={() => hook.moveMonth(-1)}
            onKeyDown={onArrowKeyDown}
            ref={leftRef}
          >
            <ArrowLeft />
          </button>
          <div className="tc flex-auto b" title={date.format('MMMM YYYY')}>
            {date.format('MMMM YYYY')}
          </div>
          <button
            tabIndex={0}
            type="button"
            className="tc pointer bw0 bg-transparent white dim"
            onClick={() => hook.moveMonth(1)}
            onKeyDown={onArrowKeyDown}
            ref={rightRef}
          >
            <ArrowRight />
          </button>
        </div>
        <div style={{ backgroundColor }}>
          <Grid gridTemplateColumns="repeat(7, 1fr)">
            {days.map((d, i) => (
              <div aria-label={d} key={i} className="black tc pa1">
                {d[0]}
              </div>
            ))}
          </Grid>
        </div>
      </>
    );
  }
);

type CalendarGridProps = {
  onSelectDay: (d: moment.Moment) => void;
  onNavUp: () => void;
  value?: [Date?, Date?];
  hook: ReturnType<typeof useDatePicker>;
  min?: moment.Moment;
};
const CalendarGrid = React.forwardRef<HTMLDivElement, CalendarGridProps>(
  ({ value, hook, min, onNavUp, onSelectDay }, ref) => {
    const [lower, upper] = value ?? [];
    // how many empty grid slots before the first day of the month
    // month starting on sunday will have 0, monday - 1, tuesday - 2 ... etc
    const monthOffset = hook.monthStart.weekday();
    const monthLength = hook.monthStart.daysInMonth();

    const onDayKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, disabled: boolean, i: number) => {
      if (e.key === 'Enter' && !disabled) return onSelectDay(hook.monthStart.clone().add(i, 'day'));

      const row = Math.floor(i / 7);
      const col = i % 7;

      const toFocusIndex = (
        {
          ArrowDown: 7 * (row + 1) + col,
          ArrowUp: 7 * (row - 1) + col,
          ArrowLeft: 7 * row + col - 1,
          ArrowRight: 7 * row + col + 1,
        } as Record<string, number | undefined>
      )[e.key];

      if (isNumber(toFocusIndex)) {
        e.preventDefault();
        // when you press up from the top row of dates
        if (toFocusIndex < 0) return onNavUp();
        (e.currentTarget.parentElement?.children.item(toFocusIndex) as any)?.focus?.();
      }
    };

    return (
      <Grid ref={ref} gridTemplateColumns="repeat(7, 1fr)" className="bg-white ph3 pv2">
        {times(monthLength, i => {
          const day = hook.monthStart.clone().add(i, 'd');
          const isDisabled = !!min && day.isBefore(min, 'd');
          const isLower = !!lower && day.isSame(lower, 'd');
          const isUpper = !!upper && day.isSame(upper, 'd');
          const isBetween = !!lower && !!upper && day.isBetween(lower, upper, 'd', '()');

          const className = cx(
            (isLower || isUpper) && 'selected',
            isDisabled && 'disabled',
            isBetween && 'between'
          );

          return (
            <StyledCalendarGridItem
              key={day.format('L')}
              style={i === 0 ? { gridColumnStart: monthOffset + 1 } : undefined}
              aria-label={`${day.format('LL')}`}
              role="gridcell"
              tabIndex={0}
              onKeyDown={e => onDayKeyDown(e, isDisabled, i)}
              onClick={isDisabled ? undefined : () => onSelectDay(day)}
              className={className}
            >
              {i + 1}
            </StyledCalendarGridItem>
          );
        })}
      </Grid>
    );
  }
);

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// prettier-ignore
const ArrowLeft = () => <svg viewBox="0 0 24 24" style={{ fill:"black",width:24,height:24 }}><title>Arrow Left</title><path fillRule="evenodd" clipRule="evenodd" d="M6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071C12.0976 16.3166 12.0976 15.6834 11.7071 15.2929L9.41421 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H9.41421L11.7071 8.70711C12.0976 8.31658 12.0976 7.68342 11.7071 7.29289C11.3166 6.90237 10.6834 6.90237 10.2929 7.29289L6.29289 11.2929Z" /></svg>
// prettier-ignore
const ArrowRight = () => <svg viewBox="0 0 24 24" style={{ fill:"black",width:24,height:24 }}><title>Arrow Right</title><path fillRule="evenodd" clipRule="evenodd" d="M6 12C6 12.5523 6.44772 13 7 13H14.5858L12.2929 15.2929C11.9024 15.6834 11.9024 16.3166 12.2929 16.7071C12.6834 17.0976 13.3166 17.0976 13.7071 16.7071L17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929L13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289C11.9024 7.68342 11.9024 8.31658 12.2929 8.70711L14.5858 11H7C6.44772 11 6 11.4477 6 12Z" /></svg>

const StyledCalendarGridItem = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 0.2rem 0.35rem;
  font: ${HELVETICA};
  margin: 0.25rem 0px;

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }

  &.between {
    background-color: ${colors.accent};
  }

  &.selected {
    background-color: ${colors.primary};
    color: white;
  }

  :not(.selected):not(.disabled) {
    :hover {
      background-color: lightgray;
    }
  }
`;

const StyledDropdownContainer = styled.div`
  left: 0;
  position: absolute;
  width: 18rem;
  max-width: 18rem;
  border: 1px solid ${colors.grey.lightBorder};
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;

  &.appear-above {
    bottom: 110%;
  }

  &.appear-below {
    top: 110%;
  }

  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled.div`
  ${baseInputStyles}
  position:relative;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
`;
