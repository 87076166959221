import { compact } from 'lodash';
import React, { useState } from 'react';
import { FinalButton } from '../../../../Components/FinalButton';
import { Select } from '../../../../Components/Form';
import { StateCodes } from '../../../../graphQL';
import { stateOptions } from '../../../../states';
import { useDrilldownContext } from '../../helpers';
import { Text, Modal as MantraModal } from '../../styles';

interface Props {
  onSelect: (state: string) => void;
  onGoBack: () => void;
  onClose: () => void;
  serviceType: string;
  loading?: boolean;
}

export function StateSelect({ onSelect, onGoBack, onClose, serviceType, loading }: Props) {
  const { user } = useDrilldownContext();
  const [st, setSt] = useState<string | undefined | null>(user.secondaryState);
  const [showStateMismatchError, setShowStateMismatchError] = useState(false);

  const handleContinue = () => {
    if (!st) return;
    const userProviders = compact([user.provider, user.therapist]);
    const allProvidersMatchState = userProviders.every(i => i.geoStates.includes(st as StateCodes));
    if (allProvidersMatchState) {
      onSelect(st);
    } else {
      setShowStateMismatchError(true);
    }
  };

  if (showStateMismatchError) {
    return <IneligibleState onGoBack={onGoBack} onClose={onClose} serviceType={serviceType} />;
  }

  return (
    <div>
      <Text.h2 className="mb4">Confirm patient’s location during break</Text.h2>
      <Text.bodySmall className="b">State</Text.bodySmall>
      <Select
        options={stateOptions}
        value={st as string | undefined}
        onChange={e => setSt(e as string | undefined)}
        clearable={false}
      />
      <FinalButton
        disabled={!st}
        onClick={() => handleContinue()}
        className="w-100 mt4"
        loading={loading}
      >
        Continue
      </FinalButton>
    </div>
  );
}

interface IneligibleStateProps {
  onGoBack: () => void;
  serviceType: string;
  onClose: () => void;
}

export function IneligibleState({ onGoBack, serviceType, onClose }: IneligibleStateProps) {
  return (
    <div>
      <Text.h2 className="mb3">Ineligible state selected</Text.h2>
      <Text.body className="mb4">
        To continue {serviceType}, the patient must be in an eligible state according to the
        school’s contract.
      </Text.body>

      <FinalButton kind="outline_black" className="w-100" onClick={onGoBack}>
        Select a different care option
      </FinalButton>
      <div className="mt4">
        <MantraModal.closeLink onClick={onClose}>Cancel</MantraModal.closeLink>
      </div>
    </div>
  );
}
