import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../Components/Icons';
import { BackButton } from '../../../../Components/Wizard/BackButton';
import { NextSteps, StateCodes, useCreateCareTransitionMutation } from '../../../../graphQL';
import { useDrilldownContext } from '../../helpers';
import { Modal as MantraModal, Text } from '../../styles';
import { StateSelect } from './StateSelect';
import { COCPathProps } from './types';

export function SelfPay({ onGoBack, onClose, careType, onSubmit }: COCPathProps) {
  const [done, setDone] = useState(false);
  const { user, refetch } = useDrilldownContext();
  const [mutate, { loading }] = useCreateCareTransitionMutation({
    onCompleted: () => {
      setDone(true);
      refetch();
    },
  });
  const handleSetState = (state: string) => {
    const transition = {
      careType,
      userId: user.id,
      nextSteps: NextSteps.ActiveSelfPay,
      secondaryState: state as StateCodes,
    };
    mutate({ variables: { transition } });
    onSubmit?.();
  };

  return (
    <div>
      <BackButton onClick={onGoBack} />
      {!done && (
        <StateSelect
          onSelect={handleSetState}
          loading={loading}
          onGoBack={onGoBack}
          onClose={onClose}
          serviceType="with self-pay services"
        />
      )}
      {done && (
        <div>
          <Text.h2 className="mb4">Confirmed! Next steps set to Self-Pay.</Text.h2>
          <ActionNeededContainer>
            <div className="flex items-center mb3">
              <Icon icon="iconsAlertSvg" alt="Alert" style={{ marginRight: 12 }} />
              <Text.label kind="danger">Patient action needed</Text.label>
            </div>
            <Text.h3>
              Patient must now complete the “Confirm Continued Care” task in order to continue with
              Self-Pay, otherwise they will lose access to care.
            </Text.h3>
          </ActionNeededContainer>
          <MantraModal.closeLink onClick={onClose}>Close</MantraModal.closeLink>
        </div>
      )}
    </div>
  );
}

const ActionNeededContainer = styled.div`
  background: #edf1f7;
  border-radius: 4px;
  border: 1px solid #c5cee0;
  padding: 20px;
  margin-bottom: 20px;
`;
