import { values } from 'lodash';
import moment from 'moment';
import React from 'react';
import { QuickEmailLink } from '../../../Components/Flair';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery, CareType, EngagementTag } from '../../../graphQL';
import { getFullNameReversed } from '../../../modelUtils/users';
import { dateSorterFactory } from '../../../utils';
import { UnstyledLink } from '../../Shared';
import { DashboardTable } from '../DashTable';
import { EmptyState } from '../EmptyState';
import * as Styles from '../styles';
import { EngagementPill, engagementTagCopy } from './EngagementTag';

const emptyState = <EmptyState text="No actions" icon="safety" />;

type Props = {
  outreachUsers: CareDashboardQuery['adminNeedsPatientOutreach'];
};

export const PatientOutreach = ({ outreachUsers }: Props) => {
  return (
    <Styles.SectionContainer>
      <Text.h3 className="mb2">Needs Patient Outreach</Text.h3>
      <DashboardTable
        data={outreachUsers}
        emptyState={emptyState}
        columns={[
          {
            title: 'Patient',
            render: user => (
              <>
                <UnstyledLink to={`/users/${user.id}`} className="mr2">
                  <Text.bodySmall className="b">{getFullNameReversed(user)}</Text.bodySmall>
                </UnstyledLink>
                <QuickEmailLink user={user} />
              </>
            ),
          },
          {
            title: 'Care Type',
            render: user => <Text.bodySmall>{user.careTypes.join(', ')}</Text.bodySmall>,
          },
          {
            title: 'Status',
            render: user => <Text.bodySmall>{user.taskStatus}</Text.bodySmall>,
          },
          {
            title: 'Last Outreach',
            initialSort: true,
            sort: dateSorterFactory(u => u.lastOutreachAttempt, 'DESC'),
            render: ({ lastOutreachAttempt }) => (
              <Text.bodySmall>
                {lastOutreachAttempt ? moment(lastOutreachAttempt).format('L') : '--'}
              </Text.bodySmall>
            ),
          },
          {
            render: user =>
              user.engagementTags
                .filter(e => e !== EngagementTag.Unverified)
                .map(e => <EngagementPill key={e} tag={e} />),
          },
        ]}
        getKey={user => user.id}
        filters={[
          {
            key: 'careTypeFilter',
            options: [CareType.Psychiatry, CareType.Therapy].map(careType => ({
              id: careType,
              label: careType,
            })),
            placeholder: 'Care Type',
            test: (user, v) => user.careTypes.includes(v as CareType),
          },
          {
            key: 'statusFilter',
            options: ['Intake', 'Ongoing Care'].map(status => ({ id: status, label: status })),
            placeholder: 'Status',
            test: (user, v) => user.taskStatus === v,
          },
          {
            key: 'eventFilter',
            options: values(engagementTagCopy).map(event => ({ id: event, label: event })),
            placeholder: 'Event',
            test: (user, v) => user.engagementTags.map(t => engagementTagCopy[t]).includes(v),
          },
        ]}
      />
    </Styles.SectionContainer>
  );
};
