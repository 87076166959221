import { StyledSpinnerNext } from 'baseui/spinner';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useSidebarQuery } from '../../Components/NavLayout/Counts';
import { isSupportingClinician, useCurrentProvider } from '../../Components/Permissions';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { When } from '../../Components/When';
import { MessageWidget } from '../../Components/Widgets/MessageWidget';
import { PaddedPage, Text } from '../../globalStyles';
import { useCounselorDashboardQuery, useSidebarDataQuery } from '../../graphQL';
import { IncompletePlans } from '../Home/Widgets/IncompletePlans';
import { UnexpectedError, UnstyledLink } from '../Shared';
import { EventList } from './EventList';
import { HighRiskList } from './HighRiskList';
import { IncompleteReferralNotes } from './IncompleteReferralNotes';
import { MissedAppointments } from './MissedAppointments';
import * as Styles from './styles';
import { SuicidalRiskList } from './SuicidalRiskList';
import { ProviderNetworkWidget } from './Widgets/ProviderNetworkWidget';
import { TwoFactorVerifyWidget } from '../Home/Widgets/TwoFactorVerifyWidget';

export function OldCounselorDashboard() {
  const params = useParams<{ organizationId: string }>();
  const organizationId = Number(params.organizationId);
  const { currentProvider } = useCurrentProvider();

  const isCollaborator = isSupportingClinician(currentProvider);

  const { data, loading, error } = useCounselorDashboardQuery({
    variables: {
      organizationId,
      notesKey: isCollaborator ? 'initial-evaluation' : null,
    },
  });

  const [, variables] = useSidebarQuery();
  const { data: unreadMessages, loading: unreadMessagesLoading } = useSidebarDataQuery({
    variables,
  });
  const unreadMessageCount = unreadMessages?.unreadCareTeamMessages ?? 0;

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  return (
    <DashboardContainer>
      <TwoFactorVerifyWidget />
      <UniversityLogo right="3em" />
      <Text.h1>{currentProvider.name}’s Dashboard</Text.h1>
      <DashboardBody>
        <DashboardLeft>
          {(unreadMessagesLoading || !unreadMessages) && (
            <div className="flex justify-center items-center">
              <StyledSpinnerNext />
            </div>
          )}
          {unreadMessageCount > 0 && (
            <div className="pb4">
              <UnstyledLink to="/messages">
                <MessageWidget count={unreadMessageCount} source="collaborating providers" />
              </UnstyledLink>
            </div>
          )}
          <When isTruthy={!isCollaborator}>
            <ProviderNetworkWidget />
            <IncompleteReferralNotes
              missing={data.organization.usersWithoutReferrals}
              incomplete={data.organization.usersWithOnlyIncompleteReferrals}
            />
          </When>
          <Styles.NotesGrid>
            <EventList
              events={data.organization.latestAssessments}
              title="New Assessments"
              description="Completed Assessment"
              icon="iconsGraphFadedSvg"
              emptyState="No new assessments"
            />
            <EventList
              events={data.organization.latestNotes}
              title={isCollaborator ? 'New Visit Notes' : 'New Intake Notes'}
              description={isCollaborator ? 'Consultation Note' : 'Initial Consultation Note'}
              icon="iconsNoteFadedSvg"
              emptyState={isCollaborator ? 'No new visit notes' : 'No new intake notes'}
            />
          </Styles.NotesGrid>
        </DashboardLeft>
        <DashboardRight>
          <IncompletePlans plans={data.organization.incompletePlans} />
          <HighRiskList users={data.organization.highRiskUsers} />
          <MissedAppointments organizationId={organizationId} />
          <SuicidalRiskList users={data.organization.suicidalRiskUsers} />
        </DashboardRight>
      </DashboardBody>
    </DashboardContainer>
  );
}

const DashboardContainer = styled(PaddedPage)`
  max-width: 1400px;
  position: relative;
`;
const DashboardBody = styled.div`
  display: flex;
  margin-top: 3em;
`;
const DashboardLeft = styled.div`
  flex: 2;
  display: flex;
  flex-flow: column;
  margin-right: 2em;
`;
const DashboardRight = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
`;
