import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { Bordered } from '../Bordered';

export const SpacingGrid = styled(Bordered)`
  display: grid;
  grid-template-columns: 18rem 1fr;
  height: 100%;
`;

export const ProviderList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const ProviderRowItem = styled(Bordered)`
  background-color: white;
  padding: 0.75rem 2rem 0.75rem 2rem;

  display: grid;
  grid-template-columns: minmax(19rem, 1fr) 0 max(30rem, 31vw);
  gap: 1rem;

  &.header {
    grid-template-columns: 18rem 1fr max(30rem, 31vw);
    gap: 0.75rem;

    &.header > .align-to-end {
      align-self: end;
      justify-self: end;
    }
  }

  // used for simple availability list
  &.gridSmall {
    grid-template-columns: 1fr 0 21rem;
  }

  &:not(.gridSmall) {
    @media (max-width: 1400px) {
      grid-template-columns: 1fr;

      &.header > .align-to-end {
        justify-self: initial;
      }
    }
  }

  &.selected {
    background-color: #e5f1ff;
  }

  :not(:first-child) {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
`;

export const DayGrid = styled.div<{ dayColumns?: number }>`
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;

  & .days {
    display: grid;
    gap: 0.25rem;
    grid-template-columns: ${({ dayColumns = 5 }) => `repeat(${dayColumns}, 1fr)`};
    grid-template-rows: max-content;
  }
`;

export const SelectedProviderContainer = styled.div`
  background-color: #007aff;
  display: flex;
  height: 3rem;
`;

export const ErrorContainer = styled.div`
  background-color: ${colors.dangerBg};
  color: ${colors.danger};
  font-weight: bold;
  padding: 0.75rem;
  margin-bottom: 0.25rem;

  & > p {
    text-align: center;
    margin: auto;
  }
`;

export const BannerItems = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  gap: 0.5rem;

  & > p {
    opacity: 0.8;
  }

  & > * {
    color: white;
  }
`;

export const SearchSelections = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 14rem);
  gap: 1rem;
`;

export const CenteredMaxWidth = styled.div`
  margin: 0 auto 0 auto;
  padding: 2rem 0 2rem 0;
  text-align: center;
  max-width: 26rem;
`;
