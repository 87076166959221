import React, { useState } from 'react';
import { BackButton } from '../../../../Components/Wizard/BackButton';
import { NextSteps, useCreateCareTransitionMutation } from '../../../../graphQL';
import { useDrilldownContext } from '../../helpers';
import { Modal as MantraModal, Text } from '../../styles';
import { NeedsReferralQuestion } from './NeedsReferralQuestion';
import { ReasonsQuestion } from './ReasonsQuestion';
import { COCPathProps } from './types';

export function Cancel({ onGoBack, onClose, careType, onSubmit }: COCPathProps) {
  const { user, refetch } = useDrilldownContext();
  const [mutate, { loading }] = useCreateCareTransitionMutation({
    onCompleted: () => {
      setStep(2);
      refetch();
    },
  });
  const [step, setStep] = useState(0);
  const [reasons, setReasons] = useState<string[]>();

  const continueToReferral = (incomingReasons: string[]) => {
    setReasons(incomingReasons);
    setStep(1);
  };

  const finish = async (needsReferral: boolean) => {
    const transition = {
      careType,
      userId: user.id,
      nextSteps: NextSteps.Cancel,
      reason: reasons?.join(', '),
      requiresReferral: needsReferral,
    };
    await mutate({ variables: { transition } });
    onSubmit?.();
  };

  return (
    <div>
      {step < 2 && <BackButton onClick={() => (step > 0 ? setStep(step - 1) : onGoBack())} />}
      {step === 0 && (
        <ReasonsQuestion
          title="Reason(s) for cancellation?"
          options={[
            'Not returning to partner school',
            'Requested cancellation',
            'Patient is stable',
            'Unclear / No response',
          ]}
          onContinue={continueToReferral}
        />
      )}
      {step === 1 && <NeedsReferralQuestion loading={loading} onConfirm={finish} />}
      {step === 2 && (
        <div>
          <Text.h2 className="mb3">Confirmed! Next steps set to Cancel</Text.h2>
          <Text.body className="mb3">
            When the patient’s sponsored care has ended, we will cancel their account.
          </Text.body>
          <Text.bodyBold className="mb4">
            Please complete a External Referral: Case Summary note if necessary.
          </Text.bodyBold>
          <MantraModal.closeLink onClick={onClose}>Close</MantraModal.closeLink>
        </div>
      )}
    </div>
  );
}
