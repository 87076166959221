import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { colors, Text } from '../../../globalStyles';
import { Availability, AvailabilityBlockedReason, Provider, StateCodes } from '../../../graphQL';
import { BookableIntakesDepleted } from '../../DedicatedGroupModel/BookableIntakesDepleted';
import { FinalButton } from '../../FinalButton';
import { getSpanned, GridSpan } from '../../GridSpan';
import { BlockedSlotModal } from '../../Scheduler/BlockedSlotModal';
import { hackyButFastIsBetween } from '../../Scheduler/TimePicker';
import { useProviderNetworkContext } from '../ProviderNetworkContext';
import * as Styles from '../Styles';
import { AppointmentOption } from '../types';
import { useDisplayableSlots, useProviderAvailability } from './hooks';
import { BookingArgs, Hoverable, Slot } from './types';

export type SelectAvailabilityFn = (a: AppointmentOption) => void;

type DetailedProps = {
  provider: Pick<Provider, 'id' | 'name' | 'careTypes'>;
  forBooking?: BookingArgs;
  onSelectAvailability?: SelectAvailabilityFn;
  nextAvailability?: Pick<Availability, 'start'>;
  patientState?: StateCodes;
};

export function DetailedAvailability({
  provider,
  forBooking,
  nextAvailability,
  onSelectAvailability,
  patientState,
}: DetailedProps) {
  const {
    days,
    jumpDays,
    jumpTo,
    hasRemainingHours,
    remainingHours,
    nextAvailableDGMDate,
    searchVariables: { careType },
  } = useProviderNetworkContext();

  const { loading, error, timesByDay, ...rest } = useProviderAvailability({
    variables: { providerId: provider.id, careType, forBooking, forDays: days!, patientState },
    skip: !days,
  });

  if (!days) {
    return null;
  }

  if (loading) {
    return (
      <div className="flex flex-column justify-center">
        <Skeleton count={1} height={45} duration={1.5} />
      </div>
    );
  }

  // checks for both DGM and dedicated availability
  const hasDGMOrDedicatedAvailability = timesByDay.some(slots => slots.some(s => s.available));

  type NoAvailabilityMessageProps = {
    width: number;
  };

  const NoAvailabilityMessage = ({ width }: NoAvailabilityMessageProps) => {
    // width is size of the span the component is in, less than 2 is too thin to display button text
    return (
      <div className="flex flex-column justify-center items-center">
        <p className="mb3">No availability</p>
        <div className="flex flex-column justify-center" style={{ width: 'fit-content' }}>
          {nextAvailability ? (
            <FinalButton
              kind="outline_black"
              iconRight="iconsRightChevronSvg"
              onClick={() => jumpTo(moment(nextAvailability.start))}
            >
              {width >= 2 && (
                <span className="ma4">
                  Next availability {moment(nextAvailability.start).format('ddd, MMM DD')}
                </span>
              )}
            </FinalButton>
          ) : (
            <FinalButton kind="outline_black" iconRight="iconsRightChevronSvg" onClick={jumpDays}>
              {width >= 2 && (
                <span className={width === 3 ? 'ma4' : 'ma2'}>Jump to next {days.length} days</span>
              )}
            </FinalButton>
          )}
        </div>
      </div>
    );
  };

  // remaining dgm hours per day in range
  const remainingDGMHoursLookup = remainingHours?.reduce((acc, curr) => {
    return { ...acc, [moment(curr.date).format('YYYY-MM-DD')]: curr.remainingHours };
  }, {} as Record<string, number>);

  /*
   * e.g. [A,A,B,A] =>
   * spannedElements: [{val: A, startIdx: 0, spanStart: 1, span: 2},
   *                   {val: B, startIdx: 2, spanStart: 3, span: 1},
   *                   {val: A, startIdx: 3, spanStart: 4, span: 1}]
   */

  const spannedElements = getSpanned({
    endIdx: days.length - 1,
    getVal: i => {
      const dateKey = days[i].format('YYYY-MM-DD');
      const isDgmDepleted = hasRemainingHours && (remainingDGMHoursLookup?.[dateKey] || 0) <= 0;
      if (isDgmDepleted && !hasDGMOrDedicatedAvailability) {
        return null;
      }
      if (!isDgmDepleted && !hasDGMOrDedicatedAvailability) {
        return 'no avail'; // should be changed to something else
      }
      return i;
    },
  });

  return (
    <div className="h-100">
      <Styles.DayGrid dayColumns={days.length}>
        <div />
        <div className="days">
          {spannedElements.map((e, i) => {
            const isDepleted = e.val === null;
            const isDepletedAndNoAvail = e.val === 'no avail';
            // TODO: bookable intakes is supposed to change the text inside depending on the width
            //  for now, the text won't appear if the container is too thin
            //  'no availability' message will do the same
            return (
              <GridSpan key={`${e.val}-${i}`} start={e.spanStart} span={e.span}>
                {isDepleted && (
                  <BookableIntakesDepleted
                    nextAvailableDGMDate={
                      // don't show availability date if there's availability ahead in the given date range
                      e.startIdx + e.span >= days.length - 1 ? nextAvailableDGMDate : null
                    }
                    onSelectStartDate={jumpTo}
                    nextAvailableDGMProviderDate={nextAvailability?.start as Date | undefined}
                    gridSpan={e.span}
                  />
                )}
                {!isDepleted && !isDepletedAndNoAvail && (
                  <Slots
                    {...rest}
                    slots={timesByDay[e.startIdx]}
                    onSelectAvailability={a => onSelectAvailability?.({ ...a, provider, careType })}
                  />
                )}
                {isDepletedAndNoAvail && <NoAvailabilityMessage width={e.span} />}
              </GridSpan>
            );
          })}
        </div>
        <div />
      </Styles.DayGrid>
    </div>
  );
}

type SlotProps = Hoverable & {
  slots: Slot[];
  onSelectAvailability?: (args: Omit<AppointmentOption, 'provider' | 'careType'>) => void;
  organizationId?: number | null;
};

const Slots = ({
  slots,
  hoveredEnd,
  hoveredTime,
  setHoveredTime,
  onSelectAvailability,
}: SlotProps) => {
  const [showBlockedModal, setShowBlockedModal] = useState<{
    reason: AvailabilityBlockedReason;
    args: {
      startTime: Readonly<Moment>;
      endTime: Readonly<Moment>;
      organizationId?: number | null;
    };
  } | null>(null);

  const { expand, close, canExpand, displayableSlots, hasLessOrSameAsBatch } =
    useDisplayableSlots(slots);

  const selectable = !!onSelectAvailability;

  const onHover = useCallback(
    (time: Moment) => (selectable ? () => setHoveredTime(time) : undefined),
    [setHoveredTime, selectable]
  );
  const onMouseOut = selectable ? () => setHoveredTime(null) : undefined;

  return (
    <>
      {showBlockedModal && (
        <BlockedSlotModal
          kind={showBlockedModal.reason}
          onClose={() => setShowBlockedModal(null)}
          onContinue={() => onSelectAvailability?.(showBlockedModal.args)}
        />
      )}
      <div className="flex flex-column gap-3">
        {displayableSlots.map(({ time, available, blockers, organizationId }, j) => {
          if (!available) {
            return (
              <div
                className="flex items-center justify-center gray"
                style={{ height: 33 }}
                key={`${j}-unavailable`}
              >
                -
              </div>
            );
          }
          return (
            <TimeCell
              key={`${time.format()}-available`}
              onMouseOver={onHover(time)}
              onFocus={onHover(time)}
              onMouseOut={onMouseOut}
              onBlur={onMouseOut}
              isHighlighted={
                selectable &&
                Boolean(
                  hoveredTime && hoveredEnd && hackyButFastIsBetween(time, hoveredTime, hoveredEnd)
                )
              }
              isBlocked={Boolean(blockers.length)}
              onClick={() => {
                if (!hoveredTime || !hoveredEnd) return;
                const args = {
                  startTime: hoveredTime,
                  endTime: hoveredEnd,
                  organizationId,
                } as const;
                if (blockers.length) {
                  setShowBlockedModal({ reason: blockers[0], args });
                } else {
                  onSelectAvailability?.(args);
                }
              }}
            >
              {time.format('h:mm a')}
            </TimeCell>
          );
        })}
        {!hasLessOrSameAsBatch && canExpand && (
          <div className="flex items-center justify-center pb2 pt2">
            <Text.linkButtonSmall onClick={expand}>+ more</Text.linkButtonSmall>
          </div>
        )}
        {!hasLessOrSameAsBatch && !canExpand && (
          <div className="flex items-center justify-center pb2 pt2">
            <Text.linkButtonSmall onClick={close}>less</Text.linkButtonSmall>
          </div>
        )}
      </div>
    </>
  );
};

const TimeCell = styled.button<{ isHighlighted?: boolean; isBlocked?: boolean }>`
  color: ${({ isBlocked }) => (isBlocked ? colors.danger : 'black')};
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  border: 1px solid;
  border-radius: 24px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border-color: ${({ isHighlighted, isBlocked }) => {
    if (isHighlighted) {
      return '#0179FF';
    }
    if (isBlocked) {
      return colors.danger;
    }
    return 'black';
  }};
  background: ${({ isHighlighted }) => (isHighlighted ? '#E5F1FF' : 'white')};
`;
