import React from 'react';
import { Warning } from '../../Components/Flair';
import { Text } from '../../globalStyles';
import { CounselorDashboardQuery } from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { UnstyledLink } from '../Shared';
import * as Styles from './styles';

interface Props {
  users: CounselorDashboardQuery['organization']['suicidalRiskUsers'];
}

export function SuicidalRiskList({ users }: Props) {
  return (
    <Styles.SectionContainer>
      <Text.h3>Suicidal Risk Students</Text.h3>
      <Styles.Divider />
      <div>
        {users.map(user => (
          <Styles.OneColumnGrid key={user.id}>
            <UnstyledLink to={`/users/${user.id}`}>
              <Warning>{getFullNameReversed(user)}</Warning>
            </UnstyledLink>
          </Styles.OneColumnGrid>
        ))}
        {users.length === 0 && (
          <Styles.EmptyStateText>No suicidal risk students</Styles.EmptyStateText>
        )}
      </div>
    </Styles.SectionContainer>
  );
}
