import { chain, Dictionary } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { ColorLookup, colors, Text } from '../../globalStyles';
import {
  AdminExistingCalendarEventsInRangeQuery,
  CareType,
  useAdminExistingCalendarEventsInRangeQuery,
} from '../../graphQL';
import { getWeek, TimeZone } from '../../utils';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { isMantraAdmin, useCurrentProvider } from '../Permissions';
import { Tooltip } from '../Tooltip';

const MAX_INTAKES_PER_DAY = 3;

type ActiveWeekProps = {
  onChangeWeek: (delta: number) => void;
  startDate: Moment;
  activeDays: number[];
  highlightedDate?: Moment;
  dottedDates?: Moment[];
  timezone: string;
  forBooking?: {
    providerId: number;
    careType: CareType;
    apptType: string;
  };
};

const DAYS_IN_WEEK = 7;

export function ActiveWeek({
  startDate,
  onChangeWeek,
  activeDays,
  highlightedDate,
  dottedDates,
  timezone,
  forBooking,
}: ActiveWeekProps) {
  const { featureFlags, currentProvider } = useCurrentProvider();

  const canSeeApptCounts =
    featureFlags.includes('AVAILABILITY_TAGGING') &&
    (isMantraAdmin(currentProvider) || currentProvider.id === forBooking?.providerId);

  const { data: existingEvents } = useAdminExistingCalendarEventsInRangeQuery({
    variables: {
      providerId: forBooking?.providerId!,
      careType: forBooking?.careType!,
      start: startDate.format(),
      apptTypes: ['intake', 'checkin'],
    },
    skip: !forBooking || !canSeeApptCounts,
  });

  const existingAppointmentBreakdown =
    existingEvents &&
    getAppointmentCountBreakdown(
      existingEvents.adminExistingCalendarEventsInRange,
      timezone as TimeZone
    );

  const days = getWeek(startDate, { timezone, startOfWeek: true });

  return (
    <>
      <Grid className="mv3">
        <FinalButton
          iconLeft="iconsLeftChevronSvg"
          kind="minimal_black"
          onClick={() => onChangeWeek(-1)}
        />
        {days.map((day, dayIndex) => (
          <DayContainer key={day.day()} highlighted={day.isSame(highlightedDate, 'date')}>
            <DayName
              disabled={!activeDays.includes(dayIndex)}
              highlighted={day.isSame(highlightedDate, 'date')}
            >
              {day.format('ddd')}
            </DayName>
            <DayNumber disabled={!activeDays.includes(dayIndex)}>{day.format('D')}</DayNumber>
            <Dot visible={dottedDates && dottedDates.some(i => i.isSame(day, 'date'))} />
          </DayContainer>
        ))}
        <FinalButton
          iconLeft="iconsRightChevronSvg"
          kind="minimal_black"
          onClick={() => onChangeWeek(1)}
        />
      </Grid>
      {canSeeApptCounts && existingAppointmentBreakdown && forBooking && (
        <Grid
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          }}
        >
          {/* first spot is for left date control */}
          <div />
          {days.map(day => {
            const breakdown = existingAppointmentBreakdown[day.format('YYYY-MM-DD')];
            const intakeCount = breakdown?.intake ?? 0;
            const followupCount = breakdown?.checkin ?? 0;
            const isIntakeBooking = forBooking.apptType === 'intake';

            const getIntakeColor = (): ColorLookup => {
              if (isIntakeBooking && intakeCount >= MAX_INTAKES_PER_DAY) return 'danger';
              return intakeCount > 0 ? 'text' : 'grayText';
            };

            const getFollowupColor = (): ColorLookup => {
              return followupCount > 0 ? 'text' : 'grayText';
            };

            return (
              <BreakdownContainer key={`${day.day()}-breakdown`}>
                <div className="flex flex-row gap-1 items-center">
                  <Text.captionBold kind={getIntakeColor()}>{intakeCount} Intake</Text.captionBold>
                  {isIntakeBooking && intakeCount >= MAX_INTAKES_PER_DAY && (
                    <Tooltip
                      size={15}
                      content={
                        <Text.bodySmall kind="white">
                          We recommend that providers are limited to{' '}
                          <u>{MAX_INTAKES_PER_DAY} booked intakes per day</u>, unless otherwise
                          agreed upon.
                        </Text.bodySmall>
                      }
                    >
                      <InlineSVG icon="alert-circle" kind="danger" />
                    </Tooltip>
                  )}
                </div>
                <Text.captionBold kind={getFollowupColor()}>
                  {followupCount} Follow-up
                </Text.captionBold>
              </BreakdownContainer>
            );
          })}
          {/* last spot is for right date control */}
          <div />
        </Grid>
      )}
    </>
  );
}

const getAppointmentCountBreakdown = (
  data: AdminExistingCalendarEventsInRangeQuery['adminExistingCalendarEventsInRange'],
  timezone: TimeZone
) => {
  return chain(data)
    .groupBy(d => moment(d.startTime).tz(timezone).format('YYYY-MM-DD'))
    .mapValues(vs =>
      vs.reduce((acc, v) => {
        const existingCount = acc[v.appointmentType] ?? 0;
        return { ...acc, [v.appointmentType]: existingCount + 1 };
      }, {} as Dictionary<number>)
    )
    .value();
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(${DAYS_IN_WEEK}, 1fr) 0.5fr;
`;

const DayContainer = styled.div`
  background: ${(props: { highlighted?: boolean }) =>
    props.highlighted ? '#EBF5FF' : 'transparent'};
  margin: auto;
  padding: 10px 20px;
  border-radius: 5px;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background: ${colors.primary};
  visibility: ${(props: { visible?: boolean }) => (props.visible ? 'visible' : 'hidden')};
  margin: 5px auto 0 auto;
  border-radius: 50%;
`;

const dayColor = (props: { disabled?: boolean; highlighted?: boolean }) => {
  if (props.highlighted) return colors.primary;
  if (props.disabled) return colors.grayText;
  return colors.grayText;
};

const DayName = styled.div`
  text-align: center;
  color: ${dayColor};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 1px;
  opacity: ${(props: { disabled?: boolean }) => (props.disabled ? '.8' : '1')};
`;

const DayNumber = styled.div`
  text-align: center;
  opacity: ${(props: { disabled?: boolean }) => (props.disabled ? '.8' : '1')};
  color: ${(props: { disabled?: boolean }) => (props.disabled ? colors.grayText : colors.black)};
  font-weight: bold;
  font-size: 1.8em;
`;

const BreakdownContainer = styled.div`
  margin: auto;
  padding: 0.5rem 20px;
  border-radius: 5px;
`;
