import { Avatar } from 'baseui/avatar';
import { capitalize, isNumber } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '../../../Components/Booking/Styles';
import { CampusTeamSelection } from '../../../Components/CampusTeam/CampusTeamSelection';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { FinalButton } from '../../../Components/FinalButton';
import {
  DateInputRHF,
  FormControl,
  InputRHF,
  MultiSelectRHF,
  PhoneInputRHF,
  SelectRHF,
} from '../../../Components/Form';
import { Icon, icons } from '../../../Components/Icons';
import { MantraSpinner } from '../../../Components/LoadingOverlay';
import { modalFactory } from '../../../Components/Modal';
import { Notification } from '../../../Components/Notification';
import { useCurrentProvider } from '../../../Components/Permissions';
import { useProviderNetworkContext } from '../../../Components/ProviderNetwork/ProviderNetworkContext';
import { getDisplayableType } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { AppointmentOption } from '../../../Components/ProviderNetwork/types';
import { When } from '../../../Components/When';
import { Text } from '../../../globalStyles';
import {
  StateCodes,
  useAdminCreateUserWithAppointmentMutation,
  useEnrollModalQuery,
  User,
} from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { stateOptions } from '../../../states';
import { Nullable, RequireJust } from '../../../types';
import { borderRadius, stripPhoneNumber } from '../../../utils';
import { FormFields, Label } from './Common';
import { getMcpEnrollmentProviderOptions } from './enrollmentModalUtils';

const required = 'This field is required.';
const emptyOption = { id: -1, label: 'None' } as const;

const Modal = modalFactory({
  style: { ...borderRadius('4px'), overflow: 'hidden', maxWidth: '60rem' },
});

export type BookingSuccess = {
  appointment: AppointmentOption;
  user: RequireJust<User, 'firstName' | 'lastName'>;
};

type ModalProps = {
  onClose: () => void;
  appointment: AppointmentOption;
  onComplete: (s: BookingSuccess) => void;
};

export const SponsoredCareModal = ({ onClose, appointment, onComplete }: ModalProps) => {
  const {
    searchVariables: { state },
  } = useProviderNetworkContext();
  const [showPreferredName, setShowPreferredName] = useState(false);
  const { currentProvider, featureFlags } = useCurrentProvider();
  const organizationId = currentProvider.organizations[0].id;

  const form = useForm<FormFields>({
    defaultValues: { geoState: state ?? undefined, primaryCareTeamId: emptyOption.id },
  });

  const { description, duration } = getApptDetails(appointment);

  const { data, loading } = useEnrollModalQuery({
    variables: { organizationId, providerIds: [appointment.provider.id] },
  });

  const [campusTeam, setCampusTeam] = useState<Nullable<EditCampusTeamMember[]>>(null);

  const [createUser, mutation] = useAdminCreateUserWithAppointmentMutation({
    onCompleted: res => onComplete({ appointment, user: res.adminCreateUser }),
  });

  const childOrganizationId = form.watch('childOrganizationId');
  const providerOptions = data
    ? getMcpEnrollmentProviderOptions(data.organization, childOrganizationId)
    : [];

  const { setValue } = form;
  useEffect(() => {
    setValue('primaryCareTeamId', undefined);
    setValue('secondaryCareTeamIds', undefined);
  }, [setValue, childOrganizationId]);

  const onSubmit = form.handleSubmit(values => {
    createUser({
      variables: {
        input: {
          organizationId: values.childOrganizationId ?? organizationId,
          firstName: values.firstName,
          lastName: values.lastName,
          preferredName: values.preferredName,
          email: values.email,
          phone: stripPhoneNumber(values.phone),
          birthDate: moment(values.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          universityCareTeamPrimary:
            values.primaryCareTeamId === emptyOption.id ? null : values.primaryCareTeamId,
          universityCareTeamSecondary: values.secondaryCareTeamIds ?? [],
          campusTeam: campusTeam?.map(c => ({
            providerId: c.id,
            relationshipType: c.relationshipType!,
          })),
          careTypes: [appointment.careType],
          geoState: values.geoState as StateCodes,
        },
        intakeAppointment: {
          startTime: new Date(appointment.startTime.format()),
          endTime: new Date(appointment.endTime.format()),
          providerId: appointment.provider.id,
          // if appointment was created using the dedicated group model allocations, the appointment.organizationId value will be null
          organizationId: appointment.organizationId,
        },
      },
    });
  });

  return (
    <Modal isOpen onClose={onClose}>
      <FormProvider {...form}>
        <div className="flex">
          <div className="flex-1" style={{ background: '#EFEFEF', padding: '55px 62px' }}>
            <Text.h2 className="mb4">Create student account to book appointment</Text.h2>
            {loading ? (
              <div className="flex justify-center pv3">
                <MantraSpinner size={25} simple />
              </div>
            ) : (
              <div>
                <Text.bodyBold>
                  {description} {`(${duration} min)`}
                </Text.bodyBold>
                <Text.body className="flex items-center">
                  <Icon icon="iconsBlackApptSvg" alt="Calendar" className="mr1" />
                  {appointment.startTime.format('M/D/YYYY h:mm a')} -{' '}
                  {appointment.endTime.clone().format('h:mma z')}
                </Text.body>
                <div className="mt4 flex flex-row gap-3 items-center">
                  <Avatar
                    size="3rem"
                    overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
                    src={
                      data?.providersById.find(p => p.id === appointment.provider.id)?.portrait
                        ?.url ?? icons.assetsDefaultPicturePng
                    }
                    name={appointment.provider.name}
                  />
                  <div>
                    <Text.h3>
                      {appointment.provider.name.split(' ').map(capitalize).join(' ')}
                    </Text.h3>
                    <Text.bodySmall kind="grayText">
                      {getDisplayableType(appointment.provider)}
                    </Text.bodySmall>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex-1" style={{ padding: '55px 62px' }}>
            {mutation.error && (
              <Notification kind="negative">
                {mutation.error.message ?? 'An unexpected error occurred.'}
              </Notification>
            )}
            <div className="flex">
              <Label name="firstName" label="First Name" className="mb0">
                <InputRHF
                  name="firstName"
                  rules={{ required }}
                  controlProps={{ className: 'mb2' }}
                />
              </Label>
              <Label name="lastName" label="Last Name" className="ml3 mb0">
                <InputRHF
                  name="lastName"
                  rules={{ required }}
                  controlProps={{ className: 'mb2' }}
                />
              </Label>
            </div>
            {showPreferredName ? (
              <Label name="preferredName" label="Preferred Name" className="mb0">
                <InputRHF name="preferredName" />
              </Label>
            ) : (
              <Text.linkButton className="b mb3" onClick={() => setShowPreferredName(true)}>
                + Add Preferred Name
              </Text.linkButton>
            )}
            <Label name="birthDate" label="Date of Birth">
              <DateInputRHF
                name="birthDate"
                rules={{
                  required,
                }}
              />
            </Label>
            <Label name="email" label="Student University Email Address">
              <InputRHF name="email" rules={{ required }} />
            </Label>
            <Label name="geoState" label="Student Residential State">
              <SelectRHF
                name="geoState"
                options={stateOptions}
                rules={{
                  required,
                }}
              />
            </Label>
            <Label name="phone" label="Student Phone Number">
              <PhoneInputRHF name="phone" rules={{ required }} />
            </Label>
            {data && data.organization.children.length > 0 && (
              <Label name="childOrganizationId" label="Campus">
                <SelectRHF
                  name="childOrganizationId"
                  options={
                    data?.organization.children.map(org => ({ id: org.id, label: org.name })) ?? []
                  }
                  rules={{ required }}
                />
              </Label>
            )}
            <When
              isTruthy={
                featureFlags.includes('CTC_ENHANCED_COLLABORATION') && isNumber(organizationId)
              }
            >
              <CampusTeamSelection
                campusTeam={campusTeam ?? []}
                setCampusTeam={c => setCampusTeam(c)}
                organizationId={organizationId}
              />
            </When>
            <When isTruthy={!featureFlags.includes('CTC_ENHANCED_COLLABORATION')}>
              <Text.h3 className="mb3 mt3">Campus Collaboration Team</Text.h3>
              <FormControl
                name="primaryCareTeamId"
                label={
                  <label htmlFor="primaryCareTeamId" className="mt3">
                    <Text.bodyBold>Student’s Primary Collaborator</Text.bodyBold>
                    <Text.bodySmallGrey>
                      Therapist/counselor or main staff member responsible for this student’s care
                    </Text.bodySmallGrey>
                  </label>
                }
              >
                <SelectRHF
                  name="primaryCareTeamId"
                  id="careTeamPrimary"
                  options={[emptyOption, ...providerOptions]}
                  rules={{ required }}
                  clearable={false}
                  placeholder="Select"
                />
              </FormControl>
              <FormControl
                name="secondaryCareTeamIds"
                label={
                  <label htmlFor="secondaryCareTeamIds" className="mt3">
                    <Text.bodyBold>Additional Collaborator(s)</Text.bodyBold>
                    <Text.bodySmallGrey>
                      Other staff members at the university that work directly with this student
                    </Text.bodySmallGrey>
                  </label>
                }
              >
                <MultiSelectRHF
                  name="secondaryCareTeamIds"
                  options={providerOptions}
                  rules={{
                    validate: value =>
                      !value ||
                      !value.includes(form.getValues('primaryCareTeamId')) ||
                      'You cannot pick the same provider for "Primary Collaborator" and "Additional Collaborators".',
                  }}
                  placeholder="Select"
                />
              </FormControl>
            </When>
            <FinalButton
              kind="primary"
              className="w-100"
              type="submit"
              onClick={onSubmit}
              loading={mutation.loading}
            >
              Submit
            </FinalButton>
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};

type EnrollmentSuccessProps = BookingSuccess;

export const EnrollmentSuccess = ({ appointment, user }: EnrollmentSuccessProps) => {
  const history = useHistory();
  const { duration, description } = getApptDetails(appointment);
  return (
    <SuccessWrapper>
      <Icon icon="iconsCircleCheckSvg" alt="Success" className="center db" size={26} />
      <div className="mh2">
        <Text.h2>Appointment booked!</Text.h2>
        <Text.body className="mb4">
          Student will receive a confirmation email with appointment details.
        </Text.body>
        <Text.body>
          This appointment will take place in the student’s Mantra Health account. Please advise
          student to complete onboarding before their appointment, including reviewing &amp;
          agreeing to all releases.
        </Text.body>
        <Box className="mv4">
          <Text.body>{getFullName(user)}</Text.body>
          <Text.body>
            {description} {`(${duration} min) with ${appointment.provider.name}`}
          </Text.body>
          <Text.body className="flex items-center">
            <Icon icon="iconsBlackApptSvg" alt="Calendar" className="mr1" />
            {appointment.startTime.format('M/D/YYYY h:mm a')} -{' '}
            {appointment.endTime.clone().format('h:mma z')}
          </Text.body>
        </Box>
        <FinalButton
          kind="outline_black"
          className="w-100 mb3"
          onClick={() => history.push(`/users/${user.id}`)}
        >
          Go to patient profile
        </FinalButton>
      </div>
    </SuccessWrapper>
  );
};

export const SuccessWrapper = styled.div`
  max-width: 400px;
  margin: 4rem auto;
`;

const getApptDetails = (appointment: AppointmentOption) => {
  const duration = moment(appointment.endTime).diff(appointment.startTime, 'minutes');
  const description =
    appointment.careType === 'Psychiatry'
      ? 'Initial Psychiatric Evaluation'
      : 'Initial Therapy Evaluation';
  return { duration, description };
};
