import React, { useState } from 'react';
import { PillCheckbox } from '../../../../Components/EzForm';
import { FinalButton } from '../../../../Components/FinalButton';
import { Icon } from '../../../../Components/Icons';
import { Text } from '../../styles';

interface Props {
  onConfirm: (value: boolean) => void;
  loading?: boolean;
}

export const NeedsReferralQuestion = ({ onConfirm, loading }: Props) => {
  const [needsReferral, setNeedsReferral] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState('');
  let needsReferralText;
  if (needsReferral !== undefined) needsReferralText = needsReferral ? 'Yes' : 'No';

  const confirm = () => {
    if (needsReferral === undefined) return setError('Please make a selection.');
    onConfirm(needsReferral);
  };

  return (
    <div>
      <Text.h2>Does the patient need a referral?</Text.h2>
      <PillCheckbox
        options={['Yes', 'No']}
        onChange={value => setNeedsReferral(value === 'Yes')}
        value={needsReferralText}
        multi={false}
        row
        selectedIcon={<Icon icon="iconsBlackCheckSvg" alt="Check Mark" />}
      />
      {error && (
        <Text.body className="mv3" kind="danger">
          {error}
        </Text.body>
      )}
      <div className="mt4">
        <FinalButton onClick={confirm} loading={loading} className="w-100">
          Confirm
        </FinalButton>
      </div>
    </div>
  );
};
