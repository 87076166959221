import { NetworkStatus } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import * as mixpanel from 'mixpanel-browser';
import React, { createContext, useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  CurrentProviderQuery,
  Permission,
  useCurrentProviderQuery,
  useLogoutMutation,
} from '../../graphQL';
import { UnexpectedError } from '../../Pages/Shared';
import { clearAuthToken } from '../../token';
import { useConfigContext } from '../ConfigContext';
import { MIXPANEL_KEY } from '../Events/EventsProvider';
import { hasDedicatedGroupModel } from '../Organization/organizationUtils';
import { CopyKey, thesaurus } from './thesaurus';

const useAuthProvider = () => {
  const history = useHistory();
  const { data, refetch, loading, networkStatus } = useCurrentProviderQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [logoutMutate] = useLogoutMutation();
  const { featureFlags } = useConfigContext();

  useEffect(() => {
    const currentProvider = data?.currentProvider;
    if (currentProvider) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: String(currentProvider.id), username: currentProvider.name });
      });
      if (MIXPANEL_KEY) {
        mixpanel.identify(currentProvider.id.toString());
        mixpanel.people?.set({
          $distinct_id: currentProvider.id.toString(),
          $first_name: currentProvider.firstName,
          $last_name: currentProvider.lastName,
          role: currentProvider.role,
        });
      }
    }
  }, [data]);

  if (!loading && !data) throw new Error();
  if (!data || !data.currentProvider) return null;

  const logout = () => {
    logoutMutate();
    clearAuthToken();
    history.push('/login');
  };

  return {
    ...data,
    featureFlags,
    refetch,
    logout,
    refetching: networkStatus === NetworkStatus.refetch,
    ...createCurrentProviderHelpers(data.currentProvider, featureFlags),
  };
};

export function createCurrentProviderHelpers(
  { appView, permissions, id, organizations }: CurrentProviderQuery['currentProvider'],
  featureFlags: string[]
) {
  const getCopy = (copy: CopyKey) => thesaurus[appView][copy];
  const perms = new Set(permissions);
  const hasPermission = (check: Permission) => perms.has(check);
  const providerOrgUsesDedicatedGroupModel = () => {
    // This check works for mcp providers/admins who have an organization, but will never work for oz admins who have no org
    const mainOrg = organizations[0];
    return mainOrg && hasDedicatedGroupModel(mainOrg, featureFlags);
  };
  const isTheCurrentProvider = (idCheck: Number) => idCheck === id;

  return {
    appView,
    getCopy,
    hasPermission,
    providerOrgUsesDedicatedGroupModel,
    isTheCurrentProvider,
  };
}

export type CurrentProviderContext = NonNullable<ReturnType<typeof useAuthProvider>>;
export const Context = createContext<CurrentProviderContext | null>(null);

export const useCurrentProvider = () => {
  const context = useContext(Context);
  if (!context) throw new Error('Must be logged in to access current Provider');
  return context;
};

// Authprovider and error boundary
export const AuthProvider: React.FC = ({ children }) => {
  try {
    const data = useAuthProvider();
    if (!data) return null;

    // MCP / referral providers must belong to an ORG
    if (['mcp', 'referral'].includes(data.appView) && !data.currentProvider.organizations[0])
      return (
        <div className="flex flex-column justify-center items-center mt4">
          <h2>You are not assigned to an organization</h2>
          <p>
            Please <a href="mailto:hi@mantrahealth.com">contact us</a> to get this resolved.
          </p>
          <button type="button" className="mt3 pointer" onClick={data.logout}>
            Log Out
          </button>
        </div>
      );

    if (data.currentProvider.accountSecurityStatus !== 'ok' && !data.currentProvider.hasSso)
      return <Redirect to="/changepassword" />;
    return <Context.Provider value={data}>{children}</Context.Provider>;
  } catch (e) {
    return <UnexpectedError />;
  }
};
