import { GraphQLError } from 'graphql';
import { pick } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FinalButton } from '../../../../Components/FinalButton';
import {
  CheckboxRHF,
  DateInputRHF,
  InputRHF,
  MaskedInputRHF,
  RadioRHF,
  SelectRHF,
} from '../../../../Components/Form';
import { Icon } from '../../../../Components/Icons';
import { LoadingPage } from '../../../../Components/LoadingOverlay';
import {
  MantraModalBodyOld,
  MantraModalHeaderOld,
  modalFactory,
} from '../../../../Components/Modal';
import { useCurrentProvider } from '../../../../Components/Permissions';
import { WizardRenderProps } from '../../../../Components/Wizard';
import { Text } from '../../../../globalStyles';
import {
  InsuranceDetailsInput,
  PatientRelationshipToSubscriber,
  PaymentSource,
  useSetUserInsuranceMutation,
  useUpdateCareFlowForUserMutation,
} from '../../../../graphQL';
import { stateOptions } from '../../../../states';
import { borderRadius, camelCaseToWords, padding } from '../../../../utils';
import { WizardContentWithBack } from '../../Common';
import { InsuranceDetails, WizardData } from '../types';

const birthDateFormat = 'MM/DD/YYYY';
const required = 'required';

const insuranceSubscriberKeys = [
  'subscriberFirstName',
  'subscriberLastName',
  'subscriberDateOfBirth',
  'subscriberGender',
  'patientRelationshipToSubscriber',
] as const;

type InsuranceSubscriber = Pick<InsuranceDetailsInput, typeof insuranceSubscriberKeys[number]>;
type InsuranceForm = Omit<InsuranceDetails, 'payerName'> &
  InsuranceSubscriber & {
    isPolicyHolder: boolean;
    consentedToCheck: boolean;
  };

const benefits = {
  selfPay: {
    title: 'SELF-PAY PRICING',
    offerings: [
      { title: 'Initial Psychiatric Evaluation', price: 180 },
      { title: 'Psychiatry Follow-ups', price: 125 },
    ],
  },
  inNetwork: {
    title: 'IN-NETWORK BENEFITS',
    offerings: [
      {
        title: 'Copay',
        price: 50,
        hint: true,
        details: 'Patient will $50 after each visit with a provider that accepts your plan.',
      },
    ],
  },
  outOfNetwork: {
    title: 'OUT-OF-NETWORK / SELF-PAY PRICING',
    offerings: [
      {
        title: 'Initial Psychiatric Evaluation',
        price: 180,
      },
      {
        title: 'Psychiatry Follow-ups',
        price: 125,
      },
    ],
  },
} as const;

const Modal = modalFactory({
  style: { maxWidth: '490px', ...borderRadius('4px'), ...padding('32px') },
});

export const InsuranceStep = ({
  prevStep,
  nextStep,
  setData,
  data,
}: WizardRenderProps<WizardData>) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentProvider } = useCurrentProvider();
  const [insuranceOutcome, setInsuranceOutcome] = useState<
    'inNetwork' | 'outOfNetwork' | 'invalid' | 'selfPay' | null
  >(null);

  // only on initial mount
  useEffect(() => {
    if (insuranceOutcome !== null) return;
    if (data.paymentSource === PaymentSource.Insurance && data.insuranceDetails) {
      setInsuranceOutcome('inNetwork');
    } else if (data.paymentSource === PaymentSource.OonInsurance && data.insuranceDetails) {
      setInsuranceOutcome('outOfNetwork');
    }
  }, [setInsuranceOutcome, data, insuranceOutcome]);

  const userBirthDate = data.user.birthDate
    ? moment(data.user.birthDate).format(birthDateFormat)
    : undefined;

  const form = useForm<InsuranceForm>({
    defaultValues: {
      isPolicyHolder: true,
      firstName: data.insuranceDetails?.firstName ?? data.user.firstName,
      lastName: data.insuranceDetails?.lastName ?? data.user.lastName,
      payerId: data.insuranceDetails?.payerId,
      state: data.insuranceDetails?.state,
      memberId: data.insuranceDetails?.memberId,
      birthDate: data.insuranceDetails?.birthDate
        ? moment(data.insuranceDetails?.birthDate).format(birthDateFormat)
        : userBirthDate,
    },
  });

  const [updateCareFlow] = useUpdateCareFlowForUserMutation();
  const [setInsurance] = useSetUserInsuranceMutation();
  const payerOptions = data.payers.map(p => ({ id: p.id, label: p.name }));

  const submit = form.handleSubmit(async fields => {
    setLoading(true);
    try {
      const payerName = data.payers.find(p => p.id === fields.payerId)!.name;

      const insuranceSubscriber: InsuranceSubscriber = fields.isPolicyHolder
        ? { patientRelationshipToSubscriber: PatientRelationshipToSubscriber.Self }
        : { ...pick(fields, insuranceSubscriberKeys) };

      const resp = await setInsurance({
        variables: {
          userId: data.user.id,
          insurance: {
            payerId: fields.payerId,
            memberId: fields.memberId,
            firstName: fields.firstName,
            lastName: fields.lastName,
            birthDate: fields.birthDate,
            state: fields.state,
            enteredByProviderId: currentProvider.id,
            ...insuranceSubscriber,
          },
        },
      });
      const { inNetwork, valid } = resp.data?.setUserInsurance ?? {};
      setData({
        ...data,
        insuranceDetails: { payerName, ...fields },
      });
      if (!valid) setInsuranceOutcome('invalid');
      else setInsuranceOutcome(inNetwork ? 'inNetwork' : 'outOfNetwork');
    } catch (e) {
      const errMessage = e instanceof GraphQLError ? e.message : 'Internal Error';
      setError(errMessage);
      throw e;
    } finally {
      setLoading(false);
    }
  });

  const onConfirmOutcome = async () => {
    let updatedPaymentSource: PaymentSource | undefined;

    if (insuranceOutcome === 'selfPay') {
      updatedPaymentSource = PaymentSource.SelfPay;
    } else if (insuranceOutcome === 'inNetwork') {
      updatedPaymentSource = PaymentSource.Insurance;
    } else if (insuranceOutcome === 'outOfNetwork') {
      updatedPaymentSource = PaymentSource.OonInsurance;
    }
    if (updatedPaymentSource) {
      try {
        setLoading(true);
        await updateCareFlow({
          variables: {
            userId: data.user.id,
            careFlow: { careType: data.careType, paymentSource: updatedPaymentSource },
          },
        });
        nextStep({ ...data, paymentSource: updatedPaymentSource });
      } finally {
        setLoading(false);
      }
    }
  };

  const { consentedToCheck, isPolicyHolder } = form.watch(['consentedToCheck', 'isPolicyHolder']);

  if (loading) {
    return <LoadingPage />;
  }

  if (insuranceOutcome === 'inNetwork') {
    return (
      <WizardContentWithBack>
        <Text.h3 className="mb4">Great news! Mantra accepts this insurance plan.</Text.h3>
        <Text.bodySmall className="mb3">
          Insurance claims will be submitted to the insurer for the covered portion of the patient’s
          appointment.
        </Text.bodySmall>
        <Benefits {...benefits.inNetwork} />
        <AgreementList />
        <FinalButton onClick={onConfirmOutcome} className="w-100">
          Select an appointment
        </FinalButton>
      </WizardContentWithBack>
    );
  }

  if (insuranceOutcome === 'outOfNetwork') {
    return (
      <WizardContentWithBack onBack={() => setInsuranceOutcome(null)}>
        <Text.h3 className="mb4">This plan is not in-network. Proceed as out-of-network?</Text.h3>
        <OutOfNetworkNote className="mb3">
          <Text.body>
            <b>Note: </b>
            Out-of-network benefits are not guaranteed, and a care navigator will reach out before
            the first appointment for more plan details if we cannot validate the entered insurance
            plan.
          </Text.body>
        </OutOfNetworkNote>
        <Text.body className="mb3">
          A patient can continue with out-of-network insurance and will pay the cash-pay rate for
          each session.
        </Text.body>
        <Text.body className="mb3">
          If you proceed to book an appointment with out-of-network insurance,{' '}
          <b>we will submit a claim on the patient’s behalf</b> to their insurance company, who may
          reimburse the patient directly, if the patient has out-of-network benefits.
        </Text.body>
        <Text.body className="mb4">
          If the patient <b>does not</b> want us to submit a claim on their behalf, but is still
          willing to pay the self-pay pricing indicated below, then click “Continue as a self-pay
          patient”.
        </Text.body>
        <Benefits {...benefits.outOfNetwork} />
        <AgreementList />
        <FinalButton onClick={onConfirmOutcome} className="w-100 mb4">
          Continue with out-of-network insurance
        </FinalButton>
        <FinalButton kind="outline_black" className="w-100">
          Continue as self-pay patient
        </FinalButton>
      </WizardContentWithBack>
    );
  }

  return (
    <>
      <Modal
        isOpen={!!error || insuranceOutcome === 'invalid'}
        onClose={() => {
          setInsuranceOutcome(null);
          setError(null);
        }}
      >
        <MantraModalHeaderOld>
          <Text.h3>There was an issue finding this insurance plan</Text.h3>
        </MantraModalHeaderOld>
        <MantraModalBodyOld>
          <Text.body className="mb3">
            We weren’t able to look up this patient’s benefits with our eligibility checker.
          </Text.body>
          <Text.body className="mb4">
            Please review the entered info to make sure it matches the insurance card exactly.
          </Text.body>
          <FinalButton
            onClick={() => {
              setInsuranceOutcome(null);
              setError(null);
            }}
            className="w-100 mb3"
          >
            Review &amp; edit
          </FinalButton>
          <FinalButton
            kind="outline_black"
            className="w-100"
            onClick={() => {
              setInsuranceOutcome('selfPay');
              setError(null);
            }}
          >
            Continue without insurance
          </FinalButton>
        </MantraModalBodyOld>
      </Modal>
      <Modal
        isOpen={!error && insuranceOutcome === 'selfPay'}
        onClose={() => setInsuranceOutcome(null)}
      >
        <MantraModalHeaderOld>
          <Text.h3>Continue booking as self-pay patient?</Text.h3>
        </MantraModalHeaderOld>
        <MantraModalBodyOld>
          <Text.body className="mb3">
            Self-pay patients are billed the self-pay rates after each Mantra visit.
          </Text.body>
          <Benefits {...benefits.selfPay} />
          <AgreementList />
          <div className="flex flex-column items-center">
            <FinalButton onClick={onConfirmOutcome} className="w-100 mb3">
              Continue booking as self-pay patient
            </FinalButton>
            <Text.linkButton
              kind="grayText"
              className="b mb4 mt2"
              onClick={() => setInsuranceOutcome(null)}
            >
              Close
            </Text.linkButton>
          </div>
        </MantraModalBodyOld>
      </Modal>
      <WizardContentWithBack data-cy="booking-insurance-step" onBack={() => prevStep()}>
        <Text.h3 className="mb3">Add insurance details to book appointment</Text.h3>
        <Text.bodySmall className="mb4">
          Coverage depends on the insurance company and the state in which the patient is insured.
          To verify coverage, please enter the information below according to how it appears on the
          patient’s health insurance card.
        </Text.bodySmall>
        <FormProvider {...form}>
          <div className="flex flex-column gap-3">
            <SelectRHF
              name="payerId"
              options={payerOptions}
              controlProps={{ label: 'Insurance Provider', required: true }}
              rules={{ required }}
            />
            <SelectRHF
              name="state"
              options={stateOptions}
              controlProps={{ label: 'Insurance State', required: true }}
              rules={{ required }}
            />
            <InputRHF
              name="memberId"
              controlProps={{ label: 'Member ID', required: true }}
              rules={{ required }}
            />
            <InputRHF
              name="firstName"
              controlProps={{ label: 'First Name', required: true }}
              rules={{ required }}
            />
            <InputRHF
              name="lastName"
              controlProps={{ label: 'Last Name', required: true }}
              rules={{ required }}
            />
            <DateInputRHF
              name="birthDate"
              controlProps={{ label: 'Date of Birth', required: true }}
              rules={{
                validate: {
                  validDate: (v: string) =>
                    moment(v, 'MM/DD/YYYY').isValid() || 'Please enter a valid date.',
                },
              }}
            />
            <div>
              <RadioRHF
                controlProps={{
                  label: 'This patient is the policyholder for this plan',
                  required: true,
                }}
                withLabel
                name="isPolicyHolder"
                options={[
                  { id: 1, label: 'Yes, they are the policyholder' },
                  { id: 0, label: 'No, they are a dependent' },
                ]}
              />
            </div>
            {!isPolicyHolder && (
              <>
                <InputRHF
                  name="subscriberFirstName"
                  controlProps={{ label: 'Policyholder First Name', required: true }}
                  rules={{ required: !isPolicyHolder ? required : undefined }}
                />
                <InputRHF
                  name="subscriberLastName"
                  controlProps={{ label: 'Policyholder Last Name', required: true }}
                  rules={{ required: !isPolicyHolder ? required : undefined }}
                />
                <MaskedInputRHF
                  name="subscriberDateOfBirth"
                  mask="99/99/9999"
                  controlProps={{ label: 'Policyholder Date of Birth', required: true }}
                  rules={{
                    validate: !isPolicyHolder
                      ? {
                          validDate: (v: string) =>
                            moment(v, 'MM/DD/YYYY').isValid() || 'Please enter a valid date.',
                        }
                      : undefined,
                  }}
                />
                <SelectRHF
                  name="patientRelationshipToSubscriber"
                  controlProps={{ label: 'Relationship to Patient', required: true }}
                  rules={{ required: !isPolicyHolder ? required : undefined }}
                  options={Object.entries(PatientRelationshipToSubscriber).map(([id, value]) => ({
                    id,
                    label: camelCaseToWords(value),
                  }))}
                />
                <RadioRHF
                  controlProps={{
                    label: 'Policyholder Gender',
                    required: !isPolicyHolder,
                  }}
                  withLabel
                  name="subscriberGender"
                  options={['Female', 'Male'].map(v => ({ id: v, label: v }))}
                />
              </>
            )}
            <CheckboxRHF
              name="consentedToCheck"
              controlProps={{ label: 'Consent to check patient eligibility', required: true }}
            >
              <Text.bodySmall>
                I confirm that I have this patient’s consent to check their insurance plan
                eligibility.
              </Text.bodySmall>
            </CheckboxRHF>
          </div>
          <FinalButton className="w-100 mv3" onClick={submit} disabled={!consentedToCheck}>
            Check eligibility
          </FinalButton>
          <FinalButton
            className="w-100"
            kind="outline_black"
            onClick={() => setInsuranceOutcome('selfPay')}
          >
            This patient does not have insurance
          </FinalButton>
        </FormProvider>
      </WizardContentWithBack>
    </>
  );
};

type BenefitsProps = {
  title: string;
  offerings: readonly {
    title: string;
    hint?: boolean;
    price: number;
    details?: string;
  }[];
};

const Benefits = ({ title, offerings }: BenefitsProps) => {
  return (
    <BenefitsCardContainer>
      <Text.label kind="primary" className="mb3">
        {title}
      </Text.label>
      <div className="flex flex-column gap-1">
        {offerings.map(o => (
          <div className="flex flex-row justify-between gap-2 items-start" key={o.title}>
            <div>
              <div className="flex flex-row gap-1 items-center">
                <Text.bodyBold>{o.title}</Text.bodyBold>
                {o.hint && <Icon icon="iconsBlackQuestionCircleSvg" size={12} />}
              </div>
              {o.details && <Text.bodySmallGrey>{o.details}</Text.bodySmallGrey>}
            </div>
            <Text.body>${o.price}</Text.body>
          </div>
        ))}
      </div>
    </BenefitsCardContainer>
  );
};

const AgreementList = () => (
  <div className="mb4">
    <Text.bodySmall className="b">
      Please review prices above with patient and ensure that the patient agrees to:
    </Text.bodySmall>
    <ul className="ph3 f5">
      <li>
        <Text.bodySmall>Pay for visits at self-pay prices above</Text.bodySmall>
      </li>
      <li>
        <Text.bodySmall>
          Pay a $50 no-show fee if they do not attend a scheduled appointment
        </Text.bodySmall>
      </li>
      <li>
        <Text.bodySmall>
          Add payment method and complete onboarding before appointment
        </Text.bodySmall>
      </li>
    </ul>
  </div>
);

const BenefitsCardContainer = styled.div`
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid #dadada;
  margin-bottom: 1.75rem;
`;

const OutOfNetworkNote = styled.div`
  background: #e5f1ff;
  padding: 1rem;
`;
