import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { lowerFirst, pick } from 'lodash';
import React, { useState } from 'react';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import {
  CareType,
  OrganizationCareFlowInput,
  useOrganizationCareFlowsQuery,
  useUpdateOrganizationCareFlowsMutation,
  PaymentSource,
  StateCodes,
  SponsoredSessionsLimitType,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';
import { MultiSelect, Select } from '../../Components/Form';
import { stateOptions } from '../../states';
import { useCurrentProvider } from '../../Components/Permissions';
import { Flag } from '../../featureFlags';

const availableCareTypes = [CareType.Psychiatry, CareType.Therapy];

interface EditableCareFlow extends OrganizationCareFlowInput {
  limitSponsoredSessions: boolean;
  statesAreLimited: boolean;
}

export function OrganizationCareFlows() {
  const organizationId = useOrganizationId();
  const [careFlows, setCareFlows] = useState<EditableCareFlow[]>([]);
  const { loading, error, data } = useOrganizationCareFlowsQuery({
    variables: { organizationId },
    onCompleted: d =>
      setCareFlows(
        d.organization.careFlows.map(flow => ({
          ...flow,
          sponsoredSessionsLimitType: flow.sponsoredSessionsLimitType,
          limitSponsoredSessions: typeof flow.sponsoredSessions === 'number',
          statesAreLimited: flow.referralRestrictedStates.length > 0,
        }))
      ),
  });
  const [mutate, mutation] = useUpdateOrganizationCareFlowsMutation();
  const [saveIsDisabled, preventSave] = useState(false);

  const readOnly = !!data?.organization.parent;

  if (error) return <UnexpectedError />;
  if (loading) return <LoadingPage />;

  const onEnable = (careType: CareType) => {
    setCareFlows(val => [
      ...val,
      {
        careType,
        sponsoredSessionsLimitType: SponsoredSessionsLimitType.ShortTermUncapped,
        limitSponsoredSessions: false, // this is front-end only, isn't used in the API
        sponsoredSessions: 0,
        canMcpUsersRefer: true,
        canReferrerUsersRefer: true,
        canSelfRefer: false,
        defaultPaymentSource: PaymentSource.Organization,
        statesAreLimited: false,
        referralRestrictedStates: [],
      },
    ]);
  };

  const onDisable = (careType: CareType) => {
    setCareFlows(current => {
      const idx = current.findIndex(i => i.careType === careType);
      return [...current.slice(0, idx), ...current.slice(idx + 1)];
    });
  };

  const onEdit = (value: EditableCareFlow) => {
    setCareFlows(current => {
      const idx = current.findIndex(i => i.careType === value.careType);
      const newCareFlows = [...current.slice(0, idx), value, ...current.slice(idx + 1)];

      const shouldPreventSave = newCareFlows.some(careTypeFlow => {
        return careTypeFlow.statesAreLimited && !careTypeFlow.referralRestrictedStates.length;
      });

      preventSave(shouldPreventSave);

      return newCareFlows;
    });
  };

  const onSave = () => {
    mutate({
      variables: {
        organizationId,
        careFlows: careFlows.map(i =>
          pick(i, [
            'careType',
            'sponsoredSessions',
            'sponsoredSessionsLimitType',
            'canMcpUsersRefer',
            'canReferrerUsersRefer',
            'canSelfRefer',
            'defaultPaymentSource',
            'referralRestrictedStates',
          ])
        ) as OrganizationCareFlowInput[],
      },
    });
  };

  return (
    <div>
      <div className="mb4">
        <h2>Care Types</h2>
        {data?.organization.parent && (
          <p>
            Inherits care types from{' '}
            <a href={`/organizations/${data.organization.parent.id}/admin`}>
              {data.organization.parent.name ?? 'Parent'}
            </a>
          </p>
        )}
      </div>
      {availableCareTypes.map(careType =>
        careFlows.find(i => i.careType === careType) ? (
          <EnabledCareFlow
            readOnly={readOnly}
            key={careType}
            value={careFlows.find(i => i.careType === careType)!}
            onDisable={() => onDisable(careType)}
            onEdit={onEdit}
          />
        ) : (
          <DisabledCareFlow
            readOnly={readOnly}
            key={careType}
            careType={careType}
            onEnable={() => onEnable(careType)}
          />
        )
      )}
      <div className="flex items-center">
        {!readOnly && (
          <FinalButton onClick={onSave} loading={mutation.loading} disabled={saveIsDisabled}>
            Save
          </FinalButton>
        )}
        {mutation.data && <div className="ml3">Saved.</div>}
        {mutation.error && <div className="ml3">An error occurred.</div>}
      </div>
    </div>
  );
}

interface DisabledCareFlowProps {
  careType: CareType;
  onEnable: () => void;
  readOnly: boolean;
}

function DisabledCareFlow({ careType, onEnable, readOnly }: DisabledCareFlowProps) {
  return (
    <label className="flex flex-row items-center mb4">
      <Checkbox name={careType} disabled={readOnly} onChange={onEnable} />
      <h3>{careType}</h3>
    </label>
  );
}

interface EnabledCareFlowProps {
  onDisable: () => void;
  value: EditableCareFlow;
  onEdit: (value: EditableCareFlow) => void;
  readOnly: boolean;
}

function EnabledCareFlow({ value, onDisable, onEdit, readOnly }: EnabledCareFlowProps) {
  const { featureFlags } = useCurrentProvider();
  const onChangeBoolean = (key: keyof EditableCareFlow) => {
    return (event: React.FormEvent<HTMLInputElement>) => {
      onEdit({ ...value, [key]: event.currentTarget.checked });
    };
  };

  return (
    <div className="mb4">
      <div>
        <label className="inline-flex flex-row items-center b">
          <Checkbox name={value.careType} disabled={readOnly} checked onChange={onDisable} />
          <h3>{value.careType}</h3>
        </label>
      </div>
      <div className="ml4">
        <div>
          <label
            className="mt3 mb2 inline-flex flex-row items-center b"
            data-testid={`${value.careType}.canMcpUsersRefer`}
          >
            <Checkbox
              disabled={readOnly}
              checked={value.canMcpUsersRefer}
              onChange={onChangeBoolean('canMcpUsersRefer')}
            />
            Allow MCP users to refer
          </label>
        </div>
        <div>
          <label
            className="mt3 mb2 inline-flex flex-row items-center b"
            data-testid={`${value.careType}.canReferrerUsersRefer`}
          >
            <Checkbox
              disabled={readOnly}
              checked={value.canReferrerUsersRefer}
              onChange={onChangeBoolean('canReferrerUsersRefer')}
            />
            Allow referral portal users to refer, if referral role entitlement is enabled
          </label>
        </div>
        <div>
          <label
            className="mt3 mb2 inline-flex flex-row items-center b"
            data-testid={`${value.careType}.canSelfRefer`}
          >
            <Checkbox
              disabled={readOnly}
              checked={value.canSelfRefer}
              onChange={onChangeBoolean('canSelfRefer')}
            />
            Allow patients to self-refer, if self-referral entitlement is enabled
          </label>
        </div>
        {/* Default payment source */}
        <div className="mt3 mb2 flex flex-row items-center b">
          <span className="mr3">Default payment source:</span>
          <div style={{ maxWidth: 300 }} className="normal">
            <Select
              value={value.defaultPaymentSource}
              options={[
                { id: PaymentSource.Organization, label: 'Sponsored Care' },
                { id: PaymentSource.SelfPay, label: 'Self-Pay' },
              ]}
              onChange={val => onEdit({ ...value, defaultPaymentSource: val as PaymentSource })}
            />
          </div>
        </div>
        {/* Sponsored Session Limit Checkbox (before CSC feature flag) */}
        {!featureFlags.includes(Flag.CscHandbook) && (
          <div>
            <label className="mt3 mb2 inline-flex flex-row items-center b">
              <Checkbox
                disabled={readOnly}
                checked={value.limitSponsoredSessions}
                onChange={event =>
                  onEdit({
                    ...value,
                    limitSponsoredSessions: event.currentTarget.checked,
                    sponsoredSessions: event.currentTarget.checked ? 0 : null,
                  })
                }
              />
              Limit the number of sponsored sessions per patient
            </label>
          </div>
        )}
        {/* Sponsored Session Limit Number Input (before CSC feature flag) */}
        {!featureFlags.includes(Flag.CscHandbook) && value.limitSponsoredSessions && (
          <div className="mt3 mb2 ml4 flex flex-row items-center">
            <div className="mr2">Sponsored {lowerFirst(value.careType)} sessions:</div>
            <div style={{ width: 100 }}>
              <Input
                disabled={readOnly}
                value={value.sponsoredSessions ?? '0'}
                onChange={event =>
                  onEdit({
                    ...value,
                    sponsoredSessions: Number(event.currentTarget.value) ?? 0,
                  })
                }
              />
            </div>
          </div>
        )}
        {/* Sponsored Session Limit Type Dropdown (After CSC feature flag) */}
        {featureFlags.includes(Flag.CscHandbook) && (
          <div className="flex items-center b">
            <span className="mr3">Limit the number of sponsored sessions per patient</span>
            <div style={{ width: 270 }} className="normal">
              <Select
                value={value.sponsoredSessionsLimitType}
                disabled={readOnly}
                options={[
                  {
                    id: SponsoredSessionsLimitType.ShortTermUncapped,
                    label: 'Short-term uncapped',
                  },
                  { id: SponsoredSessionsLimitType.ShortTermCapped, label: 'Short-term capped' },
                  {
                    id: SponsoredSessionsLimitType.Unrestricted,
                    label: 'Unrestricted (Refer when able)',
                  },
                  {
                    id: SponsoredSessionsLimitType.StudentAssistanceProgram,
                    label: 'Student Assistance Program (Evaluate and refer)',
                  },
                ]}
                onChange={newValue =>
                  onEdit({
                    ...value,
                    sponsoredSessionsLimitType: newValue as SponsoredSessionsLimitType,
                    sponsoredSessions:
                      newValue !== SponsoredSessionsLimitType.ShortTermCapped ? null : 0,
                  })
                }
              />
            </div>
          </div>
        )}
        {/* Sponsored Sessions Limit number entry: appears for short term capped session limit (After CSC feature flag) */}
        {featureFlags.includes(Flag.CscHandbook) &&
          value.sponsoredSessionsLimitType === SponsoredSessionsLimitType.ShortTermCapped && (
            <div className="mb2 ml4 mt2 flex flex-row items-center normal">
              <div className="mr2">Sponsored {lowerFirst(value.careType)} sessions:</div>
              <div style={{ width: 100 }}>
                <Input
                  disabled={readOnly}
                  value={value.sponsoredSessions ?? '0'}
                  onChange={event =>
                    onEdit({
                      ...value,
                      sponsoredSessions: Number(event.currentTarget.value) || 0,
                    })
                  }
                />
              </div>
            </div>
          )}
        {/* State Restrictions */}
        <div className="mt3 mb2 flex flex-row items-center b">
          <span className="mr3">
            State restrictions for {lowerFirst(value.careType)} referrals:
          </span>
          <div style={{ maxWidth: 300 }} className="normal">
            <Select
              disabled={readOnly}
              value={value.statesAreLimited ? 'limited' : 'all'}
              options={[
                { id: 'all', label: 'All 50 States' },
                { id: 'limited', label: 'Limited States' },
              ]}
              onChange={val => {
                onEdit({
                  ...value,
                  statesAreLimited: val === 'limited',
                  referralRestrictedStates: [],
                });
              }}
            />
          </div>
        </div>
        {value.statesAreLimited && (
          <div className="mt3 mb2 ml4">
            <span className="mr3 b">
              Students from only the following states may be referred to Mantra for{' '}
              {lowerFirst(value.careType)}:
            </span>
            <div className="mt1" style={{ maxWidth: 800 }}>
              <FormControl
                error={
                  !value.referralRestrictedStates.length
                    ? 'There must be at least 1 state selected.'
                    : undefined
                }
              >
                <MultiSelect
                  disabled={readOnly}
                  value={value.referralRestrictedStates}
                  options={stateOptions}
                  error={false}
                  onChange={val => {
                    onEdit({
                      ...value,
                      statesAreLimited: true,
                      referralRestrictedStates: val as StateCodes[],
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
