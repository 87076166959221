import { uniq } from 'lodash';
import { Moment } from 'moment';
import { TimeAllocationPurpose } from '../../../../graphQL';
import { FriendlyAllocation } from '../../util';
import { AllocationType } from './types';

export const getMsBetween = (startTime: Moment, endTime: Moment) => {
  return endTime.diff(startTime, 'milliseconds');
};

export const allocationTypeLabels: Record<AllocationType, string> = {
  default: 'Default',
  admin: 'Admin',
  intake: 'Intake Only',
  checkin: 'Follow-Up Only',
  timeOff: 'Time Off',
};

export const formatAllocation = (args: Omit<FriendlyAllocation, 'id'>) => {
  // format intake/checkin appt types as a list
  // other types are just for display and the purpose arg (below)
  const getAppointmentTypes = () => {
    return ['intake', 'checkin'].includes(args.type) ? [args.type] : null;
  };
  const getAppointmentPurpose = () => {
    if (args.type === 'admin') {
      return TimeAllocationPurpose.Admin;
    }
    if (args.type === 'timeOff') {
      return TimeAllocationPurpose.TimeOff;
    }
    return TimeAllocationPurpose.Availability;
  };
  return {
    startTime: args.startTime.toDate(),
    endTime: args.endTime.toDate(),
    organizationId: args.organizationId,
    childOrganizationIds: args.childOrganizationIds,
    purpose: getAppointmentPurpose(),
    appointmentTypes: getAppointmentTypes(),
    repeatsUntil: args.repeatsUntil ? args.repeatsUntil.toDate() : null,
    isFeeForServiceTime: args.isFeeForServiceTime,
  };
};

export const hasSingleDayIntersection = (allocations: FriendlyAllocation[], date: Moment) => {
  return allocations.some(
    a => a.startTime.isAfter(date) && !a.weekly && a.startTime.day() === date.day()
  );
};

export const getSingleDayIntersections = (allocations: FriendlyAllocation[], date: Moment) => {
  return uniq(
    allocations
      .filter(i => i.startTime.isAfter(date) && !i.weekly && i.startTime.day() === date.day())
      .map(i => i.startTime.format('M/D/YY'))
  );
};
