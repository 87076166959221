import { Breadcrumbs } from 'baseui/breadcrumbs';
import { Tab, Tabs } from 'baseui/tabs';
import { toaster } from 'baseui/toast';
import qs from 'querystring';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { McpOnly, OzOnly, useCurrentProvider } from '../../Components/Permissions';
import {
  Permission,
  useSimpleAdminProviderQuery,
  useAdminResetProviderPasswordMutation,
} from '../../graphQL';
import { UnstyledLink } from '../Shared';
import { Authentication } from './Authentication';
import { AvailabilityTab } from './Availability/Availability';
import { ProviderDrilldownContext, ProviderTabs } from './context';
import { EditProfileModal } from './EditProfileModal';
import { EmailsTab } from './Emails';
import { OrganizationsTab } from './Organizations';
import { Overview } from './Overview';
import { PatientsTab } from './PatientsTab';
import { ProviderInsuranceTab } from './ProviderInsuranceTab';
import { ProviderProfileHeader } from './ProviderProfileHeader';
import { QualificationsTab } from './Qualifications';
import { SecurityTab } from './SecurityTab';
import { Impersonate } from './Impersonate';
import * as Styles from './Styles';

interface SubRoute {
  key: string;
  text: string;
  component: any;
  permission?: Permission;
  role?: string;
  hiddenFromOtherProviders?: boolean;
}

export const SubRoutes: SubRoute[] = [
  {
    key: 'overview',
    text: 'Overview',
    component: Overview,
  },
  {
    key: 'patients',
    text: 'Patients',
    component: PatientsTab,
    hiddenFromOtherProviders: true,
  },
  {
    key: 'availability',
    text: 'Availability',
    component: AvailabilityTab,
    role: 'provider',
    hiddenFromOtherProviders: true,
  },
  {
    key: 'qualifications',
    text: 'Qualifications',
    component: QualificationsTab,
    permission: Permission.MantraAdmin,
  },
  {
    key: 'insurance',
    text: 'Insurance',
    component: ProviderInsuranceTab,
    permission: Permission.MantraAdmin,
  },
  {
    key: 'organizations',
    text: 'Organizations',
    component: OrganizationsTab,
    permission: Permission.MantraAdmin,
  },
  {
    key: 'emails',
    text: 'Emails',
    component: EmailsTab,
    permission: Permission.MantraAdmin,
  },
  {
    key: 'authentication',
    text: 'Authentication',
    component: Authentication,
    permission: Permission.MantraAdmin,
  },
  {
    key: 'security',
    text: 'Security',
    component: SecurityTab,
    permission: Permission.MantraAdmin,
  },
];

export function ProviderPage() {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const query = qs.parse(search.replace(/^\?/, ''));

  const [activeTab, setActiveTab] = useState<ProviderTabs | number>(() => {
    if (typeof query.tab === 'string' && SubRoutes.some(r => r.key === query.tab))
      return query.tab as ProviderTabs;
    return 'overview';
  });

  // two factor authentication is done on a seperate request within Authentication.tsx; search for "useAdminProviderTwoFactorConfigQuery"
  const { data, refetch } = useSimpleAdminProviderQuery({
    variables: { providerId: Number(id) },
    fetchPolicy: 'no-cache',
  });

  const { appView, hasPermission, featureFlags, currentProvider } = useCurrentProvider();
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (!data || !data.adminProvider) return null;
  const provider = data.adminProvider;

  const linkTo: string = appView === 'mcp' ? '/staff' : '/providers';
  const label: string = appView === 'mcp' ? 'Staff' : 'Providers';

  const visibleSubRoutes = SubRoutes.filter(i => !i.permission || hasPermission(i.permission))
    .filter(i => !i.role || provider.role === i.role)
    .filter(
      s => s.key !== 'authentication' || featureFlags.includes('PROVIDER_2FACTOR_VERIFICATION')
    )
    .filter(
      g =>
        !g.hiddenFromOtherProviders ||
        currentProvider.id === data.adminProvider.id ||
        hasPermission('mantraAdmin')
    );

  return (
    <ProviderDrilldownContext.Provider value={{ setActiveTab, provider, refetch }}>
      <Styles.TopWrapper>
        <Breadcrumbs>
          <Styles.BreadcrumbLink to={linkTo}>{label}</Styles.BreadcrumbLink>
          <Styles.BreadCrumbCurrent>{provider.name}</Styles.BreadCrumbCurrent>
        </Breadcrumbs>
        <div className="flex flex-wrap">
          {hasPermission(Permission.Impersonate) && <Impersonate provider={provider} />}
          {hasPermission(Permission.MantraAdmin) && (
            <UnstyledLink to={`/book?providerId=${provider.id}`}>
              <FinalButton kind="minimal_black" iconLeft={{ icon: 'iconsBlackApptSvg', size: 24 }}>
                Book Appointment
              </FinalButton>
            </UnstyledLink>
          )}
          {hasPermission(Permission.ProviderPasswordReset) && (
            <AdminResetPasswordButton id={provider.id} />
          )}
        </div>
      </Styles.TopWrapper>
      <ProviderProfileHeader provider={provider} />
      <McpOnly>
        <Overview />
      </McpOnly>
      <OzOnly>
        <Tabs
          activeKey={activeTab}
          onChange={({ activeKey }) => setActiveTab(activeKey as ProviderTabs)}
          overrides={Styles.Tabs}
        >
          {visibleSubRoutes.map((sub, i) => (
            <Tab title={sub.text} key={sub.key} overrides={Styles.Tab}>
              <sub.component />
            </Tab>
          ))}
        </Tabs>
      </OzOnly>
      {editModalOpen && (
        <EditProfileModal
          provider={provider}
          onClose={() => setEditModalOpen(false)}
          refetch={refetch}
        />
      )}
    </ProviderDrilldownContext.Provider>
  );
}

const AdminResetPasswordButton = ({ id }: { id: number }) => {
  const [reset, { called, loading }] = useAdminResetProviderPasswordMutation({ variables: { id } });

  return (
    <FinalButton
      kind="minimal_black"
      loading={loading}
      disabled={called}
      iconLeft={{ icon: 'iconsRefreshSvg', size: 24 }}
      onClick={async () => {
        try {
          await reset();
          toaster.positive('Email Sent', {});
        } catch (error) {
          toaster.negative("You do not have permission to reset this Provider's password", {});
        }
      }}
    >
      Reset Password
    </FinalButton>
  );
};
