import keyboardKey from 'keyboard-key';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../../../globalStyles';
import { cx } from '../../../utils';
import { BackLinkButton } from '../../BackLink';
import { InlineSVG } from '../../Icons';
import { InvisibleInput } from '../Input';
import { InlineControls } from '../Shared';
import { StyledSelectDiv } from './MantraSelect';
import { NestedOption, Option, Value } from './types';
import useNestedOptions from './useNestedOptions';

const directionLookup: Record<number, -1 | 1> = {
  [keyboardKey.ArrowUp]: -1,
  [keyboardKey.ArrowDown]: 1,
};

export type NestedSelectProps = Pick<
  React.HTMLProps<HTMLDivElement>,
  'name' | 'id' | 'className'
> & {
  value?: Value;
  options: NestedOption[];
  onChange: (v: Value) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  renderOption?: RenderOption;
  clearable?: boolean;
};

export const NestedSelect = ({
  value,
  options,
  onChange,
  disabled,
  name,
  id,
  error,
  placeholder = 'Select...',
  onBlur,
  onFocus,
  className,
  clearable,
  renderOption: RenderOpt = defaultRenderOption,
}: NestedSelectProps) => {
  const {
    currentOptions,
    canGoToPrevCategory,
    currIdx,
    title,
    selectOne,
    goToPrevCategory,
    moveOptionIdx,
    reset,
  } = useNestedOptions({ options, onSelect: onChange });

  const selectRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const timeoutId = useRef<number>();
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const pathToCurrentOption = useMemo(
    () => (value ? findPathToOptionMatch(value, options) : []),
    [value, options]
  );

  const openMenu = () => {
    if (open || disabled) return;
    setOpen(true);
  };

  const closeMenu = () => {
    if (!open) return;
    setOpen(false);
    setFocus(false);
    selectRef.current?.blur();
    menuRef.current?.blur();
  };

  const innerOnFocus = () => {
    setFocus(true);
    onFocus?.();
  };

  const innerOnBlur = () => {
    setFocus(false);
    setOpen(false);
    onBlur?.();
  };

  const onFocusHandler = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    innerOnFocus();
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLElement>) => {
    const { currentTarget } = event;
    timeoutId.current = setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        innerOnBlur();
      }
    }, 0);
  };

  const onSelect = (option: Option<{ hasMore: boolean }>) => {
    const { hasMore } = option.data;
    selectOne(option);
    if (!hasMore) {
      closeMenu();
      reset();
    }
  };

  const onClear = useCallback(() => {
    if (disabled) return;
    onChange(null);
  }, [disabled, onChange]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!open) return;

    const code = keyboardKey.getCode(event);
    if (!code) return;

    if (code === keyboardKey.Escape) {
      event.preventDefault();
      return closeMenu();
    }

    if (code === keyboardKey.Enter) {
      event.preventDefault();
      if (currIdx < 0 || currIdx >= currentOptions.length) return;
      const option = currentOptions[currIdx]!;
      return onSelect(option);
    }

    const direction = directionLookup[code];
    if (!direction) return;
    event.preventDefault();
    moveOptionIdx(direction);
  };

  /* when navigating on key down */
  const scrollIntoView = () => {
    const menuEl = menuRef.current;
    if (!menuEl) return;
    const selectedEl = menuEl.querySelector<HTMLLIElement>('li[aria-selected=true]');
    if (!selectedEl) return;

    const isOutOfUpperView = selectedEl.offsetTop < menuEl.scrollTop;
    const isOutOfLowerView =
      selectedEl.offsetTop + selectedEl.clientHeight > menuEl.scrollTop + menuEl.clientHeight;

    if (isOutOfUpperView) {
      menuEl.scrollTop = selectedEl.offsetTop;
    } else if (isOutOfLowerView) {
      menuEl.scrollTop = selectedEl.offsetTop + selectedEl.clientHeight - menuEl.clientHeight;
    }
  };

  useEffect(() => {
    if (!open) return;
    scrollIntoView();
  }, [currIdx, open]);

  return (
    <SelectContainer
      id={id}
      ref={selectRef}
      className={cx({ focus, disabled, error }, className)}
      onKeyDown={onKeyDown}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onClick={openMenu}
      tabIndex={0}
    >
      <PhantomInput
        tabIndex={0}
        placeholder={placeholder}
        name={name}
        value={pathToCurrentOption.map(o => o.label).join(' > ')}
        autoComplete="off"
        data-baseweb="select"
        className="flex-auto"
        readOnly
      />
      <InlineControls
        value={value}
        disabled={disabled}
        onClear={clearable ? onClear : undefined}
        onClickKarat={() => {
          if (disabled) return;
          setFocus(true);
          setOpen(true);
        }}
      />
      {open && (
        <MenuContainer ref={menuRef}>
          <>
            {canGoToPrevCategory && (
              <ButtonContainer>
                <BackLinkButton
                  onClick={() => {
                    goToPrevCategory();
                    selectRef.current?.focus();
                  }}
                  className="nl1"
                />
              </ButtonContainer>
            )}
            {title && <TitleText kind="black">{title}</TitleText>}
            <MenuList>
              {currentOptions.map((o, i) => {
                const selected = currIdx === i;
                return (
                  <MenuListItem
                    className={cx({ selected }, 'option')}
                    key={String(o.id)}
                    tabIndex={-1}
                    role="option"
                    aria-selected={selected}
                    onClick={() => onSelect(o)}
                    onKeyDown={noop}
                  >
                    <div className="flex flex-row items-center gap-2 justify-between">
                      <RenderOpt option={o} />
                      {o.data.hasMore && (
                        <InlineSVG icon="chevron-right" width="0.85rem" height="0.85rem" />
                      )}
                    </div>
                  </MenuListItem>
                );
              })}
            </MenuList>
          </>
        </MenuContainer>
      )}
    </SelectContainer>
  );
};

export type RenderOption = (props: { option: Option }) => JSX.Element;
const defaultRenderOption: RenderOption = ({ option }) => <>{option.label}</>;

const findPathToOptionMatch = (value: Value, options: NestedOption[]): Option[] => {
  for (const option of options) {
    const { subcategories } = option;
    // match found at root
    if (option.id === value) {
      return [option];
    }
    if (subcategories) {
      const subPath = findPathToOptionMatch(value, subcategories);
      if (subPath.length) return [option, ...subPath];
    }
  }
  return [];
};

const menuItemHPadding = css`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ButtonContainer = styled.div`
  padding-top: 0.5rem;
  ${menuItemHPadding}
`;

const TitleText = styled(Text.label)`
  padding-top: 1rem;
  padding-bottom: 0.125rem;
  ${menuItemHPadding};
`;

const SelectContainer = styled(StyledSelectDiv)`
  cursor: pointer;
  position: relative;
  min-height: 1rem;
  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
`;

const PhantomInput = styled(InvisibleInput)`
  cursor: inherit;
  flex: 1;
`;

const MenuContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 2px);
  max-height: 12rem;
  border-radius: 0.25rem;
  background-color: white;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow-y: auto;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuListItem = styled.li`
  outline: none;
  &.group-header {
    padding: 0.5rem;
    font-weight: bold;
    cursor: default;
  }

  &.option {
    cursor: pointer;
    ${menuItemHPadding}
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
    &:hover,
    &:focus,
    &.selected {
      background-color: rgb(238, 238, 238);
    }
  }
`;
