import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { Nullable } from '../../types';
import { cx } from '../../utils';
import { InlineSVG } from '../Icons';
import { moveToWeekStart } from '../Scheduler/index';

type BookableIntakesDepletedProps = {
  onSelectStartDate: (m: Moment) => void;
  nextAvailableDGMDate?: Nullable<Date>;
  nextAvailableDGMProviderDate?: Date;
  className?: string;
  gridSpan?: number;
};

export const BookableIntakesDepleted = ({
  nextAvailableDGMDate,
  nextAvailableDGMProviderDate,
  onSelectStartDate,
  className,
  gridSpan,
}: BookableIntakesDepletedProps) => {
  const nextAvailableMoment = moment(nextAvailableDGMProviderDate);
  const moveToWeek = moveToWeekStart(nextAvailableMoment);
  // width is used in provider-network search to prevent crowded text
  return (
    <BookableIntakesDepletedContainer
      className={cx('tc flex flex-column items-center gap-1', className)}
    >
      <InlineSVG icon="lock" size={22} kind="grayText" />
      <Text.bodyBold>No bookable intakes remaining</Text.bodyBold>
      <Text.bodyCaption kind="grayText" className={cx({ dn: gridSpan && gridSpan <= 2 })}>
        An intake cannot be booked for any day of the selected week(s) because your organization has
        used its maximum allotted bookable hours and/or intakes.
      </Text.bodyCaption>
      {nextAvailableDGMProviderDate && (
        <Text.linkButton className="b mt3" onClick={() => onSelectStartDate(moveToWeek)}>
          Next bookable intake: {nextAvailableMoment.format('ddd, MMMM D')}
        </Text.linkButton>
      )}
    </BookableIntakesDepletedContainer>
  );
};

const BookableIntakesDepletedContainer = styled.div`
  padding: 2rem;
  background: ${colors.grayTextBg};
  position: relative;
  opacity: 1;
  width: 100%;
`;
