import React, { useState } from 'react';
import { BackButton } from '../../../../Components/Wizard/BackButton';
import { StateCodes, NextSteps, useCreateCareTransitionMutation } from '../../../../graphQL';
import { useDrilldownContext } from '../../helpers';
import { Modal as MantraModal, Text } from '../../styles';
import { StateSelect } from './StateSelect';
import { COCPathProps } from './types';

export function Sponsored({ onGoBack, onClose, careType, onSubmit }: COCPathProps) {
  const [done, setDone] = useState(false);
  const { user, refetch } = useDrilldownContext();
  const [mutate, { loading }] = useCreateCareTransitionMutation({
    onCompleted: () => {
      setDone(true);
      refetch();
    },
  });
  const handleSetState = (state: string) => {
    const transition = {
      careType,
      userId: user.id,
      nextSteps: NextSteps.ActiveSponsored,
      secondaryState: state as StateCodes,
    };
    mutate({ variables: { transition } });
    onSubmit?.();
  };

  return (
    <div>
      <BackButton onClick={onGoBack} />
      {!done && (
        <StateSelect
          onSelect={handleSetState}
          loading={loading}
          onGoBack={onGoBack}
          onClose={onClose}
          serviceType="sponsored care"
        />
      )}
      {done && (
        <div>
          <Text.h2 className="mb4">
            Confirmed! Patient will continue with sponsored care during break.
          </Text.h2>
          <Text.body>
            Please confirm there are available contracted hours to accommodate this patient.
          </Text.body>
          <MantraModal.closeLink onClick={onClose}>Close</MantraModal.closeLink>
        </div>
      )}
    </div>
  );
}
