import React, { useState } from 'react';
import { PillCheckbox } from '../../../../Components/EzForm';
import { FinalButton } from '../../../../Components/FinalButton';
import { Textarea } from '../../../../Components/Form/Input';
import { Icon } from '../../../../Components/Icons';
import { Text } from '../../styles';

interface Props {
  title: string;
  options: string[];
  onContinue: (reasons: string[]) => void;
}

export function ReasonsQuestion({ title, options, onContinue }: Props) {
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherValue, setOtherValue] = useState('');
  const [error, setError] = useState('');

  const handleContinue = () => {
    if (!reasons.length) return setError('Please make a selection.');
    setError('');
    onContinue([...reasons.filter(i => i !== 'Other'), otherValue].filter(i => !!i));
  };

  return (
    <div>
      <Text.h2 className="mb4">{title}</Text.h2>
      <PillCheckbox
        options={[...options, 'Other']}
        onChange={setReasons}
        value={reasons}
        multi
        selectedIcon={<Icon icon="iconsBlackCheckSvg" alt="Check Mark" />}
      />
      {reasons.includes('Other') && (
        <Textarea value={otherValue} onChange={e => setOtherValue(e.target.value)} />
      )}
      {error && (
        <Text.body className="mv3" kind="danger">
          {error}
        </Text.body>
      )}
      <div className="mt4">
        <FinalButton onClick={handleContinue} style={{ width: '100%' }}>
          Continue
        </FinalButton>
      </div>
    </div>
  );
}
