import styled from 'styled-components';
import { colors } from '../../../globalStyles';
import { HELVETICA } from '../../../globalStyles/text';

export const SectionHeader = styled.h3`
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colors.grey.border};
  font-size: 0.75rem;
  font-family: ${HELVETICA};
`;

export const Divider = styled.hr`
  border-top: none;
  border-bottom: 1px solid #d8d8d8;
  margin: 2em 0 2.5em 0;
`;
