import { PureQueryOptions } from '@apollo/client';
import React, { useEffect } from 'react';
import { colors } from '../../globalStyles';
import {
  Permission,
  SidebarDataDocument,
  SidebarDataQueryVariables,
  useSidebarDataQuery,
} from '../../graphQL';
import { UnreadMessageIcon } from '../Flair/styles';
import { useCurrentProvider } from '../Permissions';
import { ItemId } from './NavItems';

export const useSidebarQuery = (): [PureQueryOptions, SidebarDataQueryVariables] => {
  const { hasPermission, appView } = useCurrentProvider();
  const variables: SidebarDataQueryVariables = {
    careTeamMessages: hasPermission(Permission.ProviderMessage),
    patientMessages: hasPermission(Permission.PatientMessage),
    unverified: hasPermission(Permission.MantraAdmin),
    newTicketCount: appView === 'oz' && hasPermission(Permission.TicketView),
  };
  return [{ query: SidebarDataDocument, variables }, variables];
};

export const useNavbarCounts = () => {
  const [, variables] = useSidebarQuery();

  const { data, startPolling, stopPolling } = useSidebarDataQuery({ variables });

  useEffect(() => {
    startPolling(3000);
    return stopPolling;
  }, [startPolling, stopPolling]);

  const aggregatedMessages =
    (data?.unreadPatientMessages ?? 0) + (data?.unreadCareTeamMessages ?? 0);

  const countMap: Partial<Record<ItemId, (p: Omit<CountOrNullProps, 'count'>) => JSX.Element>> = {
    [ItemId.Verification]: props => <CountOrNull {...props} count={data?.unverifiedUserCount} />,
    [ItemId.Messaging]: props => <CountOrNull {...props} count={aggregatedMessages} />,
    [ItemId.Tasks]: props => <CountOrNull {...props} count={data?.tickets?.length ?? 0} />,
  };

  return countMap;
};

type CountOrNullProps = {
  count?: number | null;
  active?: boolean;
  wrapper?: React.FC;
};

const CountOrNull = ({ count, active, wrapper: Wrapper }: CountOrNullProps) => {
  if (!count) return null;
  if (!Wrapper)
    return (
      <UnreadMessageIcon color={active ? colors.white : colors.green}>{count}</UnreadMessageIcon>
    );
  return (
    <Wrapper>
      <UnreadMessageIcon color={active ? colors.white : colors.green}>{count}</UnreadMessageIcon>
    </Wrapper>
  );
};
