import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../globalStyles';
import { Icon } from '../../../Components/Icons';

type SectionRowProps = {
  children: React.ReactNode;
  info?: string;
  label: string;
};

export function SectionRow({ children, info, label }: SectionRowProps) {
  const infoClasses = info ? 'mb3' : 'ma0';

  return (
    <SectionRowDiv>
      <SectionRowLeftDiv>
        <Text.bodySmallBold className="mb3">{label}</Text.bodySmallBold>
        <Text.caption className={infoClasses}>{info}</Text.caption>
      </SectionRowLeftDiv>

      <SectionRowRightDiv>{children}</SectionRowRightDiv>
    </SectionRowDiv>
  );
}

type SectionRowItemProps = {
  children: React.ReactNode;
  onEditClick?: () => void;
};

export function SectionRowItem({ children, onEditClick }: SectionRowItemProps) {
  return (
    <SectionRowItemDiv>
      <SectionRowItemLeftDiv>{children}</SectionRowItemLeftDiv>
      {onEditClick && (
        <SectionRowItemEditDiv>
          <EditIcon onClick={onEditClick}>
            <Icon icon="iconsPencilSvg" alt="Edit" />
          </EditIcon>
        </SectionRowItemEditDiv>
      )}
    </SectionRowItemDiv>
  );
}

export const SectionRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;

  &:not(:last-of-type) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #d8d8d8;
  }
`;

export const SectionRowLeftDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 2rem;
  width: 225px;
  min-width: 225px;
`;

export const SectionRowRightDiv = styled.div`
  flex-grow: 1;
`;

export const SectionRowItemDiv = styled.div`
  display: flex;

  ${SectionRowRightDiv} & {
    margin-bottom: 1rem;
  }
`;

export const SectionRowItemLeftDiv = styled.div`
  flex-grow: 1;
`;

export const SectionRowItemEditDiv = styled.div`
  margin-left: 1rem;
`;

export const EditIcon = styled(Text.linkButton)`
  width: 1rem;
`;
