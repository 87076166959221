import { Avatar } from 'baseui/avatar';
import { Tag } from 'baseui/tag';
import moment from 'moment';
import React from 'react';
import { icons } from '../../../Components/Icons';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery } from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { isInitialAppointment } from '../../Appointments/AppointmentListItem';
import { UnstyledLink } from '../../Shared';
import { DashboardTable } from '../DashTable';
import { EmptyState } from '../EmptyState';
import * as Styles from '../styles';

type Props = {
  appointments: CareDashboardQuery['adminAppointmentsToday'];
  admin?: boolean;
};

const emptyState = (
  <EmptyState
    icon="calendar"
    text="No appointments scheduled for today"
    linkText="View upcoming appointments"
    linkTo="/appointments"
  />
);

export const AppointmentsToday = ({ appointments, admin }: Props) => {
  return (
    <Styles.SectionContainer>
      <Text.h2>{moment().format('dddd, MMMM Do, YYYY')}</Text.h2>
      <Text.label>
        {appointments.length} appointment{appointments.length !== 1 && 's'}
      </Text.label>
      <DashboardTable
        emptyState={emptyState}
        data={appointments}
        columns={[
          {
            style: { width: '11em' },
            render: apt => (
              <div style={{ minHeight: '2em' }}>
                <Text.bodyBold style={{ whiteSpace: 'nowrap' }}>
                  {moment(apt.startTime).format('LT')} - {moment(apt.endTime).format('LT')}
                </Text.bodyBold>
                {admin && <Text.bodySmallGrey>{apt.provider?.name}</Text.bodySmallGrey>}
              </div>
            ),
          },
          {
            render: ({ user, description }) => (
              <UnstyledLink to={`/users/${user.id}`}>
                <div className="flex items-center mv2">
                  <Avatar
                    size="2.5rem"
                    overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
                    src={user.selfie?.url ?? icons.assetsDefaultPicturePng}
                    name={getFullName(user)}
                  />
                  <div className="ml2">
                    <Text.bodyBold>{getFullName(user)}</Text.bodyBold>
                    <Text.bodySmallGrey>{description}</Text.bodySmallGrey>
                  </div>
                </div>
              </UnstyledLink>
            ),
          },
          {
            style: { width: '6em' },
            render: apt => (
              <div className="flex flex-column items-end">
                {!apt.user.intakeComplete && (
                  <Tag
                    closeable={false}
                    kind="warning"
                    title="This patient has not yet completed their intake tasks, but has an appointment scheduled for today."
                  >
                    Intake
                  </Tag>
                )}
                {admin && !apt.user.identityVerified && !apt.user.organization && (
                  <Tag closeable={false} kind="neutral">
                    Unverified
                  </Tag>
                )}
                {!admin && isInitialAppointment(apt) && (
                  <Tag kind="positive" closeable={false}>
                    New Patient
                  </Tag>
                )}
              </div>
            ),
          },
        ]}
        getKey={apt => apt.id}
      />
    </Styles.SectionContainer>
  );
};
