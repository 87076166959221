import { Moment } from 'moment';
import { WizardRenderProps } from '../../Components/Wizard';
import {
  AdminAppointmentQuery,
  AppointmentTemplateFragment,
  AppointmentUserV2Fragment,
  AttendeeDetailsQuery,
  Availability,
  CareType,
  Provider,
  ReschedulingAppointmentFragment,
} from '../../graphQL';
import { Nullable } from '../../types';

export type AppointmentType = 'checkin' | 'adhoc' | 'intake';

type ProviderWithUpcomingAvailability = Pick<Provider, 'id' | 'name' | 'careTypes'> & {
  upcomingAvailability?: Nullable<Pick<Availability, 'start'>>[];
};

export interface BookingWizardData {
  patient?: AttendeeDetailsQuery['patient'];
  providers: ProviderWithUpcomingAvailability[];
  appointmentTemplates?: AppointmentTemplateFragment[];
  // specific for rescheduling
  reschedulingAppointment?: AdminAppointmentQuery['adminAppointment'] & { outOfPolicy?: boolean };
  // actual data needed in scheduling an appt.
  appointment: {
    provider?: Pick<Provider, 'id' | 'name' | 'careTypes'>;
    duration?: number;
    time?: Moment;
    templateIndex?: number;
    careType?: CareType;
    appointmentType?: string;
    organizationId?: number | null;
  };
  initialStepIndex: number;
  didEncounterTimeConflict: boolean;
}

export enum BookingBlocker {
  InvalidInsurance = 'invalid-insurance',
  MissingPaymentMethod = 'missing-payment-method',
}

export type AppointmentDetails = {
  provider?: Pick<Provider, 'id' | 'name' | 'careTypes'>;
  duration?: number;
  time?: Moment;
  templateIndex?: number;
  careType?: CareType;
  appointmentType?: string;
  organizationId?: number | null;
};

export type BookingWizardDataV2 = {
  initialStepIndex: number;
  patient: AppointmentUserV2Fragment;
  appointmentTemplates: AppointmentTemplateFragment[];
  reschedulingAppointment?: ReschedulingAppointmentFragment & { outOfPolicy?: boolean };
  appointment: AppointmentDetails;
  didEncounterTimeConflict: boolean;
  usesProviderNetwork?: boolean;
  blockers?: BookingBlocker[];
};

export type BookingWizardProps = WizardRenderProps<BookingWizardData>;
export type BookingWizardPropsV2 = WizardRenderProps<BookingWizardDataV2>;
