import { StatefulCheckbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { toaster } from 'baseui/toast';
import { isNil, omitBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { colors, overrides, Text } from '../../globalStyles';
import {
  CareteamPreferencesQuery,
  useCareteamPreferencesQuery,
  useUpdateCareteamPreferencesMutation,
} from '../../graphQL';
import { Styles } from '../Users/styles';

export const NotificationsTab = () => {
  const { data, loading } = useCareteamPreferencesQuery();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <NotificationContainer>
      <h2 className="mb3">Email Notifications</h2>
      <p>
        If you&apos;re on a student&apos;s collaboration team, you&apos;ll receive emails
        notifications for:
      </p>
      {data && <YesNoForm data={data.configs.filter(c => typeof c.value === 'boolean')} />}
    </NotificationContainer>
  );
};

type FormProps = {
  data: CareteamPreferencesQuery['configs'];
};

const YesNoForm = ({ data }: FormProps) => {
  const [updatePreferences] = useUpdateCareteamPreferencesMutation();
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const form = useForm<Record<string, boolean>>();

  const submit = form.handleSubmit(args => {
    try {
      setLoading(true);
      updatePreferences({
        variables: {
          preferences: Object.keys(omitBy(args, isNil)).map(key => ({
            key,
            value: args[key] as any,
          })),
        },
      });
      toaster.positive('Your notification settings have been successfully updated.', {});
    } catch {
      toaster.negative('There was a problem saving your notification settings.', {});
    } finally {
      // this is too fast, need to slow it down or nothing shows
      setTimeout(() => {
        setLoading(false);
        setUpdated(false);
      }, 200);
    }
  });

  useEffect(() => {
    for (const { key } of data) {
      form.register({ name: key });
    }
  }, [form, data]);

  return (
    <form onSubmit={submit}>
      {data.map(c => (
        <CheckSelect
          key={c.key}
          checked={c.value as any}
          onChange={e => {
            form.setValue(c.key, e.currentTarget.checked);
            setUpdated(true);
          }}
          data={c}
        />
      ))}
      <div className="mt4" style={{ maxWidth: 177 }}>
        <FinalButton
          kind="primary"
          className="w-100"
          loading={loading}
          type="submit"
          disabled={!updated}
        >
          Save Changes
        </FinalButton>
      </div>
    </form>
  );
};

type CheckProps = {
  checked: boolean;
  onChange: (e: any) => void;
  data: CareteamPreferencesQuery['configs'][number];
};

const CheckSelect = ({ checked, onChange, data }: CheckProps) => (
  <div className="mt4 flex flex-row align-start">
    <div className="mr2" style={{ flexShrink: 1 }}>
      <FormControl>
        <StatefulCheckbox
          overrides={overrides.checkbox}
          onChange={onChange}
          initialState={{ checked }}
        />
      </FormControl>
    </div>
    <div>
      <h4 className="nt1">{data.title}</h4>
      <Text.body>{data.description}</Text.body>
      {data.subText && (
        <Text.body>
          <em>
            <b>Note: </b>
          </em>
          <em>{data.subText}</em>
        </Text.body>
      )}
    </div>
  </div>
);

export const NotificationContainer = styled(Styles.widget)`
  padding: 32px 36px 43px;
  margin-top: 41;
  min-width: 60%;
  max-width: 750px;
  border: 1px solid ${colors.grey.widgetBorder};
  border-radius: 4px;
`;
