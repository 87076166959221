import React from 'react';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../../Components/Permissions';
import { Wizard } from '../../../Components/Wizard';
import { CareType } from '../../../graphQL';
import { useQueryParams } from '../../../Hooks';
import { UnexpectedError } from '../../Shared';
import { Success } from './Success';
import { useBookingFlow } from './useBookingFlow';

export const Booking = () => {
  const [parsedSearch] = useQueryParams();
  const rescheduleId = Number(parsedSearch.rescheduleId) || undefined;
  const userId = Number(parsedSearch.userId) || undefined;
  const careType = (parsedSearch.careType || undefined) as CareType | undefined;
  const outOfPolicy = Boolean(Number(parsedSearch.outOfPolicy || 0) && rescheduleId);

  if (!userId) {
    return <UnexpectedError message="User not found" cannotRetry />;
  }

  return (
    <BookingWizard
      userId={userId}
      rescheduleId={rescheduleId}
      careType={careType}
      outOfPolicy={outOfPolicy}
    />
  );
};

type Props = {
  userId: number;
  rescheduleId?: number;
  careType?: CareType;
  outOfPolicy?: boolean;
};

export const BookingWizard = ({ userId, rescheduleId, careType, outOfPolicy }: Props) => {
  const { appView } = useCurrentProvider();
  const { loading, data, steps, error } = useBookingFlow({
    userId,
    rescheduleId,
    careType,
    outOfPolicy,
  });

  if (loading) {
    return <LoadingPage />;
  }
  if (!data) {
    return <UnexpectedError message={error} cannotRetry />;
  }

  return (
    <Wizard
      initialData={data}
      initialStepIndex={data.initialStepIndex}
      steps={steps}
      renderSuccess={Success}
      disableProgress={appView === 'referral'}
    />
  );
};
