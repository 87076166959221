import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useLogoQuery } from '../../graphQL';
import { UnstyledLink } from '../../Pages/Shared';

export const LinkedLogo = styled.img`
  max-height: 36px;
`;
export const Logo = styled.img`
  position: absolute;
  max-height: 36px;
`;

interface Props {
  organizationId?: number;
  right?: string;
  top?: string;
  linkTo?: string;
}

export function UniversityLogo({
  organizationId: manualOrganizationId,
  right = '2.5em',
  top = 'auto',
  linkTo = '',
}: Props) {
  // This is all a little bit magical, but I really didn't want to have to repeat
  // this logic all over the place.
  const location = useLocation();
  const split = location.pathname.split('/');
  let organizationId: number | undefined = manualOrganizationId;
  if (!organizationId) {
    const orgIdx = split.indexOf('organizations');
    if (orgIdx !== -1) {
      organizationId = Number(split[orgIdx + 1]);
    }
  }

  const { data } = useLogoQuery({
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });

  if (!data) return null;

  const url = data.organization.logo?.url ?? data.organization.parent?.logo?.url;
  const alt = data.organization?.name ?? data.organization.parent?.name;
  if (!url) return null;

  if (linkTo) {
    return (
      <UnstyledLink to={linkTo} className="absolute" style={{ right, top }}>
        <LinkedLogo src={url} alt={alt} />
      </UnstyledLink>
    );
  }

  return <Logo src={url} alt={alt} style={{ right, top }} />;
}
