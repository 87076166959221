import { initial, isEmpty, last, lowerCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useCurrentProvider } from '../../Components/Permissions';
import { colors, Text } from '../../globalStyles';
import {
  CareStatus,
  CareType,
  NextStepsFilter,
  TaskStatusFilter,
  UserListItemFragment,
} from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { getProviderCareTypeLookup, getRemainingSessions, getTransitionEndDate } from '../../utils';
import { careStatusCopy, cocNextStepsCopy } from '../Users/copy';

export const taskStatusOptions = [
  { id: TaskStatusFilter.Intake, label: 'Intake' },
  { id: TaskStatusFilter.OngoingCare, label: 'Ongoing Care' },
  { id: TaskStatusFilter.Cancelled, label: 'Cancelled' },
  { id: TaskStatusFilter.Discharged, label: 'Discharged' },
  { id: TaskStatusFilter.OnHold, label: 'On Hold' },
];

export const careStatusOptions = [
  { id: CareStatus.Active, label: 'Active' },
  { id: CareStatus.Cancelled, label: 'Cancelled' },
  { id: CareStatus.Discharged, label: 'Discharged' },
  { id: CareStatus.OnHold, label: 'On Hold' },
];

export const nextStepsOptions = [
  { id: NextStepsFilter.Na, label: 'N/A' },
  { id: NextStepsFilter.ActiveSponsored, label: 'Continue Sponsored' },
  { id: NextStepsFilter.CompletedSelfPay, label: 'Self Pay (Completed)' },
  { id: NextStepsFilter.PendingSelfPay, label: 'Self Pay (Pending)' },
  { id: NextStepsFilter.OnHold, label: 'On Hold' },
  { id: NextStepsFilter.Cancelled, label: 'Cancelled' },
];

export const NameColumn = ({
  user,
  showEmail,
}: {
  user: UserListItemFragment;
  showEmail?: boolean;
}) => {
  const onClickPatientId = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.preventDefault();
    window.getSelection()?.selectAllChildren(e.target as Node);
  };

  return (
    <div>
      <Text.bodyBold className="ttc" data-cy="name">
        {getFullNameReversed(user)}
      </Text.bodyBold>
      {showEmail && (
        <p data-cy="email" className="mb1">
          {user.email}
        </p>
      )}
      <Text.captionGrey
        onClick={onClickPatientId}
        style={{ cursor: 'auto', userSelect: 'text', whiteSpace: 'nowrap' }}
      >
        {user.customerId}
      </Text.captionGrey>
    </div>
  );
};

export const EnrollmentColumn = ({ user }: { user: UserListItemFragment }) => {
  return (
    <div>
      <p className="b" data-cy="organizations">
        {user.organization?.name ?? 'DTC'}
      </p>
      <p>Enrolled {moment(user.createdAt).format('M/D/YYYY')}</p>
    </div>
  );
};

export const SponsoredCareColumn = ({ user }: { user: UserListItemFragment }) => {
  const careTransitions = user.continuityOfCareTransitions;

  const sessionLimits = user.careFlows.reduce((a, c) => {
    const sessions = getRemainingSessions(c);
    if (!sessions) return a;
    return [
      ...a,
      <div key={c.id} className="mb2">
        <Text.body kind={sessions.remaining <= 0 ? 'grayText' : 'text'} className="mv0">
          {sessions.remaining} {lowerCase(c.careType)} sessions remaining
        </Text.body>
      </div>,
    ];
  }, new Array<JSX.Element>());

  if (!careTransitions.length && isEmpty(sessionLimits)) {
    return <span>--</span>;
  }
  if (!careTransitions.length) {
    return <div>{sessionLimits}</div>;
  }

  const careEndDate = getTransitionEndDate(careTransitions[0]);
  const careEnded = careEndDate && moment().isAfter(careEndDate);
  return (
    <div data-cy="sponsoredCare" style={{ color: careEnded ? colors.grayText : undefined }}>
      {careEndDate && (
        <p>
          {careEnded ? 'ended ' : 'ends '}
          {moment(careEndDate).format('l')}
        </p>
      )}
      <div>{sessionLimits}</div>
      <p className="i">
        <span className="b">Next:</span>{' '}
        {careTransitions.map(i => `${cocNextStepsCopy(i)} (${i.careType})`).join(', ')}
      </p>
    </div>
  );
};

export const CareFlowStatusColumn = ({ user }: { user: UserListItemFragment }) => {
  const { currentProvider } = useCurrentProvider();
  const providerCareTypes = getProviderCareTypeLookup(currentProvider);
  const careFlows = user.careFlows.filter(f => providerCareTypes.has(f.careType));

  const careStatusMap = careFlows.reduce((acc, { careType, careStatus }) => {
    const existing = acc.get(careStatus) ?? [];
    return acc.set(careStatus, [careType, ...existing]);
  }, new Map<CareStatus, CareType[]>());

  // show active first
  const careFlowStatuses = [...careStatusMap.keys()]
    .sort(status => (status === CareStatus.Active ? 1 : 0))
    .map(careStatus => {
      const careTypes = careStatusMap.get(careStatus)!;

      const careTypesCopy = `${initial(careTypes).join(', ')}${
        careTypes.length > 1 ? ' & ' : ''
      }${last(careTypes)}`;

      // Just show the status if the provider only handles one careType
      return (
        <p key={careStatus}>
          {providerCareTypes.size <= 1
            ? careStatusCopy[careStatus]
            : `${careStatusCopy[careStatus]} (${careTypesCopy})`}
        </p>
      );
    });

  return (
    <div data-cy="careFlowStatus">{careFlowStatuses.length <= 0 ? '--' : careFlowStatuses}</div>
  );
};
