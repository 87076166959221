import { Notification } from 'baseui/notification';
import moment from 'moment';
import React from 'react';
import { FinalButton } from '../../Components/FinalButton';
import { WizardWrapper } from '../../Components/Wizard';
import { BackButton } from '../../Components/Wizard/BackButton';
import { colors, Text } from '../../globalStyles';
import {
  AdminAppointmentsDocument,
  useAdminRescheduleAppointmentMutation,
  useAdminScheduleAppointmentMutation,
} from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { Box, Divider, FieldName, FieldValue } from '../../Components/Booking/Styles';
import { BookingWizardProps } from './types';

export function Confirm({ prevStep, nextStep, data }: BookingWizardProps) {
  const refetchQueries = [
    {
      query: AdminAppointmentsDocument,
      variables: { userId: data!.patient!.id },
    },
  ];
  const onError = (err: any) => {
    if (err?.graphQLErrors[0]?.message?.code === 'CONFLICT') {
      prevStep({ ...data, didEncounterTimeConflict: true });
    } else {
      throw err;
    }
  };
  const onCompleted = () => nextStep();

  const [adminScheduleAppointmentMutation, { error: err1, loading: load1 }] =
    useAdminScheduleAppointmentMutation({
      refetchQueries,
      onError,
      onCompleted,
    });

  const [rescheduleAppointment, { error: err2, loading: load2 }] =
    useAdminRescheduleAppointmentMutation({
      refetchQueries,
      onError,
      onCompleted,
    });

  const error = err1 || err2;
  const loading = load1 || load2;

  const endTime = data.appointment.time!.clone().add(data.appointment.duration!, 'minutes');
  const startTimeStr = data.appointment.time!.format('h:mm a');
  const endTimeStr = endTime.format('h:mm a');
  const timeZoneStr = data.appointment.time!.format('z');

  const handleConfirm = async () => {
    if (data.reschedulingAppointment) {
      await rescheduleAppointment({
        variables: {
          id: data.reschedulingAppointment.id,
          start: data.appointment.time!.toDate(),
          end: endTime.toDate(),
          outOfPolicy: data.reschedulingAppointment.outOfPolicy,
        },
      });
    } else {
      await adminScheduleAppointmentMutation({
        variables: {
          start: new Date(data.appointment.time!.format()),
          end: new Date(endTime.format()),
          userId: data.patient!.id,
          organizationId: data.appointment.organizationId,
          providerId: data.appointment.provider!.id,
          appointmentType:
            data.appointmentTemplates![data.appointment.templateIndex!].appointmentType!,
        },
      });
    }
  };

  return (
    <WizardWrapper data-cy="confirm-appointment">
      <BackButton onClick={() => prevStep()} />
      {error && (
        <Notification kind="negative">
          Sorry, there was an error booking this appointment. Please contact us if this persists.
        </Notification>
      )}
      <Text.h2>Confirm Appointment</Text.h2>
      <p className="mt2">Please verify all details before booking appointment.</p>
      {data.reschedulingAppointment && (
        <>
          <Box>
            <p>{getFullName(data.patient!)}</p>
            <p>
              {data.appointment.templateIndex !== undefined
                ? data.appointmentTemplates?.[data.appointment.templateIndex]?.description
                : ''}{' '}
              ({data.appointment.duration!} min)
            </p>
            <p style={{ fontWeight: 'bold' }}>
              {data.appointment.time!.format('M/D/YYYY h:mm a')} - {endTimeStr}
            </p>
            <p style={{ textDecoration: 'line-through', color: colors.grey.dark }}>
              {moment(data.reschedulingAppointment.startTime).format('M/D/YYYY h:mm a')} -{' '}
              {moment(data.reschedulingAppointment.endTime).format('h:mm a')}
            </p>
          </Box>
        </>
      )}
      {!data.reschedulingAppointment && (
        <div className="mt4">
          <FieldName>Patient</FieldName>
          <FieldValue>
            {getFullName(data.patient!)} ({data.patient!.customerId})
          </FieldValue>
          <Divider />
          <FieldName>Type</FieldName>
          <FieldValue>
            {data.appointment.templateIndex !== undefined
              ? data.appointmentTemplates?.[data.appointment.templateIndex]?.description
              : ''}
          </FieldValue>
          <Divider />
          <FieldName>Provider</FieldName>
          <FieldValue>{data.appointment.provider!.name}</FieldValue>
          <Divider />
          <FieldName>Duration</FieldName>
          <FieldValue>{data.appointment.duration} min</FieldValue>
          <Divider />
          <FieldName>Date</FieldName>
          <FieldValue>{data.appointment.time!.format('dddd, MMMM D')}</FieldValue>
          <Divider />
          <FieldName>Time</FieldName>
          <FieldValue>
            {startTimeStr} - {endTimeStr} {timeZoneStr}
          </FieldValue>
        </div>
      )}
      <div>
        {
          // div below is for safari browser edgecase
        }
        <FinalButton kind="primary" className="w-100 mt4" onClick={handleConfirm} loading={loading}>
          {data.reschedulingAppointment ? 'Confirm Appointment Update' : 'Book Appointment'}
        </FinalButton>
      </div>
    </WizardWrapper>
  );
}
