import { Breadcrumbs } from 'baseui/breadcrumbs';
import { Tab, Tabs } from 'baseui/tabs';
import qs from 'query-string';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { Flag } from '../../featureFlags';
import { Text } from '../../globalStyles';
import { Permission, useOrganizationTopLevelQuery } from '../../graphQL';
import { useQueryParams } from '../../Hooks';
import { HandbookFromParams } from '../Handbook/Handbook';
import { UnexpectedError } from '../Shared';
import { Overrides } from '../Users/baseuiOverrides';
import { OrganizationCareFlows } from './OrganizationCareFlows';
import { OrganizationCareOps } from './OrganizationCareOps';
import { OrganizationCarePeriods } from './OrganizationCarePeriods';
import { OrganizationCrisisTeam } from './OrganizationCrisisTeam';
import { OrganizationEligibility } from './OrganizationEligibility';
import { OrganizationEntitlements } from './OrganizationEntitlements';
import { OrganizationOverview } from './OrganizationOverview';
import { OrganizationPatients } from './OrganizationPatients';
import { OrganizationReporting } from './OrganizationReporting';
import { OrganizationResources } from './OrganizationResources';
import { OrganizationSSOConfig } from './OrganizationSSOConfig';
import { OrganizationStaff } from './OrganizationStaff';
import { OrganizationTopDisplay } from './OrganizationTopDisplay';
import { OrgTabProps, useOrganizationId } from './util';

type TabKey =
  | 'reporting'
  | 'handbook'
  | 'overview'
  | 'careOps'
  | 'students'
  | 'patients'
  | 'staff'
  | 'entitlements'
  | 'resources'
  | 'carePeriods'
  | 'careTypes'
  | 'sso';

type OrgListItem = {
  content: React.FC<OrgTabProps>;
  title: string;
  key: TabKey;
  hidden?: boolean;
};

export function OrganizationAdmin() {
  const id = useOrganizationId();
  const [queryParams, updateQueryParams] = useQueryParams();
  const { search } = useLocation();
  const { hasPermission, featureFlags } = useCurrentProvider();
  const { data, loading, error, refetch } = useOrganizationTopLevelQuery({ variables: { id } });

  const hasCrisisTeamEntitlement = data?.organization.entitlements.some(
    e => e.key === 'CrisisTeam'
  );

  const hasDedicatedGroupModel = data?.organization.entitlements.some(
    e => e.key === 'DedicatedGroupModel'
  );

  const tabs = useMemo(
    () =>
      [
        {
          title: 'Reporting',
          key: 'reporting',
          content: OrganizationReporting,
          hidden: !hasPermission(Permission.Reporting),
        },
        { title: 'Overview', key: 'overview', content: OrganizationOverview },
        {
          title: 'Handbook',
          key: 'handbook',
          content: HandbookFromParams,
          hidden: !featureFlags.includes(Flag.CscHandbook),
        },
        {
          title: 'Care Ops',
          key: 'careOps',
          content: OrganizationCareOps,
          hidden: !hasDedicatedGroupModel || !hasPermission(Permission.UtilizationView),
        },
        { title: 'Staff', key: 'staff', content: OrganizationStaff },
        { title: 'Patients', key: 'patients', content: OrganizationPatients },
        { title: 'Care Types', key: 'careTypes', content: OrganizationCareFlows },
        { title: 'Entitlements', key: 'entitlements', content: OrganizationEntitlements },
        {
          title: 'Resources',
          key: 'resources',
          content: OrganizationResources,
          hidden: featureFlags.includes(Flag.CscHandbook),
        },
        { title: 'Care Periods', key: 'carePeriods', content: OrganizationCarePeriods },
        { title: 'Eligibility', key: 'eligibility', content: OrganizationEligibility },
        {
          title: 'Single Sign-On',
          key: 'sso',
          content: OrganizationSSOConfig,
        },
        {
          title: 'Crisis Team',
          key: 'crisisTeam',
          content: OrganizationCrisisTeam,
          hidden:
            !(hasPermission(Permission.CrisisTeamEdit) && hasCrisisTeamEntitlement) ||
            !featureFlags.includes('CRISIS_TEAM'),
        },
      ].filter(t => !t.hidden) as OrgListItem[],
    [hasCrisisTeamEntitlement, featureFlags, hasPermission]
  );

  const activeTab = (() => {
    const { tab } = qs.parse(search);
    const tabItem = tabs.find(t => t.key === tab) ?? tabs[0];
    return tabItem.key;
  })();

  const setActiveTab = (activeKey: string) => {
    updateQueryParams({ ...queryParams, tab: activeKey });
  };

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const { organization } = data;

  return (
    <div className="bg-white">
      <div className="ph5 pv3">
        <Breadcrumbs>
          <Text.breadcrumbLink to="/organizations">Organizations</Text.breadcrumbLink>
          <Text.body kind="grayText">{organization.name}</Text.body>
        </Breadcrumbs>
      </div>
      <OrganizationTopDisplay organization={organization} />
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => setActiveTab(activeKey as string)}
        overrides={Overrides.Tabs}
      >
        {tabs.map(({ content: C, title, key }) => (
          <Tab title={title} key={key} overrides={Overrides.Tab}>
            <C setActiveTab={setActiveTab} refetchHeader={refetch} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
