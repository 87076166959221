import { CareType, Entitlement, OrganizationCareFlow, PaymentSource } from '../../graphQL';
import { RequireJust } from '../../types';
import { hasEntitlement } from '../../utils';
import {
  hasOrganizations,
  isMantraAdmin,
  isMcpOrReferralUser,
  useCurrentProvider,
} from '../Permissions';

export function getDisplayableType<T extends { careTypes: CareType[] }>(hasCareTypes: T) {
  const careTypeToName: Record<CareType, string> = {
    [CareType.Psychiatry]: 'Psychiatric Provider',
    [CareType.Therapy]: 'Therapist',
  };
  return careTypeToName[hasCareTypes.careTypes[0]];
}

export type OrganizationWithCareFlows = {
  careFlows: RequireJust<OrganizationCareFlow, 'careType' | 'defaultPaymentSource'>[];
};

export const isSelfPayReferral = (
  provider: { organizations: OrganizationWithCareFlows[] },
  careType: CareType
) => {
  return Boolean(
    provider.organizations.length && hasSelfPayFlow(provider.organizations[0], [careType])
  );
};

export const hasSelfPayFlow = (org: OrganizationWithCareFlows, forCareTypes?: CareType[]) => {
  return org.careFlows.some(
    i =>
      i.defaultPaymentSource === PaymentSource.SelfPay &&
      (!forCareTypes || forCareTypes.includes(i.careType))
  );
};

export const hasAnySelfPayFlow = (org: {
  careFlows: RequireJust<OrganizationCareFlow, 'defaultPaymentSource' | 'careType'>[];
}) => hasSelfPayFlow(org);

// REMOVE W BOOKING V1
export const useIsBookingV2 = () => {
  const { currentProvider, featureFlags } = useCurrentProvider();
  // if is a psychiatrist/therapist, who is booking for their patient and doesnt need network search
  const hasNetworkReferralEntitlement =
    isMantraAdmin(currentProvider) ||
    (isMcpOrReferralUser(currentProvider) &&
      hasOrganizations(currentProvider) &&
      hasEntitlement(currentProvider.organizations[0], Entitlement.NetworkReferral));

  const bookingV2Enabled = featureFlags.includes('MCP_BOOKING_V2');
  return hasNetworkReferralEntitlement && bookingV2Enabled;
};
