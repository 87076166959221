import qs from 'querystring';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { useIsBookingV2 } from '../../Components/ProviderNetwork/providerNetworkUtils';
import { Wizard } from '../../Components/Wizard';
import { CareType } from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useBookingFlow } from './hooks';
import { Success } from './Success';
import { Booking as BookingV2 } from './v2/BookingWizardV2';

export const Booking = () => {
  const isBookingV2 = useIsBookingV2();
  return isBookingV2 ? <BookingV2 /> : <BookingWizard />;
};

export const BookingWizard = () => {
  const location = useLocation();
  const { appView } = useCurrentProvider();

  const queryString = qs.parse(location.search.replace(/^\?/, ''));
  const rescheduleId = Number(queryString.rescheduleId) || undefined;
  const userId = Number(queryString.userId) || undefined;
  const careType = (queryString.careType || undefined) as CareType | undefined;
  const outOfPolicy = Boolean(Number(queryString.outOfPolicy || 0) && rescheduleId);

  const { loading, data, steps, error } = useBookingFlow({
    userId,
    rescheduleId,
    careType,
    outOfPolicy,
  });

  if (loading) {
    return <LoadingPage />;
  }
  if (!data) {
    return <UnexpectedError message={error} cannotRetry />;
  }
  return (
    <Wizard
      initialData={data}
      initialStepIndex={data.initialStepIndex}
      steps={steps}
      renderSuccess={Success}
      disableProgress={appView === 'referral'}
    />
  );
};
