import { compact, reduceRight } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Accordion, AccordionItem } from '../../../Components/Accordion';
import { scoreDisplayMap } from '../../../Components/Assessments/ScoreDisplay';
import { Grid } from '../../../Components/Grid';
import { Icon, IconKey } from '../../../Components/Icons';
import { OzOnly } from '../../../Components/Permissions';
import { parseEvent } from '../../../Components/Timeline/Timeline';
import { Text } from '../../../globalStyles';
import { AssessmentResultsQuery, Maybe, useAssessmentResultsQuery } from '../../../graphQL';
import * as assessmentUtil from '../../../modelUtils/assessment';
import { getFullName } from '../../../modelUtils/users';
import { compareJsDates, englishList } from '../../../utils';
import { UnexpectedError } from '../../Shared';
import { useDrilldownContext, ViewWarning } from '../helpers';
import { Styles } from '../styles';
import { LoadingWidget } from '../Widgets/Loading';
import { SendScales } from '../Widgets/SendScales';

export const AssessmentsTab = () => {
  const { user, refetchCount } = useDrilldownContext();
  const { data, loading, error, refetch } = useAssessmentResultsQuery({
    variables: { userId: user.id },
  });

  useEffect(() => void refetch?.(), [refetchCount, refetch]);

  if (loading) return <LoadingWidget />;
  if (error || !data) return <UnexpectedError />;

  return (
    <div>
      <ViewWarning limited restricted>
        Due to limited record access, some assessments may not be visible.
      </ViewWarning>
      <LatestAssessment results={data.results} />
      <OzOnly>
        <SendScales />
      </OzOnly>
      <AllAssessments results={data.results} />
    </div>
  );
};

type Results = AssessmentResultsQuery['results'];
type Questionnaire = Results[number]['questionnaires'][number];
type Props = { results: Results };

const AllAssessments = ({ results }: Props) => {
  const { user, events } = useDrilldownContext();

  const eventTags = new Set(['provider:assessment:pushed']);

  const patientName = getFullName(user);
  const parsedEvents = events
    .filter(e => eventTags.has(e.tag))
    .map(e => parseEvent(e, patientName)!)
    .filter(Boolean);

  const allAssessmentEvents: {
    date: Date;
    text: string;
    icon: IconKey;
    content?: JSX.Element;
  }[] = [
    ...parsedEvents.map(e => ({
      date: e.createdAt,
      text: e.text,
      icon: e.icon,
    })),
    ...results.map(r => ({
      date: r.createdAt,
      text: `${r.provider?.name ?? patientName} completed the ${englishList(
        r.questionnaires
          .filter(assessmentUtil.shouldShow)
          .map(q => assessmentUtil.getTitle(q.key, true))
      )}`,
      icon: 'iconsBlackMbcSvg' as const,
      content: (
        <>
          <ul className="list pl0 pb3 bb b--light-gray">
            {r.questionnaires.filter(assessmentUtil.shouldShow).map(q => (
              <li className="mb2" key={q.key}>
                <Grid gridTemplateColumns="7rem 1fr" alignItems="center">
                  <div className="f5 b">{assessmentUtil.getTitle(q.key)}</div>
                  <div className="f6">{assessmentUtil.formatResult(q)}</div>
                </Grid>
              </li>
            ))}
          </ul>
          {r.questionnaires.filter(assessmentUtil.shouldShow).map(q => (
            <div className="pb4 bb b--light-gray" key={q.key}>
              <h4 className="f4 mt4 mb3">{assessmentUtil.getTitle(q.key)}</h4>
              <QuestionnaireDisplay questionnaire={q} />
            </div>
          ))}
        </>
      ),
    })),
  ].sort((a, b) => compareJsDates({ a: a.date, b: b.date }));

  if (!allAssessmentEvents.length)
    return (
      <Styles.widget>
        <h3 className="f3">Assessment Activity</h3>
        <em>No activity</em>
      </Styles.widget>
    );

  return (
    <Styles.widget flatBottom>
      <h3 className="f3">Assessment Activity</h3>
      <Accordion
        borders
        items={allAssessmentEvents.map(i => {
          const parsedTime = moment(i.date);
          return {
            content: i.content,
            title: (
              <Grid gridTemplateColumns="6rem 2.25rem 1fr" alignItems="center" className="normal">
                <Text.caption kind="grayText">
                  {parsedTime.format('MM/DD/YYYY')}
                  <br />
                  {parsedTime.format('hh:mm a')}
                </Text.caption>
                <Icon size={22} icon={i.icon} />
                <div>
                  <Text.body>{i.text}</Text.body>
                </div>
              </Grid>
            ),
          };
        })}
      />
    </Styles.widget>
  );
};

const order = [
  'phq',
  'gad7',
  'asrsv11',
  'sds',
  'mdq',
  'ymrs',
  'dropout-risk-measurement',
  'flourishing-scale',
] as const;

const LatestAssessment = ({ results }: Props) => {
  const { user } = useDrilldownContext();

  if (results.length === 0) return null;

  // assuming results are sorted
  const latestByKey = reduceRight(
    results,
    (acc, { questionnaires, ...assessment }) => {
      for (const questionnaire of questionnaires) {
        const groupedKeys: Record<string, string> = {
          phq9: 'phq',
          phq8: 'phq',
        };
        const groupedKey = groupedKeys[questionnaire.key] ?? questionnaire.key;
        if (acc.has(groupedKey) || !assessmentUtil.shouldShow(questionnaire)) continue;
        acc.set(groupedKey, [questionnaire, assessment]);
      }
      return acc;
    },
    new Map<string, [Questionnaire, Omit<Results[number], 'questionnaires'>]>()
  );

  const items: AccordionItem[] = compact(
    order.map(key => {
      const latest = latestByKey.get(key);
      const titleText = assessmentUtil.getTitle(key, true);

      const ScoreDisplay = scoreDisplayMap.get(key);
      const scoreDisplay = ScoreDisplay && <ScoreDisplay result={latest?.[0]} />;

      if (latest) {
        const [questionnaire, { provider, createdAt, id }] = latest;
        const name = provider?.name ?? getFullName(user);

        return {
          key: `${key}_${id}`,
          title: (
            <AssessmentTitle
              title={titleText}
              subtitle={`Submitted by ${name} on ${moment(createdAt).format('L')}`}
              right={scoreDisplay || assessmentUtil.formatResult(questionnaire)}
            />
          ),
          content: <QuestionnaireDisplay questionnaire={questionnaire} />,
        };
      }
      if (scoreDisplay) {
        return { title: <AssessmentTitle title={titleText} right={scoreDisplay} /> };
      }

      return null;
    })
  );

  return (
    <Styles.widget flatBottom>
      <div className="flex justify-between mr3">
        <Text.label className="mb1">Assessment</Text.label>
        <Text.label className="mb1 mr4">Result</Text.label>
      </div>
      <Accordion borders items={items} />
    </Styles.widget>
  );
};

type TitleProps = { title: string; subtitle?: string; right?: JSX.Element | string };
const AssessmentTitle = ({ title, subtitle, right }: TitleProps) => (
  <div className="flex items-center justify-between w-100">
    <div className="normal">
      <Text.h3 className="mb0">{title}</Text.h3>
      {subtitle && (
        <Text.body className="mt0" kind="grayText">
          {subtitle}
        </Text.body>
      )}
    </div>
    {right && <div className="ml3 f6 normal ttc mr4 nowrap">{right}</div>}
  </div>
);

const flourishingContent = {
  '0': 'Strongly disagree',
  '1': 'Disagree',
  '2': 'Slightly disagree',
  '3': 'Mixed or neither agree nor disagree',
  '4': 'Slightly agree',
  '5': 'Agree',
  '6': 'Strongly agree',
} as const;

type FlourishingKeys = keyof typeof flourishingContent;

function renderSwitch(
  key: string,
  answer: Maybe<string> | undefined,
  score: Maybe<number> | undefined
) {
  switch (key) {
    case 'flourishing-scale':
      return answer
        ? `${Number(answer) + 1}  ${flourishingContent[answer as FlourishingKeys]}`
        : null;
    case 'dropout-risk-measurement':
      return score !== 0 ? `${score} ${answer}` : `${answer}`;
    default:
      return `${answer}`;
  }
}

const QuestionnaireDisplay = ({ questionnaire }: { questionnaire: Questionnaire }) => (
  <div>
    <Text.label>{questionnaire.description}</Text.label>
    {questionnaire.answers.map(({ text, textForProviders, answer, key, score }) => (
      <div key={key} className="mt3">
        <p className="b">{textForProviders || text}</p>
        <p className="ml3">{renderSwitch(questionnaire.key, answer, score)}</p>
      </div>
    ))}
  </div>
);
