import { Tag } from 'baseui/tag';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../../../Components/FinalButton';
import { isMantraProvider, useCurrentProvider } from '../../../../Components/Permissions';
import {
  AdminAlertType,
  CareType,
  NextSteps,
  PaymentSource,
  useAdminAlertsQuery,
} from '../../../../graphQL';
import { getRemainingSessions, getTransitionEndDate } from '../../../../utils';
import { useDrilldownContext } from '../../helpers';
import { Text } from '../../styles';
import { COCModal } from './COCModal';
import { CoCTransition } from './types';

export function COCAlert() {
  const [modalCareType, setModalCareType] = useState<CareType | undefined>();
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const { data } = useAdminAlertsQuery({
    variables: { userId: user.id },
  });

  const alert = data?.adminUser.adminAlerts.find(
    a => a.type === AdminAlertType.SponsoredCareExpiring
  );
  const incompleteTransitions = user.continuityOfCareTransitions;

  // omitted by directive
  if (!incompleteTransitions) return null;

  const resolveByMoment = alert?.resolveBy ? moment(alert.resolveBy) : undefined;

  const getCareEnd = (careType: CareType) => {
    const transition = incompleteTransitions.find(t => t.careType === careType);
    return resolveByMoment ?? (transition && getTransitionEndDate(transition));
  };

  if (!alert && !incompleteTransitions.length) return null;

  const visibleCareFlows = isMantraProvider(currentProvider)
    ? user.careFlows.filter(f => currentProvider.careTypes.includes(f.careType))
    : user.careFlows;

  const displayableTransitions = visibleCareFlows.reduce((acc, careFlow) => {
    const { careType, paymentSource } = careFlow;
    const transition = incompleteTransitions.find(i => i.careType === careType);

    if (!transition && paymentSource !== PaymentSource.Organization) return acc;

    const careEnd = (transition && getTransitionEndDate(transition)) ?? resolveByMoment;
    const sessionLimits = getRemainingSessions(careFlow);
    if (!careEnd && !(sessionLimits && sessionLimits.remaining <= 2)) return acc;

    return [
      ...acc,
      <Transition
        transition={transition}
        key={careType}
        careEnd={careEnd}
        careType={careType}
        openModal={() => setModalCareType(careType)}
        sessionLimits={sessionLimits}
      />,
    ];
  }, new Array<JSX.Element>());

  if (!displayableTransitions.length) return null;

  return (
    <Wrapper>
      <Text.h3>Continuity of Care</Text.h3>
      {displayableTransitions}
      {modalCareType && (
        <COCModal
          careType={modalCareType}
          careEnd={getCareEnd(modalCareType)}
          onClose={() => setModalCareType(undefined)}
        />
      )}
    </Wrapper>
  );
}

const Transition = ({
  transition,
  careEnd,
  careType,
  openModal,
  sessionLimits,
}: {
  transition?: CoCTransition;
  careEnd?: Moment;
  careType: CareType;
  openModal: () => void;
  sessionLimits: ReturnType<typeof getRemainingSessions>;
}) => {
  const careExpired = careEnd && careEnd.isBefore(moment(), 'day');
  const sessionsOver = sessionLimits && sessionLimits.remaining <= 0;
  const expired = careExpired || sessionsOver;

  return (
    <div style={{ opacity: expired ? 0.6 : 1 }}>
      <Divider />
      <Text.label className="mb2">{careType}</Text.label>
      {expired && (
        <Text.body className="i">
          Sponsored {careType} ended {careEnd ? careEnd.format('M/D/YYYY') : ''}
        </Text.body>
      )}
      {!expired && sessionLimits && (
        <div className="flex flex-row justify-between mb2">
          <Text.bodyBold>Sponsored visits left</Text.bodyBold>
          <Text.body>{`${sessionLimits.remaining} of ${sessionLimits.sessionsLimit}`}</Text.body>
        </div>
      )}
      {!expired && careEnd && (
        <div className="flex flex-row justify-between mb2">
          <Text.bodyBold>Sponsored care ends</Text.bodyBold>
          <Text.body>{careEnd.format('M/D/YYYY')}</Text.body>
        </div>
      )}
      {!transition && <NoSelection />}
      {transition?.nextSteps === NextSteps.ActiveSelfPay && (
        <SelfPayExplanation pendingUser={!!transition.pendingUser} />
      )}
      {transition?.nextSteps === NextSteps.ActiveSponsored && <SponsoredExplanation />}
      {transition?.nextSteps === NextSteps.OnHold && <OnHoldExplanation transition={transition} />}
      {transition?.nextSteps === NextSteps.Cancel && <CancelExplanation transition={transition} />}
      {!expired &&
        (transition ? (
          <Text.linkButton className="b" onClick={openModal}>
            Change
          </Text.linkButton>
        ) : (
          <div className="mt3">
            <FinalButton kind="outline_black" className="w-100" onClick={openModal}>
              Select Option
            </FinalButton>
          </div>
        ))}
    </div>
  );
};

const NoSelection = () => (
  <div className="flex flex-row justify-between">
    <Text.bodyBold>Next steps</Text.bodyBold>
    <Text.bodyBold kind="danger">No selection</Text.bodyBold>
  </div>
);

const SelfPayExplanation = ({ pendingUser }: { pendingUser: boolean }) => (
  <div className="flex flex-row items-center justify-between">
    <Text.bodyBold>Next steps</Text.bodyBold>
    <Text.body>
      Self-Pay{' '}
      <Tag kind={pendingUser ? 'red' : 'green'} closeable={false}>
        {pendingUser ? 'Pending' : 'Complete'}
      </Tag>
    </Text.body>
  </div>
);

const SponsoredExplanation = () => (
  <div className="flex flex-row justify-between">
    <Text.bodyBold>Next steps</Text.bodyBold>
    <Text.body>Continue sponsored care</Text.body>
  </div>
);

const OnHoldExplanation = ({ transition }: { transition: CoCTransition }) => (
  <>
    <div className="flex flex-row justify-between">
      <Text.bodyBold>Next steps</Text.bodyBold>
      <Text.body>On Hold</Text.body>
    </div>
    <ReasonAndReferral transition={transition} />
  </>
);

const CancelExplanation = ({ transition }: { transition: CoCTransition }) => (
  <>
    <div className="flex flex-row justify-between">
      <Text.bodyBold>Next steps</Text.bodyBold>
      <Text.body>Cancel</Text.body>
    </div>
    <ReasonAndReferral transition={transition} />
  </>
);

const ReasonAndReferral = ({ transition }: { transition: CoCTransition }) => (
  <>
    <Text.bodySmall className="mt1 i">
      <span>Reason:</span> {transition.reason}
    </Text.bodySmall>
    <Text.bodySmall className="mt1 mb3 i">
      <span>Referral Needed?:</span> {transition.requiresReferral ? 'Yes' : 'No'}
    </Text.bodySmall>
  </>
);

const Wrapper = styled.div`
  background: #edf1f7;
  border: 1px solid #c5cee0;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 40px;
`;
const Divider = styled.hr`
  border-color: #d8d8d8;
  border-width: 0px;
  border-bottom-width: 1px;
  margin: 16px 0px;
`;
