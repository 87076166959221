import { Tab, Tabs } from 'baseui/tabs';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useCurrentProvider } from '../../Components/Permissions';
import { Permission } from '../../graphQL';
import { Overrides } from '../Users/baseuiOverrides';
import { Visualizations } from './Visualization';

export function ExperimentsPage() {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { hasPermission } = useCurrentProvider();

  const tabs = [
    {
      title: 'visualization',
      component: Visualizations,
      hidden: !hasPermission(Permission.MantraAdmin),
    },
  ].filter(t => !t.hidden);

  // no available experiments
  if (tabs.length === 0) return <Redirect to="*" />;
  const activeTab = (() => tabs.find(t => t.title === id)?.title ?? tabs[0].title)();

  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => history.replace(`/experiments/${activeKey}`)}
        overrides={Overrides.Tabs}
      >
        {tabs.map(sub => (
          <Tab title={sub.title} key={sub.title} overrides={Overrides.Tab}>
            <sub.component />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
