import React from 'react';
import { colors } from '../../globalStyles';
import { UtilizationPage } from '../Utilization/UtilizationPage';

export function OrganizationCareOps() {
  return (
    <div className="pv3" style={{ backgroundColor: colors.grey.lightBackground }}>
      <UtilizationPage />
    </div>
  );
}
