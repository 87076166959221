import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/link-error';
import { ThemeProvider } from 'baseui';
import { LayersManager } from 'baseui/layer';
import { ToasterContainer } from 'baseui/toast';
import React from 'react';
import styled from 'styled-components';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { MantraTheme } from './globalStyles/baseuiTheme';
import { getAuthToken } from './token';
import { isTokenExpired, logout } from './utils';

const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthToken();
  if (isTokenExpired(token)) logout();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

const errorHandler = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach(gqlError => {
    const statusCode = gqlError?.extensions?.exception?.httpStatus;
    if (statusCode === 401) {
      logout();
      window.location.reload();
    }
  });
});

export const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache,
  // @ts-ignore
  link: authMiddleware.concat(errorHandler).concat(httpLink),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache' },
    query: { fetchPolicy: 'no-cache' },
  },
});

const engine = new Styletron({ prefix: 'x' });

const Centered = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  min-height: 100vh;
`;

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white
  min-height: 100vh;
  max-width: 1600px;
  width: 100%;
`;

export const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <StyletronProvider value={engine}>
    <ThemeProvider theme={MantraTheme}>
      <LayersManager zIndex={5}>
        <ApolloProvider client={apolloClient}>
          <Centered>
            <Inner>{children}</Inner>
          </Centered>
        </ApolloProvider>
      </LayersManager>
      <ToasterContainer placement="bottomRight" />
    </ThemeProvider>
  </StyletronProvider>
);
