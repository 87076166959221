import { CurrentProviderContext } from '../../../Components/Permissions';
import { Permission } from '../../../graphQL';
import { UserType } from '../types';
import { AppointmentsTab } from './Appointments';
import { AssessmentsTab } from './Assessments';
import { CareTeamTab } from './CareTeam';
import { FilesTab } from './Files';
import { MessagesTab } from './Messages';
import { NotesTab } from './Notes';
import { OutreachTab } from './Outreach';
import { OverviewTab } from './Overview';
import { SafetyPlanTab } from './SafetyPlan';

const keys = [
  'overview',
  'messages',
  'notes',
  'assessments',
  'files',
  'appointments',
  'provider-messages',
  'safety-plan',
  'outreach',
] as const;

type Tab = {
  key: typeof keys[number];
  text: string;
  component: () => JSX.Element;
  condition: (arg0: CurrentProviderContext, arg1: UserType) => boolean;
};

export const DrilldownTabs: Tab[] = [
  {
    key: 'overview',
    text: 'Overview',
    component: OverviewTab,
    condition: () => true,
  },
  {
    key: 'messages',
    text: 'Messages',
    component: MessagesTab,
    condition: ({ hasPermission }) => hasPermission(Permission.PatientMessage),
  },
  {
    key: 'notes',
    text: 'Notes',
    component: NotesTab,
    condition: ({ hasPermission }) => hasPermission(Permission.Phi),
  },
  {
    key: 'assessments',
    text: 'Assessments',
    component: AssessmentsTab,
    condition: ({ hasPermission }) => hasPermission(Permission.Phi),
  },
  {
    key: 'files',
    text: 'Files',
    component: FilesTab,
    condition: () => true,
  },
  {
    key: 'appointments',
    text: 'Appointments',
    component: AppointmentsTab,
    condition: () => true,
  },
  {
    key: 'safety-plan',
    text: 'Safety',
    component: SafetyPlanTab,
    condition: ({ hasPermission }) => hasPermission(Permission.Phi),
  },
  {
    key: 'provider-messages',
    text: 'Collaboration Team',
    component: CareTeamTab,
    condition: ({ hasPermission }, { organization }) =>
      hasPermission(Permission.ProviderMessage) && !!organization,
  },
  {
    key: 'outreach',
    text: 'Outreach',
    component: OutreachTab,
    condition: () => true,
  },
];
