import moment from 'moment';
import React from 'react';
import { Icon, IconKey } from '../../Components/Icons';
import { Text } from '../../globalStyles';
import { CounselorDashboardQuery } from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { UnstyledLink } from '../Shared';
import * as Styles from './styles';

interface Props {
  events: CounselorDashboardQuery['organization']['latestNotes'];
  title: string;
  description: string;
  icon: IconKey;
  emptyState: string;
}

export function EventList({ events, title, description, icon, emptyState }: Props) {
  const destination = (title.includes('Notes') ? '?tab=notes' : '?tab=assessments') || '';
  return (
    <Styles.SectionContainer>
      <Text.h3 className="mb4">{title}</Text.h3>
      {events.map((event, index) => (
        <React.Fragment key={event.createdAt.toString()}>
          <UnstyledLink to={`/users/${event.user.id}${destination}`}>
            <div className="flex items-start justify-center">
              <Icon icon={icon} className="mr2 mt1" />
              <div style={{ flex: 1 }}>
                <Text.bodyBold>{getFullNameReversed(event.user)}</Text.bodyBold>
                <Text.body>{description}</Text.body>
                <Text.bodyGrey style={{ fontSize: '0.75rem' }}>
                  Submitted {moment(event.createdAt).format('M/D/YYYY')} at{' '}
                  {moment(event.createdAt).format('h:mm a')}
                </Text.bodyGrey>
              </div>
            </div>
          </UnstyledLink>
          {index < events.length - 1 && <Styles.Divider />}
        </React.Fragment>
      ))}
      {events.length === 0 && <Styles.EmptyStateText>{emptyState}</Styles.EmptyStateText>}
    </Styles.SectionContainer>
  );
}
