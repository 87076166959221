import { reverse } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';
import { useCurrentProvider } from '../../../../Components/Permissions';
import { Text } from '../../../../globalStyles';
import { Permission, StateCodes } from '../../../../graphQL';
import { TimeZone } from '../../../../utils';
import { UnexpectedError } from '../../../Shared';
import * as util from '../../util';
import { WeekStateAllocationModalState } from '../Availability';
import * as Styles from '../Styles';

interface WeekSummaryCellProps {
  week: (Moment | undefined)[];
  tz: TimeZone;
  weekAllocations: util.FriendlyAllocation[][];
  weekStateAllocations: UIStateAllocation[];
  geoStates: StateCodes[];
  setWeekModalDate: (weekModalProps: WeekStateAllocationModalState) => void;
}

export interface UIStateAllocation {
  id: number;
  startDate: string;
  endDate: string;
  maxHours: number;
  maxIntakeHours: number;
  providerState: {
    state: string;
  };
}

export const WeekSummaryCell = ({
  week,
  tz,
  geoStates,
  weekAllocations,
  weekStateAllocations,
  setWeekModalDate,
}: WeekSummaryCellProps) => {
  const { hasPermission } = useCurrentProvider();
  const startOfWeek = week.find(day => day);
  const endOfWeek = reverse(week).find(day => day);
  reverse(week);
  const isPast = !moment().tz(tz).startOf('day').isSameOrBefore(endOfWeek);
  const editable = !isPast && hasPermission(Permission.MantraAdmin);

  const { adminAvailability, bookableAvailability, DGMAvailability, daysWithDGMAvailabilityCount } =
    useMemo(() => {
      return util.summarizeWeeklyAvailability(weekAllocations);
    }, [weekAllocations]);

  const {
    allocatedSponsoredText,
    allocatedSponsoredIntakeText,
    allocatedStateHours,
    allocatedStateIntakeHours,
  } = useMemo(
    () => util.summarizeWeeklyStateAvailability(weekStateAllocations, geoStates),
    [weekStateAllocations]
  );

  if (!startOfWeek) {
    return <UnexpectedError />;
  }
  return (
    <Styles.SummaryCell>
      <Text.bodyBold>
        {startOfWeek?.format('M/D')} - {endOfWeek?.format('M/D')}
      </Text.bodyBold>
      <Styles.TimeSummaryContainer>
        <Text.bodySmallBold>Total Provider Hr</Text.bodySmallBold>
        <Text.bodySmallBold>{`${adminAvailability + bookableAvailability}h`}</Text.bodySmallBold>
      </Styles.TimeSummaryContainer>
      <Styles.TimeBreakdownContainer>
        <Text.caption>Admin</Text.caption>
        <Styles.TimePercentageText>
          {adminAvailability + bookableAvailability > 0
            ? `(${Math.trunc(
                (adminAvailability / (adminAvailability + bookableAvailability)) * 100
              )}%)`
            : ''}
        </Styles.TimePercentageText>
        <Text.caption>{adminAvailability}h</Text.caption>
      </Styles.TimeBreakdownContainer>
      <Styles.TimeBreakdownContainer>
        <Text.caption>Bookable</Text.caption>
        <Styles.TimePercentageText>
          {adminAvailability + bookableAvailability > 0
            ? `(${Math.trunc(
                (bookableAvailability / (adminAvailability + bookableAvailability)) * 100
              )}%)`
            : ''}
        </Styles.TimePercentageText>
        <Text.caption>{bookableAvailability}h</Text.caption>
      </Styles.TimeBreakdownContainer>
      <Styles.Divider />
      <Styles.TimeSummaryContainer>
        <Text.bodySmallBold>
          {DGMAvailability.toString().length > 4 ? 'Pooled Avail.' : 'Pooled Availability'}
        </Text.bodySmallBold>
        <Styles.NoWidthToolTip
          showArrow
          placement="top"
          content={
            <>
              <span className="db">
                <b>Allocated Spon Hours</b>
                <br /> {allocatedSponsoredText}
              </span>
              <span className="db">
                <b> Allocated Spon Intakes</b>
                <br /> {allocatedSponsoredIntakeText}
              </span>
            </>
          }
          size={20}
          fontSize="f7"
        >
          <Text.bodySmallBold>{DGMAvailability}h</Text.bodySmallBold>
        </Styles.NoWidthToolTip>
      </Styles.TimeSummaryContainer>
      <Styles.TimeBreakdownContainer>
        <Styles.StateAllocationLabelText>Spon Care Hours</Styles.StateAllocationLabelText>
        <Text.caption>{allocatedStateHours}h</Text.caption>
      </Styles.TimeBreakdownContainer>
      <Styles.TimeBreakdownContainer>
        <Styles.StateAllocationLabelText>Spon Intakes</Styles.StateAllocationLabelText>
        <Text.caption>{allocatedStateIntakeHours}h</Text.caption>
      </Styles.TimeBreakdownContainer>
      {editable && (
        <Text.linkButton
          className="b tl mt1"
          onClick={() =>
            editable &&
            setWeekModalDate({
              date: startOfWeek,
              timezone: tz,
              maxWeekHours: DGMAvailability,
              maxWeekIntakeHours: daysWithDGMAvailabilityCount * 3, // 3 intakes allowed per day
            })
          }
        >
          Edit Allocation
        </Text.linkButton>
      )}
    </Styles.SummaryCell>
  );
};
