import { MutationFunctionOptions, MutationTuple } from '@apollo/client';
import { compact, uniqBy } from 'lodash';
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import {
  isMcpUser,
  MantraAdminOnly,
  McpOrAdminOnly,
  useCurrentProvider,
} from '../../Components/Permissions';
import { CalendarEvent, UserCareTeamQuery, UserRecordViewStatus } from '../../graphQL';
import { EditMantraCollaborators } from './EditModals/EditMantraCollaborators';
import { EditMcpUser } from './EditModals/EditMcpUser';
import { EditPatientCareFlows } from './EditModals/EditPatientCareFlow';
import { EditPatientInfo } from './EditModals/EditPatientInfo';
import { Text } from './styles';
import { DrillDownContextType, RefetchUserPage } from './types';

export const DrilldownContext = createContext<DrillDownContextType | null>(null);

export const useDrilldownContext = () => {
  const context = useContext(DrilldownContext);
  if (!context) throw new Error("can't use patient context outside of Drilldown view");
  return context;
};

type RecordViewOnlyProps = {
  oz?: boolean;
  restricted?: boolean;
  limited?: boolean;
  full?: boolean;
};
// hides children in all but allowed views
export const RecordViewOnly: React.FC<RecordViewOnlyProps> = ({ children, oz, ...rest }) => {
  const { appView } = useCurrentProvider();
  const { recordViewStatus: rs } = useDrilldownContext().userView;

  if (oz && appView === 'oz') return <>{children}</>;
  if (appView === 'mcp') {
    if (rest.full && rs === UserRecordViewStatus.Full) return <>{children}</>;
    if (rest.limited && rs === UserRecordViewStatus.Limited) return <>{children}</>;
    if (rest.restricted && rs === UserRecordViewStatus.Restricted) return <>{children}</>;
  }

  return null;
};

type RestrictedProps = { override?: boolean };
// show in OZ and non-restricted MCP
export const Restricted: React.FC<RestrictedProps> = ({ override, children }) => {
  if (override) return <>{children}</>;
  return (
    <RecordViewOnly oz full limited>
      {children}
    </RecordViewOnly>
  );
};

export const ViewWarning: React.FC<RecordViewOnlyProps> = ({ children, ...props }) => (
  <RecordViewOnly {...props}>
    <p className="mb4 o-50 i">{children}</p>
  </RecordViewOnly>
);

export function useSaveAndRefetch<TData, TVariables>(
  [mutate, mutation]: MutationTuple<TData, TVariables>,
  refetch: RefetchUserPage
): MutationTuple<TData, TVariables> {
  const [loading, setLoading] = useState(false);
  const save = async (mutateParams?: MutationFunctionOptions<TData, TVariables>) => {
    setLoading(true);
    try {
      const data = await mutate(mutateParams);
      await refetch();
      return data;
    } finally {
      setLoading(false);
    }
  };
  return [save, { ...mutation, loading }];
}

type UpcomingAptArgs = Pick<CalendarEvent, 'endTime' | 'status'>;
export const getUpcomingAppts = <T extends UpcomingAptArgs>(appts: T[]): T[] => {
  // stay on dashboard for an hr after appt, unless complete, no-show, or cancelled/rescheduled
  const is1HourPastEnd = (endTime: Date, now = moment()) =>
    now.subtract(1, 'hour').isAfter(endTime);

  return appts
    .filter(apt => !is1HourPastEnd(apt.endTime) && apt.status === 'upcoming')
    .sort((a, b) => moment(a.endTime).diff(b.endTime));
};

export const getSortedUniversityCareTeam = (
  user: Pick<UserCareTeamQuery['user'], 'universityCareTeam' | 'universityCareTeamPrimary'>
) => {
  const { universityCareTeam, universityCareTeamPrimary } = user;
  return uniqBy(compact([universityCareTeamPrimary, ...universityCareTeam]), p => p.id);
};

export const CareTypeEditModal = ({ ...props }) => {
  const [isCareTypeModalOpen, setIsCareTypeModalOpen] = useState<boolean>(false);
  return (
    <>
      <McpOrAdminOnly>
        <Text.linkButton onClick={() => setIsCareTypeModalOpen(true)}>{props.text}</Text.linkButton>
      </McpOrAdminOnly>
      <EditPatientCareFlows
        isOpen={isCareTypeModalOpen}
        onClose={() => setIsCareTypeModalOpen(false)}
      />
    </>
  );
};

export const PatientInfoModal = () => {
  const [isPatientInfoModalOpen, setIsPatientInfoModalOpen] = useState<boolean>(false);
  const { currentProvider } = useCurrentProvider();
  const isMCP = isMcpUser(currentProvider);

  return (
    <>
      <Text.linkButton onClick={() => setIsPatientInfoModalOpen(true)}>Edit</Text.linkButton>
      {isPatientInfoModalOpen && (
        <>
          {isMCP && <EditMcpUser closeModal={() => setIsPatientInfoModalOpen(false)} />}
          {!isMCP && <EditPatientInfo closeModal={() => setIsPatientInfoModalOpen(false)} />}
        </>
      )}
    </>
  );
};

export const MantraCollaboratorsModal = () => {
  const [isMantraCollabModalOpen, setIsMantraCollabModalOpen] = useState<boolean>(false);
  return (
    <MantraAdminOnly>
      <Text.linkButton onClick={() => setIsMantraCollabModalOpen(true)}>Edit</Text.linkButton>
      {isMantraCollabModalOpen && (
        <EditMantraCollaborators closeModal={() => setIsMantraCollabModalOpen(false)} />
      )}
    </MantraAdminOnly>
  );
};
