import React from 'react';
import { FeatureFlagged } from '../../../Components/Permissions';
import { Flag } from '../../../featureFlags';
import { VideoCallDataFragment } from '../../../graphQL';
import { CrisisModeSection } from '../VideoCallSidebar';

type CrisisComponentProps = {
  appointment: VideoCallDataFragment;
};
export const CrisisTab = ({ appointment }: CrisisComponentProps) => {
  return (
    <FeatureFlagged flag={Flag.CrisisTeam}>
      <CrisisModeSection appointment={appointment} />
    </FeatureFlagged>
  );
};
