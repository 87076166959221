import { Avatar } from 'baseui/avatar';
import { Tag } from 'baseui/tag';
import React from 'react';
import { Text } from '../../globalStyles';
import { Organization, Permission, ProviderFragment } from '../../graphQL';
import { useOrgParentChildCheck } from '../../Hooks/useOrgParentChildCheck';
import { Nullable } from '../../types';
import { margin, padding } from '../../utils';
import { icons } from '../Icons';
import { Link } from '../Links';
import { MCP_ROLES, useCurrentProvider } from '../Permissions';

type Provider = Pick<ProviderFragment, 'id' | 'name' | 'classification' | 'role'> & {
  portrait?: Nullable<{ url: string }>;
};

type ProviderCardProps = {
  provider: Provider;
  organization?: Pick<Organization, 'id'>;
  primary?: boolean;
  query?: string;
};
export const ProviderCard = ({
  provider,
  primary,
  organization,
  query = '',
}: ProviderCardProps) => {
  const { appView, hasPermission } = useCurrentProvider();
  const avatarUrl = provider.portrait?.url ?? icons.assetsDefaultPicturePng;
  const { name: providerName, role: providerRole } = provider;

  const { providerOrgMatchesCurrentProvider } = useOrgParentChildCheck({ organization });

  // oz providers can view all providers, mcp providers can only see staff accounts within organization
  const link =
    (hasPermission(Permission.ProviderView) &&
      (appView === 'oz' ||
        (MCP_ROLES.includes(providerRole) && providerOrgMatchesCurrentProvider))) ??
    false;

  const Wrapper: React.FC = React.useCallback(
    ({ children }) =>
      link ? (
        <Link className="br2 pointer link" to={`/providers/${provider.id}${query}`}>
          {children}
        </Link>
      ) : (
        <div>{children}</div>
      ),
    [link, provider.id, query]
  );

  return (
    <Wrapper>
      <div
        className={`pv2 ph1 flex justify-start items-start ${link ? 'hover-bg-light-gray' : ''}`}
        key={provider.id}
      >
        <Avatar
          size="32px"
          src={avatarUrl}
          name={providerName}
          overrides={{
            Root: { style: { minWidth: '32px' } },
            Avatar: { style: { border: '1px solid white' } },
          }}
        />
        <div title={providerName} className="pl2 truncate">
          <div className="flex items-start">
            <Text.bodyBold className="mt0 truncate flex-auto">{providerName}</Text.bodyBold>
            {primary && (
              <Tag closeable={false} kind="positive" overrides={tagOverrides}>
                Primary
              </Tag>
            )}
          </div>
          <Text.bodySmallGrey className="mt0">{provider.classification}</Text.bodySmallGrey>
        </div>
      </div>
    </Wrapper>
  );
};

const tagOverrides = {
  Text: { style: { fontSize: '.6875rem' } },
  Root: { style: { ...margin('0px'), marginLeft: '.25rem', ...padding('0px .35rem') } },
} as const;
