import { compact, lowerCase, pick } from 'lodash';
import React, { useState } from 'react';
import { Text } from '../../globalStyles';
import { useQueryParams } from '../../Hooks';
import { Hr } from '../../Pages/Messages/Styles';
import { states } from '../../states';
import { useConfigContext } from '../ConfigContext';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { MantraModalBodyOld, MantraModalHeaderOld } from '../Modal';
import { Modal } from '../Modal/Modal';
import { useCurrentProvider } from '../Permissions';
import { useProviderNetworkContext } from './ProviderNetworkContext';
import { isSelfPayReferral } from './providerNetworkUtils';
import { ProvidersSoonerAvailability } from './ProvidersSoonerAvailability';
import * as Styles from './Styles';

type Props = {
  onBack?: () => void;
  clearGeoState?: boolean;
  nextAvailableProviderDate?: Date;
};

export const ProvidersNotFound = ({ onBack, clearGeoState, nextAvailableProviderDate }: Props) => {
  const {
    outOfNetworkCount,
    hasActiveTraits,
    organizationId,
    hasRemainingHours,
    dedicatedGroupModelActive,
    stateNotAllowed,
    searchVariables: { state, careType },
    jumpTo,
  } = useProviderNetworkContext();

  const { currentProvider } = useCurrentProvider();
  const [parsedSearch, updateQueryParams] = useQueryParams();
  const { stateHasCare } = useConfigContext();
  const [soonerAvailabilityModal, setSoonerAvailabilityModal] = useState(false);
  const [outOfNetworkConfirm, setOutOfNetworkConfirm] = useState(false);

  if (stateNotAllowed) {
    const stateName = states[stateNotAllowed];

    return (
      <Styles.CenteredMaxWidth>
        <InlineSVG kind="greyIcon" icon="search" size={42} />
        <Text.h3 className="mt2">
          Your organization is not contracted to refer students for {lowerCase(careType)} in{' '}
          {stateName}.
        </Text.h3>
      </Styles.CenteredMaxWidth>
    );
  }

  if (careType && state) {
    const isSelfPayOrg = isSelfPayReferral(currentProvider, careType);
    const organization = currentProvider.organizations[0];
    if (!stateHasCare(careType, state, isSelfPayOrg ? null : organization)) {
      return (
        <Styles.CenteredMaxWidth>
          <InlineSVG kind="greyIcon" icon="search" size={42} />
          <Text.h3 className="mt2">
            We currently don&apos;t have any available {lowerCase(careType)} providers who are
            licensed in the selected state
          </Text.h3>
        </Styles.CenteredMaxWidth>
      );
    }
  }

  if (outOfNetworkCount > 1) {
    return (
      <div>
        <Modal
          size="tiny"
          isOpen={outOfNetworkConfirm}
          onClose={() => setOutOfNetworkConfirm(false)}
        >
          <MantraModalHeaderOld>Explore out-of-network providers?</MantraModalHeaderOld>
          <MantraModalBodyOld>
            <Text.body className="mb4 nt2">
              The providers shown will not be in-network with the originally selected insurance
              plan, and we’ll remove the insurer from your search criteria.
            </Text.body>
            <FinalButton
              kind="outline_black"
              className="w-100 mb4"
              onClick={() => {
                setOutOfNetworkConfirm(false);
                updateQueryParams({ ...parsedSearch, payerId: null });
              }}
            >
              Continue
            </FinalButton>
            <Text.linkButton
              kind="grayText"
              className="b tc w-100"
              onClick={() => setOutOfNetworkConfirm(false)}
            >
              Cancel
            </Text.linkButton>
          </MantraModalBodyOld>
        </Modal>
        <Styles.CenteredMaxWidth>
          <InlineSVG kind="greyIcon" icon="search" size={42} />
          <Text.h3 className="mt2">
            We currently don&apos;t have any providers who are in-network with the selected
            insurance plan
          </Text.h3>
        </Styles.CenteredMaxWidth>
        <Hr />
        <Styles.CenteredMaxWidth>
          <Text.h3 className="mb3" style={{ fontWeight: 'normal' }}>
            Good news! Mantra has {outOfNetworkCount} out-of-network providers available
          </Text.h3>
          <FinalButton
            kind="outline_black"
            className="w-100"
            onClick={() => setOutOfNetworkConfirm(true)}
          >
            View out-of-network providers
          </FinalButton>
        </Styles.CenteredMaxWidth>
      </div>
    );
  }

  if (!hasActiveTraits && organizationId && hasRemainingHours && dedicatedGroupModelActive) {
    return (
      <ProvidersSoonerAvailability
        soonerAvailabilityModalState={soonerAvailabilityModal}
        setSoonerAvailabilityModalState={setSoonerAvailabilityModal}
        nextAvailabilityButtonClick={jumpTo}
        nextAvailableProviderDate={nextAvailableProviderDate}
      />
    );
  }

  const canGoBack = !hasActiveTraits && onBack;

  return (
    <Styles.CenteredMaxWidth>
      <InlineSVG kind="greyIcon" icon="search" size={42} />
      <Text.h3 className="mv2">
        We currently don&apos;t have any providers who meet your search criteria
      </Text.h3>
      <Text.body className="mb3">Adjust your search filters to see available providers</Text.body>
      <FinalButton
        kind="outline_black"
        className="w-100"
        onClick={() => {
          updateQueryParams(
            pick(
              parsedSearch,
              compact(['careType', 'payerId', 'organizationId', clearGeoState && 'state'])
            )
          );
          if (canGoBack) onBack();
        }}
      >
        {hasActiveTraits ? 'Clear Filters' : 'Go back'}
      </FinalButton>
    </Styles.CenteredMaxWidth>
  );
};
