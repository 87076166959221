import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input, MaskedInput } from 'baseui/input';
import { StatefulSelect } from 'baseui/select';
import { capitalize, chain, difference, isNumber } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { MaskedPhoneInput } from '../../Components/EzForm/MaskedInputs';
import { FinalButton } from '../../Components/FinalButton';
import { validatePhoneNumber } from '../../Components/FormValidations/Validators';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Notification } from '../../Components/Notification';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { isMcpUser, McpOnly, ReferralOnly, useCurrentProvider } from '../../Components/Permissions';
import { hasSelfPayFlow } from '../../Components/ProviderNetwork/providerNetworkUtils';
import { CampusTeamSelection } from '../../Components/CampusTeam/CampusTeamSelection';
import { When } from '../../Components/When';
import { PaddedPage, Text } from '../../globalStyles';
import {
  CareType,
  OrganizationForEnrollPageQuery,
  Provider,
  StateCodes,
  useAdminCreateUserMutation,
  useOrganizationForEnrollPageQuery,
} from '../../graphQL';
import { useGeoStateAvailability } from '../../Hooks/useGeoStateAvailability';
import { stateOptions, states } from '../../states';
import { canReferCareType, englishList, stripPhoneNumber } from '../../utils';
import { UnexpectedError } from '../Shared';
import { DuplicateEmailModal } from './DuplicateEmailModal';
import { ReferralSuccessPage } from './ReferralSuccessPage';
import { ResentActivationPage } from './ResentActivationPage';
import * as Styles from './styles';
import { Nullable } from '../../types';
import { EditCampusTeamMember } from '../../Components/CampusTeam/types';

interface FormFields {
  firstName: string;
  lastName: string;
  birthDate: string;
  careTypes: CareType[];
  email: string;
  confirmEmail: string;
  phone: string;
  geoState: string;
  careTeamPrimary: number;
  careTeamSecondary: number[];
  childOrganizationId: number | undefined;
  preferredName?: string;
  acknowledge?: boolean;
}

const required = 'This field is required.';

export function EnrollStudent() {
  const { appView, providerOrgUsesDedicatedGroupModel, featureFlags } = useCurrentProvider();
  const [careTeamPrimary, setCareTeamPrimary] = useState<number | undefined>();
  const params = useParams<{ organizationId: string }>();
  const organizationId = Number(params.organizationId);
  const oneMonthOutRef = useRef(moment().add(1, 'month').format());
  const [error, setError] = useState<string>();
  const [campusTeam, setCampusTeam] = useState<Nullable<EditCampusTeamMember[]>>(null);

  const {
    data: orgData,
    loading,
    error: loadError,
  } = useOrganizationForEnrollPageQuery({
    variables: {
      id: organizationId,
      includeProviders: appView !== 'referral',
      // use a ref to prevent this query from re-running when the time changes (i.e. constantly)
      nextTimeSlotEnd: oneMonthOutRef.current,
    },
  });
  const {
    loading: validateLoading,
    loadError: loadError2,
    validateCareTypesState,
  } = useGeoStateAvailability(organizationId);
  const [showPreferredNameField, setShowPreferredNameField] = useState(false);
  const [showDuplicateEmailModal, setShowDuplicateEmailModal] = useState(false);
  const [showResentActivationPage, setShowResentActivationPage] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    watch,
    control,
    getValues,
    trigger,
    formState,
  } = useForm<FormFields>({
    defaultValues: { careTeamSecondary: [] },
    shouldUnregister: false,
  });

  useEffect(() => {
    // Register the select components manually.
    register({ name: 'careTeamPrimary' });
    register(
      { name: 'careTeamSecondary' },
      {
        validate: value =>
          !value.includes(watch('careTeamPrimary')) ||
          'You cannot pick the same provider for "On-Campus Counselor" and "Other Staff".',
      }
    );
    register(
      { name: 'birthDate' },
      {
        required,
        validate: {
          validDate: (value: string) =>
            moment(value, 'MM/DD/YYYY').isValid() || 'Please enter a valid date.',
          format: (value: string) =>
            /^\d{2}\/\d{2}\/\d{4}$/.test(value) || 'Enter date in MM/DD/YYYY format.',
        },
      }
    );
    register(
      { name: 'phone' },
      {
        required,
        validate: {
          format: validatePhoneNumber,
        },
      }
    );
    register(
      { name: 'careTypes' },
      { required, validate: { length: value => value?.length > 0 || required } }
    );
  }, [register, watch, appView]);

  const [createUser, { loading: createUserLoading, error: createUserError, data: createUserData }] =
    useAdminCreateUserMutation({
      onError: err => {
        if (err.message === 'Email already exists.') {
          setShowDuplicateEmailModal(true);
        }
        formRef.current?.scrollIntoView?.();
      },
    });

  if (loading || validateLoading) return <LoadingPage />;
  if (loadError || loadError2 || !orgData) return <UnexpectedError />;

  const { organization } = orgData;
  const isSuperOrg = organization.children.length > 0;

  const nameSort = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);
  let possibleProviders: Pick<Provider, 'name' | 'id' | 'role'>[] = [
    ...(organization.providers ?? []),
  ].sort(nameSort);
  const childOrganizationId = watch('childOrganizationId');
  if (childOrganizationId) {
    const childOrg = organization.children.find(i => i.id === childOrganizationId);
    const childProviders = childOrg ? [...(childOrg.providers ?? [])].sort(nameSort) : [];
    // Super admins should be below the providers in the child org
    possibleProviders = [...childProviders, ...possibleProviders];
  } else if (organization.parent) {
    const parentProviders = [...(organization.parent.providers ?? [])].sort(nameSort);
    possibleProviders = [...possibleProviders, ...parentProviders];
  }
  const providerOptions = possibleProviders
    .filter(isMcpUser)
    .map(({ id, name }) => ({ label: name, id }));

  const submit = async (values: FormFields) => {
    setError('');
    const missingRelationship = campusTeam?.find(c => !c.relationshipType);
    if (missingRelationship) {
      setError(`${missingRelationship.name} missing relationship to patient.`);
    } else {
      createUser({
        variables: {
          input: {
            organizationId: values.childOrganizationId ?? organizationId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: stripPhoneNumber(values.phone),
            // we get a type error if it's not a Date, but the backend rejects it if it is,
            // not sure what's the dealio
            birthDate: moment(values.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD') as any,
            universityCareTeamPrimary:
              values.careTeamPrimary === -1 ? null : values.careTeamPrimary,
            universityCareTeamSecondary: values.careTeamSecondary,
            campusTeam: campusTeam?.map(c => ({
              providerId: c.id,
              relationshipType: c.relationshipType!,
            })),
            preferredName: values.preferredName,
            careTypes: values.careTypes,
            geoState: values.geoState as StateCodes,
          },
        },
      });
    }
  };

  const onPrimaryCareTeamSelect = (id: number) => {
    setValue('careTeamPrimary', id);
    setCareTeamPrimary(id === -1 ? undefined : id);
  };

  const onAddPreferredName = () => {
    setShowPreferredNameField(val => !val);
  };

  const showRequestAccessCopy = hasSelfPayFlow(organization, watch('careTypes') ?? []);

  /**
   * Validates the given state against both the organization restricted states
   * and the provider available states for the currently selected care types.
   */
  const validateStateIsAllowedForCareType = (state: string): string | boolean => {
    if (!validateCareTypesState) {
      return true;
    }

    const selectedCareTypes = getValues('careTypes') ?? [];

    const validationResponse = validateCareTypesState(selectedCareTypes)(state);
    if (validationResponse.emptyCareTypes) {
      return 'Select a care type above to validate whether this student can be seen by a contracted provider.';
    }

    if (validationResponse.passed) {
      return true;
    }

    if (validationResponse.organizationFailed) {
      const { careTypes, stateName, isAnyTypeAvailable } = validationResponse.organizationFailed;
      return [
        `Your organization is not contracted to refer students for ${careTypes} services in ${stateName}.`,
        isAnyTypeAvailable
          ? " You may select a different care type above, if applicable, for the student's needs."
          : '',
        ' Email us at hi@mantrahealth.com if you have any questions.',
      ].join('');
    }

    if (validationResponse.providerFailed) {
      const { careTypes, stateName, isAnyTypeAvailable } = validationResponse.providerFailed;
      return [
        `Mantra Health is not contracted to provide ${careTypes} services to students that reside in ${stateName}.`,
        isAnyTypeAvailable
          ? " You may select a different care type above, if applicable, for the student's needs."
          : '',
        ' Email us at hi@mantrahealth.com if you have any questions.',
      ].join('');
    }

    return true;
  };

  if (createUserData && appView === 'mcp') {
    return <Redirect to={`/users/${createUserData.adminCreateUser.id}`} />;
  }
  if (createUserData?.adminCreateUser && appView === 'referral') {
    return (
      <ReferralSuccessPage
        user={{
          ...getValues(),
          id: createUserData.adminCreateUser.id,
        }}
      />
    );
  }
  if (showResentActivationPage) {
    return (
      <ResentActivationPage
        email={watch('email')}
        firstName={watch('firstName')}
        preferredName={watch('preferredName')}
      />
    );
  }

  return (
    <Styles.Background>
      <form onSubmit={handleSubmit(submit)} ref={formRef}>
        <Styles.Wrapper>
          <UniversityLogo />
          <Text.h1>Create Student Account</Text.h1>
          <NextAvailableIntake
            organization={organization}
            providerOrgUsesDedicatedGroupModel={providerOrgUsesDedicatedGroupModel}
          />
          {(createUserError || error) && (
            <>
              {!createUserError?.message.includes('Email already exists') &&
                (createUserError?.graphQLErrors?.[0].extensions?.exception.code ===
                'NOT_ON_ENROLL_LIST' ? (
                  <Notification kind="negative">
                    This student&apos;s email is not on the enrollment list. Please verify the email
                    address is accurate. If you think this is a mistake, email{' '}
                    {organization.director ? (
                      <a href={`mailto:${organization.director.email}`}>
                        {organization.director.email}
                      </a>
                    ) : (
                      'your organization administrator'
                    )}
                    .
                  </Notification>
                ) : (
                  <Notification kind="negative">
                    {createUserError
                      ? createUserError.message.replace('GraphQL error:', '')
                      : error}
                  </Notification>
                ))}
            </>
          )}
          {showDuplicateEmailModal && (
            <DuplicateEmailModal
              email={watch('email')}
              onClose={() => setShowDuplicateEmailModal(false)}
              onResendActivation={() => setShowResentActivationPage(true)}
            />
          )}
          <Styles.HeaderContainer>
            <Styles.Header>Basics</Styles.Header>
            <Text.body>
              If present, a student’s preferred name will appear on their profile, but we ask for
              the legal name because it’s required for telehealth practices.
            </Text.body>
          </Styles.HeaderContainer>
          <Styles.FormSection>
            <div>
              <Styles.FieldName htmlFor="firstName">Legal First Name</Styles.FieldName>
              <FormControl caption={errors.firstName && errors.firstName.message}>
                <Input
                  inputRef={register({ required })}
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  error={!!errors.firstName}
                />
              </FormControl>
              <Styles.AddField onClick={onAddPreferredName}>+ Add Preferred Name</Styles.AddField>
            </div>
            <div>
              <Styles.FieldName htmlFor="lastName">Legal Last Name</Styles.FieldName>
              <FormControl caption={errors.lastName && errors.lastName.message}>
                <Input
                  inputRef={register({ required })}
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  error={!!errors.lastName}
                />
              </FormControl>
            </div>
            {showPreferredNameField && (
              <>
                <div>
                  <Styles.FieldName htmlFor="preferredName">
                    What should we call this patient?
                  </Styles.FieldName>
                  <FormControl caption={errors.preferredName && errors.preferredName.message}>
                    <Input
                      inputRef={register({ required })}
                      id="preferredName"
                      name="preferredName"
                      placeholder="Preferred Name"
                      error={!!errors.preferredName}
                    />
                  </FormControl>
                </div>
                <div>{/* spacer for grid layout */}</div>
              </>
            )}
            <div>
              <Styles.FieldName htmlFor="birthDate">Date of Birth</Styles.FieldName>
              {/* non-breaking space so this dropdown lines up with the one next to it */}
              <Styles.FieldHint>{'\u00A0'}</Styles.FieldHint>
              <FormControl caption={errors.birthDate && errors.birthDate.message}>
                <MaskedInput
                  name="birthDate"
                  id="birthDate"
                  placeholder="MM/DD/YYYY"
                  error={!!errors.birthDate}
                  mask="99/99/9999"
                  onChange={event => setValue('birthDate', event.currentTarget.value)}
                />
              </FormControl>
            </div>
            <div>
              <Styles.FieldName>Treatment Type</Styles.FieldName>
              <Styles.FieldHint>
                Type of care this student will receive through Mantra
              </Styles.FieldHint>
              <FormControl caption={errors.careTypes && (errors.careTypes as any).message}>
                <StatefulSelect
                  id="careTypes"
                  multi
                  options={[
                    {
                      label: CareType.Psychiatry,
                      id: CareType.Psychiatry,
                      disabled: !canReferCareType(
                        organization.careFlows,
                        appView,
                        CareType.Psychiatry
                      ),
                    },
                    {
                      label: CareType.Therapy,
                      id: CareType.Therapy,
                      disabled: !canReferCareType(
                        organization.careFlows,
                        appView,
                        CareType.Therapy
                      ),
                    },
                  ].sort((a, _) => (a.disabled ? 0 : -1))}
                  onChange={({ value }) => {
                    setValue('careTypes', value.map(v => v.id) as CareType[]);
                    // If the form has already been submitted, trigger re-validation
                    // on the geoState field to see if we have providers matching the
                    // state.
                    if (formState.isSubmitted) trigger('geoState');
                  }}
                  clearable={false}
                  error={!!errors.careTypes}
                />
              </FormControl>
            </div>
          </Styles.FormSection>
          <Styles.HeaderContainer>
            <Styles.Header>Student Contact</Styles.Header>
          </Styles.HeaderContainer>
          <Styles.FormSection>
            <div>
              <Styles.FieldName htmlFor="email">Student University Email Address</Styles.FieldName>
              <FormControl caption={errors.email && errors.email.message}>
                <Input
                  inputRef={register({
                    required,
                    validate: {
                      at: value => value.includes('@') || 'Please enter a valid email address.',
                    },
                  })}
                  name="email"
                  id="email"
                  placeholder="name@university.edu"
                  type="email"
                  error={!!errors.email}
                />
              </FormControl>
            </div>
            <div>
              <Styles.FieldName htmlFor="confirmEmail">
                Confirm Student University Email
              </Styles.FieldName>
              <FormControl caption={errors.confirmEmail && errors.confirmEmail.message}>
                <Input
                  inputRef={register({
                    required,
                    validate: {
                      match: value => value === watch('email') || 'Emails do not match.',
                    },
                  })}
                  name="confirmEmail"
                  id="confirmEmail"
                  placeholder="name@university.edu"
                  type="email"
                  error={!!errors.confirmEmail}
                />
              </FormControl>
            </div>
            <div>
              <Styles.FieldName htmlFor="phone">Student Phone Number</Styles.FieldName>
              <FormControl caption={errors.phone && errors.phone.message}>
                <MaskedPhoneInput
                  name="phone"
                  id="phone"
                  error={!!errors.phone}
                  control={control}
                />
              </FormControl>
            </div>
            <div>
              <Styles.FieldName htmlFor="geoState">Student’s Residential State</Styles.FieldName>
              <FormControl caption={errors.geoState && errors.geoState.message}>
                <Controller
                  defaultValue={null}
                  render={({ onChange, ...handler }) => (
                    <StatefulSelect
                      id="geoState"
                      options={stateOptions}
                      clearable={false}
                      onChange={({ option }) => onChange(option!.id)}
                      maxDropdownHeight="15rem"
                      error={!!errors.geoState}
                      {...handler}
                    />
                  )}
                  control={control}
                  name="geoState"
                  rules={{
                    required,
                    validate: {
                      unavailableCareType: (value: keyof typeof states) => {
                        return validateStateIsAllowedForCareType(value);
                      },
                    },
                  }}
                />
              </FormControl>
            </div>
          </Styles.FormSection>
        </Styles.Wrapper>
        <McpOnly>
          <When
            isTruthy={
              featureFlags.includes('CTC_ENHANCED_COLLABORATION') && isNumber(organizationId)
            }
          >
            <PaddedPage style={{ paddingTop: 0, paddingBottom: 0 }} className="mb2">
              <CampusTeamSelection
                campusTeam={campusTeam ?? []}
                setCampusTeam={c => setCampusTeam(c)}
                showAccessFaq
                organizationId={organizationId}
              />
            </PaddedPage>
          </When>
          {/* otherwise ... */}
          <Styles.Wrapper>
            <When isTruthy={!featureFlags.includes('CTC_ENHANCED_COLLABORATION')}>
              <Styles.HeaderContainer>
                <Styles.Header>Collaboration Team</Styles.Header>
                {showRequestAccessCopy && (
                  <Text.bodySmall className="mb4">
                    Adding collaboration team members will send a request to collaborate with this
                    patient’s Mantra provider. Upon sign up, patient will be able to accept or deny
                    request. If the patient will not have any ongoing collaboration team members on
                    campus, select “None”.
                  </Text.bodySmall>
                )}
              </Styles.HeaderContainer>
              {isSuperOrg && (
                <Styles.FormSection style={{ marginBottom: '1em' }}>
                  <div>
                    <Styles.FieldName>Campus</Styles.FieldName>
                    <FormControl
                      caption={errors.childOrganizationId && errors.childOrganizationId.message}
                    >
                      <Controller
                        defaultValue={null}
                        render={({ onChange, ...handler }) => (
                          <StatefulSelect
                            options={organization.children
                              .map(({ id, name }) => ({ id, label: name }))
                              .sort((a, b) => a.label.localeCompare(b.label))}
                            clearable={false}
                            onChange={({ option }) => onChange(option!.id)}
                            placeholder="Select a campus"
                            error={!!errors.childOrganizationId}
                            {...handler}
                          />
                        )}
                        control={control}
                        name="childOrganizationId"
                        rules={{ required }}
                      />
                    </FormControl>
                  </div>
                </Styles.FormSection>
              )}
              <Styles.FormSection>
                <div>
                  <Styles.FieldName>Student’s Primary Collaborator </Styles.FieldName>
                  <Styles.FieldHint>
                    Therapist/counselor or main staff member responsible for this student’s care
                  </Styles.FieldHint>
                  <FormControl caption={errors.careTeamPrimary && errors.careTeamPrimary.message}>
                    <StatefulSelect
                      id="careTeamPrimary"
                      options={[{ id: -1, label: 'None' }, ...providerOptions]}
                      error={!!errors.careTeamPrimary}
                      onChange={({ option }) => onPrimaryCareTeamSelect(Number(option!.id))}
                      clearable={false}
                      placeholder="Select"
                      disabled={isSuperOrg && !watch('childOrganizationId')}
                      maxDropdownHeight="24rem"
                    />
                  </FormControl>
                </div>
                <div>
                  <Styles.FieldName>Additional Collaborator(s)</Styles.FieldName>
                  <div>
                    <Styles.FieldHint>
                      Other staff members at the university that work directly with this student
                    </Styles.FieldHint>
                  </div>
                  <FormControl
                    caption={errors.careTeamSecondary && (errors.careTeamSecondary as any).message}
                  >
                    <StatefulSelect
                      id="careTeamSecondary"
                      filterOptions={value => value.filter(({ id }) => id !== careTeamPrimary)}
                      filterOutSelected
                      options={providerOptions}
                      error={!!errors.careTeamSecondary}
                      onChange={({ value }) =>
                        setValue(
                          'careTeamSecondary',
                          value.map(i => Number(i.id))
                        )
                      }
                      clearable={false}
                      multi
                      placeholder="Select"
                      disabled={isSuperOrg && !watch('childOrganizationId')}
                      maxDropdownHeight="24rem"
                    />
                  </FormControl>
                </div>
              </Styles.FormSection>
            </When>
          </Styles.Wrapper>
        </McpOnly>
        <Styles.Wrapper>
          <ReferralOnly>
            <Controller
              control={control}
              name="acknowledge"
              rules={{ required: 'You must read and acknowlege these terms' }}
              defaultValue={false}
              render={({ value, onChange, onBlur }) => (
                <div className="mb4">
                  <Checkbox
                    isError={!!errors.acknowledge}
                    checked={value}
                    onChange={() => onChange(!value)}
                    onBlur={onBlur}
                  >
                    <Text.body className="mt0 ml2">
                      I acknowledge and understand that Mantra Health &amp; Wellround Provider Group
                      are <strong>not an emergency service</strong> nor do they provide urgent care
                      services. If you have an emergency, if a student is in crisis, or is in
                      imminent risk of harming themselves or others,
                      <strong> call 911 or another local emergency resource.</strong>
                    </Text.body>
                  </Checkbox>
                  {errors.acknowledge && (
                    <Text.body kind="danger">{errors.acknowledge.message}</Text.body>
                  )}
                </div>
              )}
            />
          </ReferralOnly>
          <Styles.FormSection>
            <FinalButton
              type="submit"
              className="w-100"
              kind="primary"
              loading={createUserLoading}
              data-cy="enrollSubmit"
            >
              Submit
            </FinalButton>
          </Styles.FormSection>
        </Styles.Wrapper>
      </form>
    </Styles.Background>
  );
}

const NextAvailableIntake = ({
  organization,
  providerOrgUsesDedicatedGroupModel,
}: {
  organization: OrganizationForEnrollPageQuery['organization'];
  providerOrgUsesDedicatedGroupModel: () => boolean;
}) => {
  if (providerOrgUsesDedicatedGroupModel()) {
    // Do not show next available appointments if the org uses DGM.
    return null;
  }

  const availableIntakes = organization.nextAvailableIntakeAppts;
  const orgCareTypes = organization.careFlows.map(f => f.careType);

  if (!orgCareTypes.length) return null;

  const careTypesWithoutNext = difference(
    orgCareTypes,
    availableIntakes.map(a => a.careType)
  );

  // group appts with the same time
  const nextAppts = chain(availableIntakes)
    .filter(a => orgCareTypes.includes(a.careType))
    .groupBy(a => moment(a.start).format('dddd, MMMM Do'))
    .mapValues((v, k) => `${k} (${englishList(v.map(a => capitalize(a.careType)))})`)
    .values()
    .value();

  return (
    <>
      {!!nextAppts.length && (
        <Text.body>
          Next available appointment{nextAppts.length > 1 ? 's' : ''}:{' '}
          <span className="b">{nextAppts.join(', ')}</span>.
        </Text.body>
      )}
      {!!careTypesWithoutNext.length && (
        <Text.body>
          There are no appointments available in the next month
          {orgCareTypes.length > 1
            ? ` for ${englishList(careTypesWithoutNext.map(c => capitalize(c)))}.`
            : '.'}
        </Text.body>
      )}
    </>
  );
};
