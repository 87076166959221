import { ChevronRight } from 'baseui/icon';
import { Modal } from 'baseui/modal';
import { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CareType } from '../../../../graphQL';
import { Modal as MantraModal, Text } from '../../styles';
import { Cancel } from './Cancel';
import { OnHold } from './OnHold';
import { SelfPay } from './SelfPay';
import { Sponsored } from './Sponsored';
import { COCPathProps } from './types';

interface Props {
  careEnd?: Moment;
  onClose: () => void;
  careType: CareType;
  onSubmit?: () => void;
}

type Path = 'self-pay' | 'sponsored' | 'on-hold' | 'cancel';

export function COCModal({ careEnd, onClose, careType, onSubmit }: Props) {
  const [path, setPath] = useState<Path | undefined>();
  const pathProps: COCPathProps = {
    careType,
    careEnd,
    onClose,
    onGoBack: () => setPath(undefined),
  };
  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <MantraModal.body>
        {path === 'self-pay' && <SelfPay onSubmit={onSubmit} {...pathProps} />}
        {path === 'sponsored' && <Sponsored onSubmit={onSubmit} {...pathProps} />}
        {path === 'on-hold' && <OnHold onSubmit={onSubmit} {...pathProps} />}
        {path === 'cancel' && <Cancel onSubmit={onSubmit} {...pathProps} />}
        {!path && <SelectPath onSetPath={setPath} onClose={onClose} careEnd={careEnd} />}
      </MantraModal.body>
    </Modal>
  );
}

interface SelectPathProps {
  onSetPath: (path: Path) => void;
  careEnd?: Moment;
  onClose: () => void;
}

function SelectPath({ onSetPath, careEnd, onClose }: SelectPathProps) {
  return (
    <>
      <Text.h2 className="mb3">Choose next steps for patient care</Text.h2>
      {careEnd && (
        <Text.body className="mb4">
          Patient&apos;s sponsored care ends {careEnd.format('M/D/YYYY')} for break.
        </Text.body>
      )}
      <Option
        title="Self-Pay"
        description="Patient would like to continue care by paying for services through insurance/out of pocket."
        onClick={() => onSetPath('self-pay')}
      />
      <Option
        title="Continue Sponsored Care"
        description="Patient can continue services sponsored by the university. NOTE: Not all schools enable this option, so be sure to check the policy."
        onClick={() => onSetPath('sponsored')}
      />
      <Option
        title="On Hold"
        description="Pause services until further notice. Patient may return for services at a later time."
        onClick={() => onSetPath('on-hold')}
      />
      <Option
        title="Cancel"
        description="Patient will not be returning to Mantra care in the future, due to patient's request (e.g., transferred to community provider, no longer needs services)."
        onClick={() => onSetPath('cancel')}
      />
      <MantraModal.closeLink onClick={onClose}>Close</MantraModal.closeLink>
    </>
  );
}

interface OptionProps {
  title: string;
  description: string;
  onClick: () => void;
}

function Option({ title, description, onClick }: OptionProps) {
  return (
    <OptionContainer onClick={onClick}>
      <div className="flex flex-row items-start">
        <div className="flex-1">
          <Text.h3 className="mb1">{title}</Text.h3>
          <Text.body className="i">{description}</Text.body>
        </div>
        <ChevronRight size={30} />
      </div>
    </OptionContainer>
  );
}

const OptionContainer = styled.div`
  padding: 20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
`;
