import React from 'react';
import { SectionRow, SectionRowItem } from './SectionRow';
import { Text } from '../../../globalStyles';
import { EditableSectionRowInfo, HandBookInfo } from '../Handbook';
import { EditOrganizationHandbookInput } from '../../../graphQL';
import { EditHandbookInput } from '../EditHandbookInput';

type Props = {
  organizationId: number;
  editable?: boolean;
  handbookInfo: HandBookInfo;
  selectedField: keyof HandBookInfo | null;
  setSelectedField: (editFieldName: keyof HandBookInfo | null) => void;
  openEdit: (item: keyof HandBookInfo) => (() => void) | undefined;
  editableSectionRowInfo: EditableSectionRowInfo[] | undefined;
  submitFunction: (
    editFieldName: keyof EditOrganizationHandbookInput
  ) => (value: string) => Promise<void>;
};

export const titleMedical = 'Labs, EKGs, Vitals';

export function MedicalSection({
  handbookInfo,
  openEdit,
  editableSectionRowInfo,
  submitFunction,
  selectedField,
  setSelectedField,
}: Props) {
  return (
    <>
      {editableSectionRowInfo?.map(row => (
        <SectionRow label={row.label} info={row.info}>
          <SectionRowItem onEditClick={openEdit(row.displayKey)}>
            {selectedField === row.displayKey ? (
              <EditHandbookInput
                handbookRowInfo={String(handbookInfo[row.displayKey])}
                selectedField={row.displayKey}
                setSelectedField={setSelectedField}
                onSave={submitFunction(row.editInputKey)}
              />
            ) : (
              <Text.bodySmall>{handbookInfo[row.displayKey]}</Text.bodySmall>
            )}
          </SectionRowItem>
        </SectionRow>
      ))}
    </>
  );
}
