import React from 'react';
import { EditTaskAside } from '../../Tasks/TaskAside';
import { IEditTask } from '../../Tasks/tasks.context';
import { useDrilldownContext } from '../helpers';
import { StyledSidebar } from '../styles';

type EditProviderTaskProps = {
  editing: IEditTask;
  defaultValues?: Record<string, any>;
  refetchList?: () => void;
};
export const EditProviderTask = ({
  editing,
  defaultValues,
  refetchList,
}: EditProviderTaskProps) => {
  const { sidebarController, user } = useDrilldownContext();

  return (
    <StyledSidebar>
      <EditTaskAside
        key={String(editing)}
        defaultValues={defaultValues}
        lockUser={user}
        editing={editing}
        onSuccess={() => refetchList?.()}
        onClose={() => sidebarController.reset()}
      />
    </StyledSidebar>
  );
};
