import { IconKey } from '../../Icons/index';

export enum ItemId {
  Dashboard = '/organizations/:organizationId/dashboard',
  Reporting = '/organizations/:organizationId/reporting',
  Home = '/home',
  Tasks = '/tasks',
  Users = '/users',
  Students = '/organizations/:organizationId/students',
  Referrals = '/organizations/:organizationId/referrals',
  Appointments = '/appointments',
  Verification = '/verification',
  Activity = '/activity',
  Settings = '/settings',
  Providers = '/providers',
  Staff = '/organizations/:organizationId/staff',
  Organizations = '/organizations',
  Messaging = '/messages',
  Debug = '/debug',
  Config = '/config',
  Handbook = '/organizations/:organizationId/handbook',
  Resources = '/organizations/:organizationId/resources',
  Utilization = '/organizations/:organizationId/Utilization',
}

export const iconMap: Record<ItemId, IconKey> = {
  [ItemId.Home]: 'iconsNavHomeActiveSvg',
  [ItemId.Dashboard]: 'iconsNavHomeActiveSvg',
  [ItemId.Tasks]: 'iconsNavClipboardSvg',
  [ItemId.Users]: 'iconsNavPatientsActiveSvg',
  [ItemId.Students]: 'iconsNavPatientsActiveSvg',
  [ItemId.Referrals]: 'iconsNavReferralsActiveSvg',
  [ItemId.Appointments]: 'iconsNavApptActiveSvg',
  [ItemId.Verification]: 'iconsNavVerificationActiveSvg',
  [ItemId.Settings]: 'iconsSettingsActiveSvg',
  [ItemId.Providers]: 'iconsNavProvidersActiveSvg',
  [ItemId.Staff]: 'iconsNavProvidersActiveSvg',
  [ItemId.Activity]: 'iconsNavClaimsActiveSvg',
  [ItemId.Organizations]: 'iconsNavOrganizationsActiveSvg',
  [ItemId.Messaging]: 'iconsNavMessagesActiveSvg',
  [ItemId.Debug]: 'iconsSettingsActiveSvg',
  [ItemId.Config]: 'iconsSettingsActiveSvg',
  [ItemId.Reporting]: 'iconsBarChartSvg',
  [ItemId.Handbook]: 'iconsNavResourcesActiveSvg',
  [ItemId.Resources]: 'iconsNavResourcesActiveSvg',
  [ItemId.Utilization]: 'iconsNavEyeSvg',
};

export const allItems = [
  {
    title: 'Home',
    itemId: ItemId.Home,
  },
  {
    title: 'Dashboard',
    itemId: ItemId.Dashboard,
  },
  {
    title: 'Tasks',
    itemId: ItemId.Tasks,
  },
  {
    title: 'Students',
    itemId: ItemId.Students,
  },
  {
    title: 'Referrals',
    itemId: ItemId.Referrals,
  },
  {
    title: 'Patients',
    itemId: ItemId.Users,
  },
  {
    title: 'Staff',
    itemId: ItemId.Staff,
  },
  {
    title: 'Reporting',
    itemId: ItemId.Reporting,
  },
  {
    title: 'Utilization',
    itemId: ItemId.Utilization,
  },
  {
    title: 'Providers',
    itemId: ItemId.Providers,
  },
  {
    title: 'Organizations',
    itemId: ItemId.Organizations,
  },
  {
    title: 'Appointments',
    itemId: ItemId.Appointments,
    divider: true,
  },
  {
    title: 'Messages',
    itemId: ItemId.Messaging,
  },
  {
    title: 'Campus Resources',
    itemId: ItemId.Resources,
  },
  {
    title: 'Handbook',
    itemId: ItemId.Handbook,
  },
  {
    title: 'ID Verification',
    itemId: ItemId.Verification,
    divider: true,
  },
  {
    title: 'Activity',
    itemId: ItemId.Activity,
  },
  {
    title: 'Settings',
    itemId: ItemId.Settings,
  },
  {
    title: 'Debug',
    itemId: ItemId.Debug,
  },
  {
    title: 'Config',
    itemId: ItemId.Config,
  },
];

// Supporting clinicians should maintain the mcp view, but have a more limited
// set of items that they have access to. We can expand upon this in the future
// if other roles follow a similar pattern.
export const visibleSupportingClinicianItems = new Set<ItemId>([
  ItemId.Dashboard,
  ItemId.Students,
  ItemId.Messaging,
  ItemId.Settings,
]);
