import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { nextStepsModuleCopy } from '../../../Components/ContinuityOfCare/EligibilityCopy';
import { EligibilityFormPopover } from '../../../Components/ContinuityOfCare/EligibilityFormPopover';
import { useCocEligibility } from '../../../Components/ContinuityOfCare/hooks/useCocEligibility';
import { useSessionsEnding } from '../../../Components/ContinuityOfCare/hooks/useSessionsEnding';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { usePopoverTabContext } from '../../../Components/PopoverTabs/PopoverTabContainer';
import { ColorLookup, colorMap, colors, Text } from '../../../globalStyles';
import { CareType, NextSteps } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { COCModal as COCModalV2 } from '../Sidebar/ContinuityOfCareV2/COCModal';

interface EligibilityModuleProps {
  careType: CareType;
}

export const ContinuityOfCareEligibility = ({ careType }: EligibilityModuleProps) => {
  const { pushTab } = usePopoverTabContext();
  const sessionsEnding = useSessionsEnding({ careType });
  const { user, setActiveTab } = useDrilldownContext();
  const nextSteps = user.continuityOfCareTransitions?.filter(i => i.careType === careType);
  const [showNextStepsModal, setShowNextStepsModal] = useState(false);

  const {
    hasFilledOutEligibilityForm,
    latestEligibilityResponse,
    currentCarePeriod,
    nextCarePeriod,
    showEligibilityModule,
  } = useCocEligibility({ careType });

  if (
    !user?.organization ||
    !currentCarePeriod ||
    !showEligibilityModule ||
    !user?.careTypes.includes(careType)
  ) {
    return null;
  }

  const careFlow = user.careFlows.find(i => i.careType === careType);
  const copyCategory = sessionsEnding
    ? nextStepsModuleCopy.lessThan3ApptsLeft
    : nextStepsModuleCopy.moreThan2ApptsLeft;

  let copy = null;
  if (nextSteps && nextSteps?.length) {
    const nextStep = nextSteps[0].nextSteps as NextSteps;
    copy = copyCategory[nextStep];
  } else {
    copy = copyCategory.NotSelected;
  }

  const completedBy = latestEligibilityResponse?.completedBy.name;
  const updatedAt = latestEligibilityResponse?.updatedAt;

  return (
    <Wrapper>
      <div>
        <Text.h3 className="mb3">
          Next steps for upcoming {careType.toLowerCase()} care period
        </Text.h3>
        {sessionsEnding && (
          <Text.bodySmall className="mb3" style={{ fontWeight: 500 }}>
            {careFlow?.careSessionInfo?.remainingAppts} sponsored {careType.toLowerCase()} session
            {careFlow?.careSessionInfo?.remainingAppts !== 1 ? 's ' : ' '}
            remaining
          </Text.bodySmall>
        )}
        <Text.bodySmall style={{ fontWeight: 500 }}>
          Sponsored {currentCarePeriod?.periodType === 'sponsoredBreak' ? 'break' : 'term'} ends on{' '}
          {moment(currentCarePeriod?.endDate).format('MM/DD/YY')}
        </Text.bodySmall>
      </div>
      <GreyBox>
        <div className="mb2 flex justify-between items-center">
          <Text.label kind="black">{careType} next steps</Text.label>
          <div className="flex">
            <Text.bodyBold kind={copy.textColor ? (copy.textColor as ColorLookup) : 'text'}>
              {copy.label}{' '}
            </Text.bodyBold>
            <FinalButton
              kind="minimal_black"
              type="button"
              size="tiny"
              onClick={() => setShowNextStepsModal(true)}
              aria-label="Edit next steps"
            >
              <Icon icon="iconsBlackEditSvg" alt="Pencil icon" width={12} />
            </FinalButton>
          </div>
        </div>
        <div>
          <Text.bodySmall>
            {copy.description({
              nextStartDate: moment(nextCarePeriod?.startDate).format('MM/DD/YY'),
              endDate: moment(currentCarePeriod?.endDate).format('MM/DD/YY'),
            })}
          </Text.bodySmall>
        </div>
        {hasFilledOutEligibilityForm ? (
          <div
            className="flex justify-between items-start w-100 mt3 pt3"
            style={{ borderTop: `1px solid ${colors.grey.lightBorder}` }}
          >
            <div className="flex items-start" style={{ gap: 8 }}>
              <Icon icon="iconsNoteFadedSvg" alt="Form icon" width={15} style={{ marginTop: 7 }} />
              <div>
                <Text.bodyBold>Eligibility Form</Text.bodyBold>
                <Text.bodySmall
                  kind="grayText"
                  className="mt2"
                  style={{ fontWeight: 500, maxWidth: 170 }}
                >
                  Last completed by {completedBy} on {moment(updatedAt).format('MM/DD/YY')}
                </Text.bodySmall>
              </div>
            </div>
            <div className="flex flex-column items-end">
              <div>
                <Text.linkButton
                  onClick={() => {
                    setActiveTab('notes');
                  }}
                  className="link"
                  style={{
                    fontWeight: 500,
                  }}
                >
                  View results
                </Text.linkButton>
              </div>
              <div>
                <Text.linkButton
                  onClick={() =>
                    pushTab({
                      title: 'Eligibility Form: Upcoming Break',
                      tabKey: 'eligibility-form',
                      renderBody: ({ tab }) => (
                        <EligibilityFormPopover tab={tab} careType={careType} />
                      ),
                    })
                  }
                  className="mt2 flex items-center"
                  style={{
                    fontWeight: 500,
                    color: colors.grayText,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Icon
                    icon="iconsRefreshSvg"
                    alt="Pencil icon"
                    width={14}
                    className="mr2"
                    style={{ opacity: 0.6, marginTop: 1 }}
                  />
                  Restart form
                </Text.linkButton>
              </div>
            </div>
          </div>
        ) : (
          <FinalButton
            className="mt3 w-100"
            kind="primary"
            onClick={() =>
              pushTab({
                title: 'Eligibility Form: Upcoming Break',
                tabKey: 'eligibility-form',
                renderBody: ({ tab }) => <EligibilityFormPopover tab={tab} careType={careType} />,
              })
            }
          >
            Complete Eligibility Form
          </FinalButton>
        )}
      </GreyBox>

      {showNextStepsModal && (
        <COCModalV2
          careType={careType}
          careEnd={moment(currentCarePeriod?.endDate)}
          onClose={() => setShowNextStepsModal(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.lightBorder};
  border-left: 10px solid ${colorMap.warning[0]};
  width: 100%;
  padding: 24px 20px 24px 28px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 5fr);
  grid-gap: 40px;
  margin-bottom: 1rem;
`;

const GreyBox = styled.div`
  background-color: ${colors.grey.light};
  border-radius: 4px;
  padding: 24px 20px 20px 20px;
`;
