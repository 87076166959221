import { chain } from 'lodash';
import React, { useState } from 'react';
import { InlineSVG } from '../../../Components/Icons';
import { PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { ProviderCard } from '../../../Components/Provider/AvatarCard';
import { Tooltip } from '../../../Components/Tooltip';
import { AdminUserQuery, UniversityRelationship } from '../../../graphQL';
import { EditCareTeamInfo } from '../EditModals/EditCareTeamInfo';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';

export const CampusTeamSection = () => {
  const { user } = useDrilldownContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { featureFlags } = useCurrentProvider();
  const { campusTeam } = user;

  if (!campusTeam || !featureFlags.includes('CTC_ENHANCED_COLLABORATION')) {
    return null;
  }

  const campusTeamMembersByKind = chain(campusTeam)
    .groupBy(team => team.relationshipType)
    .mapValues(team => team.map(t => t.provider))
    .value();

  return (
    <Styles.sidebarSection>
      {isModalOpen && <EditCareTeamInfo closeModal={() => setIsModalOpen(false)} />}
      <Styles.sidebarSectionHeader>
        <Text.h3>Campus Team</Text.h3>
        <PermsOnly allowed="campusTeamEdit">
          <Text.linkButton onClick={() => setIsModalOpen(true)}>Edit/Add</Text.linkButton>
        </PermsOnly>
      </Styles.sidebarSectionHeader>
      <Collaborators
        providers={campusTeamMembersByKind[UniversityRelationship.UniversityCollaborator] ?? []}
      />
      <Monitors
        providers={campusTeamMembersByKind[UniversityRelationship.UniversityMonitor] ?? []}
      />
    </Styles.sidebarSection>
  );
};

type Props = {
  providers: NonNullable<AdminUserQuery['adminUser']['campusTeam']>[number]['provider'][];
};

const Collaborators = ({ providers }: Props) => {
  const hasCollaborators = Boolean(providers.length);
  return (
    <div className="flex flex-row gap-2 items-start mb2">
      <InlineSVG kind={hasCollaborators ? 'success' : 'grayText'} icon="message" size={20} />
      <div className="flex-1">
        <Text.bodySmallBold kind={hasCollaborators ? 'success' : 'grayText'} className="ma0 mb1">
          Collaborating ({providers.length})
        </Text.bodySmallBold>
        {providers.map(p => (
          <ProviderCard key={`${p.id}-${p.name}`} provider={p} />
        ))}
      </div>
    </div>
  );
};

const Monitors = ({ providers }: Props) => {
  const hasMonitors = Boolean(providers.length);
  return (
    <div className="flex flex-row gap-2 items-start mb2">
      <Tooltip content="Monitoring users receive updates on major patient events (i.e. significant risk elevation, safety plan activation), but are not actively available to collaborate with Mantra providers via messaging.">
        <InlineSVG
          className="flex-shrink-0"
          kind={hasMonitors ? 'black' : 'grayText'}
          icon="bell"
          size={20}
        />
      </Tooltip>
      <div className="flex-1">
        <Text.bodySmallBold kind={hasMonitors ? 'black' : 'grayText'} className="ma0 mb1">
          Monitoring ({providers.length})
        </Text.bodySmallBold>
        <Text.bodySmall className="i">
          {providers.map(p => `${p.firstName} ${p.lastName}`).join(', ')}
        </Text.bodySmall>
      </div>
    </div>
  );
};
