import { Checkbox } from 'baseui/checkbox';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { MultiSelect } from '../../Components/Form';
import { Input } from '../../Components/Form/Input';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  CareType,
  OrganizationEligibilityCriteriaInput,
  useOrganizationEligibilityQuery,
  useUpdateEligibilityCriteriaMutation,
} from '../../graphQL';
import { stateOptions } from '../../states';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';

export function OrganizationEligibility() {
  const organizationId = useOrganizationId();
  const [criteria, setCriteria] = useState<OrganizationEligibilityCriteriaInput[]>([]);
  const query = useOrganizationEligibilityQuery({
    variables: { organizationId },
    onCompleted: data =>
      setCriteria(
        data.organization.eligibilityCriteria.map(crit =>
          pick(crit, 'minimumAge', 'states', 'permittedCareTypes')
        )
      ),
  });
  const [mutate, mutation] = useUpdateEligibilityCriteriaMutation({
    onCompleted: () => query.refetch(),
  });

  const onSave = () => {
    mutate({ variables: { organizationId, criteria } });
  };

  if (query.loading) return <LoadingPage />;
  if (query.error || !query.data) return <UnexpectedError />;

  return (
    <div>
      <h2 className="mb4">Eligibility</h2>
      <Text.body className="mb4">
        <b>In addition to users who are 18+ and in an eligible state,</b> allow the following users
        to receive care:
      </Text.body>
      {criteria.length === 0 && <Text.body>(none)</Text.body>}
      <EzMultirow
        values={criteria}
        blank={{ minimumAge: null, permittedCareTypes: null, states: null }}
        setValues={setCriteria}
        component={Criteria}
      />
      <div className="mt3 flex items-center">
        <FinalButton onClick={onSave} loading={mutation.loading}>
          Save
        </FinalButton>
        {mutation.error && <Text.body className="ml3">An error occurred.</Text.body>}
        {mutation.data?.updateEligibilityCriteria && <Text.body className="ml3">Saved.</Text.body>}
      </div>
    </div>
  );
}

function Criteria({ value, onChange }: ComponentArgs<OrganizationEligibilityCriteriaInput>) {
  return (
    <div>
      <div className="flex items-center">
        <Text.body className="mr2">Minimum age:</Text.body>
        <Input
          className="flex-1"
          value={value.minimumAge?.toString() ?? '0'}
          onChange={e => onChange({ ...value, minimumAge: Number(e.currentTarget.value) })}
        />
      </div>
      <div className="flex items-center mb2">
        <Checkbox
          checked={!!value.states}
          onChange={e => onChange({ ...value, states: e.currentTarget.checked ? [] : null })}
        />
        <Text.body>Restrict states for this criteria</Text.body>
      </div>
      {!!value.states && (
        <div className="ml4 mb2">
          <MultiSelect
            options={stateOptions}
            value={value.states}
            onChange={states => onChange({ ...value, states: states as string[] })}
          />
        </div>
      )}
      <div className="flex items-center mb2">
        <Checkbox
          checked={!!value.permittedCareTypes}
          onChange={e =>
            onChange({ ...value, permittedCareTypes: e.currentTarget.checked ? [] : null })
          }
        />
        <Text.body>Restrict care types for this criteria</Text.body>
      </div>
      {!!value.permittedCareTypes && (
        <div className="ml4 mb2">
          <MultiSelect
            options={[
              { id: CareType.Psychiatry, label: 'Psychiatry' },
              { id: CareType.Therapy, label: 'Therapy' },
            ]}
            value={value.permittedCareTypes}
            onChange={careTypes =>
              onChange({ ...value, permittedCareTypes: careTypes as CareType[] })
            }
          />
        </div>
      )}
      <hr className="mt4 mb4" />
    </div>
  );
}
