import React from 'react';
import { Risk } from '../../Components/Flair';
import { Text } from '../../globalStyles';
import { CounselorDashboardQuery } from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { UnstyledLink } from '../Shared';
import * as Styles from './styles';

interface Props {
  users: CounselorDashboardQuery['organization']['highRiskUsers'];
}

export function HighRiskList({ users }: Props) {
  return (
    <Styles.SectionContainer>
      <Text.h3>High Risk Students</Text.h3>
      <Styles.Divider />
      <Styles.OneColumnGrid>
        {users.map(user => (
          <div className="flex justify-between items-center" key={user.id}>
            <UnstyledLink to={`/users/${user.id}`} key={user.id}>
              {getFullNameReversed(user)}
            </UnstyledLink>
            <Risk level={user.assessmentInfo.riskLevel === 'Imminent' ? 'imminent' : 'high'}>
              {user.assessmentInfo.riskLevel}
            </Risk>
          </div>
        ))}
        {users.length === 0 && <Styles.EmptyStateText>No high risk students</Styles.EmptyStateText>}
      </Styles.OneColumnGrid>
    </Styles.SectionContainer>
  );
}
