import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { HUMAN_READABLE_UPLOAD_SIZE, MAX_UPLOAD_SIZE } from '../../../constants';
import { Text } from '../../../globalStyles';
import {
  useAdminBeginUploadMutation,
  useAdminCompleteUploadMutation,
  useSendMessageToMutation,
} from '../../../graphQL';
import { postUploadData } from '../../../utils';
import { FinalButton } from '../../FinalButton';
import { Textarea } from '../../Form';
import { Icon } from '../../Icons';
import { Message } from './Messaging';

type Props = {
  userId: number;
  onSend: (msg: Message) => void;
};
export const MessageComposeArea = ({ userId, onSend }: Props) => {
  const { current: id } = useRef(`patient_${userId}_message`);
  const { register, handleSubmit, reset } = useForm<{ content: string }>();
  const [attachments, setAttachments] = useState<{ id: number; name: string }[]>([]);
  const [attachmentUploading, setAttachmentUploading] = useState(false);
  const [attachError, setAttachError] = useState('');
  const [send, { loading, error }] = useSendMessageToMutation();
  const [beginUpload] = useAdminBeginUploadMutation();
  const [completeUpload] = useAdminCompleteUploadMutation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const submit = handleSubmit(async ({ content }) => {
    if (!content && !attachments.length) return;
    const { data } = await send({
      variables: { userId, content, attachmentIds: attachments.map(i => i.id) },
    });
    if (!data) return;
    onSend(data.providerMessagePatient);
    reset();
    setAttachments([]);
    setAttachError('');
  });

  const onAttachFile = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const onFileSelect = async () => {
    if (!fileInputRef.current) return;
    const { files } = fileInputRef.current;
    if (!files || !files.length) return;
    const file = files[0];
    if (file.size > MAX_UPLOAD_SIZE) {
      return setAttachError(`File size exceeds the limit of ${HUMAN_READABLE_UPLOAD_SIZE}.`);
    }
    setAttachmentUploading(true);
    setAttachError('');
    try {
      const beginRes = await beginUpload({ variables: { contentType: file.type } });
      if (!beginRes.data) return;
      const { fields, key, url } = beginRes.data.adminBeginUpload;
      await postUploadData(file, url, fields);
      const completeRes = await completeUpload({
        variables: { input: { key, type: 'Other', globallyShared: false, name: file.name } },
      });
      if (!completeRes.data) return;
      const upload = completeRes.data.adminCompleteUpload;
      setAttachments(val => [...val, upload]);
    } catch (err) {
      setAttachError('There was an error uploading this file. Please try again.');
      throw err;
    } finally {
      fileInputRef.current.value = '';
      setAttachmentUploading(false);
    }
  };

  const onRemoveAttachment = (idx: number) => {
    setAttachments(val => [...val.slice(0, idx), ...val.slice(idx + 1)]);
  };

  useEffect(() => {
    setImmediate(() => document.getElementById(id)?.focus());
  }, [id]);

  return (
    <div>
      <form onSubmit={submit}>
        <div className="flex flex-column items-stretch">
          {error && <p className="tc">There was a problem sending your message at this time</p>}
          <Textarea
            id={id}
            ref={register}
            name="content"
            placeholder="Type your message here..."
            className="mv4"
          />
          {attachError && (
            <Text.body kind="danger" className="mb2">
              {attachError}
            </Text.body>
          )}
          <div className="flex justify-between mt2">
            <div className="flex align-center flex-1">
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                accept="image/png,application/pdf,image/jpeg"
                onChange={onFileSelect}
              />
              <FinalButton
                onClick={onAttachFile}
                loading={attachmentUploading}
                kind="outline_black"
              >
                Attach File
              </FinalButton>
              <div className="flex flex-wrap items-center flex-1">
                {attachments.map((attach, idx) => (
                  <AttachmentBubble key={attach.id}>
                    <span>{attach.name}</span>
                    <FinalButton
                      kind="minimal_black"
                      className="pointer nr2 ml1"
                      size="tiny"
                      onClick={() => onRemoveAttachment(idx)}
                    >
                      <Icon icon="iconsRedXSvg" size={20} />
                    </FinalButton>
                  </AttachmentBubble>
                ))}
              </div>
            </div>
            <FinalButton loading={loading ?? attachmentUploading} type="submit">
              Send
            </FinalButton>
          </div>
        </div>
      </form>
    </div>
  );
};

const AttachmentBubble = styled.div`
  display: flex;
  justify-items: space-between;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 15px;
  margin-left: 10px;
  margin-bottom: 5px;
`;
