import { flatMap, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FancyRadio } from '../../Components/FancyRadio';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import { WizardWrapper } from '../../Components/Wizard';
import { BackButton } from '../../Components/Wizard/BackButton';
import { colors, Text } from '../../globalStyles';
import {
  CareStatus,
  CareType,
  PaymentSource,
  useAppointmentTemplatesForUserQuery,
} from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { UnexpectedError } from '../Shared';
import { AppointmentType, BookingWizardProps } from './types';

export function SelectAppointmentType({
  data: wizardData,
  setData,
  prevStep,
  nextStep,
}: BookingWizardProps) {
  const { appView, currentProvider } = useCurrentProvider();

  const [templateIndex, setTemplateIndex] = useState<number | undefined>(
    wizardData.appointment.templateIndex
  );
  const [appointmentDuration, setAppointmentDuration] = useState<number | undefined>(
    wizardData.appointment.duration
  );

  const {
    data: userData,
    loading,
    error,
  } = useAppointmentTemplatesForUserQuery({
    variables: { userId: wizardData.patient!.id },
  });

  const careFlows = userData?.adminUser.careFlows;
  const templateData = userData?.adminUser.appointmentTemplates;
  const providerCareTypes = useMemo(
    () => new Set(flatMap(wizardData.providers, p => p.careTypes)),
    [wizardData]
  );
  const isAdmin = isMantraAdmin(currentProvider);
  const careDisabled = careFlows && !careFlows.some(f => f.careStatus === CareStatus.Active);
  const noState = !wizardData.patient?.primaryAddressState;
  const canBook = !careDisabled && !noState;

  const nameAndId = `${getFullName(wizardData.patient!)} (${wizardData.patient!.customerId})`;

  useEffect(() => {
    if (!templateData) return;
    // Add templates to the wizard data so the other steps can use them
    if (!wizardData.appointmentTemplates) {
      setData({
        ...wizardData,
        appointmentTemplates: templateData,
      });
    }
    const selectedTemplate = !isUndefined(templateIndex) && templateData[templateIndex];
    // If the user went back in the flow and the selected template is no longer applicable
    if (!selectedTemplate || !(isAdmin || providerCareTypes.has(selectedTemplate.careType))) {
      setTemplateIndex(undefined);
      setAppointmentDuration(undefined);
    }
  }, [templateData, setData, wizardData, templateIndex, providerCareTypes, isAdmin]);

  if (loading) return <LoadingPage />;
  if (error || !templateData) return <UnexpectedError />;

  const templates = templateData;
  const activeTemplate = templateIndex === undefined ? undefined : templates[templateIndex];

  const appointmentTypeOptions = templates
    .filter(i => isAdmin || providerCareTypes.has(i.careType))
    .map(template => ({
      id: template.id,
      label: `${template.description} (${template.duration} min)`,
    }));

  const handleSetTemplate = (id: number) => {
    const template = templates.find(i => i.id === id)!;
    setTemplateIndex(templates.indexOf(template));
    setAppointmentDuration(template.duration);
  };

  const DurationRadio = () => {
    if (isUndefined(templateIndex) || appView !== 'oz') return null;
    const template = wizardData.appointmentTemplates?.[templateIndex];
    if (
      !template ||
      template.appointmentType !== 'checkin' ||
      !wizardData.patient!.careFlows.some(
        f => f.paymentSource === PaymentSource.Organization && f.careType === template.careType
      )
    ) {
      return null;
    }

    const therapyDurations = [30, 45, 60];
    const psychiatryDurations = [15, 30, 45];
    const durations =
      template.careType === CareType.Psychiatry ? psychiatryDurations : therapyDurations;
    const durationOptions = durations.map(num => ({ id: num, label: `${num} min` }));

    return (
      <>
        <Text.bodyBold>Select appointment duration</Text.bodyBold>
        <Text.body>
          The contracted appointment length for this patient&apos;s sponsored services is{' '}
          <b>{template.duration} minutes.</b>
        </Text.body>
        <FancyRadio
          value={appointmentDuration}
          onChange={setAppointmentDuration}
          options={durationOptions}
          layout="horizontal"
        />
      </>
    );
  };

  return (
    <WizardWrapper>
      <BackButton onClick={() => prevStep()} />
      <h2>What type of appointment?</h2>
      {canBook && (
        <div>
          You’re booking an appointment for
          <strong> {nameAndId}</strong>
        </div>
      )}
      {noState && (
        <div>
          Unable to book because<strong> {nameAndId}</strong> has no state address. To resolve this,
          have the patient complete onboarding, or{' '}
          <a href="mailto:hi@mantrahealth.com">contact us</a> to update their state.
        </div>
      )}
      {careDisabled && (
        <div>
          Unable to book. Please activate care for
          <strong> {nameAndId}</strong>
          before proceeding.
        </div>
      )}
      <FancyRadio
        value={templateIndex !== undefined ? templates[templateIndex].id : undefined}
        onChange={handleSetTemplate}
        options={appointmentTypeOptions}
      />
      <DurationRadio />
      <FinalButton
        kind="primary"
        disabled={isUndefined(templateIndex) || isUndefined(appointmentDuration)}
        className="w-100 mt4"
        onClick={() =>
          nextStep({
            ...wizardData,
            appointment: {
              ...wizardData.appointment,
              templateIndex,
              duration: appointmentDuration,
              careType: activeTemplate?.careType,
              appointmentType: (activeTemplate?.appointmentType as AppointmentType) ?? undefined,
            },
          })
        }
      >
        Next: Select Date &amp; Time
      </FinalButton>
      {appointmentDuration && activeTemplate && appointmentDuration !== activeTemplate.duration && (
        <div className="ph4 pv3 mv3" style={{ backgroundColor: colors.warningBg }}>
          <b>Note:</b> This is not the contracted appointment time for this partner. Only select
          this if appropriate for patient care.
        </div>
      )}
    </WizardWrapper>
  );
}
