import React from 'react';
import { useCocEligibility } from '../../../Components/ContinuityOfCare/hooks/useCocEligibility';
import {
  McpOnly,
  OzOnly,
  PermsOnly,
  ReferralOnly,
  useCurrentProvider,
  FeatureFlagged,
} from '../../../Components/Permissions';
import { MessageWidget } from '../../../Components/Widgets/MessageWidget';
import { CareType, Permission, useAdminUserMessagesQuery } from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { useDrilldownContext } from '../helpers';
import { CocExportWidget } from '../Widgets/CoCExport';
import { ContinuityOfCareEligibility } from '../Widgets/ContinuityOfCareEligibility';
import { UpcomingAppointmentWidgets } from '../Widgets/NextAppointmentWidget';
import { McpOnboarding, OzOnboarding, ReferralPortalOnboarding } from '../Widgets/Onboarding';
import { PatientAlertsWidgets } from '../Widgets/PatientActions';
import { ProviderTasks } from '../Widgets/ProviderTasks';
import { ScalesOverTime } from '../Widgets/ScalesOverTime';
import { Tasks } from '../Widgets/Tasks';
import { UserTimeline } from '../Widgets/Timeline';
import { UserNotifications } from '../Widgets/UserNotifications';
import { When } from '../../../Components/When';

export const OverviewTab = () => {
  const { currentProvider } = useCurrentProvider();
  const { user, setActiveTab } = useDrilldownContext();
  const { data, loading } = useAdminUserMessagesQuery({
    variables: { id: user.id },
    skip: ![user.provider?.id, user.therapist?.id].includes(currentProvider.id),
  });
  const { hasNextStepsSelected: hasNextStepsSelectedPsych } = useCocEligibility({
    careType: CareType.Psychiatry,
  });
  const { hasNextStepsSelected: hasNextStepsSelectedTherapy } = useCocEligibility({
    careType: CareType.Therapy,
  });

  return (
    <div>
      <FeatureFlagged flag="COC22_R2">
        <OzOnly>
          <When isTruthy={!hasNextStepsSelectedPsych}>
            <ContinuityOfCareEligibility careType={CareType.Psychiatry} />
          </When>
          <When isTruthy={!hasNextStepsSelectedTherapy}>
            <ContinuityOfCareEligibility careType={CareType.Therapy} />
          </When>
        </OzOnly>
      </FeatureFlagged>
      <PatientAlertsWidgets />
      <div className="mb3">
        <MessageWidget
          isLoading={loading}
          source={getFullName(user)}
          count={loading ? 0 : data?.adminUser.privateChannel.unreadMessageCount ?? 0}
          onClick={() => setActiveTab('messages')}
        />
      </div>
      <PermsOnly allowed={Permission.MantraAdmin}>
        <CocExportWidget />
      </PermsOnly>
      <UpcomingAppointmentWidgets />
      <OzOnly>
        <OzOnboarding />
      </OzOnly>
      <McpOnly>
        <McpOnboarding />
      </McpOnly>
      <ReferralOnly>
        <ReferralPortalOnboarding />
      </ReferralOnly>
      <OzOnly>
        <PermsOnly allowed={Permission.TicketView}>
          <ProviderTasks />
        </PermsOnly>
      </OzOnly>
      <FeatureFlagged flag="COC22_R2">
        <OzOnly>
          <When isTruthy={hasNextStepsSelectedPsych}>
            <ContinuityOfCareEligibility careType={CareType.Psychiatry} />
          </When>
          <When isTruthy={hasNextStepsSelectedTherapy}>
            <ContinuityOfCareEligibility careType={CareType.Therapy} />
          </When>
        </OzOnly>
      </FeatureFlagged>
      <PermsOnly allowed={Permission.Phi}>
        <ScalesOverTime />
      </PermsOnly>
      <UserTimeline />
      <PermsOnly allowed={Permission.MantraAdmin}>
        <Tasks />
      </PermsOnly>
      <PermsOnly allowed={Permission.MantraAdmin}>
        <UserNotifications />
      </PermsOnly>
    </div>
  );
};
