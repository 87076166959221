import { StyledSpinnerNext } from 'baseui/spinner';
import moment from 'moment';
import React from 'react';
import { Text } from '../../globalStyles';
import { useMissedAppointmentsQuery } from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { UnexpectedError } from '../Shared';
import * as Styles from './styles';

interface Props {
  organizationId: number;
}

export function MissedAppointments({ organizationId }: Props) {
  // This is queried separately from the other data because it loads a little slower
  // due to the need to make a request to OnSched.
  const { data, loading, error } = useMissedAppointmentsQuery({ variables: { organizationId } });

  const rawAppointments = data?.organization.missedAppointments ?? [];
  const sortedAppointments = [...rawAppointments].sort(
    (a, b) => -moment(a.startTime).diff(moment(b.startTime))
  );

  return (
    <Styles.SectionContainer>
      <Text.h3>No-Shows (Last 30 Days)</Text.h3>
      <Styles.Divider />
      {loading && <StyledSpinnerNext />}
      {error && <UnexpectedError />}
      {data && (
        <>
          {sortedAppointments.map(appointment => (
            <Styles.LinkedNoShowRow key={appointment.id} to={`/users/${appointment.user.id}`}>
              <div style={{ flex: 1 }}>{moment(appointment.startTime).format('MM/DD/YYYY')}</div>
              <div style={{ flex: 1.5 }}>{getFullNameReversed(appointment.user)}</div>
            </Styles.LinkedNoShowRow>
          ))}
          {sortedAppointments.length === 0 && (
            <Styles.EmptyStateText>No missed appointments</Styles.EmptyStateText>
          )}
        </>
      )}
    </Styles.SectionContainer>
  );
}
