import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Accordion, Panel } from 'baseui/accordion';
import styled from 'styled-components';
import * as Styles from '../Home/styles';
import { Text } from '../../globalStyles';
import { Tab, Tabs } from '../../Components/Tabs';
import { CareType, OrganizationUtilizationWeek, Permission } from '../../graphQL';
import { carePeriodTypeCopy } from '../Organizations/OrganizationCarePeriods';
import { UtilizationDashboard } from './UtilizationDashboard';
import { CarePeriodSubset } from './UtilizationPage';
import { useCurrentProvider } from '../../Components/Permissions';
import { Icon } from '../../Components/Icons';

interface CarePeriodUtilizationDashboardProps {
  carePeriod: CarePeriodSubset;
  utilizationRecords: OrganizationUtilizationWeek[];
  firstCarePeriod?: boolean;
  refetch: () => void;
  careTypes: CareType[];
  isSuperOrg: boolean;
}

const CarePeriodUtilizationHeader = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 20px;
  }
`;

export const CarePeriodUtilizationDashboard = ({
  carePeriod,
  utilizationRecords,
  firstCarePeriod = false,
  refetch,
  careTypes,
  isSuperOrg,
}: CarePeriodUtilizationDashboardProps) => {
  const [activeTab, setActiveTab] = useState<string>(
    careTypes.length ? careTypes[0].toLowerCase() : ''
  );
  const { hasPermission } = useCurrentProvider();

  const incompleteRecords = getIncompleteRecords(utilizationRecords, careTypes);

  useEffect(() => {
    setCarePeriodAllocationsComplete(incompleteRecords.length === 0);
  }, [incompleteRecords]);

  const [carePeriodAllocationsComplete, setCarePeriodAllocationsComplete] = useState<boolean>(
    incompleteRecords.length === 0
  );

  const tabs: Tab<string>[] = [];
  for (const careType of careTypes) {
    tabs.push({
      title: careType,
      key: careType.toLowerCase(),
      render: () =>
        UtilizationDashboard({
          careType,
          editable: isSuperOrg && hasPermission(Permission.DedicatedGroupModelAllocationEdit),
          utilizationRecords,
          refetch,
          setCarePeriodAllocationsComplete,
        }),
    });
  }

  const TabsComponent = () => {
    return (
      <Tabs
        initialTab={activeTab}
        config={{ tabpanel: ({ children }) => <div className="pa4">{children}</div> }}
        onChange={tab => setActiveTab(tab)}
        tabs={tabs}
      />
    );
  };

  const CarePeriodText = () => {
    return (
      <Text.h2>
        {carePeriodTypeCopy[carePeriod.periodType]} (
        {moment(carePeriod.startDate).format('M/D/YYYY')} -{' '}
        {moment(carePeriod.endDate).format('M/D/YYYY')}){' '}
      </Text.h2>
    );
  };

  if (!firstCarePeriod) {
    return (
      <Accordion
        accordion={false}
        overrides={{
          Header: {
            style: () => ({
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end',
            }),
          },
          Content: {
            style: () => ({
              paddingLeft: '0',
              paddingRight: '0',
              paddingTop: '0',
              paddingBottom: '0',
            }),
          },
          ToggleIcon: ({ $expanded }) => (
            <div className="flex flex-row justify-center">
              {$expanded ? (
                <Icon icon="iconsDownChevronSvg" size={18} className="mr1" />
              ) : (
                <Icon icon="iconsRightChevronSvg" size={18} className="mr1" />
              )}
            </div>
          ),
        }}
      >
        <Panel
          title={
            <>
              {!carePeriodAllocationsComplete &&
                hasPermission(Permission.DedicatedGroupModelAllocationEdit) && (
                  <Text.label className="ml3" kind="danger">
                    INCOMPLETE
                  </Text.label>
                )}
              <CarePeriodText />
            </>
          }
        >
          <Styles.SectionContainer>
            <TabsComponent />
          </Styles.SectionContainer>
        </Panel>
      </Accordion>
    );
  }

  return (
    <Styles.SectionContainer>
      <div className="mb4">
        <>
          <Text.label>Current Care Period</Text.label>
          <CarePeriodUtilizationHeader>
            <CarePeriodText />
            {!carePeriodAllocationsComplete &&
              hasPermission(Permission.DedicatedGroupModelAllocationEdit) && (
                <Text.label kind="danger">INCOMPLETE</Text.label>
              )}
          </CarePeriodUtilizationHeader>
        </>
      </div>
      <TabsComponent />
    </Styles.SectionContainer>
  );
};
function getIncompleteRecords(
  utilizationRecords: OrganizationUtilizationWeek[],
  careTypes: CareType[]
) {
  return utilizationRecords.filter(record => {
    // If in current or future period, and for each caretype at play, if maxHours or maxIntake hours are null, return the care period
    return (
      moment(record.startDate) >= moment().startOf('week') &&
      ((careTypes.includes(CareType.Therapy) &&
        (record.therapyHours.maxHours === null || record.therapyHours.maxIntakeHours === null)) ||
        (careTypes.includes(CareType.Psychiatry) &&
          (record.psychiatryHours.maxHours === null ||
            record.psychiatryHours.maxIntakeHours === null)))
    );
  });
}
