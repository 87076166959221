import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from '../globalStyles';
import { clearAuthToken } from '../token';
import { isLoggedIn } from '../utils';
import { FinalButton } from './FinalButton';
import { Modal } from './Modal/Modal';

const TIMEOUT = 1000 * 60 * 15;
const SHOW_MODAL_THRESHOLD = 1000 * 30;
const POLL_INTERVAL = 5000;

const EVENT_TRIGGERS = ['click', 'keypress'];
const LOCAL_STORAGE_KEY = 'mantra_last_active';

export function TimeoutManager() {
  const history = useHistory();
  const isMounted = useRef(true);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  const logout = useCallback(
    (forInactivity: boolean) => {
      clearAuthToken();
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      history.push(`/login${forInactivity && '?loggedOutForInactivity=true'}`);
    },
    [history]
  );

  const onActivity = useCallback((event?: Event) => {
    // Clicks within the warning modal shouldn't count for keeping people active
    if (event instanceof MouseEvent && modalBodyRef.current?.contains(event.target as Node)) {
      return;
    }
    const existing = Number(localStorage.getItem(LOCAL_STORAGE_KEY) ?? 0);
    if (Date.now() <= existing) return;
    localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());
  }, []);

  const stayLoggedIn = () => {
    setShowWarningModal(false);
    onActivity();
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    onActivity();

    const checkActivity = () => {
      if (!isLoggedIn() || !isMounted.current) return;
      setTimeout(checkActivity, POLL_INTERVAL);

      // Providers should not be timed out while they are in video calls
      const isInVideo = history.location.pathname.includes('video');
      if (isInVideo) return onActivity();

      // time since last recorded event
      const idleTime = Date.now() - Number(localStorage.getItem(LOCAL_STORAGE_KEY));

      // Log out if there was no activity within the last timeout period
      if (idleTime >= TIMEOUT) logout(true);
      // Show modal if within thershold of logout time
      if (idleTime >= TIMEOUT - SHOW_MODAL_THRESHOLD) setShowWarningModal(true);
    };

    EVENT_TRIGGERS.forEach(event => document.addEventListener(event, onActivity));
    checkActivity();
    return () => {
      isMounted.current = false;
      EVENT_TRIGGERS.forEach(event => document.removeEventListener(event, onActivity));
    };
  }, [history, onActivity, setShowWarningModal, logout]);

  return (
    <Modal size="tiny" isOpen={showWarningModal}>
      <div className="pa4" ref={modalBodyRef}>
        <Text.h3 className="mb3">Your session is about to expire</Text.h3>
        <Text.body className="mb3">
          For security reasons, you will be logged out soon due to inactivity.
        </Text.body>
        <div className="flex flex-row">
          <FinalButton className="mr2 flex-1" kind="primary" onClick={stayLoggedIn}>
            Stay logged in
          </FinalButton>
          <FinalButton className="flex-1" kind="outline_black" onClick={() => logout(false)}>
            Log out
          </FinalButton>
        </div>
      </div>
    </Modal>
  );
}
