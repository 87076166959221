import React from 'react';
import { CareTypeEditModal, useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { UserCareFlow } from './UserCareFlow';

export const CareFlowsSection = () => {
  const { user } = useDrilldownContext();
  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Care Types</Text.h3>
        <CareTypeEditModal text="Add/Edit" />
      </Styles.sidebarSectionHeader>
      <Styles.spaceBetween>
        <div>
          <Text.label>CURRENT {user.careFlows.length === 1 ? 'PLAN' : 'PLANS'}</Text.label>
          {user.careFlows.map(flow => (
            <UserCareFlow key={flow.careType} careFlow={flow} />
          ))}
          {user.careFlows.length === 0 && <Text.body className="mt3">None</Text.body>}
        </div>
      </Styles.spaceBetween>
    </Styles.sidebarSection>
  );
};
