import { FileUploader as BaseFileUploader, FileUploaderProps } from 'baseui/file-uploader';
import { isArray, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { MAX_UPLOAD_SIZE } from '../constants';
import { colors } from '../globalStyles';
import { borderColor } from '../utils';
import { Icon } from './Icons';

export type UploaderProps = Omit<FileUploaderProps, 'multiple' | 'overrides'>;

export const FileUploader = ({ accept, maxSize, errorMessage, ...props }: UploaderProps) => (
  <>
    <BaseFileUploader
      multiple={false}
      maxSize={MAX_UPLOAD_SIZE}
      overrides={{
        Root: { style: { color: colors.black } },
        FileDragAndDrop: {
          style: p => ({
            borderRadius: '4px',
            backgroundColor: colors.white,
            ...borderColor(p.$isDragActive ? colors.primary : colors.grey.border),
          }),
        },
        ContentMessage: () => (
          <div>
            <Icon icon="iconsCloudUploadSvg" alt="Upload " className="center db" />
            <p>Drop a file here</p>
          </div>
        ),
        ButtonComponent: {
          component: (p: any) => (
            <button
              onClick={p.onClick}
              style={{ color: colors.primary, fontWeight: 'bold', textDecoration: 'none' }}
              type="button"
              className="f5 link white bg-inherit bw0 pointer"
            >
              {errorMessage ? 'Retry upload' : 'click to browse files'}
            </button>
          ),
        },
      }}
      accept={accept}
      errorMessage={errorMessage}
      {...props}
    />
    {isArray(accept) && !isEmpty(accept) && (
      <AcceptedFileTypes className="f6">
        {`Accepted file types: ${accept.map(a => `.${a.split('/').pop()}`).join(', ')}`}
      </AcceptedFileTypes>
    )}
  </>
);

const AcceptedFileTypes = styled.p`
  width: fit-content;
  color: ${colors.grey.dark};
  margin: 12px auto 0px auto;
`;
