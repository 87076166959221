/**
 * remember that danger[0] is danger-1 when talking to non-eng
 */
export const colorMap = {
  text: ['#000000', '#676767'],
  background: ['#F5F5F5', '#FFFFFF'],
  primary: ['#0D2DA2', '#1B50E1', '#507EEC', '#749EF6', '#A3C2FC', '#D0E1FF', '#EBF2FF'],
  danger: [
    '#A9110F',
    '#E81E16',
    '#EE4A44',
    '#F16E6A',
    '#F5928F',
    '#FAC8C7',
    '#FDEDEC',
    '#EA3131',
    '#F26F62',
  ],
  success: ['#2C8118', '#409621', '#75C04F', '#A1DF76', '#CCF4A8', '#EBFDDA'],
  warning: ['#DB9A00', '#FFBB00', '#FFD13F', '#FFDE66', '#FFEC99', '#FFF7D7'],
  alt: ['#D36440', '#F68958', '#F9AE81', '#FCC69B', '#FEDEBC', '#FFEEDC'],
} as const;

export const colors = {
  background: colorMap.background[0],
  primary: colorMap.primary[1],
  primaryBg: colorMap.primary[5],
  link: colorMap.primary[1],
  success: colorMap.success[1],
  successBg: colorMap.success[5],
  successDark: colorMap.success[0],
  warning: colorMap.warning[1],
  danger: colorMap.danger[0],
  brandAlt: colorMap.alt[0],
  text: colorMap.text[0],
  grayText: colorMap.text[1],
  grayTextBg: '#EFEFEF',
  accent: colorMap.primary[5], // should be called primaryBg
  friendlyWarningBg: colorMap.primary[6],
  warningBg: colorMap.warning[5],
  dangerBg: colorMap.danger[5],
  dangerBgInternal: colorMap.danger[7],
  dangerBgLogo: colorMap.danger[8],
  brandAltBg: colorMap.alt[5],
  white: '#FFFFFF',
  black: '#000000',
  offBlack: '#333333',
  green: '#38DE98',
  navBg: '#101426',
  greyBg: '#F9F9F9',
  greyIcon: '#999999',
  // @TODO get rid of most of these greys
  grey: {
    border: '#9F9F9F',
    lightBorder: '#E5E5E5',
    dark: '#757575',
    light: '#EEE',
    lightBackground: '#FAFAFA',
    hr: '#D8D8D8',
    widgetBorder: '#E5E5E5',
    unfocusedText: '#3A3A3A',
    disabledBackground: '#EFEFEF',
    ozGray2: '#666666',
    ozGray4: '#B6B4B4',
  },
  basic: '#8F9BB3',
  orange: '#b84925',
  orangeBg: '#ffeedc',
} as const;

// To ensure we don't accidentally add a color that doesn't exist
type Subset<A, B extends A> = B;

type ColorKey = keyof typeof colors;
type ColorSubKeyPath<
  K extends ColorKey,
  T extends string = string
> = typeof colors[K] extends Record<T, string> ? `${K}.${T}` : never;

export type ColorLookup = Subset<
  ColorKey | ColorSubKeyPath<'grey'>,
  | 'primary'
  | 'primaryBg'
  | 'accent'
  | 'success'
  | 'successBg'
  | 'successDark'
  | 'warning'
  | 'warningBg'
  | 'danger'
  | 'dangerBg'
  | 'dangerBgLogo'
  | 'white'
  | 'text'
  | 'black'
  | 'basic'
  | 'brandAlt'
  | 'grayText'
  | 'grayTextBg'
  | 'greyIcon'
  | 'offBlack'
  | 'link'
  | 'orange'
  | 'green'
  | 'grey.ozGray2'
  | 'grey.ozGray4'
>;
