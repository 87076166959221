import { compact, flatMap } from 'lodash';
import React, { useState } from 'react';
import { Text } from '../../globalStyles';
import { Checkbox } from '../Form';
import { Icon } from '../Icons';
import { Tag } from '../Labels';
import { TraitCategory } from './hooks/useTraits';
import { useProviderNetworkContext } from './ProviderNetworkContext';

export const ProviderTraitsFilter = () => {
  const { allProviderTraits, traitFilterMap, addTrait, toggleTrait } = useProviderNetworkContext();
  const genderApplied = traitFilterMap.gender;
  return (
    <div className="bg-white ph4 pt4 h-100">
      <Text.label>Refine Results</Text.label>
      <Category
        values={compact(Array.isArray(genderApplied) ? genderApplied : [genderApplied])}
        category="Gender Identity"
        options={['Woman', 'Man', 'Non-Binary', 'Transgender Woman', 'Transgender Man']}
        onChange={v => toggleTrait('gender', v)}
      />
      <>
        {allProviderTraits.map(c => {
          const applied = traitFilterMap[c.category];
          const values = compact(Array.isArray(applied) ? applied : [applied]);
          return (
            <Category
              key={c.category}
              values={values}
              category={traitCategoryCopy[c.category]}
              options={c.values}
              onChange={val => addTrait(c.category, val)}
            />
          );
        })}
      </>
    </div>
  );
};

type CategoryProps = {
  category: string;
  options: string[];
  values: string[];
  onChange: (val: string) => void;
};

const Category = ({ category, options, values, onChange }: CategoryProps) => {
  const [expanded, setExpanded] = useState(!!values.length);
  return (
    <div className="pv2 bb b--light-gray">
      <div
        role="button"
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && setExpanded(c => !c)}
        className="flex justify-between items-center mb2 pointer"
        onClick={() => setExpanded(c => !c)}
      >
        <h4>{category}</h4>
        <span className="f3 pb1">{expanded ? '-' : '+'}</span>
      </div>
      <div className={expanded ? 'db' : 'dn'}>
        {options.map((v, i) => (
          <div key={v} className="mv3">
            <Checkbox
              onChange={() => onChange(v)}
              checked={values.includes(v)}
              name={`${category}-${i}`}
            >
              {v}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TraitsFilterDisplay = () => {
  const {
    addTrait: onChangeTrait,
    clearTraitFilters,
    traitFilterMap,
  } = useProviderNetworkContext();
  const filters = flatMap(Object.entries(traitFilterMap), ([key, val]) => val.map(v => [key, v]));

  if (filters.length === 0) return null;

  return (
    <section className="bg-white">
      <div className="flex flex-wrap gap-2">
        {filters.map(([category, value]) => (
          <Tag
            kind="black"
            size="compact"
            key={value}
            title={value}
            className="flex items-center mw5"
          >
            <span className="white truncate">{value}</span>
            <button
              type="button"
              className="bg-black white bw0 mr0 ml2 pa0 pointer inline-flex"
              onClick={() => onChangeTrait(category as TraitCategory, value)}
            >
              <Icon icon="iconsXWhiteSvg" size={16} title="close" alt="close" />
            </button>
          </Tag>
        ))}
        <Text.linkButton onClick={clearTraitFilters}>Clear filters</Text.linkButton>
      </div>
    </section>
  );
};

const traitCategoryCopy: Record<TraitCategory, string> = {
  gender: 'Gender',
  Sexuality: 'Sexuality',
  Ethnicity: 'Race & Ethnicity',
  Faith: 'Faith',
  Languages: 'Languages',
  Specialties: 'Specialities',
  TherapyTypes: 'Therapy Types',
};
