import { invert } from 'lodash';
import { isMantraProvider } from '../../Components/Permissions';
import {
  AppointmentTemplateFragment,
  AppointmentUserV2Fragment,
  AttendeeDetailsQuery,
  BaseProviderFragment,
  CareType,
  PaymentSource,
  SelectAttendeesQuery,
  UserBookingQuery,
} from '../../graphQL';
import { Nullable } from '../../types';
import { AppointmentDetails, AppointmentType } from './types';

const copy: Record<AppointmentType, string> = {
  checkin: 'Monthly Check-In',
  adhoc: 'Ad Hoc Medication Management',
  intake: 'Initial Consultation',
};
const invertedCopy = invert(copy) as Record<string, AppointmentType>;

export function getCopyForAppointmentType(type: AppointmentType) {
  return copy[type];
}

export function getAppointmentTypeForDescription(description: string): AppointmentType {
  return invertedCopy[description] || 'adhoc';
}

export function getSelectableProviders(
  providers: SelectAttendeesQuery['providers'],
  patient: AttendeeDetailsQuery['patient']
) {
  const { organization: org } = patient;
  const patientCareTypeLookup = new Set<CareType>(patient.careTypes);

  const belongsToPatientOrganization = (provider: typeof providers[number]) => {
    if (!org) return true;
    const careType = provider.careTypes[0];
    const careFlow = patient.careFlows.find(i => i.careType === careType);
    const paymentSource = careFlow?.paymentSource;
    const isSelfPay = paymentSource === PaymentSource.SelfPay;
    const isInsurance =
      paymentSource === PaymentSource.Insurance || paymentSource === PaymentSource.OonInsurance;
    if (isSelfPay || isInsurance) return true;
    return provider.organizations.some(o => o.id === org.id || o.id === org.parent?.id);
  };

  const servesPatientState = (provider: typeof providers[number]) => {
    if (!patient.primaryAddress?.state) return false;
    return provider.geoStates.includes(patient.primaryAddress?.state);
  };
  const matchesSomePatientCareType = (provider: typeof providers[number]) => {
    // show all providers if patient is "New" (has no careFlow)
    return (
      patientCareTypeLookup.size <= 0 || provider.careTypes.some(c => patientCareTypeLookup.has(c))
    );
  };

  return providers
    .filter(isMantraProvider)
    .filter(belongsToPatientOrganization)
    .filter(servesPatientState)
    .filter(matchesSomePatientCareType);
}

export function hasProviderWithId(id: number, providers: SelectAttendeesQuery['providers']) {
  return providers.some(p => p.id === id);
}

export const isPatientCaregiver = (
  provider: BaseProviderFragment,
  patient: UserBookingQuery['adminUser']
) => provider.id === patient.provider?.id || provider.id === patient.therapist?.id;

export const findIntakeApptTemplate = (
  careType: CareType,
  templates: AppointmentTemplateFragment[]
) => {
  return templates.reduce((result, template, idx) => {
    const hasCareType = template.careType === careType;
    const isIntake = template.appointmentType === 'intake';
    return hasCareType && isIntake ? ([template, idx] as const) : result;
  }, null as Nullable<readonly [AppointmentTemplateFragment, number]>);
};

export const canBookIntake = (
  careType: CareType,
  patient: Pick<
    AppointmentUserV2Fragment,
    'therapyComplete' | 'therapyUpcoming' | 'psychComplete' | 'psychUpcoming'
  >
) => {
  const { therapyComplete, therapyUpcoming, psychComplete, psychUpcoming } = patient;
  const careTypes: Record<CareType, boolean> = {
    [CareType.Psychiatry]: !psychUpcoming && !psychComplete,
    [CareType.Therapy]: !therapyUpcoming && !therapyComplete,
  };
  return careTypes[careType];
};

export const getInitialAppointmentDetails = (
  user: AppointmentUserV2Fragment,
  careType: CareType
) => {
  const { appointmentTemplates } = user;
  const appointmentType = determineAppointmentType(user, careType);

  return appointmentTemplates.reduce((details, template, templateIndex) => {
    const hasCareType = template.careType === careType;
    const hasApptType = template.appointmentType === appointmentType;

    if (hasCareType && hasApptType) {
      return {
        appointmentType,
        templateIndex,
        careType,
        duration: template.duration,
      } as AppointmentDetails;
    }

    return details;
  }, null as Nullable<AppointmentDetails>);
};

const determineAppointmentType = (user: AppointmentUserV2Fragment, careType: CareType) => {
  const careTypeToApptType: Record<CareType, AppointmentType> = {
    [CareType.Psychiatry]: user.psychComplete ? 'checkin' : 'intake',
    [CareType.Therapy]: user.therapyComplete ? 'checkin' : 'intake',
  };
  return careTypeToApptType[careType];
};

export const getIntakeApptData = (
  careType: CareType,
  appointmentTemplates: AppointmentTemplateFragment[]
) => {
  const result = findIntakeApptTemplate(careType, appointmentTemplates);
  if (!result) return;
  const [template, templateIndex] = result;
  return {
    templateIndex,
    duration: template.duration,
    careType,
    appointmentType: 'intake',
  };
};
