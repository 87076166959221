import { Avatar } from 'baseui/avatar';
import { UnregisterCallback } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BackLinkButton } from '../../../Components/BackLink';
import { FinalButton } from '../../../Components/FinalButton';
import { icons } from '../../../Components/Icons';
import { MantraModalBodyOld, MantraModalHeaderOld } from '../../../Components/Modal';
import { Modal } from '../../../Components/Modal/Modal';
import { McpOnly } from '../../../Components/Permissions';
import { SimpleAvailability } from '../../../Components/ProviderNetwork/Availability/SimpleAvailability';
import {
  ProviderNetworkContextProvider,
  useProviderNetworkContext,
} from '../../../Components/ProviderNetwork/ProviderNetworkContext';
import { ProviderNetworkRow } from '../../../Components/ProviderNetwork/ProviderNetworkRow';
import { ProviderNetworkShell } from '../../../Components/ProviderNetwork/ProviderNetworkShell';
import { getDisplayableType } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { ProvidersNotFound } from '../../../Components/ProviderNetwork/ProvidersNotFound';
import { ErrorContainer } from '../../../Components/ProviderNetwork/Styles';
import { SearchProvider } from '../../../Components/ProviderNetwork/types';
import { Text } from '../../../globalStyles';
import {
  SuggestedProviderUserQuery,
  useSuggestedProviderUserQuery,
  useUpsertSuggestedProvidersMutation,
} from '../../../graphQL';
import { useQueryParams } from '../../../Hooks';
import { getFullName } from '../../../modelUtils/users';

export const SuggestedProviders = () => {
  const [queryParams] = useQueryParams();
  const { data: userData } = useSuggestedProviderUserQuery({
    variables: { id: Number(queryParams.userId) },
  });

  if (!userData) {
    return null;
  }

  const user = userData.adminUser;

  return (
    <McpOnly>
      <ProviderNetworkContextProvider searchBy={{ user }}>
        <Body user={user} />
      </ProviderNetworkContextProvider>
    </McpOnly>
  );
};

type BodyProps = {
  user: SuggestedProviderUserQuery['adminUser'];
};

const Body = ({ user }: BodyProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { providers, selectedProviders, getSelectedProviders } = useProviderNetworkContext();

  const [attemptedLeave, setAttemptedLeave] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const unblockHandler = useRef<UnregisterCallback>();

  const [saveProviders] = useUpsertSuggestedProvidersMutation();

  const onSelect = async (providerIds: number[]) => {
    try {
      setError(null);
      await saveProviders({
        variables: { userId: user.id, providerIds },
      });
    } catch {
      setError('Error, please try again');
    }
    unblockHandler.current?.();
    setConfirmation(true);
  };

  useEffect(() => {
    unblockHandler.current = history.block(tx => {
      if (tx.pathname !== pathname && selectedProviders.size > 0) {
        // bocked
        setAttemptedLeave(tx.pathname);
        return false;
      }
    });
    return () => unblockHandler.current?.();
  }, [pathname, selectedProviders.size, history]);

  if (confirmation) {
    return <Confirmation providers={getSelectedProviders()} user={user} />;
  }

  return (
    <>
      <Modal size="tiny" isOpen={!!attemptedLeave} onClose={() => setAttemptedLeave(null)}>
        <MantraModalHeaderOld>
          <Text.h3 className="mb3">Leave without saving suggested providers?</Text.h3>
        </MantraModalHeaderOld>
        <MantraModalBodyOld>
          <Text.body className="mb4">
            If you continue, your selections and updates will not been saved.{' '}
          </Text.body>
          <div className="flex flex-column gap-3">
            <FinalButton
              kind="outline_danger"
              onClick={() => {
                unblockHandler.current?.();
                history.push(attemptedLeave!);
                setAttemptedLeave(null);
              }}
            >
              Yes, leave without saving
            </FinalButton>
            <FinalButton kind="minimal_gray" onClick={() => setAttemptedLeave(null)}>
              Stay on this page
            </FinalButton>
          </div>
        </MantraModalBodyOld>
      </Modal>
      <ProviderNetworkShell
        withSearch
        header={
          <>
            <BackLinkButton className="mb3" onClick={() => history.goBack()} />
            <Text.bodyBold>
              Search &amp; select suggested providers for {getFullName(user)}
            </Text.bodyBold>
          </>
        }
        banner={
          <>
            {error && (
              <ErrorContainer>
                <Text.bodySmall kind="danger">{error}</Text.bodySmall>
              </ErrorContainer>
            )}
          </>
        }
        selectedProvidersButtonText="Save selection >"
        onSelectProviders={onSelect}
      >
        {providers.map(p => (
          <ProviderNetworkRow
            key={p.id}
            provider={p}
            renderAvailability={SimpleAvailability}
            gridSmall
            canSelectProviders
          />
        ))}
        {!providers.length && <ProvidersNotFound clearGeoState />}
      </ProviderNetworkShell>
    </>
  );
};

type ConfirmationProps = {
  providers: SearchProvider[];
  user: SuggestedProviderUserQuery['adminUser'];
};

const Confirmation = ({ providers, user }: ConfirmationProps) => {
  const history = useHistory();
  return (
    <div className="flex flex-column center mt5" style={{ width: 351 }}>
      <Text.h2 className="mb3">
        Provider selections for {getFullName(user)} have been saved.
      </Text.h2>
      <Text.body className="mb3">
        When {user.firstName} is choosing a Mantra provider, they will see your suggestions at the
        top of the list.
      </Text.body>
      <Text.body className="mb4">
        You can add or edit suggested providers in the patient’s profile as long as they don’t have
        an assigned provider yet.
      </Text.body>
      <Text.label className="mv2">Suggested Providers</Text.label>
      {providers.map(p => (
        <div key={p.id} className="flex items-start gap-3">
          <Avatar
            size="2.5rem"
            overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
            src={p.portrait?.url ?? icons.assetsDefaultPicturePng}
            name={p.name}
          />
          <div>
            <Text.bodyBold className="mb1">{p.name}</Text.bodyBold>
            <Text.bodySmall className="mb3">{getDisplayableType(p)}</Text.bodySmall>
          </div>
        </div>
      ))}
      <div className="flex flex-column gap-2 mt3">
        <FinalButton kind="outline_black" onClick={() => history.push(`/users/${user.id}`)}>
          Go to patient profile
        </FinalButton>
        <FinalButton kind="minimal_gray" onClick={() => history.push('/')}>
          Go to dashboard
        </FinalButton>
      </div>
    </div>
  );
};
