import { NestedOption } from '../../Components/Form/Select/types';
import { TicketCategory, TicketPriority } from '../../graphQL';

const priorityMap: Record<TicketPriority, number> = {
  [TicketPriority.High]: 3,
  [TicketPriority.Medium]: 2,
  [TicketPriority.Low]: 1,
};
export const prioritySorter = (a?: TicketPriority | null, b?: TicketPriority | null) => {
  if (!a) return -Number.MAX_SAFE_INTEGER;
  if (!b) return Number.MAX_SAFE_INTEGER;
  if (b && a) return priorityMap[a] - priorityMap[b];
  return 0;
};

export const ticketCategoryCopy: Record<TicketCategory, string> = {
  [TicketCategory.ReferralPsychiatry]: 'Psychiatry',
  [TicketCategory.ReferralTherapy]: 'Therapy',
  [TicketCategory.ReferralOther]: 'Other (please specify in details)',
  [TicketCategory.Scheduling]: 'Scheduling',
  [TicketCategory.Outreach]: 'Outreach',
  [TicketCategory.Labs]: 'Labs / EKGS / Vitals',
  [TicketCategory.PriorAuth]: 'Prior Auth',
  [TicketCategory.OnHold]: 'On Hold / Terminate',
  [TicketCategory.TechSupport]: 'Tech Support',
  [TicketCategory.Insurance]: 'Insurance',
  [TicketCategory.Coc]: 'External Referral: Case Summary',
  [TicketCategory.DatasharingCoc]: 'External Referral: Case Summary (CoC)',
  [TicketCategory.DatasharingRoi]: 'Consent for external contact (ROI)',
  [TicketCategory.Other]: 'Other (specify in details)',
};

export const ticketCategoryOptions: NestedOption[] = [
  {
    id: 'referrals',
    label: 'Referral',
    subcategories: [
      {
        id: TicketCategory.ReferralPsychiatry,
      },
      {
        id: TicketCategory.ReferralTherapy,
      },
      { id: TicketCategory.ReferralOther },
    ],
  },
  {
    id: TicketCategory.Scheduling,
  },
  {
    id: TicketCategory.Outreach,
  },
  {
    id: TicketCategory.Labs,
  },
  {
    id: TicketCategory.PriorAuth,
  },
  {
    id: TicketCategory.OnHold,
  },
  {
    id: TicketCategory.TechSupport,
  },
  {
    id: TicketCategory.Insurance,
  },
  {
    id: TicketCategory.Coc,
  },
  {
    id: 'datasharing',
    label: 'Consent to share info.',
    subcategories: [{ id: TicketCategory.DatasharingCoc }, { id: TicketCategory.DatasharingRoi }],
  },
  {
    id: TicketCategory.Other,
  },
].map(({ id, label, subcategories }) => ({
  id,
  label: label ?? ticketCategoryCopy[id as TicketCategory],
  subcategories:
    subcategories && subcategories.map(s => ({ id: s.id, label: ticketCategoryCopy[s.id] })),
}));
