import { isNil, last } from 'lodash';
import flatMap from 'lodash/flatMap';
import {
  AdminUserQuery,
  GetAllNotesForUserQuery,
  QuestionnaireResult,
  SectionFormat,
} from '../graphQL';
import { DeepPartial } from '../types';

type MedicalHistory = NonNullable<AdminUserQuery['adminUser']['latestMedicalHistoryResult']>;
type Note = GetAllNotesForUserQuery['adminUser']['notes'][number];

export const formatMedicalHistoryAsNote = (medHistoryResult: MedicalHistory): Note => ({
  id: 0,
  key: medHistoryResult.uuid,
  createdAt: medHistoryResult.createdAt,
  updatedAt: medHistoryResult.updatedAt,
  submittedAt: medHistoryResult.createdAt,
  provider: { id: -1, name: '' },
  template: {
    key: medHistoryResult.uuid,
    title: 'Medical History',
    uuid: medHistoryResult.uuid,
    questions: flatMap(medHistoryResult.questionnaires, s =>
      s.questions.map(q => {
        let rawValue = q.value;
        // Empty arrays should appear as "None" instead of "Not answered"
        if (Array.isArray(rawValue) && rawValue.length === 0) rawValue = ['None'];
        const value = JSON.stringify(rawValue);
        const format = Array.isArray(rawValue) ? SectionFormat.Multitext : SectionFormat.Textarea;
        return { title: q.text, key: q.key, value, format, optional: true };
      })
    ),
  },
});

// [short name, long name if it exists]
const titleMap = new Map<string, [string, string?]>([
  ['phq9', ['PHQ-8']],
  ['phq8', ['PHQ-8']],
  ['phq', ['PHQ-8']],
  ['gad7', ['GAD-7']],
  ['asrsv11', ['ASRS v1.1']],
  ['lifestyle', ['Lifestyle']],
  ['sds', ['SDS', 'Sheehan Disability Scale (SDS)']],
  ['mdq', ['MDQ', 'Mood Disorder Questionnaire (MDQ)']],
  ['ymrs', ['YMRS', 'Young Mania Rating Scale (YMRS)']],
  ['dropout-risk-measurement', ['Dropout Risk']],
  ['flourishing-scale', ['Flourishing Scale']],
]);

export const getTitle = (titleKey: string, long?: boolean) => {
  const entry = titleMap.get(titleKey);
  if (!entry) return 'Other';
  if (long) return last(entry)!;
  return entry[0];
};

export const assessmentEventTitle = (key: string) => {
  switch (key) {
    case 'bipolar-1-screener':
    case 'mdq':
      return 'the Mood Disorder Questionnaire';
    case 'medical-history':
      return 'the Medical History Questionnaire';
    case 'scales':
      return 'the monthly scales';
    case 'intake':
      return 'the intake assessment';
    default:
      return 'an assessment';
  }
};

export const formatResult = ({
  score,
  symptoms,
  key,
  answers,
}: DeepPartial<QuestionnaireResult>) => {
  if (key === 'dropout-risk-measurement' && answers) return `${answers[0]?.score} of 7`;
  if (key === 'flourishing-scale' && answers) return `${Number(score) + 1} of 7`;
  if (key === 'mdq' && symptoms) return symptoms;
  const scoreText = isNil(score) ? 'n/a' : `${score}`;
  const symptomText = isNil(symptoms) ? '' : ` (${symptoms})`;
  return `${scoreText}${symptomText}`;
};

const weDoNotCare = new Set(['basicInformation', 'lifestyle']);
export const shouldShow = ({ key }: Pick<QuestionnaireResult, 'key'>) => !weDoNotCare.has(key);
