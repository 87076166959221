import { StatefulCheckbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { KIND, Notification } from 'baseui/notification';
import { isNil } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { Text } from '../../globalStyles';
import { useChangePasswordAndAcceptTosMutation, useCurrentProviderQuery } from '../../graphQL';
import { getAuthToken } from '../../token';
import { getTerms } from '../Terms/utils';
import { SessionPageWrapper } from './Shared';
import { Form, FormElement, inputOverrides } from './Style';
import { validPasswordReg } from './utils';

interface FormFields {
  password: string;
  confirmPassword: string;
  acceptTos: boolean;
}

export const ChangePassword = () => {
  const currentProviderQuery = useCurrentProviderQuery();
  const [changePassword, changePasswordMutation] = useChangePasswordAndAcceptTosMutation({
    onCompleted: async data => {
      await currentProviderQuery?.refetch?.();
      history.push('/users');
    },
    errorPolicy: 'none',
    onError: error => {
      // The component below directly uses the error attribute of the changePasswordMutation
      // and further error handling is not needed by this callback.
    },
  });
  const history = useHistory();

  const terms = getTerms('referral');

  const { handleSubmit, register, errors, setError, control } = useForm<FormFields>({
    defaultValues: { acceptTos: !!isNil(terms) },
  });

  const submit = handleSubmit(async values => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword)
      return setError('confirmPassword', {
        type: 'confirm',
        message: 'Confirmation does not match',
      });
    await changePassword({ variables: { password } });
  });

  if (!getAuthToken()) return <Redirect to="/login" />;
  return (
    <SessionPageWrapper title="Change Password">
      {changePasswordMutation.error && (
        <Notification
          kind={KIND.negative}
          overrides={{ InnerContainer: { style: { margin: 'auto' } } }}
        >
          An error occured: {changePasswordMutation.error.message}
        </Notification>
      )}

      <Form onSubmit={submit}>
        <FormElement>
          <FormControl caption={errors.password && errors.password.message}>
            <Input
              inputRef={register({
                required: 'This field is required',
                minLength: { value: 10, message: 'Password must be at least 10 characters' },
                pattern: {
                  value: validPasswordReg,
                  message:
                    'Password must contain a special character, number, an uppercase letter, and a lowercase letter.',
                },
              })}
              type="password"
              name="password"
              error={!!errors.password}
              size="large"
              placeholder="New Password"
              overrides={inputOverrides}
            />
          </FormControl>
        </FormElement>
        <FormElement>
          <FormControl caption={errors.confirmPassword && errors.confirmPassword.message}>
            <Input
              inputRef={register({ required: 'This field is required' })}
              name="confirmPassword"
              type="password"
              error={!!errors.confirmPassword}
              size="large"
              placeholder="Confirm New Password"
              overrides={inputOverrides}
            />
          </FormControl>
        </FormElement>
        {!isNil(terms) && (
          <FormElement>
            <FormControl caption={errors.acceptTos && errors.acceptTos.message}>
              <Controller
                name="acceptTos"
                control={control}
                rules={{ validate: v => !!v || 'You must accept the terms of use' }}
                render={({ onChange, ...props }) => (
                  <StatefulCheckbox
                    {...props}
                    onChange={({ currentTarget }) => onChange(currentTarget.checked)}
                    isError={!!errors.acceptTos}
                  >
                    I accept the{' '}
                    <Text.link to={terms} target="_blank">
                      Terms of Use
                    </Text.link>
                  </StatefulCheckbox>
                )}
              />
            </FormControl>
          </FormElement>
        )}
        <FinalButton
          type="submit"
          className="w-100"
          loading={changePasswordMutation.loading}
          kind="primary"
        >
          Change Password
        </FinalButton>
      </Form>
    </SessionPageWrapper>
  );
};
