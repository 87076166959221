import { Tab, Tabs } from 'baseui/tabs';
import qs from 'query-string';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { Flag } from '../../featureFlags';
import { useOrganizationTopLevelQuery } from '../../graphQL';
import { useQueryParams } from '../../Hooks';
import { HandbookFromParams } from '../Handbook/Handbook';
import { UnexpectedError } from '../Shared';
import { Overrides } from '../Users/baseuiOverrides';
import { OrganizationTopDisplay } from './OrganizationTopDisplay';
import { OrgTabProps, useOrganizationId } from './util';

type TabKey = 'handbook';

type OrgListItem = {
  content: React.FC<OrgTabProps>;
  title: string;
  key: TabKey;
  hidden?: boolean;
};

export function OrganizationMantraProvider() {
  const id = useOrganizationId();
  const [queryParams, updateQueryParams] = useQueryParams();
  const { search } = useLocation();
  const { featureFlags } = useCurrentProvider();
  const { data, loading, error, refetch } = useOrganizationTopLevelQuery({ variables: { id } });

  const tabs = useMemo(
    () =>
      [
        {
          title: 'Handbook',
          key: 'handbook',
          content: HandbookFromParams,
          hidden: !featureFlags.includes(Flag.CscHandbook),
        },
      ].filter(({ hidden }) => !hidden) as OrgListItem[],
    [featureFlags]
  );

  const activeTab = (() => {
    const { tab } = qs.parse(search);
    const tabItem = tabs.find(t => t.key === tab) ?? tabs[0];
    return tabItem.key;
  })();

  const setActiveTab = (activeKey: string) => {
    updateQueryParams({ ...queryParams, tab: activeKey });
  };

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const { organization } = data;

  return (
    <div className="bg-white">
      <OrganizationTopDisplay organization={organization} />
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => setActiveTab(activeKey as string)}
        overrides={Overrides.Tabs}
      >
        {tabs.map(({ content: C, title, key }) => (
          <Tab title={title} key={key} overrides={Overrides.Tab}>
            <C setActiveTab={setActiveTab} refetchHeader={refetch} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
