import React, { useState } from 'react';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { Text } from '../../../globalStyles';
import { CampusResourcesQuery, ResourceType, useCampusResourcesQuery } from '../../../graphQL';
import { getClickableLink } from '../../../utils';
import { ResourceModalV2 } from '../../CampusResources/CampusResourcesV2';
import { UnexpectedError } from '../../Shared';
import { SectionRow, SectionRowItem } from './SectionRow';

type Props = {
  organizationId: number;
  editable?: boolean;
};

export const titleCampusResources = 'Campus Resources';

export function CampusResourcesSection({ organizationId, editable }: Props) {
  const { data, refetch, error, loading } = useCampusResourcesQuery({
    variables: { organizationId },
  });

  type Resource = CampusResourcesQuery['organization']['resources'][number];
  const [modalResource, setModalResource] = useState<Resource | 'new' | null>(null);
  const [modalResourceType, setModalResourceType] = useState<Partial<ResourceType> | null>(null);

  if (loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;

  // show this if provider is not Mantra Admin and there are no resources of a particular type
  const NoResources = () => {
    if (editable) {
      return null;
    }
    return (
      <SectionRowItem>
        <Text.bodySmallItalicized>None listed</Text.bodySmallItalicized>
      </SectionRowItem>
    );
  };

  const openAddResourceModal = (category: Partial<ResourceType>) => {
    setModalResource('new');
    setModalResourceType(category);
  };

  const closeResourceModal = () => {
    setModalResource(null);
    setModalResourceType(null);
  };

  type AddResourceProp = {
    category: Partial<ResourceType>;
  };
  const AddResource = ({ category }: AddResourceProp) => {
    if (!editable) {
      return null;
    }
    return (
      <SectionRowItem>
        <Text.linkButton onClick={() => openAddResourceModal(category)}>
          + Add Resource
        </Text.linkButton>
      </SectionRowItem>
    );
  };

  const onEdit = editable
    ? (id: number) => () => {
        const filteredResource = data?.organization.resources.find(
          ({ id: resourceId }) => resourceId === id
        );
        if (!filteredResource) {
          setModalResource('new');
          return;
        }
        if (filteredResource.resourceType in NewResourceTypes) {
          setModalResource(filteredResource);
          return;
        }
        setModalResource({ ...filteredResource, resourceType: ResourceType.Other }!);
      }
    : () => undefined;

  // CscHandbook - this can be removed once CscHandbook feature flag is removed
  const NewResourceTypes: Partial<Record<ResourceType, string>> = {
    [ResourceType.HealthServices]: 'Health Services',
    [ResourceType.ResidenceLife]: 'Residence Life',
    [ResourceType.PublicSafetyCampusPolice]: 'Public Safety/Campus Police',
    [ResourceType.UrgentCareCenter]: 'Urgent Care Center',
    [ResourceType.LocalHospitalCommunityProvider]: 'Local Hospitals or Other Community Providers',
    [ResourceType.PreferredCampusPharmacy]: 'Preferred Campus Pharmacy',
    [ResourceType.DisabilityAccessibilityCenter]: 'Disability/Accesibility Center',
    [ResourceType.LgbtqiaCenter]: 'LGBTQIA+ Center',
  };

  type Resources = CampusResourcesQuery['organization']['resources'];
  const healthServices: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.HealthServices
  );
  const residenceLife: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.ResidenceLife
  );
  const publicSafetyCampusPolice: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.PublicSafetyCampusPolice
  );
  const urgentCareCenter: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.UrgentCareCenter
  );
  const localHospitalCommunityProvider: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.LocalHospitalCommunityProvider
  );
  const preferredCampusPharmacy: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.PreferredCampusPharmacy
  );
  const disabilityAccessibilityCenter: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.DisabilityAccessibilityCenter
  );
  const lgbtqiaCenter: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.LgbtqiaCenter
  );
  const otherResources: Resources = data.organization.resources.filter(
    ({ resourceType }) => resourceType === ResourceType.Other || !(resourceType in NewResourceTypes)
  );

  const renderResources = (resources: Resources) => {
    if (resources.length <= 0) {
      return <NoResources />;
    }
    return resources.map(filteredResource => (
      <SectionRowItem onEditClick={onEdit(filteredResource.id)}>
        <Text.bodyBold>{filteredResource.name}</Text.bodyBold>
        {filteredResource.description ? (
          <Text.bodySmall>Description: {filteredResource.description}</Text.bodySmall>
        ) : null}
        {filteredResource.contact ? (
          <Text.bodySmall>Contact: {filteredResource.contact}</Text.bodySmall>
        ) : null}
        {filteredResource.email ? (
          <Text.bodySmall>Email: {filteredResource.email}</Text.bodySmall>
        ) : null}
        {filteredResource.hours ? (
          <Text.bodySmall>Hours: {filteredResource.hours}</Text.bodySmall>
        ) : null}
        {filteredResource.website ? (
          <Text.bodySmall>
            Website:{' '}
            <Text.externalLink href={getClickableLink(filteredResource.website)} target="_blank">
              {filteredResource.website}
            </Text.externalLink>
          </Text.bodySmall>
        ) : null}
      </SectionRowItem>
    ));
  };

  return (
    <>
      <SectionRow label="Health Services">
        {renderResources(healthServices)}
        <AddResource category="HealthServices" />
      </SectionRow>

      <SectionRow label="Residence Life">
        {renderResources(residenceLife)}
        <AddResource category="ResidenceLife" />
      </SectionRow>

      <SectionRow label="Public Safety/Campus Police">
        {renderResources(publicSafetyCampusPolice)}
        <AddResource category="PublicSafetyCampusPolice" />
      </SectionRow>

      <SectionRow label="Urgent Care Center">
        {renderResources(urgentCareCenter)}
        <AddResource category="UrgentCareCenter" />
      </SectionRow>

      <SectionRow label="Local Hospitals or Other Community Providers">
        {renderResources(localHospitalCommunityProvider)}
        <AddResource category="LocalHospitalCommunityProvider" />
      </SectionRow>

      <SectionRow
        label="Preferred Campus Pharmacy"
        info="If added to org, the location will show up as suggested pharmacy for patients during onboarding."
      >
        {renderResources(preferredCampusPharmacy)}
        <AddResource category="PreferredCampusPharmacy" />
      </SectionRow>

      <SectionRow label="Disability/Accessibility Center">
        {renderResources(disabilityAccessibilityCenter)}
        <AddResource category="DisabilityAccessibilityCenter" />
      </SectionRow>

      <SectionRow label="LGBTQIA+ Center">
        {renderResources(lgbtqiaCenter)}
        <AddResource category="LgbtqiaCenter" />
      </SectionRow>

      <SectionRow label="Other">
        {renderResources(otherResources)}
        <AddResource category="Other" />
      </SectionRow>

      {modalResource && (
        <ResourceModalV2
          modalResourceType={modalResourceType}
          organizationId={organizationId}
          resource={modalResource === 'new' ? undefined : modalResource}
          onClose={() => closeResourceModal()}
          refetch={refetch}
        />
      )}
    </>
  );
}
