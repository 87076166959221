import { uniqBy } from 'lodash';
import { isMcpUser } from '../../../Components/Permissions';
import { EnrollModalQuery } from '../../../graphQL';

export const getMcpEnrollmentProviderOptions = (
  organization: EnrollModalQuery['organization'],
  childOrganizationId?: number
) => {
  const nameSort = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);
  let possibleProviders = [...(organization.providers ?? [])].sort(nameSort);
  if (childOrganizationId) {
    const childOrg = organization.children.find(i => i.id === childOrganizationId);
    const childProviders = childOrg ? [...(childOrg.providers ?? [])].sort(nameSort) : [];
    // Super admins should be below the providers in the child org
    possibleProviders = [...childProviders, ...possibleProviders];
  } else if (organization.parent) {
    const parentProviders = [...(organization.parent.providers ?? [])].sort(nameSort);
    possibleProviders = [...possibleProviders, ...parentProviders];
  }
  possibleProviders = possibleProviders.filter(isMcpUser);
  possibleProviders = uniqBy(possibleProviders, provider => provider.id);
  return possibleProviders.map(({ id, name }) => ({ label: name, id }));
};
