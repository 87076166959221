/* eslint-disable no-param-reassign */
import * as d3 from 'd3';
import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFullMantraQuery } from '../../graphQL/index';

const BASE_WIDTH = 720;
const BASE_HEIGHT = 480;
const BASE_TEXT = 12;

const types = ['provider', 'care-team', 'organization', 'patient'] as const;

type NodeType = typeof types[number];

type Edge = { source: string; target: string; type: NodeType };
type Node = { id: string; url?: string; type: NodeType };

export const Visualizations = () => {
  const history = useHistory();
  const divRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const { data } = useFullMantraQuery();
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (!data || !divRef.current) return;
    const parent = divRef.current;

    const nodes: Node[] = [];
    const links: Edge[] = [];

    for (const org of data.organizations) {
      nodes.push({ id: org.name, type: 'organization', url: `/organizations/${org.id}/admin` });
      for (const child of org.children) {
        links.push({ source: org.name, target: child.name, type: 'organization' });
      }
      for (const provider of org.providers) {
        nodes.push({
          id: provider.name,
          type: provider.role === 'provider' ? 'provider' : 'care-team',
          url: `/providers/${provider.id}`,
        });
        links.push({ source: org.name, target: provider.name, type: 'organization' });
        for (const user of provider.patients) {
          if (!user.customerId) continue;
          nodes.push({ id: user.customerId, type: 'patient', url: `/users/${user.id}` });
          links.push({ source: provider.name, target: user.customerId, type: 'provider' });
        }
      }
    }

    const { node, simulation } = getChart({
      nodes: uniqBy(nodes, 'id'),
      links,
      onClickNode: n => n.url && history.push(n.url),
    });
    if (node) {
      parent.appendChild(node);
      svgRef.current = node;
    }
    setZoom(1);

    return () => {
      simulation.stop();
      if (node) parent.removeChild(node);
    };
  }, [data, setZoom, history]);

  useEffect(() => {
    const width = BASE_WIDTH * zoom;
    const height = BASE_HEIGHT * zoom;
    const text = BASE_TEXT * zoom;

    if (svgRef.current) {
      svgRef.current.setAttribute('viewBox', [-width / 2, -height / 2, width, height].join(','));
      svgRef.current.setAttribute('style', `width:${BASE_WIDTH}px; height:${BASE_HEIGHT}px;`);
      svgRef.current.setAttribute('font', `${text}px sans-serif`);
    }
  }, [zoom]);

  return (
    <div className="relative">
      <input
        className="absolute mt2 ml2"
        type="range"
        min={0.5}
        step={0.1}
        max={3}
        value={zoom}
        onChange={e => setZoom(Number(e.target.value))}
      />
      <div ref={divRef} />
    </div>
  );
};

const color = d3.scaleOrdinal(types, d3.schemeCategory10);

const nodeSize: Record<NodeType, number> = {
  patient: 4,
  provider: 6,
  'care-team': 6,
  organization: 8,
};

function linkArc(d: any) {
  const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
  return `
      M${d.source.x},${d.source.y}
      A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
    `;
}

const drag = (simulation: d3.Simulation<any, undefined>) => {
  function dragstarted(event: any, d: any) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(event: any, d: any) {
    d.fx = event.x;
    d.fy = event.y;
  }

  function dragended(event: any, d: any) {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  return d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended);
};

type ChartArgs = { links: Edge[]; nodes: Node[]; onClickNode: (arg0: Node) => void };
const getChart = ({ onClickNode, ...data }: ChartArgs) => {
  const links = data.links.map(d => Object.create(d));
  const nodes = data.nodes.map(d => Object.create(d));

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      'link',
      d3.forceLink(links).id((d: any) => d.id)
    )
    .force('charge', d3.forceManyBody().strength(-400))
    .force('x', d3.forceX())
    .force('y', d3.forceY());

  const svg = d3.create('svg').attr('class', 'ba');

  // Per-type markers, as they don't inherit styles.
  svg
    .append('defs')
    .selectAll('marker')
    .data(types)
    .join('marker')
    .attr('id', d => `arrow-${d}`)
    .attr('viewBox', '0 -5 10 10')
    // .attr('refX', d => nodeSize[d] + 6)
    .attr('refX', 15)
    .attr('refY', -0.5)
    .attr('markerWidth', 6)
    .attr('markerHeight', 6)
    .attr('orient', 'auto')
    .append('path')
    .attr('fill', color)
    .attr('d', 'M0,-5L10,0L0,5');

  const link = svg
    .append('g')
    .attr('fill', 'none')
    .attr('stroke-width', 1.5)
    .selectAll('path')
    .data(links)
    .join('path')
    .attr('stroke', d => color(d.type))
    .attr('marker-end', d => `url(${new URL(`#arrow-${d.type}`, window.location as any)})`);

  const node = svg
    .append('g')
    .attr('fill', 'currentColor')
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round')
    .selectAll('g')
    .data(nodes)
    .join('g')
    // @ts-ignore
    .call(drag(simulation))
    .attr('class', 'pointer')
    .on('click', (d, x) => onClickNode(data.nodes[x.index]));

  node
    .append('circle')
    .attr('stroke', 'white')
    .attr('fill', d => color(d.type))
    .attr('stroke-width', 1.5)
    .attr('r', d => nodeSize[d.type as NodeType]);

  node
    .append('text')
    .attr('x', d => nodeSize[d.type as NodeType] + 4)
    .attr('y', '0.31em')
    .text(d => d.id)
    .clone(true)
    .lower()
    .attr('fill', 'none')
    .attr('stroke', 'white')
    .attr('stroke-width', 3);

  simulation.on('tick', () => {
    link.attr('d', linkArc);
    node.attr('transform', d => `translate(${d.x},${d.y})`);
  });

  return { simulation, node: svg.node() };
};
