import { ApolloError } from '@apollo/client';
import decode from 'jwt-decode';
import { compact, isNil, last, pickBy } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import * as qs from 'querystring';
import { isMcpUser } from './Components/Permissions';
import {
  AdminUserQuery,
  AppView,
  CareFlowAndSessionsFragment,
  CareType,
  CurrentProviderQuery,
  Entitlement,
  OrganizationCareFlowFragment,
  Provider,
} from './graphQL';
import { clearAuthToken, getAuthToken } from './token';
import { HasName, Nullable, PartialOrNulled } from './types';

export const DAYS_IN_WEEK = 7;

export const isTokenExpired = (token: string | null) => {
  if (!token) return true;
  return doesTokenExpireWithin(token, 0);
};

export const doesTokenExpireWithin = (token: string, milliseconds: number) => {
  try {
    const { exp } = decode(token);
    return exp < (Date.now() + milliseconds) / 1000;
  } catch {
    return false;
  }
};

export const publicUrl = (path: string) => `${process.env.PUBLIC_URL}/${path}`;

export const logout = () => clearAuthToken();

export const isLoggedIn = () => !isTokenExpired(getAuthToken());

type CompareJsDateArgs = {
  a?: Date | string | null;
  b?: Date | string | null;
};
export const compareJsDates = ({ a, b }: CompareJsDateArgs) => {
  if (!a) return -1;
  if (!b) return 1;
  return new Date(b).getTime() - new Date(a).getTime();
};

/**
 * Styletron will often have problems if you try to use shorthand CSS syntax
 * like "padding: 1em" or whatever, but writing out "paddingLeft", "paddingRight",
 * etc is annoying. This helper creates functions (see below it) that let us just
 * write like { ...padding("1em") } instead.
 */
function toLonghand(properties: string[]) {
  return (input: string) => {
    const longhand: Record<string, string> = {};
    // eslint-disable-next-line prefer-const
    let [top, right, bottom, left] = input.trim().split(' ');
    if (right === undefined) right = top;
    if (bottom === undefined) bottom = top;
    if (left === undefined) left = right;
    const values = [top, right, bottom, left];
    for (let i = 0; i < properties.length; i += 1) {
      longhand[properties[i]] = values[i];
    }
    return longhand;
  };
}
export const borderColor = toLonghand([
  'borderLeftColor',
  'borderRightColor',
  'borderBottomColor',
  'borderTopColor',
]);
export const borderRadius = toLonghand([
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderBottomRightRadius',
  'borderBottomLeftRadius',
]);
export const borderWidth = toLonghand([
  'borderBottomWidth',
  'borderTopWidth',
  'borderLeftWidth',
  'borderRightWidth',
]);
export const borderStyle = toLonghand([
  'borderTopStyle',
  'borderBottomStyle',
  'borderRightStyle',
  'borderLeftStyle',
]);
export const padding = toLonghand(['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft']);
export const horizontalPadding = toLonghand(['paddingRight', 'paddingLeft']);
export const margin = toLonghand(['marginTop', 'marginRight', 'marginBottom', 'marginLeft']);

export const wait = (ms: number = 0) => new Promise(resolve => setTimeout(resolve, ms));

export function buildWeeksGrid(
  month: Moment,
  timezone?: TimeZone,
  showLeadingAndTrailingDates?: boolean
) {
  const weeks: (Moment | undefined)[][] = [Array(DAYS_IN_WEEK).fill(undefined)];

  // Just switching the timezone w clone will shift the time
  const i = moment
    .tz({ date: 1, month: month.month(), year: month.year() }, timezone ?? guessTz())
    .startOf('day');

  // If we want to show leading and trailing dates, go to the start of the week,
  // and fill in the first week array
  if (showLeadingAndTrailingDates) {
    if (i.day() !== 0) {
      i.startOf('week');
      while (i.month() !== month.month()) {
        weeks[weeks.length - 1][i.day()] = i.clone();
        i.add(1, 'day');
      }
    }
  }

  while (i.month() === month.month()) {
    weeks[weeks.length - 1][i.day()] = i.clone();
    if (i.day() === DAYS_IN_WEEK - 1 && i.date() < i.daysInMonth()) {
      weeks.push([]);
    }
    i.add(1, 'day');
  }

  // If we want to show leading and trailing dates, go to the start of the week,
  // then add dates until the day equals 6, then add one more
  if (showLeadingAndTrailingDates) {
    while (i.day() !== 6) {
      weeks[weeks.length - 1].push(i.clone());
      i.add(1, 'day');
    }
    weeks[weeks.length - 1].push(i.clone());
  } else {
    while (weeks[weeks.length - 1].length < DAYS_IN_WEEK) {
      weeks[weeks.length - 1].push(undefined);
    }
  }
  return weeks;
}

export function getWeek(startDate: Moment, opts?: { timezone?: string; startOfWeek?: boolean }) {
  const { timezone, startOfWeek } = opts ?? {};
  const days: Moment[] = [];
  // it's important to put this at 00:00 in the correct time zone, because otherwise
  // comparing dates to it to see if they're in the same day won't work as expected
  const dateRunner = timezone
    ? moment.tz([startDate.year(), startDate.month(), startDate.date()], timezone)
    : startDate.clone();

  if (startOfWeek) {
    dateRunner.startOf('week');
  }

  for (let i = 0; i < DAYS_IN_WEEK; i += 1) {
    days.push(dateRunner.clone());
    dateRunner.add(1, 'day');
  }
  return days;
}

export function friendlyTimeZoneName(tz: string | null | undefined): string {
  if (!tz) return 'Unknown';
  const zones: Record<string, string> = {
    'America/New_York': 'Eastern Time (ET)',
    'America/Chicago': 'Central Time (CT)',
    'America/Denver': 'Mountain Time (MT)',
    'America/Los_Angeles': 'Pacific Time (PT)',
  };
  return zones[tz] || 'Unknown';
}

export const formatPhoneNumber = (phone: string) => {
  let formatted = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  if (phone.length > 10) {
    formatted += ` Ext. ${phone.slice(10)}`;
  }
  return formatted;
};

export const stripPhoneNumber = (phone: string) =>
  phone.replace(/\s+/g, '').replace(/[()-]/g, '').replace('Ext.', '');

export const uuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
    const random = (Math.random() * 16) | 0; // Nachkommastellen abschneiden
    const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemäss RFC 4122
    return value.toString(16); // Hexadezimales Zeichen zurückgeben
  });

export function getFirstErrorMessage(error: ApolloError): string | undefined {
  // ApolloError is incorrectly typed 🙄
  return (error?.graphQLErrors?.[0]?.message as any)?.message;
}

export const hasEntitlement = (org: { entitlements: { key: string }[] }, key: string) => {
  return org.entitlements.some(i => i.key === key);
};

export const canReferCareType = (
  careFlows: OrganizationCareFlowFragment[],
  appView: AppView,
  careType: CareType
) => {
  const careFlow = careFlows.find(c => c.careType === careType);
  // use a record for type safety
  const appViewCheck: Record<AppView, boolean> = {
    mcp: !!careFlow && careFlow.canMcpUsersRefer,
    referral: !!careFlow && careFlow.canReferrerUsersRefer,
    oz: !!careFlow,
  };
  return appViewCheck[appView];
};

export const titleCase = (str: string) => {
  if (!str.length) return str;
  return str
    .split(' ')
    .map(i => i[0].toUpperCase() + i.slice(1))
    .join(' ');
};

export const postUploadData = (file: File, url: string, fields: string[][]) => {
  const formData = new FormData();
  fields.forEach(([name, value]) => formData.append(name, value));
  formData.append('file', file);
  return fetch(url, { method: 'POST', body: formData });
};

export const getRemainingSessions = ({
  careSessionInfo,
}: Pick<CareFlowAndSessionsFragment, 'careSessionInfo'>) => {
  if (!careSessionInfo || !careSessionInfo.flowLimit) return;

  let remaining = careSessionInfo.remainingAppts;
  const hasUnlimitedAppts = careSessionInfo.unlimitedAppts;

  if (isNil(remaining)) {
    if (!hasUnlimitedAppts) return;
    // otherwise
    remaining = Infinity;
  }
  return {
    sessionsLimit: careSessionInfo.flowLimit,
    sessionsUsed: careSessionInfo.completedAppts,
    remaining,
  };
};

export const hasAtLeastNRemainingSessions = (
  user: AdminUserQuery['adminUser'],
  careType: CareType,
  threshold: number
) => {
  const careFlow = user.careFlows.find(f => f.careType === careType);
  const remainingSessions = careFlow ? getRemainingSessions(careFlow) : null;
  // if remaining sessions is undefined, then user has unlimited sessions
  return !remainingSessions || remainingSessions.remaining >= threshold;
};

export const getProviderCareTypeLookup = (provider: Pick<Provider, 'careTypes'>) => {
  return new Set<CareType>(provider.careTypes);
};

export const getTransitionEndDate = (
  transition: NonNullable<AdminUserQuery['adminUser']['continuityOfCareTransitions']>[number]
) => {
  const completedOn = transition?.completedOn;
  if (completedOn) return moment(completedOn);
  const carePeriodEnd = transition?.user?.organization?.latestCarePeriod?.endDate;
  if (carePeriodEnd) return moment(carePeriodEnd, 'YYYY-MM-DD');
};

export const nameSorter = <T extends { name: string }>(a: T, b: T) => {
  const charSorter = (v1?: string, v2?: string) => {
    if ((!v2 && !v2) || v1 === v2) return 0;
    if (!v1) return 1;
    if (!v2) return -1;
    // uses ascii index where the ascii index of A/a is less than Z/z
    return v1 > v2 ? -1 : 1;
  };

  const [aFirst, ...aRest] = a.name.split(' ');
  const [bFirst, ...bRest] = b.name.split(' ');

  const firstSort = charSorter(aFirst[0], bFirst[0]);
  return firstSort !== 0 ? firstSort : charSorter(last(aRest)?.[0], last(bRest)?.[0]);
};

export const dateSorterFactory = <T>(
  dateFetcher: (t: T) => Nullable<Date>,
  order: 'ASC' | 'DESC' = 'ASC'
) => {
  const orderMap: Record<typeof order, -1 | 1> = {
    ASC: 1,
    DESC: -1,
  };
  return (a: T, b: T) => {
    const aDate = dateFetcher(a);
    const bDate = dateFetcher(b);
    if (!aDate) return orderMap[order];
    if (!bDate) return orderMap[order] * -1;
    return orderMap[order] * (aDate.valueOf() - bDate.valueOf());
  };
};

export function englishList(items: string[], conjunction: string = 'and') {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];
  if (items.length === 2) return `${items[0]} ${conjunction} ${items[1]}`;
  return `${items.slice(0, items.length - 1).join(', ')}, ${conjunction} ${
    items[items.length - 1]
  }`;
}

export const isReportingDisabled = (provider: CurrentProviderQuery['currentProvider']) => {
  return (
    isMcpUser(provider) &&
    hasEntitlement(provider.organizations[0], Entitlement.HideReportingDashboard)
  );
};

const timezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
] as const;

export type TimeZone = typeof timezones[number];

// Ensure that the tz matches those availble as options
export const guessTz = (fallback: TimeZone = 'America/New_York') => {
  const guess = moment.tz.guess();
  return timezones.find(tz => tz === guess) ?? fallback;
};

export const padLeft = (num: number, amount = 3) => num.toString().padStart(amount, '0');

/**
 * takes an array of falsey values, strings or objects (based on https://www.npmjs.com/package/classnames)
 *  - falsey values are removed
 *  - objects are converted into strings of truthy keys
 *  - the strings are joined with a space as css classnames
 *
 * (see tests for examples)
 */
export const cx = (...names: (string | null | undefined | false | Record<string, any>)[]) =>
  compact(names)
    .map(s => {
      if (typeof s === 'string') return s;
      return Object.entries(s)
        .reduce((acc, [key, val]) => {
          if (val) acc.push(key);
          return acc;
        }, new Array<string>())
        .join(' ');
    })
    .join(' ');

type KeyEvent = React.KeyboardEvent<any>;
export const keyDownHandler = (fns: Record<string, (e: KeyEvent) => void>) => (e: KeyEvent) => {
  if (e.key in fns) {
    e.preventDefault();
    fns[e.key](e);
  }
};

export const stringifyParamsBy = (
  obj: qs.ParsedUrlQueryInput,
  pred: (v: typeof obj['string']) => boolean
) => qs.stringify(pickBy(obj, pred));

export const camelCaseToWords = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1');
};

export const withHttp = (url: string) => {
  if (url.match(/^http[s]{0,1}:\/\//)) {
    return url;
  }
  const slashTrimmed = url.replace(/\/$/, '');
  return `https://${slashTrimmed}`;
};

export const hasName = <T extends PartialOrNulled<HasName>>(v: T): v is T & HasName => {
  return !!v.firstName && !!v.lastName;
};

export const collectAfterFirstMatchBy = <T>(arr: T[], matcher: (v: T) => boolean) => {
  type MatchState = Readonly<{ collection: T[]; matched: boolean }>;

  const seed: MatchState = {
    collection: [],
    matched: false,
  };

  return arr.reduce((state, curr) => {
    const isMatch = matcher(curr);
    if (!state.matched || !isMatch) return state;
    const newCollection = [...state.collection, curr];
    return { ...state, collection: newCollection, matched: true };
  }, seed).collection;
};

export const getNodeText = (node: any): any => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

export const visitNotes = [
  'initial-evaluation',
  'follow-up',
  'initial-evaluation-therapy',
  'follow-up-therapy',
];

export const getClickableLink = (link: string): string => {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
};
