import { Value } from 'baseui/select';
import { chain, compact, groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import {
  hasSupportingClinicianRole,
  hasReferrerRole,
  OrgWithEntitlements,
} from '../../Components/Organization/organizationUtils';
import {
  AdminGetAllocationsQuery,
  CareType,
  CreateProviderStateAllocationsModelInput,
  CurrentProviderQuery,
  Provider,
  Role,
  StateCodes,
  TimeAllocationPurpose,
  UpdateProviderStateAllocationsModelInput,
} from '../../graphQL';
import { TimeZone } from '../../utils';
import { UIProviderState } from './Availability/Availability';
import { StateAllocationMap } from './Availability/Modal/Hooks/useEditStateAllocations';
import { UIStateAllocation } from './Availability/WeekSummaryCell/WeekSummaryCell';

export function getAllocationsForWeek(
  week: (Moment | undefined)[],
  allocations: FriendlyAllocation[]
) {
  const weekAllocations = [];
  for (const day of week) {
    if (day) {
      weekAllocations.push(getAllocationsForBlock(day, allocations));
    }
  }
  return weekAllocations;
}

export function getStateAllocationsForWeek(
  week: (Moment | undefined)[],
  allocations: UIStateAllocation[],
  tz: TimeZone
) {
  // TODO: check if timezone needs to be considered here
  const startOfWeek = week.find(day => day);
  if (startOfWeek) {
    return getStateAllocationsForWeekStartDate(startOfWeek, allocations, tz);
  }
  return [];
}

export function getStateAllocationsForWeekStartDate(
  day: Moment,
  allocations: UIStateAllocation[],
  tz: TimeZone
) {
  return allocations.filter(allocation => {
    return (
      day.clone().startOf('week').isSameOrBefore(allocation.startDate) &&
      day.clone().endOf('week').isSameOrAfter(allocation.endDate)
    );
  });
}

export function calculatedUsedPooledHours(
  localStateAllocations: (
    | CreateProviderStateAllocationsModelInput
    | UpdateProviderStateAllocationsModelInput
  )[]
) {
  return localStateAllocations.reduce((result, allocation) => {
    if (allocation.maxHours) {
      result += allocation.maxHours;
    }
    return result;
  }, 0);
}

export function summarizeWeeklyStateAvailability(
  weekProviderStateAllocations: UIStateAllocation[],
  geoStates: StateCodes[]
) {
  let allocatedSponsoredText = '';
  let allocatedSponsoredIntakeText = '';
  let allocatedStateHours = 0;
  let allocatedStateIntakeHours = 0;

  const usedGeos = [];
  if (weekProviderStateAllocations.length) {
    for (const weekStateAllocation of weekProviderStateAllocations) {
      if (weekStateAllocation.maxHours || weekStateAllocation.maxHours === 0) {
        allocatedStateHours += weekStateAllocation.maxHours;
        allocatedSponsoredText += `${weekStateAllocation.providerState.state}: ${weekStateAllocation.maxHours} / `;
      }
      if (weekStateAllocation.maxIntakeHours || weekStateAllocation.maxIntakeHours === 0) {
        allocatedStateIntakeHours += weekStateAllocation.maxIntakeHours;
        allocatedSponsoredIntakeText += `${weekStateAllocation.providerState.state}: ${weekStateAllocation.maxIntakeHours} / `;
      }
      usedGeos.push(weekStateAllocation.providerState.state);
    }
  }

  for (const geoState of geoStates) {
    if (!usedGeos.includes(geoState)) {
      allocatedSponsoredText += `${geoState}: 0 / `;
      allocatedSponsoredIntakeText += `${geoState}: 0 / `;
    }
  }

  // remove trailing '/ '
  allocatedSponsoredIntakeText = allocatedSponsoredIntakeText.slice(0, -2);
  allocatedSponsoredText = allocatedSponsoredText.slice(0, -2);

  return {
    allocatedSponsoredText,
    allocatedSponsoredIntakeText,
    allocatedStateHours,
    allocatedStateIntakeHours,
  };
}

// TODO: there is a potential improvement to have a DGMFollowUpAvailability and then default should be added to both FollowUp and Intake Availability
export function summarizeWeeklyAvailability(weekAllocations: FriendlyAllocation[][]) {
  let adminAvailability = 0;
  let bookableAvailability = 0;
  let DGMAvailability = 0;
  let DGMIntakeAvailability = 0;
  let daysWithDGMAvailabilityCount = 0;

  for (const dayAllocations of weekAllocations) {
    for (const allocation of dayAllocations) {
      const allocationDuration = moment
        .duration(allocation.endTime.diff(allocation.startTime))
        .asHours();
      if (allocation.type === 'admin') {
        adminAvailability += allocationDuration;
      } else if (
        allocation.type === 'intake' ||
        allocation.type === 'checkin' ||
        allocation.type === 'default'
      ) {
        bookableAvailability += allocationDuration;
        if (!allocation.organizationId) {
          DGMAvailability += allocationDuration;
          if (allocation.type === 'intake') {
            DGMIntakeAvailability += allocationDuration;
          }
        }
      }
    }
    if (dayAllocations.length) {
      daysWithDGMAvailabilityCount += 1;
    }
  }

  return {
    adminAvailability,
    bookableAvailability,
    DGMAvailability,
    DGMIntakeAvailability,
    daysWithDGMAvailabilityCount,
  };
}

export function getAllocationsForBlock(blockDate: Moment, allocations: FriendlyAllocation[]) {
  const dateAllocs: FriendlyAllocation[] = [];
  for (const alloc of allocations) {
    const dateInTimezone = moment
      .tz(
        { date: blockDate.date(), month: blockDate.month(), year: blockDate.year() },
        alloc.timezone
      )
      .startOf('day');
    const isRecurringMatch =
      alloc.weekly &&
      alloc.startTime.isSameOrBefore(dateInTimezone, 'date') &&
      (!alloc.repeatsUntil || alloc.repeatsUntil.isSameOrAfter(dateInTimezone, 'date')) &&
      alloc.startTime.day() === dateInTimezone.day();
    const isAdHocMatch = !alloc.weekly && alloc.startTime.isSame(dateInTimezone, 'date');
    if (isRecurringMatch || isAdHocMatch) {
      dateAllocs.push(alloc);
    }
  }
  dateAllocs.sort(
    (a, b) =>
      a.startTime.hour() * 60 +
      a.startTime.minute() -
      (b.startTime.hour() * 60 + b.startTime.minute())
  );
  return dateAllocs;
}

export function prepInitialStateAllocations(
  date: Moment,
  providerStateAllocations: UIStateAllocation[],
  providerStates: UIProviderState[]
) {
  const initialState: StateAllocationMap = {};
  for (const providerState of providerStates) {
    const possibleRecord = providerStateAllocations.find(
      allocation => allocation.providerState.state === providerState.state
    );

    if (possibleRecord) {
      initialState[possibleRecord.providerState.state] = (({ id, maxHours, maxIntakeHours }) => ({
        id,
        maxHours,
        maxIntakeHours,
      }))(possibleRecord);
    } else {
      initialState[providerState.state] = {
        startDate: date.format('YYYY-MM-DD'),
        endDate: date.clone().endOf('week').format('YYYY-MM-DD'),
        maxHours: 0,
        maxIntakeHours: 0,
        providerStateId: providerState.id,
      };
    }
  }
  return initialState;
}

export function getAllocationLookupForBlocks<T extends string | number | symbol>(
  blockDates: Moment[],
  allocations: FriendlyAllocation[],
  keyBy: (d: Moment) => T
) {
  return new Map<T, FriendlyAllocation[]>(
    blockDates.map(d => [keyBy(d), getAllocationsForBlock(d, allocations)])
  );
}

export type AllocationType = 'default' | 'admin' | 'intake' | 'checkin' | 'timeOff';

export interface FriendlyAllocation {
  startTime: Moment;
  endTime: Moment;
  repeatsUntil?: Moment | null;
  id: number;
  weekly: boolean;
  isFeeForServiceTime?: boolean | null;
  organizationId?: number | null;
  type: AllocationType;
  timezone: TimeZone;
  childOrganizationIds?: number[];
  childrenCount?: number;
}

function hasAppointmentTypeOnly(allocation: { appointmentTypes?: string[] | null }, type: string) {
  return (
    allocation.appointmentTypes &&
    allocation.appointmentTypes.length === 1 &&
    allocation.appointmentTypes[0] === type
  );
}

export function isIntakeOnly(allocation: { appointmentTypes?: string[] | null }) {
  return hasAppointmentTypeOnly(allocation, 'intake');
}

export function isCheckinOnly(allocation: { appointmentTypes?: string[] | null }) {
  return hasAppointmentTypeOnly(allocation, 'checkin');
}

export function toFriendlyAllocation(
  allocation: AdminGetAllocationsQuery['adminGetAllocations'][number]
): FriendlyAllocation {
  const { timezone } = allocation;
  let type: AllocationType = 'default';
  if (allocation.purpose === TimeAllocationPurpose.Admin) {
    type = 'admin';
  } else if (allocation.purpose === TimeAllocationPurpose.TimeOff) {
    type = 'timeOff';
  } else if (isIntakeOnly(allocation)) {
    type = 'intake';
  } else if (isCheckinOnly(allocation)) {
    type = 'checkin';
  }
  return {
    ...allocation,
    startTime: moment(allocation.startTime).tz(timezone),
    endTime: moment(allocation.endTime).tz(timezone),
    repeatsUntil: allocation.repeatsUntil
      ? moment(allocation.repeatsUntil).tz(timezone)
      : undefined,
    organizationId: allocation.organization?.id,
    type,
    timezone: timezone as TimeZone,
    childOrganizationIds: allocation.childOrganizations?.map(i => i.id),
    childrenCount: allocation.organization?.childrenCount ?? 0,
  };
}

export function doAllocationTimesOverlap(allocations: FriendlyAllocation[]) {
  for (const a of allocations) {
    for (const b of allocations) {
      if (a !== b && a.startTime >= b.startTime && a.startTime < b.endTime) {
        return true;
      }
    }
  }
  return false;
}

export const getMcpRoleOptions = (org: OrgWithEntitlements) => {
  return compact([
    { id: 'university-staff', label: 'Staff' },
    hasReferrerRole(org) && { id: 'university-referrer', label: 'Referrer' },
    hasSupportingClinicianRole(org) && {
      id: 'university-supporting-clinician',
      label: 'Supporting Clinician',
    },
    { id: 'university-admin', label: 'Admin' },
  ]);
};

// Eventually deprecate the above function in favor for this one.
// We should be using the Role enum format for the Id
export const getMcpRoleOptionsV2 = (org: OrgWithEntitlements): { id: Role; label: string }[] => {
  return compact([
    { id: Role.UniversityStaff, label: 'Staff' },
    hasReferrerRole(org) && { id: Role.UniversityReferrer, label: 'Referrer' },
    hasSupportingClinicianRole(org) && {
      id: Role.UniversitySupportingClinician,
      label: 'Supporting Clinician',
    },
    { id: Role.UniversityAdmin, label: 'Admin' },
  ]);
};

export const mcpRoleOptions = [
  { id: 'university-staff', label: 'Staff' },
  { id: 'university-admin', label: 'Admin' },
];

export function groupByOrganization(allocs: FriendlyAllocation[]) {
  const grouped = groupBy(allocs, 'organization.id');
  const asArr: Array<{ organizationId: number; allocations: FriendlyAllocation[] }> = [];
  for (const [organizationId, allocations] of Object.entries(grouped)) {
    asArr.push({ organizationId: Number(organizationId), allocations });
  }
  return asArr;
}

export const getProviderOrganizationOptions = (
  provider: CurrentProviderQuery['currentProvider'],
  orgId: number
): Value => {
  return chain(provider.organizations)
    .filter(o => o.id === orgId)
    .flatMap(o => [o, ...o.children])
    .map(o => ({ id: o.id, label: o.name }))
    .value();
};

export const isTherapist = (provider: Pick<Provider, 'careTypes'>) =>
  provider.careTypes.includes(CareType.Therapy);

export const isPsychiatrist = (provider: Pick<Provider, 'careTypes'>) =>
  provider.careTypes.includes(CareType.Psychiatry);

export function isCreateProviderStateAllocationsModelInput(
  stateAllo: CreateProviderStateAllocationsModelInput | UpdateProviderStateAllocationsModelInput
): stateAllo is CreateProviderStateAllocationsModelInput {
  return (stateAllo as CreateProviderStateAllocationsModelInput).startDate !== undefined;
}

export function isUpdateProviderStateAllocationsModelInput(
  stateAllo: CreateProviderStateAllocationsModelInput | UpdateProviderStateAllocationsModelInput
): stateAllo is UpdateProviderStateAllocationsModelInput {
  return (stateAllo as UpdateProviderStateAllocationsModelInput).id !== undefined;
}
