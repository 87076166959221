import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../../Components/FinalButton';
import { TextareaRHF } from '../../Components/Form';
import { HandBookInfo } from './Handbook';

type EditInputProps = {
  handbookRowInfo: string;
  selectedField: keyof HandBookInfo;
  setSelectedField: (editFieldName: keyof HandBookInfo | null) => void;
  onSave: (input: string) => Promise<void>;
};

export const EditHandbookInput = ({
  handbookRowInfo,
  selectedField,
  setSelectedField,
  onSave,
}: EditInputProps) => {
  const closeEdit = () => {
    setSelectedField(null);
  };

  const form = useForm();
  const saveEdit = form.handleSubmit(async values => {
    await onSave(values[selectedField]);
    closeEdit();
  });

  return (
    <FormProvider {...form}>
      <div>
        <TextareaRHF
          name={`${selectedField}`}
          controlProps={{ required: true }}
          placeholder={handbookRowInfo}
          defaultValue={handbookRowInfo}
        />
        <FinalButton type="submit" onClick={saveEdit}>
          Save
        </FinalButton>
        <FinalButton onClick={closeEdit} kind="white">
          Cancel
        </FinalButton>
      </div>
    </FormProvider>
  );
};
