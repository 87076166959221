import { isEqual, mapValues, pick } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FinalButton } from '../../../Components/FinalButton';
import {
  baseInputStyles,
  DateInputRHF,
  InputRHF,
  PhoneInputRHF,
  SelectRHF,
} from '../../../Components/Form';
import { getEmailValidator } from '../../../Components/FormValidations/Validators';
import { Modal } from '../../../Components/Modal/Modal';
import { Notification } from '../../../Components/Notification';
import { colors, Text } from '../../../globalStyles';
import {
  AddressInput,
  AdminUserQuery,
  EditableUserFields,
  EditUser,
  useAdminEditUserMutation,
  useTriggerPasswordlessAuthFlowMutation,
} from '../../../graphQL';
import {
  useGeoStateAvailability,
  ValidateCareTypesAndGeoState,
} from '../../../Hooks/useGeoStateAvailability';
import { stateOptions } from '../../../states';
import { useDrilldownContext } from '../helpers';
import { Divider } from '../Widgets/Styles';
import { CareTeamFields } from './EditCareTeamInfo';
import { EditModalContainer } from './editModals';

interface EditPatientInfoProps {
  closeModal: () => void;
}

type Form = Pick<
  EditUser,
  | 'firstName'
  | 'lastName'
  | 'preferredName'
  | 'email'
  | 'birthDate'
  | 'phone'
  | 'universityCareTeamPrimary'
  | 'universityCareTeamSecondary'
  | 'mantraAdminCareTeam'
> &
  Pick<AddressInput, 'state'>;

export const pickDefaultValues = (user: AdminUserQuery['adminUser']): Form => {
  return {
    ...pick(user, 'firstName', 'lastName', 'preferredName', 'email'),
    state: user.primaryAddress?.state,
    birthDate: user.birthDate ? moment(user.birthDate).format('MM/DD/YYYY') : undefined,
    phone: user.phone ? user.phone : undefined,
    universityCareTeamPrimary: user.universityCareTeamPrimary?.id,
    universityCareTeamSecondary: user.universityCareTeam
      .filter(p => p.id !== user.universityCareTeamPrimary?.id)
      .map(p => p.id),
    mantraAdminCareTeam: user.mantraAdminCareTeam.map(a => a.id),
  };
};

export const EditMcpUser = ({ closeModal }: EditPatientInfoProps) => {
  const { user, refetch } = useDrilldownContext();
  const defaultValues = pickDefaultValues(user);

  const formContext = useForm<Form>({
    defaultValues,
  });

  const [triggerAuthFlow, { loading: l2 }] = useTriggerPasswordlessAuthFlowMutation();
  const [editUser, { loading: l1, error }] = useAdminEditUserMutation();
  const { validateCareTypesState } = useGeoStateAvailability(user.organization?.id || 0);

  const validateState =
    validateCareTypesState && user.careTypes ? validateCareTypesState(user.careTypes) : undefined;

  const submit = formContext.handleSubmit(async values => {
    // if submitted with no changes then just close the modal
    if (isEqual(defaultValues, values)) {
      closeModal();
      return;
    }

    if (values.birthDate) {
      values.birthDate = moment(values.birthDate).format('YYYY-MM-DD');
    }

    // since the MCP user can only change the state
    const newState = values.state;
    delete values.state;
    const newUserFields: EditUser = { id: user.id, ...values };
    if (newState && newState !== defaultValues.state) {
      newUserFields.primaryAddress = { state: newState };
    }

    const oldEmail = user.email;
    const { data } = await editUser({
      variables: { editUser: newUserFields },
    });
    if (!data) return;
    if (data.adminEditUser.email !== oldEmail) {
      await triggerAuthFlow({ variables: { email: data.adminEditUser.email } });
    }
    await refetch();
    closeModal();
  });

  return (
    <Modal
      isOpen
      onClose={closeModal}
      size="tiny"
      // overflow:'visible' allows dropdown content to appear outside the modal
      style={{ content: { overflow: 'visible', position: 'relative' } }}
    >
      <EditModalContainer>
        <Text.h1 className="mb3">Edit Student</Text.h1>
        {user.emailVerified ? (
          <Text.body className="mb3">
            Note: once an account has been activated, you cannot edit the student&apos;s
            identification details.
          </Text.body>
        ) : (
          <Text.body className="mb3">
            Note: you can edit a patient profile up to the point of account activation
          </Text.body>
        )}
        <div>
          {error && (
            <Notification kind="negative">{error.message ?? 'Something went wrong'}</Notification>
          )}
          <FormProvider {...formContext}>
            <form onSubmit={submit}>
              {Object.entries(formParts)
                .filter(([field]) => user.editableFields.includes(field as any))
                .map(([key, Element]) => (
                  <div key={key} className="mb4">
                    <Element validateState={validateState} />
                  </div>
                ))}
              <div className="mt4">
                <FinalButton className="w-100" type="submit" kind="primary" loading={l1 || l2}>
                  Save Changes
                </FinalButton>
              </div>
            </form>
          </FormProvider>
        </div>
      </EditModalContainer>
    </Modal>
  );
};

const ShowPreferredName = () => {
  const { user } = useDrilldownContext();
  const [showPreferredName, setShowPreferredName] = useState(user.preferredName ?? false);
  return showPreferredName ? (
    <InputRHF
      data-testid="input-preferred-name"
      name="preferredName"
      rules={{ required: false }}
      controlProps={{ label: 'Preferred Name' }}
    />
  ) : (
    <Text.linkButton className="b mb3 ml3" onClick={() => setShowPreferredName(true)}>
      + Add Preferred Name
    </Text.linkButton>
  );
};

const ShowEmail = () => {
  const { user } = useDrilldownContext();
  return (
    <>
      <Divider />
      <Text.label className="mb4">Student&apos;s Contact</Text.label>
      <StyledContainer>
        <Text.bodySmallBold>Did you misspell the email?</Text.bodySmallBold>
        <Text.bodySmall>
          Updating the patient&apos;s email address will send a new account activation link to the
          new email. Don&apos;t worry -- the previously sent activation link will no longer be
          active.
        </Text.bodySmall>
      </StyledContainer>
      <InputRHF
        type="text"
        name="email"
        rules={{
          validate: mapValues(getEmailValidator(user.organization)),
        }}
        controlProps={{ label: "Student's University Email" }}
      />
    </>
  );
};

const formParts: Record<string, (props: Record<string, unknown>) => JSX.Element | null> = {
  [EditableUserFields.LegalName]: () => (
    <>
      <InputRHF
        data-testid="input-first-name"
        name="firstName"
        rules={{ required: true }}
        controlProps={{ label: 'Legal First Name' }}
      />
      <InputRHF
        data-testid="input-last-name"
        name="lastName"
        rules={{ required: true }}
        controlProps={{ label: 'Legal Last Name' }}
      />
    </>
  ),
  [EditableUserFields.PreferredName]: () => <ShowPreferredName />,
  [EditableUserFields.DateOfBirth]: () => (
    <DateInputRHF
      name="birthDate"
      controlProps={{ label: 'Date of Birth', required: false }}
      rules={{
        validate: {
          validDate: (v: string) =>
            moment(v, 'MM/DD/YYYY').isValid() || 'Please enter a valid date.',
        },
      }}
    />
  ),
  [EditableUserFields.Email]: () => <ShowEmail />,
  [EditableUserFields.Phone]: () => (
    <PhoneInputRHF name="phone" controlProps={{ label: "Student's Phone Number" }} />
  ),
  [EditableUserFields.Address]: ({
    validateState,
  }: {
    validateState?: ReturnType<ValidateCareTypesAndGeoState>;
  }) => (
    <>
      <SelectRHF
        name="state"
        controlProps={{ label: 'State' }}
        options={stateOptions}
        rules={{
          validate: {
            validationOfState: (value: string) => {
              if (!validateState) {
                return true;
              }

              const validationResponse = validateState(value);
              if (validationResponse.passed || validationResponse.emptyCareTypes) {
                return true;
              }

              if (validationResponse.organizationFailed) {
                const { careTypes, stateName } = validationResponse.organizationFailed;
                return `Your organization is not contracted to refer students for ${careTypes} services in ${stateName}.`;
              }

              if (validationResponse.providerFailed) {
                const { careTypes, stateName } = validationResponse.providerFailed;
                return `Mantra Health is not contracted to refer students for ${careTypes} services in ${stateName}.`;
              }

              return true;
            },
          },
        }}
      />
      <Divider />
      <Text.label className="mb4">Collaboration Team</Text.label>
    </>
  ),
  [EditableUserFields.CareTeam]: () => <CareTeamFields />,
};

const StyledContainer = styled.div`
  ${baseInputStyles}
  background-color: ${colors.accent};
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;
