import { Delete } from 'baseui/icon';
import React, { useRef } from 'react';
import { Input } from '../Form/Input';
import * as Styles from './styles';

type Props = {
  value?: string[];
  onChange: (arg0: string[]) => void;
  placeholder?: {
    numbered?: boolean;
    text: string;
  };
  removeLocation?: 'left' | 'right';
};
export const EzMultitext = ({
  onChange,
  value = [],
  placeholder,
  removeLocation = 'left',
}: Props) => {
  const lastRef = useRef<HTMLInputElement | null>(null);
  const getPlaceholder = (index: number) =>
    placeholder && `${placeholder.text} ${placeholder.numbered ? `#${index + 1}` : ''}`;

  const addValue = () => {
    const newValue = [...value, ''];
    onChange(newValue);
    setImmediate(() => lastRef.current && lastRef.current.focus());
  };

  const removeValue = (index: number) => () => {
    const newValue = value.filter((_, i) => i !== index);
    onChange(newValue);
    setImmediate(() => lastRef.current && lastRef.current.focus());
  };

  const onEditIndex = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = value.map((v, i) => (i !== index ? v : e.target.value));
    onChange(newValue);
  };

  const onKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (index !== value.length - 1) return lastRef.current && lastRef.current.focus();
      if (value[value.length - 1]?.length === 0) return;
      addValue();
    }
    if (e.key === 'Backspace') {
      if (value[index]?.length === 0) removeValue(index)();
    }
  };

  return (
    <div>
      {value.map((text, index) => (
        <div
          className={`mb3 flex ${removeLocation === 'left' ? 'flex-row' : 'flex-row-reverse'}`}
          key={index}
        >
          <Styles.DeleteButton className={removeLocation} onClick={removeValue(index)}>
            <Delete size={24} color="red" />
          </Styles.DeleteButton>
          <Input
            ref={index === value.length - 1 ? lastRef : undefined}
            value={text}
            onChange={onEditIndex(index)}
            onKeyDown={onKeyDown(index)}
            placeholder={getPlaceholder(index)}
          />
        </div>
      ))}
      <Styles.AddButton onClick={addValue} data-testid="add-value-button">
        + Add
      </Styles.AddButton>
    </div>
  );
};
