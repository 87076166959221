import { Notification } from 'baseui/notification';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useRef } from 'react';
import { Scheduler } from '../../Components/Scheduler';
import { WizardWrapper } from '../../Components/Wizard';
import { BackButton } from '../../Components/Wizard/BackButton';
import { colors } from '../../globalStyles';
import { getFullName } from '../../modelUtils/users';
import { PaddedDivider } from '../../Components/Booking/Styles';
import { BookingWizardData, BookingWizardProps } from './types';

interface Props extends BookingWizardProps {
  displayAllHours?: boolean;
}

export function SelectTime({ prevStep, nextStep, data, displayAllHours }: Props) {
  const handleSelectTime = (
    time: Moment,
    provider: BookingWizardData['appointment']['provider'],
    organizationId?: number | null
  ) => {
    nextStep({
      ...data,
      didEncounterTimeConflict: false,
      appointment: { ...data.appointment, time, provider, organizationId },
    });
  };
  const startDate = useRef(moment());

  const providersAdjusted = data.providers.filter(({ careTypes }) =>
    careTypes.includes(data.appointmentTemplates![data.appointment.templateIndex!].careType)
  );

  const { patient, appointment } = data;

  const apptPaymentSource = () => {
    if (patient) {
      return patient.careFlows.find(f => {
        return f.careType === appointment.careType;
      })?.paymentSource;
    }
  };

  return (
    <div>
      <WizardWrapper>
        {!data.reschedulingAppointment && !isEmpty(data.providers) && (
          <BackButton onClick={() => prevStep()} />
        )}
        {data.didEncounterTimeConflict && (
          <Notification kind="negative" overrides={{ Body: { style: { width: '100%' } } }}>
            Sorry, the time you selected is no longer available. Please pick another time.
          </Notification>
        )}
        <h2>Select date &amp; time</h2>
        <div>
          {`Type: `}
          <strong>
            {data.appointmentTemplates![data.appointment.templateIndex!]!.description}
          </strong>
          <br />
          Duration: <strong>{data.appointment.duration} min</strong>
          <br />
          {`Patient: `}
          <strong>
            {getFullName(data.patient!)} ({data.patient!.customerId})
          </strong>
          {data.reschedulingAppointment && (
            <div style={{ color: colors.primary }}>
              {`Former Time: `}
              <strong>
                {moment(data.reschedulingAppointment.startTime).format('M/D/YYYY h:mm a')} -{' '}
                {moment(data.reschedulingAppointment.endTime).format('h:mm a')}
              </strong>
            </div>
          )}
        </div>
      </WizardWrapper>
      <PaddedDivider />
      <Scheduler
        initialStartDate={startDate.current}
        providers={providersAdjusted}
        appointment={data.appointment}
        onSelectTime={handleSelectTime}
        user={data.patient!}
        displayAllHours={displayAllHours}
        reschedulingAppointment={data.reschedulingAppointment}
        careFlowPaymentSource={apptPaymentSource()}
      />
    </div>
  );
}
