import { identity, pickBy } from 'lodash';
import qs from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type QueryParams<K extends keyof any = keyof any> = Partial<Record<K, any>>;

export function useQueryParams<K extends keyof any>() {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const parsedSearch = useMemo(() => qs.parse(search), [search]);

  const updateQueryParams = useCallback(
    (newQuery: QueryParams<K>) => {
      const truthy = pickBy(newQuery, identity);
      history.replace(`${pathname}?${qs.stringify(truthy)}`);
    },
    [history]
  );

  return [parsedSearch as QueryParams<K>, updateQueryParams] as const;
}
