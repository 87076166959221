import { isNil } from 'lodash';
import { FriendlyAllocation } from '../../../util';

export type CompleteAllocation = FriendlyAllocation;
export type NewAllocation = Omit<FriendlyAllocation, 'startTime' | 'endTime'>;
export type LocalAllocation = CompleteAllocation | NewAllocation;

export const isCompletedAllocation = (a: LocalAllocation): a is CompleteAllocation => {
  return 'startTime' in a && !isNil(a.startTime) && 'endTime' in a && !isNil(a.endTime);
};
