import { keyBy } from 'lodash';
import React from 'react';
import { Text } from '../../../globalStyles';
import { useDrilldownContext } from '../helpers';
import { Styles } from '../styles';
import * as assessmentUtils from '../../../modelUtils/assessment';

const questionnaireKeys = ['phq9', 'phq8', 'gad7', 'asrsv11', 'ymrs'];

export const AssessmentsSection = () => {
  const { user } = useDrilldownContext();
  if (!(user.baselineQuestionnaires && user.latestQuestionnaires)) return null;

  const baselines = keyBy(user.baselineQuestionnaires, 'key');
  const latests = keyBy(user.latestQuestionnaires, 'key');

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Assessments</Text.h3>
      </Styles.sidebarSectionHeader>
      {!questionnaireKeys.some(key => key in baselines) ? (
        <Text.body>No Assessments</Text.body>
      ) : (
        <Styles.sidebarTable>
          <thead>
            <tr>
              {['type', 'last', 'baseline'].map(t => (
                <th key={t}>
                  <Text.label>{t}</Text.label>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {questionnaireKeys.map(key => {
              const base = baselines[key];
              const latest = latests[key];

              // if there is base, can assert there is latest!
              if (!base) return;

              return (
                <tr key={key}>
                  <Text.bodySmall as="td">{assessmentUtils.getTitle(key)}</Text.bodySmall>
                  <Text.bodySmall as="td" className="pl2">
                    {latest.score}
                  </Text.bodySmall>
                  <Text.bodySmall as="td" kind="grayText" className="pl3">
                    {base.score}
                  </Text.bodySmall>
                </tr>
              );
            })}
          </tbody>
        </Styles.sidebarTable>
      )}
    </Styles.sidebarSection>
  );
};
