import React, { useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { SelectRHF } from '../../Components/Form';
import { Input, Textarea } from '../../Components/Form/Input';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  Entitlement,
  SamlConfigurationType,
  UpdateSamlConfiguration,
  useSamlConfigurationQuery,
  useUpdateSamlConfigurationMutation,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';

const defaultValues = {
  entryPoint: '',
  certificates: [],
  profileEmailField: 'email',
  type: SamlConfigurationType.User,
  authnRequestBinding: '',
  issuer: 'mantra-prod',
};

export function OrganizationSSOConfig() {
  const organizationId = useOrganizationId();
  const form = useForm<UpdateSamlConfiguration & { type: SamlConfigurationType }>({
    defaultValues,
  });
  const type = form.watch('type');
  const { data, loading, error, refetch } = useSamlConfigurationQuery({
    variables: { organizationId, type },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: result => {
      const { samlConfiguration } = result.organization;
      const fields = [
        'entryPoint',
        'certificates',
        'profileEmailField',
        'issuer',
        'authnRequestBinding',
      ] as const;
      fields.forEach(field =>
        form.setValue(field, samlConfiguration?.[field] ?? defaultValues[field])
      );
    },
  });
  const [save, mutation] = useUpdateSamlConfigurationMutation({ onCompleted: () => refetch() });
  const metadataRef = useRef<HTMLTextAreaElement | null>(null);

  if (!data && loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;

  if (!data.organization?.entitlements.some(i => i.key === Entitlement.Sso)) {
    return (
      <Text.body>You need to enable SSO on the entitlements tab before using this tab.</Text.body>
    );
  }

  const handleSave = form.handleSubmit(async values => {
    const update = {
      entryPoint: values.entryPoint,
      profileEmailField: values.profileEmailField,
      issuer: values.issuer,
      authnRequestBinding: values.authnRequestBinding,
      certificates: values.certificates.map(cert =>
        // strip headers, footers, and newlines from certificates
        cert.replace(/-----.*-----/g, '').replace(/\n/g, '')
      ),
    };
    save({ variables: { organizationId, type: values.type, update } });
  });

  const handleCopyMetadata = () => {
    if (metadataRef.current) {
      metadataRef.current.focus();
      metadataRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div style={{ maxWidth: 800 }} className="mb4">
      <Text.h2>SSO Configuration</Text.h2>
      <FormProvider {...form}>
        <label htmlFor="type" className="db mt4 mb2">
          <Text.bodyBold>Type</Text.bodyBold>
        </label>
        <div style={{ maxWidth: 300 }}>
          <SelectRHF
            name="type"
            options={[
              { id: SamlConfigurationType.User, label: 'Patient' },
              { id: SamlConfigurationType.Provider, label: 'MCP User' },
            ]}
          />
        </div>
        <label htmlFor="entryPoint" className="db mt4 mb2">
          <Text.bodyBold>Entry Point</Text.bodyBold>
          <Text.bodySmall>Mantra will use this URL to initiate the login flow.</Text.bodySmall>
        </label>
        <Input
          id="entryPoint"
          name="entryPoint"
          ref={form.register({ required: 'This field is required.' })}
        />
        {form.errors.entryPoint && (
          <Text.bodySmall kind="danger" className="mt1">
            {form.errors.entryPoint.message}
          </Text.bodySmall>
        )}
        <label htmlFor="certificates" className="db mt4 mb2">
          <Text.bodyBold>Certificates</Text.bodyBold>
          <Text.bodySmall>
            X.509 certificates to validate against. Include only the body with no headers or line
            breaks (or just paste with that stuff and we’ll strip it for you).
          </Text.bodySmall>
        </label>
        <Controller
          name="certificates"
          rules={{
            validate: (value: string[]) => value.length > 0 || 'You must provide a certificate.',
          }}
          render={({ value, onChange }) => (
            <EzMultirow values={value} setValues={onChange} blank="" component={Certificate} />
          )}
        />
        {form.errors.certificates && (
          <Text.bodySmall kind="danger" className="mt1">
            {(form.errors.certificates as any).message}
          </Text.bodySmall>
        )}
        <label htmlFor="profileEmailField" className="db mt4 mb2">
          <Text.bodyBold>Profile Email Field</Text.bodyBold>
          <Text.bodySmall>
            The name of the email field on the profile returned by this IdP (e.g. “email”). Mantra
            will use this field to look up the user in our database.
          </Text.bodySmall>
        </label>
        <Input
          id="profileEmailField"
          name="profileEmailField"
          ref={form.register({ required: 'This field is required.' })}
        />
        <label htmlFor="issuer" className="db mt4 mb2">
          <Text.bodyBold>Custom Issuer (optional)</Text.bodyBold>
          <Text.bodySmall>
            A custom entityID. Used in situations where the provider and patient SP must have two
            separate entityIDs. If blank, default is mantra-prod.
          </Text.bodySmall>
        </label>
        <Input id="issuer" name="issuer" ref={form.register()} />
        <label htmlFor="authnRequestBinding" className="db mt4 mb2">
          <Text.bodyBold>Custom Authn Request Binding (optional)</Text.bodyBold>
          <Text.bodySmall>
            Can be used to alter the HTTP request sent to the iDP, i.e. HTTP-POST. If blank, the
            default is HTTP GET.
          </Text.bodySmall>
        </label>
        <Input id="authnRequestBinding" name="authnRequestBinding" ref={form.register()} />
        <div className="mt3 flex flex-row items-center">
          <FinalButton onClick={handleSave} loading={mutation.loading}>
            Save
          </FinalButton>
          {mutation.data && <Text.body className="ml3">Saved.</Text.body>}
          {mutation.error && <Text.body className="ml3">An error occurred.</Text.body>}
        </div>
      </FormProvider>
      <Text.bodyBold className="mt5">Metadata</Text.bodyBold>
      <Textarea
        readOnly
        value={data.organization.samlConfiguration?.metadata}
        style={{ height: 250, fontFamily: 'monospace', wordBreak: 'break-all' }}
        ref={metadataRef}
        className="mb2"
      />
      <FinalButton onClick={handleCopyMetadata}>Copy</FinalButton>
    </div>
  );
}

function Certificate({ value, onChange }: ComponentArgs<string>) {
  return <Textarea value={value} onChange={e => onChange(e.target.value)} />;
}
