import React from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { Icon } from '../../Components/Icons';
import { McpOnly, OzOnly, ReferralOnly, useCurrentProvider } from '../../Components/Permissions';
import { WizardWrapper } from '../../Components/Wizard';
import { Text } from '../../globalStyles';
import { AppointmentTemplateFragment, BasicAppointmentUserFragment, CareType } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { Box } from '../../Components/Booking/Styles';
import { BookingWizardData, BookingWizardProps } from './types';
import { canBookIntake } from './bookingUtils';

export function Success({ data }: BookingWizardProps) {
  return (
    <WizardWrapper data-cy="appointment-sucess">
      <Icon icon="iconsCircleCheckSvg" alt="Success" className="center db" size={26} />
      <Text.h2 className="mt4">Appointment booked!</Text.h2>
      <OzOnly>
        <OzBody data={data} />
      </OzOnly>
      <McpOnly>
        <McpBody data={data} />
      </McpOnly>
      <ReferralOnly>
        <ReferralBody data={data} />
      </ReferralOnly>
    </WizardWrapper>
  );
}

type BodyProps = {
  data: BookingWizardData;
};

function OzBody({ data }: BodyProps) {
  const history = useHistory();
  const { getCopy } = useCurrentProvider();
  return (
    <div className="mt3">
      <Text.body>Your appointment has been added to your calendar.</Text.body>
      <Text.body className="mt2">
        <strong>{getFullName(data.patient!)}</strong> will receive a confirmation email with
        appointment details and a calendar invitation.
      </Text.body>
      <FinalButton
        kind="primary"
        className="w-100 mt4"
        onClick={() => history.push(`/users/${data.patient!.id}`)}
      >
        Return to {getCopy('patient')} profile
      </FinalButton>
    </div>
  );
}

function McpBody({ data }: BodyProps) {
  const history = useHistory();
  const { getCopy } = useCurrentProvider();
  return (
    <div className="mt3">
      <Text.body>Student will receive a confirmation email with appointment details.</Text.body>
      <Text.body className="mt4">
        This appointment will take place in the student&apos;s Mantra Health account. Please advise
        student to complete onboarding at{' '}
        <Text.link to="https://www.mantrahealth.com/" target="_blank">
          www.mantrahealth.com
        </Text.link>
        , including reviewing &amp; agreeing to all releases.
      </Text.body>
      <Box className="mt4">
        <Text.body>{getFullName(data.patient!)}</Text.body>
        <Text.body>
          {`${data.appointmentTemplates![data.appointment.templateIndex!]!.description} 
            (${data.appointment.duration} min) with ${data.appointment.provider!.name}`}
        </Text.body>
        <Text.body className="flex items-center">
          <Icon icon="iconsBlackApptSvg" alt="Calendar" className="mr1" />
          {data.appointment.time!.format('M/D/YYYY h:mm a')} -{' '}
          {data.appointment
            .time!.clone()
            .add(data.appointment.duration!, 'minutes')
            .format('h:mma z')}
        </Text.body>
      </Box>
      <FinalButton
        kind="primary"
        className="w-100 mt4"
        onClick={() => history.push(`/users/${data.patient!.id}`)}
      >
        Return to {getCopy('patient')} profile
      </FinalButton>
    </div>
  );
}

function ReferralBody({ data }: BodyProps) {
  const history = useHistory();
  const { getCopy } = useCurrentProvider();
  const patient = data.patient!;
  const apptTemplate = data.appointmentTemplates![data.appointment.templateIndex!];
  const careType = nextIntakeCareTypeForBooking(patient, apptTemplate);
  return (
    <div className="mt3">
      <Text.body>Student will receive a confirmation email with appointment details.</Text.body>
      <Text.body className="mt4">
        This appointment will take place in the student&apos;s Mantra Health account. Please advise
        student to complete onboarding before their appointment, including reviewing &amp; agreeing
        to all releases.
      </Text.body>
      <Box className="mt4">
        <Text.body>{getFullName(patient)}</Text.body>
        <Text.body>
          {`${data.appointmentTemplates![data.appointment.templateIndex!]!.description} 
            (${data.appointment.duration} min) with ${data.appointment.provider!.name}`}
        </Text.body>
        <Text.body className="flex items-center">
          <Icon icon="iconsBlackApptSvg" alt="Calendar" className="mr1" />
          {data.appointment.time!.format('M/D/YYYY h:mm a')} -{' '}
          {data.appointment
            .time!.clone()
            .add(data.appointment.duration!, 'minutes')
            .format('h:mma z')}
        </Text.body>
      </Box>
      {careType && (
        <FinalButton
          kind="outline_black"
          className="w-100 mt4"
          onClick={() => {
            history.push(`/book?userId=${patient.id}&careType=${careType}`);
            history.go(0);
          }}
        >
          <div className="flex flex-row justify-between w-100 items-center">
            <div>Next: Book {careType} Appointment</div>
            <Icon icon="iconsRightChevronSvg" size={16} />
          </div>
        </FinalButton>
      )}
      <FinalButton
        kind="minimal_gray"
        className="mt3 w-100"
        onClick={() => history.push(`/${getCopy('patients')}`)}
      >
        Return to {getCopy('patients')} list
      </FinalButton>
    </div>
  );
}

const nextIntakeCareTypeForBooking = (
  patient: BasicAppointmentUserFragment,
  { careType }: AppointmentTemplateFragment
) => {
  const { careTypes } = patient;

  const hasPsych = careTypes.includes(CareType.Psychiatry);
  const hasTherapy = careTypes.includes(CareType.Therapy);

  const bookedPsych = careType === CareType.Psychiatry;
  const bookedTherapy = careType === CareType.Therapy;

  const canBookPsych = canBookIntake(CareType.Psychiatry, patient);
  const canBookTherapy = canBookIntake(CareType.Therapy, patient);

  if (canBookPsych && !bookedPsych && hasPsych) {
    return CareType.Psychiatry;
  }
  if (canBookTherapy && !bookedTherapy && hasTherapy) {
    return CareType.Therapy;
  }
};
