import React from 'react';
import { TextIconSvg } from '../../Components/Flair';
import { Logo } from '../../Components/Organization/UniversityLogo';
import { colors, Text } from '../../globalStyles';
import { Maybe } from '../../graphQL';
import { formatActivityStatus } from './util';

type OrganizationTopDisplayProps = {
  organization: {
    logo?: Maybe<{ url: string }>;
    name: string;
    abbreviation: string;
    activityStatus: string;
  };
};

export function OrganizationTopDisplay({ organization }: OrganizationTopDisplayProps) {
  return (
    <div className="ph5 pv4" style={{ borderTop: '2px solid #E5E5E5' }}>
      {organization.logo && (
        <Logo src={organization.logo.url} style={{ right: '2.5em', top: 'auto' }} />
      )}

      <Text.h1>{organization.name}</Text.h1>

      <TextIconSvg icon="university" fill={colors.grey.dark}>
        <Text.bodyGrey>{organization.abbreviation}</Text.bodyGrey>
      </TextIconSvg>

      <Text.body>
        <span className="bold">Status:</span> {formatActivityStatus(organization)}
      </Text.body>
    </div>
  );
}
