import { isEmpty, isArray, memoize } from 'lodash';
import React, { ReactNode, ReactElement } from 'react';

export const findFirstTextChild = memoize((root: ReactNode) => {
  const isDiscoverable = (v: any): v is string | number | ReactElement | any[] => {
    return typeof v === 'string' || typeof v === 'number' || React.isValidElement(v) || isArray(v);
  };

  let asArray = React.Children.toArray(root).filter(isDiscoverable);
  // bfs
  while (!isEmpty(asArray)) {
    const [node, ...rest] = asArray;
    asArray = rest;

    if (typeof node === 'string') {
      return node;
    }
    if (typeof node === 'number') {
      return String(node);
    }
    if (isDiscoverable(node)) {
      const children = (isArray(node) ? node : [node.props?.children]).filter(isDiscoverable);
      asArray.push(...children);
    }
  }
});
