import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../globalStyles';

export type Tab<T> = {
  title: string;
  key: T;
  render: () => JSX.Element;
};

export type TabProps<T> = {
  tabs: Tab<T>[];
  config?: TabConfig;
  initialTab?: T;
  onChange?: (tab: T) => void;
};
export const Tabs = <T extends string | number>({
  tabs,
  initialTab,
  onChange,
  config: {
    tab: TC = DefaultTabComponent,
    tablist: TL = DefaultTabList,
    tabpanel: TP = DefaultTabPanel,
  } = {},
}: TabProps<T>) => {
  const [activeTab, setActiveTab] = useState(initialTab ?? tabs[0].key);

  const onSelectTab = (tab: T) => {
    onChange?.(tab);
    setActiveTab(tab);
  };

  return (
    <>
      <TL>
        {tabs.map(t => (
          <TC
            key={t.key}
            tab={t}
            onSelect={() => onSelectTab(t.key)}
            isActive={t.key === activeTab}
          />
        ))}
      </TL>
      {tabs.map(t => {
        const isActive = t.key === activeTab;

        if (!isActive) return null;
        return (
          <TP key={t.key}>
            <t.render />
          </TP>
        );
      })}
    </>
  );
};

export type TabConfig = {
  tab?: TabComponent;
  tablist?: TabListComponent;
  tabpanel?: TabPanelComponent;
};

export const defaults = {
  tabListHeight: '2.5rem',
};

type TabComponentProps<T> = {
  tab: Tab<T>;
  isActive: boolean;
  onSelect: () => void;
};
type TabComponent = <T>(props: TabComponentProps<T>) => JSX.Element;
const DefaultTabComponent: TabComponent = ({ tab, isActive, onSelect }) => {
  return (
    <StyledTab
      data-active={isActive}
      onClick={onSelect}
      onKeyDown={e => e.key === 'Enter' && onSelect()}
      tabIndex={0}
      role="tab"
      title={tab.title}
      className={tab.title === 'Crisis' ? 'crisis' : ''}
    >
      {tab.title}
    </StyledTab>
  );
};

const StyledTab = styled.div`
  cursor: pointer;
  color: ${colors.grayText};
  transition: color 0.15s ease-in, border-color 0.1s ease;
  border-bottom: 2px solid transparent;
  outline: none;
  padding-top: 0.35rem;

  &:before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  :hover,
  &[data-active='true'] {
    color: ${colors.black};
  }

  &[data-active='true'] {
    border-color: ${colors.black};
    font-weight: 500;
  }
`;

type TabListComponent = React.FC;
const DefaultTabList: TabListComponent = ({ children }) => {
  return <StyledTabList role="tablist">{children}</StyledTabList>;
};
const StyledTabList = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: white;
  display: flex;
  gap: 2rem;
  height: ${defaults.tabListHeight};
  border-bottom: 1px solid ${colors.grey.lightBorder};
  z-index: 1;

  .crisis {
    color: ${colors.danger};

    &:hover,
    &[data-active='true'] {
      color: ${colors.danger};
    }

    &[data-active='true'] {
      border-color: ${colors.danger};
      font-weight: 500;
    }
  }
`;

export type TabPanelComponent = React.FC;
const DefaultTabPanel: TabPanelComponent = ({ children }) => {
  return <div role="tabpanel">{children}</div>;
};
