import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { compact } from 'lodash';
import { FinalButton } from '../../Components/FinalButton';
import { CopyToClipboard, TextIcon } from '../../Components/Flair';
import { Grid } from '../../Components/Grid';
import { Icon, InlineSVG } from '../../Components/Icons';
import { MantraSpinner } from '../../Components/LoadingOverlay';
import { FeatureFlagged, isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import { mediaScreenFeatures } from '../../Components/Video/common';
import { VideoPageIconButton } from '../../Components/Video/VideoComponents';
import { useVideoContextV2 } from '../../Components/Video/VideoProvider';
import { When } from '../../Components/When';
import { colors, Text } from '../../globalStyles';
import {
  AddressFragment,
  useNotifyOrganizationCrisisTeamMutation,
  useVideoCallSidebarQuery,
} from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { Nullable } from '../../types';
import { cx, formatPhoneNumber, withHttp } from '../../utils';
import { AgeAndPronouns } from '../Users/Profile';
import { areCrisisHoursActive } from './videoCallUtils';
import { CrisisProtcolModal } from './CrisisProtocolModal';
import { VideoCallPageProps } from './VideoCallPage';
import { Tabs } from '../../Components/Tabs';
import { Flag } from '../../featureFlags';
import { StudentTab } from './SidebarTabs/StudentTab';
import { CrisisTab } from './SidebarTabs/CrisisTab';
import { SafeOperatingHandbookTab } from './SidebarTabs/SafeOperatingHandbookTab';

const ONE_SECOND = 1000; // in MS

type VideoCallSidebarProps = {} & Pick<VideoCallPageProps, 'appointment'>;

export const VideoCallSidebar = (props: VideoCallSidebarProps) => {
  const { featureFlags } = useCurrentProvider();
  return featureFlags.includes(Flag.CscPartTwo) ? (
    <VideoCallSidebarV2 {...props} />
  ) : (
    <VideoCallSidebarV1 {...props} />
  );
};

export const VideoCallSidebarV2 = ({ appointment }: VideoCallSidebarProps) => {
  const { data } = useVideoCallSidebarQuery({ variables: { id: appointment.user.id } });

  const [hidden, setHidden] = useState(window.innerWidth < 744);

  if (!data)
    return (
      <VideoSidebarContainer className={cx({ hidden })}>
        <MantraSpinner simple className="center mt5" />
      </VideoSidebarContainer>
    );

  const TabPanelOverride: React.FC = ({ children }) => (
    <div className="overflow-y-auto flex-1">{children}</div>
  );

  return (
    <>
      <FinalButton onClick={() => setHidden(false)} kind="white" pill>
        <div className="flex gap-2 items-center">
          <InlineSVG icon="expand" kind="black" width={16} height={16} />
          <SideBarButtonText className="nowrap">Open Sidebar</SideBarButtonText>
        </div>
      </FinalButton>
      {!hidden && (
        <VideoSidebarContainerV2>
          <div className="flex justify-end nb3">
            <VideoPageIconButton
              onClick={() => setHidden(true)}
              size="tiny"
              toggled
              icon={{ icon: 'collapse', kind: 'black' }}
            />
          </div>
          <div className="h-100 flex flex-column mt2 items-stretch">
            <Tabs
              initialTab="patient"
              config={{ tabpanel: TabPanelOverride }}
              tabs={compact([
                {
                  title: 'Patient',
                  key: 'patient',
                  render: () =>
                    StudentTab({
                      data,
                      appointment,
                    }),
                },
                {
                  title: 'Handbook',
                  key: 'handbook',
                  render: () => SafeOperatingHandbookTab({ data }),
                },
                {
                  title: 'Crisis',
                  key: 'crisis',
                  render: () => CrisisTab({ appointment }),
                },
              ])}
            />
          </div>
        </VideoSidebarContainerV2>
      )}
    </>
  );
};

export const VideoCallSidebarV1 = ({ appointment }: VideoCallSidebarProps) => {
  const { openPathWithPopover } = useVideoContextV2();
  const { currentProvider } = useCurrentProvider();

  const { data } = useVideoCallSidebarQuery({ variables: { id: appointment.user.id } });
  const [hidden, setHidden] = React.useState(true);

  if (!data)
    return (
      <VideoSidebarContainer className={cx({ hidden })}>
        <MantraSpinner simple className="center mt5" />
      </VideoSidebarContainer>
    );

  const { user } = data;

  const fallBack = 'unspecified';
  const emergContact = user.emergencyContacts.find(contact => contact.primary);
  const displayInfo = [
    {
      label: 'dob',
      value: user.birthDate ? moment(user.birthDate).format('MM/DD/YYYY') : fallBack,
    },
    {
      label: 'address',
      value: user.primaryAddress ? formatUserAddress(user.primaryAddress) : fallBack,
    },
    { label: 'phone', value: user.phone ? formatPhoneNumber(user.phone) : fallBack },
    {
      label: 'Emerg. Contact',
      value: emergContact ? `${emergContact.name} ${emergContact.phoneNumber}` : fallBack,
    },
  ];

  const bookPath = `/book?userId=${user.id}&providerId=${currentProvider.id}`;
  const { safeOperatingHandbookUrl } = data.user.organization ?? {};

  return (
    <>
      <FinalButton onClick={() => setHidden(false)} kind="white" pill>
        <div className="flex gap-2 items-center">
          <InlineSVG icon="expand" kind="black" width={16} height={16} />
          <SideBarButtonText className="nowrap">Open Sidebar</SideBarButtonText>
        </div>
      </FinalButton>
      <VideoSidebarContainer className={cx({ hidden })}>
        <div className="flex justify-end nb3">
          <VideoPageIconButton
            onClick={() => setHidden(true)}
            size="tiny"
            toggled
            icon={{ icon: 'collapse', kind: 'black' }}
          />
        </div>
        <section>
          <Text.h2 className="mv0">{getFullName(user)}</Text.h2>
          {user.customerId && (
            <CopyToClipboard copyContent={user.customerId} size={20}>
              <Text.body kind="grayText">{user.customerId}</Text.body>
            </CopyToClipboard>
          )}
          <AgeAndPronouns user={user} />
          {user.organization && (
            <Text.body kind="grayText">
              <TextIcon opacity={0.6} preset="university">
                {user.organization.name}
              </TextIcon>
            </Text.body>
          )}
        </section>
        <section>
          <Grid gridTemplateColumns="4.25rem 1fr" gap=".35rem 1rem" className="mb2">
            {displayInfo.map(({ label, value }) => (
              <React.Fragment key={label}>
                <Text.label>{label}</Text.label>
                <Text.body
                  className="mt0"
                  style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                >
                  {value}
                </Text.body>
              </React.Fragment>
            ))}
          </Grid>
          <FinalButton
            className="w-100 tl"
            iconLeft={{ icon: 'iconsUserSvg', size: 20 }}
            kind="minimal_black"
            onClick={() => openPathWithPopover(`/users/${user.id}`)}
          >
            View Full Patient Chart
          </FinalButton>
        </section>
        <section>
          <Text.h3 className="mv0">In-Call Actions</Text.h3>
          <Text.body>Note: Call will remain active</Text.body>
          <FinalButton
            className="w-100 tl"
            iconLeft={{ icon: 'iconsBlackApptSvg', size: 20 }}
            kind="minimal_black"
            onClick={() => openPathWithPopover(bookPath)}
          >
            Schedule Appointment
          </FinalButton>
          {safeOperatingHandbookUrl && (
            <>
              <FinalButton
                className="w-100 tl"
                iconLeft={{ icon: 'iconsBlackHandbookSvg', size: 20 }}
                kind="minimal_black"
                // eslint-disable-next-line
                onClick={() => window.open(withHttp(safeOperatingHandbookUrl), '_blank')}
              >
                View Safe Operating Handbook*
              </FinalButton>
              <Text.body className="ml3">* Opens in a new tab</Text.body>
            </>
          )}
        </section>
        <FeatureFlagged flag="CRISIS_TEAM">
          <CrisisModeSection appointment={appointment} />
        </FeatureFlagged>
      </VideoSidebarContainer>
    </>
  );
};

type CrisisModeSectionProps = Pick<VideoCallPageProps, 'appointment'>;

export const CrisisModeSection = ({ appointment }: CrisisModeSectionProps) => {
  const { adminCrisisTeamNotifiedAt, adminHostCrisisId, user: apptUser } = appointment;

  const crisisTeamProviders = apptUser.organization?.crisisTeamProviders ?? [];
  const organizationCrisisProviders = crisisTeamProviders.filter(p => !isMantraAdmin(p));
  const crisisHours = apptUser.organization?.currentCrisisHours;

  const [crisisId, setCrisisId] = useState<Nullable<string>>(adminHostCrisisId);
  const [teamNotifiedAt, setTeamNotifiedAt] = useState<Nullable<Date>>(adminCrisisTeamNotifiedAt);
  const [crisisModalOpen, setCrisisModalOpen] = useState(false);
  const [crisisHoursActive, setCrisisHoursActive] = useState(
    !!crisisHours && areCrisisHoursActive(crisisHours)
  );

  const [inviteCrisisTeam, { loading: loadingInviteCrisisTeam }] =
    useNotifyOrganizationCrisisTeamMutation({
      ignoreResults: true,
      onCompleted: () => setTeamNotifiedAt(new Date()),
    });

  /*
   * We want to ensure that the crisisTeam is available to the provider
   * if their hours start mid-way through the call (check every 1.5min)
   */
  useEffect(() => {
    if (!crisisHours) return;
    const intervalId = setInterval(
      () => setCrisisHoursActive(areCrisisHoursActive(crisisHours)),
      1.5 * 60 * ONE_SECOND
    );
    return () => clearInterval(intervalId);
  }, [setCrisisHoursActive, crisisHours]);

  if (!crisisId) {
    return (
      <>
        <CrisisProtcolModal
          isOpen={crisisModalOpen}
          onClose={() => setCrisisModalOpen(false)}
          onSuccess={id => {
            setCrisisId(id);
            setCrisisModalOpen(false);
          }}
          appointmentId={appointment.id}
          crisisHours={crisisHours}
          organizationCrisisProviders={organizationCrisisProviders}
        />
        <section>
          <div className="flex flex-row gap-2 items-center">
            <Icon icon="iconsAlertSvg" alt="Alert" size={18} />
            <Text.linkButton kind="danger" onClick={() => setCrisisModalOpen(true)}>
              Engage Crisis Protocol
            </Text.linkButton>
          </div>
        </section>
      </>
    );
  }
  return (
    <section>
      <CrisisModeStepContainer>
        <Icon icon="iconsCircleCheckFilledSvg" size={18} />
        <div className="nt2">
          <Text.bodyBold>Mantra Health on standby</Text.bodyBold>
          <div className="flex flex-row justify-between gap-3 items-center">
            <Text.body>Coordinate with Mantra on Slack</Text.body>
            <Icon
              icon="iconsExternalLinkSvg"
              className="pointer"
              size={13}
              onClick={() =>
                window.open(
                  'https://wellroundprov-drj6019.slack.com/archives/C02KTDNA6TB',
                  '_blank'
                )
              }
            />
          </div>
        </div>
      </CrisisModeStepContainer>
      <When isTruthy={teamNotifiedAt || (organizationCrisisProviders.length && crisisHoursActive)}>
        <CrisisModeStepContainer>
          {teamNotifiedAt ? (
            <Icon icon="iconsCircleCheckFilledSvg" size={18} />
          ) : (
            <IncompleteCircle size={18} />
          )}
          <div className="nt2">
            <Text.bodyBold>Invite Crisis Team</Text.bodyBold>
            {!teamNotifiedAt ? (
              <>
                <Text.body>
                  Doing so will allow an on-call campus crisis team member to join this call.
                </Text.body>
                <FinalButton
                  kind="danger"
                  pill
                  className="w-100 mt3"
                  loading={loadingInviteCrisisTeam}
                  onClick={() => inviteCrisisTeam({ variables: { crisisId } })}
                >
                  Invite Crisis Team
                </FinalButton>
              </>
            ) : (
              <CrisisTeamNotifiedAgo teamNotifiedAt={teamNotifiedAt} />
            )}
          </div>
        </CrisisModeStepContainer>
      </When>
    </section>
  );
};

const CrisisTeamNotifiedAgo = ({ teamNotifiedAt }: { teamNotifiedAt: Date }) => {
  const [teamNotifiedAgo, setTeamNotifiedAgo] = useState(moment(teamNotifiedAt).fromNow());

  useEffect(() => {
    const intervalId = setInterval(
      () => setTeamNotifiedAgo(moment(teamNotifiedAt).fromNow()),
      ONE_SECOND
    );
    return () => clearInterval(intervalId);
  }, [setTeamNotifiedAgo, teamNotifiedAt]);

  return <Text.body>Crisis team alerted {teamNotifiedAgo}</Text.body>;
};

export const formatUserAddress = (address: AddressFragment) => {
  return `${address.line1} ${address.line2} ${address.city}, ${address.state} ${address.zip}`;
};

const SideBarButtonText = styled.span`
  @media only screen and (${mediaScreenFeatures.mobile}) {
    display: none;
  }
`;

const IncompleteCircle = styled.div<{ size: number }>`
  flex-shrink: 0;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: 1px solid ${colors.grey.lightBorder};
  border-radius: 50%;
`;

const CrisisModeStepContainer = styled.div`
  padding: 1em 0.125em;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  border-top: 1px solid ${colors.grey.lightBorder};
`;

const VideoSidebarContainer = styled.aside`
  overflow-y: auto;
  position: fixed;
  inset: 0 auto 0 0;
  width: min(26rem, 80%);
  background-color: white;
  padding: 1rem 1.25rem 1rem 1.25rem;
  box-shadow: 0 0 5px 0 gray;
  z-index: 2; // one layer above controls

  transition: transform 0.4s;
  will-change: transform;

  & section {
    margin-bottom: 3rem;
  }

  &.hidden {
    transform: translateX(-28rem);

    & section {
      display: none;
    }
  }
`;

const VideoSidebarContainerV2 = styled.aside`
  overflow-y: auto;
  position: fixed;
  inset: 0 auto 0 0;
  width: min(26rem, 80%);
  background-color: white;
  padding: 1rem 1.25rem 1rem 1.25rem;
  box-shadow: 0 0 5px 0 gray;
  z-index: 2; // one layer above controls

  transition: transform 0.4s;
  will-change: transform;

  & section {
    margin-bottom: 2rem;
  }

  &.hidden {
    transform: translateX(-28rem);

    & section {
      display: none;
    }
  }
`;
