export enum Flag {
  Seed = 'SEED',
  Provider2FactorVerification = 'PROVIDER_2FACTOR_VERIFICATION',
  DebugTools = 'DEBUG_TOOLS',
  CrisisTeam = 'CRISIS_TEAM',
  AdminOverrideAppointment = 'ADMIN_OVERRIDE_APPOINTMENT',
  MCPBookingV2 = 'MCP_BOOKING_V2',
  TicketCategory = 'TICKET_CATEGORY',
  SchedulingV2 = 'SCHEDULING_V2',
  AvailabilityTagging = 'AVAILABILITY_TAGGING',
  CocR2 = 'COC22_R2',
  DgmR1 = 'DGM_R1',
  MCPShowDGM = 'MCP_SHOW_DGM',
  CtcReferralsTab = 'CTC_REFERRALS_TAB',
  CtcEnhancedCollaboration = 'CTC_ENHANCED_COLLABORATION',
  CscHandbook = 'CSC_HANDBOOK',
  CscPartTwo = 'CSC_PART_TWO',
  DemoReportingNumbers = 'DEMO_REPORTING_NUMBERS',
}
