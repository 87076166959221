import React, { useEffect } from 'react';
import { NextSteps, useCreateCareTransitionMutation } from '../../../../graphQL';
import { FinalButton } from '../../../../Components/FinalButton';
import { useDrilldownContext } from '../../helpers';
import { Text } from '../../styles';
import { COCPathProps } from './types';

export function OnHold({ onClose, careType }: COCPathProps) {
  const { user, refetch } = useDrilldownContext();
  const [mutate, { error }] = useCreateCareTransitionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    const transition = {
      careType,
      userId: user.id,
      nextSteps: NextSteps.OnHold,
    };
    mutate({ variables: { transition } });
  }, [careType, user, mutate]);

  return (
    <div>
      {!error ? (
        <div>
          <Text.h2 className="mt3 mb3">Confirmed! Next steps set to On Hold</Text.h2>
          <Text.body className="mb3">
            When the patient&apos;s sponsored care has ended, we will pause their care.
          </Text.body>
          <Text.bodyBold className="mb4">
            Please complete a External Referral: Case Summary note if necessary.
          </Text.bodyBold>
          <div className="tc">
            <FinalButton kind="minimal_gray" onClick={onClose} className="mt1 mb3">
              Close
            </FinalButton>
          </div>
        </div>
      ) : (
        <Text.h2 className="mb3">
          There was an error while trying to set the patient&apos;s account to On Hold.
        </Text.h2>
      )}
    </div>
  );
}
