import { isEmpty, keyBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Text } from '../../globalStyles';
import { CalendarEvent, PaymentSource, User } from '../../graphQL';
import { BookingWizardData } from '../../Pages/Booking/types';
import { Nullable } from '../../types';
import { getWeek, guessTz, TimeZone } from '../../utils';
import { BookableHoursTag } from '../DedicatedGroupModel/BookableHoursTag';
import { useOrganizationDGMConstraints } from '../DedicatedGroupModel/useOrganizationDGMConstraints';
import { getSpanned } from '../GridSpan';
import { InlineSVG } from '../Icons';
import { OrgWithEntitlements } from '../Organization/organizationUtils';
import { Tooltip } from '../Tooltip';
import { When } from '../When';
import { CalendarRangePicker } from './CalendarRangePicker';
import { TimePicker } from './TimePicker';

const DATE_FORMAT = 'YYYY-MM-DD';

type SchedulerProps = {
  initialStartDate: Moment;
  providers: BookingWizardData['providers'];
  user: Pick<User, 'id'> & { organization?: Nullable<OrgWithEntitlements<'id'>> };
  careFlowPaymentSource?: PaymentSource;
  appointment: BookingWizardData['appointment'];
  onSelectTime: (
    time: Moment,
    provider: BookingWizardData['appointment']['provider'],
    organizationId?: number | null
  ) => void;
  displayAllHours?: boolean;
  reschedulingAppointment?: Pick<CalendarEvent, 'id'>;
};

export const moveToWeekStart = (m: Moment) =>
  m.clone().day(0).hour(0).minute(0).second(0).millisecond(0);

export function Scheduler({
  initialStartDate,
  providers,
  appointment,
  onSelectTime,
  user,
  displayAllHours,
  reschedulingAppointment,
  careFlowPaymentSource,
}: SchedulerProps) {
  const [startDate, setStartDate] = useState(moveToWeekStart(initialStartDate));
  const [timezone, setTimezone] = useState<TimeZone>(guessTz());
  const [soonerAvailabilityModalState, setSoonerAvailabilityModalState] = useState(false);
  const handleChangeWeek = (increment: number) =>
    setStartDate(startDate.clone().add(increment, 'weeks'));

  const days = useMemo(() => getWeek(startDate), [startDate]);
  const { careType, appointmentType, duration } = appointment;

  const isOrganizationCareFlow = Boolean(
    careType && careFlowPaymentSource === PaymentSource.Organization
  );

  const { remainingHours, organizationName, dedicatedGroupModelActive } =
    useOrganizationDGMConstraints({
      variables: {
        organizationId: user.organization?.id!,
        userId: user?.id,
        careType: careType!,
        appointmentType: appointmentType!,
        start: days[0].toISOString(),
        end: days[days.length - 1].toISOString(),
        isOrganizationCareFlow,
      },
      skip: !user.organization || !careType || !appointmentType || isEmpty(days),
    });

  // TODO: [19324] Fix- if there is no future availability or if DGM availability is further out than the provider availability.
  const nextAvailableProviderAppointment = () => {
    if (providers[0]?.upcomingAvailability?.[0]) {
      return new Date(providers[0].upcomingAvailability[0].start);
    }
  };

  const nextAvailableProviderAppointmentDate = nextAvailableProviderAppointment();

  const hoursForRanges = useMemo(() => {
    if (!remainingHours) return [];
    const remainingHoursLookup = keyBy(remainingHours, r => r.date);
    const groupedByCount = getSpanned({
      endIdx: days.length - 1,
      getVal: i => {
        const day = days[i];
        return remainingHoursLookup[day.format(DATE_FORMAT)]?.remainingHours || 0;
      },
    });
    const withDays = groupedByCount.map(g => ({
      remainingHours: g.val,
      start: days[g.startIdx],
      end: days[g.startIdx + g.span - 1],
    }));
    return withDays.length <= 1 ? withDays : withDays.filter(v => v.remainingHours > 0);
  }, [days, remainingHours]);

  const dgmHoursDepleted =
    dedicatedGroupModelActive &&
    !isEmpty(hoursForRanges) &&
    hoursForRanges.every(r => r.remainingHours * 60 < duration!);

  // For DGM, automatically move to the first date (once!) where the org has hours and the provider has availability
  const [movedToFirstAvailability, setMovedToFirstAvailability] = useState(false);
  useEffect(() => {
    if (
      !movedToFirstAvailability &&
      dedicatedGroupModelActive &&
      dgmHoursDepleted &&
      nextAvailableProviderAppointmentDate
    ) {
      setMovedToFirstAvailability(true);
      setStartDate(
        moment(startDate).add(
          moment(nextAvailableProviderAppointmentDate).diff(moment(startDate), 'weeks'),
          'weeks'
        )
      );
    }
  }, [
    dedicatedGroupModelActive,
    nextAvailableProviderAppointmentDate,
    dgmHoursDepleted,
    movedToFirstAvailability,
  ]);

  return (
    <div>
      <CalendarRangePicker
        startDate={startDate}
        timeZone={timezone}
        onChangeStartDate={date => setStartDate(moveToWeekStart(date))}
        onChangeTimeZone={tz => setTimezone(tz)}
        showTimeZone
      >
        <When isTruthy={dedicatedGroupModelActive && !isEmpty(hoursForRanges)}>
          <BookableHours
            hoursForRange={hoursForRanges}
            appointmentType={appointment.appointmentType || ''}
            appointmentDurationMinutes={appointment.duration || 0}
          />
        </When>
      </CalendarRangePicker>
      <div className="relative">
        <TimePicker
          startDate={startDate}
          timezone={timezone}
          onChangeWeek={handleChangeWeek}
          providers={providers}
          appointment={appointment}
          onSelectTime={onSelectTime}
          user={user}
          displayAllHours={displayAllHours}
          reschedulingAppointment={reschedulingAppointment}
          organizationName={organizationName}
          nextAvailableDGMDate={nextAvailableProviderAppointmentDate}
          soonerAvailabilityModalState={soonerAvailabilityModalState}
          setSoonerAvailabilityModalState={setSoonerAvailabilityModalState}
          onSelectStartDate={v => setStartDate(v.startOf('week'))}
          dedicatedGroupModelActive={dedicatedGroupModelActive}
          dgmHoursDepleted={dgmHoursDepleted}
        />
      </div>
    </div>
  );
}

type BookableHoursProps = {
  appointmentType: string;
  appointmentDurationMinutes: number;
  hoursForRange: {
    remainingHours: number;
    start: Moment;
    end: Moment;
  }[];
};

const BookableHours = ({
  appointmentDurationMinutes,
  hoursForRange,
  appointmentType,
}: BookableHoursProps) => (
  <div>
    {hoursForRange.map(h => (
      <div key={h.start.format()} className="flex flex-row gap-3">
        <div className="flex flex-column" style={{ alignItems: 'baseline' }}>
          <Text.bodySmall className="mv0">
            {h.start.format('M/D/YY')} - {h.end.format('M/D/YY')}
          </Text.bodySmall>
          <div className="flex flex-row gap-1 align-center">
            <Text.bodySmall className="mv0">
              {' '}
              {appointmentType === 'intake' ? 'Intakes' : 'Hours'} Bookable
            </Text.bodySmall>
            <Tooltip
              content={
                appointmentType === 'intake'
                  ? "Intakes Bookable: Your organization's remaining balance of intakes that can be booked for students in the given week"
                  : "Hours Bookable: Your organization's remaining balance of hours that can be used to book sessions for students in the given week."
              }
            >
              <InlineSVG icon="alert-circle" size={15} />
            </Tooltip>
          </div>
        </div>
        <BookableHoursTag
          className="flex-1"
          count={h.remainingHours}
          kind={appointmentDurationMinutes / 60 > h.remainingHours ? 'grayText' : undefined}
        />
      </div>
    ))}
  </div>
);
