import { Dictionary, entries, isNil, keyBy } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Select } from '../../Components/Form';
import { SynchronousFilter } from '../../Components/ListView';
import { SortIndicator } from '../../Components/ListView/SortIndicator';
import { TableControlWrapper } from '../../Components/ListView/styles';
import { colors, Text } from '../../globalStyles';
import { SortDirection } from '../../modelUtils/users';

type OptionVal = any | null | undefined;

type Props<T> = {
  data: T[];
  columns: Column<T>[];
  getKey: (arg0: T) => string | number;
  emptyState?: JSX.Element;
  filters?: SynchronousFilter<T>[];
  initialSortDir?: SortDirection;
};

type Sorter<T> = (a: T, b: T) => number;

type Column<T> = {
  render: (arg0: T) => React.ReactNode;
  initialSort?: boolean;
  sort?: Sorter<T>;
  title?: string;
  style?: React.CSSProperties;
};

export function DashboardTable<T>({
  data,
  columns,
  getKey,
  emptyState,
  filters = [],
  initialSortDir = 'desc',
}: Props<T>) {
  const showTitles = columns.some(c => c.title);
  const filterLookup = keyBy(filters, f => f.key);

  const initialSorterIdx = columns.reduce((initialIdx, col, i) => {
    const isValidSort = isNil(initialIdx) && col.sort;
    const isInitialSort = col.initialSort && col.sort;
    return isInitialSort || isValidSort ? i : initialIdx;
  }, null as number | null);

  const [sortDir, setSortDir] = useState<SortDirection>(initialSortDir);
  const [sorterIdx, setSorterIdx] = useState<number | null>(initialSorterIdx);
  const [filterVals, setFilterVals] = useState<Dictionary<OptionVal>>({});

  const clickSortIndicatorFactory = (idx: number) => {
    const col = columns[idx];
    if (!col?.sort) return;
    return () => {
      setSortDir(d => (d === 'asc' ? 'desc' : 'asc'));
      setSorterIdx(idx);
    };
  };

  const getSortedData = () => {
    if (isNil(sorterIdx)) return data;
    const sorter = columns[sorterIdx]?.sort;
    if (!sorter || !sortDir) return data;
    return data.sort(sortDir === 'desc' ? (a, b) => -sorter(a, b) : sorter);
  };

  let sortedData = getSortedData();

  const predicates = entries(filterVals).reduce((acc, [k, v]) => {
    return v ? [...acc, d => filterLookup[k].test(d, String(v))] : acc;
  }, new Array<(d: T) => boolean>());

  if (predicates.length) {
    sortedData = sortedData.filter(d => predicates.every(p => p(d)));
  }

  const Filters = () => (
    <div className="flex flex-wrap">
      {filters.map(filter => {
        const value = filterVals[filter.key];
        return (
          <TableControlWrapper key={filter.key} className="flex-1">
            <Select
              placeholder={filter.placeholder}
              options={filter.options}
              onChange={val => setFilterVals(v => ({ ...v, [filter.key]: val }))}
              value={value}
              clearable
            />
          </TableControlWrapper>
        );
      })}
    </div>
  );

  if (emptyState && sortedData.length === 0)
    return (
      <>
        <Filters />
        {emptyState}
      </>
    );

  return (
    <>
      <Filters />
      <TableContainer>
        <Table>
          {showTitles && (
            <thead>
              <tr>
                {columns.map((c, i) => {
                  const title = <Text.label>{c.title}</Text.label>;
                  return (
                    <th key={i} onClick={clickSortIndicatorFactory(i)}>
                      {c.sort ? (
                        <SortIndicator direction={(i === sorterIdx && sortDir) ?? false}>
                          {title}
                        </SortIndicator>
                      ) : (
                        title
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {sortedData.map(item => {
              const key = getKey(item);
              return (
                <tr key={key}>
                  {columns.map((col, idx) => (
                    <td style={col.style} key={`${key}-${idx}`}>
                      {col.render(item)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
}

const TableContainer = styled.div`
  margin: 0.5rem 0px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.25rem;

  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
    linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
    radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%),
    radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
`;

const Table = styled.table`
  width: 100%;
  min-width: 40rem;
  border-collapse: collapse;
  table-layout: fixed;

  th {
    position: sticky;
    top: 0;
    text-align: left;
    background-color: white;
    z-index: 1;
  }

  th,
  td {
    margin: 0px;
    padding: 0.5em;

    &:first-of-type {
      padding-left: 0px;
    }
  }

  tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.grey.light};
    }
  }
`;
