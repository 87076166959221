import { Entitlement, Organization, OrganizationEntitlement } from '../../graphQL';

export type OrgWithEntitlements<OrgKey extends keyof Organization | undefined = undefined> =
  (OrgKey extends keyof Organization ? Pick<Organization, OrgKey> : {}) & {
    entitlements: Pick<OrganizationEntitlement, 'key' | 'config'>[];
  };

export const hasReferrerRole = (org: OrgWithEntitlements) => {
  return org.entitlements.some(e => e.key === Entitlement.ReferrerRole);
};

export const hasSupportingClinicianRole = (org: OrgWithEntitlements) => {
  return org.entitlements.some(e => e.key === Entitlement.SupportingClinicianRole);
};

export const hasDedicatedGroupModel = (org: OrgWithEntitlements, featureFlags: string[]) => {
  return (
    featureFlags.includes('DGM_R1') &&
    org.entitlements.some(e => e.key === Entitlement.DedicatedGroupModel)
  );
};
