import { Tab, Tabs } from 'baseui/tabs';
import React, { useState } from 'react';
import { useEvents } from '../../Components/Events/EventsProvider';
import { useCurrentProvider } from '../../Components/Permissions';
import { Overrides } from '../Users/baseuiOverrides';
import { SettingsPageTitle } from './SettingsPageTitle';
import { settingsTabs } from './SettingsTabs';

type Tabkeys = 'profile' | 'notifications' | 'availability' | 'faq';

export function ProviderSettings() {
  const { track } = useEvents();
  const providerContext = useCurrentProvider();
  const [activeTab, setActiveTab] = useState<Tabkeys>('profile');

  const permissibleTabs = settingsTabs.filter(t => t.condition(providerContext));

  const handleActiveTabChanged = (newTab: Tabkeys) => {
    if (!permissibleTabs.some(t => t.key === newTab)) return;
    setActiveTab(newTab);
    track(`settings.tab.changed`, { tab: newTab });
  };

  return (
    <div>
      <SettingsPageTitle />
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => handleActiveTabChanged(activeKey as Tabkeys)}
        overrides={Overrides.Tabs}
      >
        {permissibleTabs.map(sub => (
          <Tab title={sub.text} key={sub.key} overrides={Overrides.Tab}>
            <sub.component />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
