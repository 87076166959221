import { toaster } from 'baseui/toast';
import { pick, pickBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { TextIconSvg } from '../../Components/Flair';
import {
  InputRHF,
  NestedSelectRHF,
  RadioRHF,
  RenderOption,
  SelectRHF,
  TextareaRHF,
} from '../../Components/Form';
import { GenericSmallModal } from '../../Components/Modal';
import { FeatureFlagged, useCurrentProvider } from '../../Components/Permissions';
import { Tooltip } from '../../Components/Tooltip';
import { colors, Text } from '../../globalStyles';
import {
  EditTicketInput,
  PossibleAssigneesQuery,
  TicketAssignOption,
  TicketPriority,
  TicketQuery,
  TicketStatus,
  TicketUserFragment,
  useCreateTicketMutation,
  useEditTicketMutation,
  usePossibleAssigneesQuery,
  useTicketFormQuery,
} from '../../graphQL';
import { compareLastNames, getFullNameReversed } from '../../modelUtils/users';
import { ticketCategoryOptions } from './helpers';

type Ticket = TicketQuery['ticket'];

type TaskFormProps = {
  ticket?: Ticket;
  onComplete: (shouldClose: boolean) => void;
  onCancel: () => void;
  viewOnly: boolean;
  lockUser?: TicketUserFragment;
  defaultValues?: Record<string, any>;
};

export const TaskForm = ({
  ticket,
  viewOnly,
  lockUser,
  onCancel,
  onComplete,
  defaultValues,
}: TaskFormProps) => {
  const [createTicket] = useCreateTicketMutation();
  const [editTicket] = useEditTicketMutation();

  const { currentProvider } = useCurrentProvider();

  const formContext = useForm<any>({
    defaultValues: {
      userId: lockUser?.id,
      requesterId: currentProvider.id,
      ...(defaultValues ?? {}),
      ...getDefaultValues(ticket),
    },
    reValidateMode: 'onBlur',
  });

  const watchedUserId = formContext.watch('userId');

  const ticketFormQuery = useTicketFormQuery({ skip: !!lockUser });
  const potentialProviders = usePossibleAssigneesQuery({
    variables: { userId: watchedUserId, ticketId: ticket?.id },
    skip: !watchedUserId,
  });

  const { reset, setValue } = formContext;
  // clear assignee option when changing user
  useEffect(
    () => void (ticket ? null : setValue('assigneeId', defaultValues?.assigneeId ?? null)),
    [watchedUserId, ticket, setValue, defaultValues]
  );
  // reset default values when reloading ticket
  useEffect(() => void (ticket ? reset(getDefaultValues(ticket)) : null), [ticket, reset]);

  const sendEdits = async (input: EditTicketInput, shouldClose: boolean) => {
    if (!ticket) return;
    await editTicket({ variables: { input, id: ticket.id } });
    onComplete(shouldClose);
  };

  const onClickCancel = () => {
    formContext.reset();
    onCancel();
  };

  const onSubmit = formContext.handleSubmit(async ({ requesterId, resolveIn24Hrs, ...values }) => {
    // yes/no answer are stored as integer ids on the form
    const normalizedValues = {
      resolveIn24Hrs: Boolean(resolveIn24Hrs),
      category: 'other', // remove when flag is removed
      ...values,
    };
    if (ticket) {
      const defaults = getDefaultValues(ticket) as any;
      const input = pickBy(normalizedValues, (v, key) => defaults[key] !== v);
      if (input.userId) throw new Error("Can't change which user a ticket is assigned to");
      const edit = await editTicket({ variables: { input, id: ticket.id } });
      onComplete(!edit.data?.ticket);
    } else {
      await createTicket({
        variables: { input: normalizedValues },
      });
      onComplete(false);
    }
    toaster.positive('Task created', {});
  });

  const userOptions = lockUser ? [lockUser] : ticketFormQuery.data?.users ?? [];
  userOptions.sort(compareLastNames);

  return (
    <FormProvider {...formContext}>
      <form className="flex flex-column gap-3" onSubmit={onSubmit}>
        {ticket?.assignee?.id === currentProvider.id && (
          <SelectRHF
            disabled={viewOnly || (ticket && !ticket.canEdit)}
            controlProps={{ label: 'Priority' }}
            options={[
              { id: TicketPriority.High, label: 'High' },
              { id: TicketPriority.Medium, label: 'Medium' },
              { id: TicketPriority.Low, label: 'Low' },
              { id: null, label: 'None' },
            ]}
            name="priority"
          />
        )}
        <InputRHF
          disabled={viewOnly || (ticket && !ticket.canEdit)}
          controlProps={{ label: 'What is the task?', required: true }}
          rules={{ required: 'This field is required' }}
          name="title"
          placeholder="Example: Request patient insurance info"
        />
        <FeatureFlagged flag="TICKET_CATEGORY">
          <NestedSelectRHF
            controlProps={{ label: 'What category does this task fall under?', required: true }}
            rules={{ required: 'This field is required' }}
            name="category"
            placeholder="Select category"
            options={ticketCategoryOptions}
            disabled={viewOnly || (ticket && !ticket.canEdit)}
          />
        </FeatureFlagged>
        <SelectRHF
          controlProps={{ label: 'Which patient is this task for?', required: true }}
          rules={{ required: 'This field is required' }}
          clearable
          renderOption={renderUserOptions}
          options={userOptions.map(u => ({
            data: u,
            id: u.id,
            label: `${getFullNameReversed(u)} (${u.customerId})`,
          }))}
          name="userId"
          disabled={!!lockUser || viewOnly || !!ticket}
        />
        <SelectRHF
          controlProps={{ label: 'Who are you assigning this task to?', required: true }}
          renderOption={renderProviderOption}
          noResultsText={watchedUserId ? undefined : 'Please select a patient'}
          rules={{ required: 'This field is required' }}
          disabled={viewOnly || (ticket && ticket.assignOptions === TicketAssignOption.None)}
          name="assigneeId"
          options={
            potentialProviders.data?.providers.map(p => ({ id: p.id, label: p.name, data: p })) ??
            []
          }
        />
        <SelectRHF
          controlProps={{ label: 'Requested by' }}
          rules={{ required: true }}
          disabled
          options={[
            ticket?.requester
              ? { id: ticket.requester.id, label: ticket.requester.name }
              : { id: currentProvider.id, label: `${currentProvider.name} (Me)` },
          ]}
          name="requesterId"
        />
        <RadioRHF
          name="resolveIn24Hrs"
          optionsInline
          controlProps={{
            label: (
              <div className="flex flex-row">
                <label
                  htmlFor="resolveIn24Hrs"
                  className="b db mb1 f5"
                  style={{ color: colors.grayText }}
                >
                  Does this task require attention in the next 24h due to patient safety or risk?{' '}
                  <span style={{ color: colors.danger }}>*</span>
                </label>
                <div className="flex-1">
                  <Tooltip
                    content={
                      <div className="mw5">
                        For urgent, non-safety or risk related tasks, please message Care Nav
                        through Slack
                      </div>
                    }
                  />
                </div>
              </div>
            ),
            required: true,
          }}
          withLabel
          options={[
            { id: 1, label: 'Yes' },
            { id: 0, label: 'No' },
          ]}
          rules={{ required: 'This field is required' }}
        />
        <TextareaRHF
          controlProps={{ label: 'Additional Details' }}
          name="description"
          disabled={viewOnly || (ticket && !ticket.canEdit)}
          placeholder="Optional: add your details here"
        />
        {ticket?.status === TicketStatus.Blocked && (
          <div className="bt b--light-gray pt3">
            <TextareaRHF
              controlProps={{ label: 'Block Details' }}
              name="blockedReason"
              disabled={viewOnly || (ticket && !ticket.canEdit)}
              placeholder="Optional: explain why you are blocked here"
            />
          </div>
        )}
        <div className="flex flex-column gap-2">
          <TaskFormButtons
            ticket={ticket}
            viewOnly={viewOnly}
            toggleBlocked={b =>
              sendEdits({ status: b ? TicketStatus.Blocked : TicketStatus.Active }, true)
            }
            onClickCancel={onClickCancel}
            onClickResolve={() => sendEdits({ status: TicketStatus.Complete }, true)}
            onClickDelete={() => sendEdits({ status: TicketStatus.Deleted }, true)}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const renderUserOptions: RenderOption<TicketUserFragment> = ({ option }) => {
  return (
    <div>
      <div>
        {getFullNameReversed(option.data!, { noPreferred: true })}{' '}
        <span className="f7 gray">({option.data!.customerId})</span>{' '}
      </div>
      <div className="f7 o-80">
        <TextIconSvg icon="university">{option.data?.organization?.name ?? 'DTC'}</TextIconSvg>
      </div>
    </div>
  );
};

const renderProviderOption: RenderOption<PossibleAssigneesQuery['providers'][number]> = ({
  option,
}) => {
  return (
    <div>
      <Text.body className="mv0">{option.data?.name}</Text.body>
      <Text.bodySmall kind="grayText" className="mv0">
        {option.data?.classification}
      </Text.bodySmall>
    </div>
  );
};

const getDefaultValues = (ticket?: TicketQuery['ticket']) => {
  if (!ticket) return {};
  return {
    assigneeId: ticket.assignee?.id,
    requesterId: ticket.requester?.id,
    userId: ticket.user?.id,
    resolveIn24Hrs: Number(ticket.resolveIn24Hrs),
    ...pick(ticket, [
      'title',
      'description',
      'dueDate',
      'blocked',
      'priority',
      'blockedReason',
      'category',
    ]),
  };
};

type TaskFormButtonProps = {
  toggleBlocked: (blocked: boolean) => any;
  onClickResolve: () => void;
  onClickDelete: () => void;
  onClickCancel: () => void;
} & Pick<TaskFormProps, 'ticket' | 'viewOnly'>;

const TaskFormButtons = ({
  ticket,
  viewOnly,
  onClickResolve,
  toggleBlocked,
  onClickDelete,
  onClickCancel,
}: TaskFormButtonProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const history = useHistory();

  if (!ticket)
    return (
      <>
        <FinalButton type="submit" kind="primary">
          Create Task
        </FinalButton>
        <FinalButton type="button" kind="outline_black" onClick={onClickCancel}>
          Cancel
        </FinalButton>
      </>
    );

  if (ticket.status === TicketStatus.Complete)
    return <p className="tc b gray mt4">Task resolved on {moment(ticket.updatedAt).format('L')}</p>;

  if (viewOnly)
    return (
      <>
        {ticket.canResolve && (
          <FinalButton type="button" kind="primary" onClick={onClickResolve}>
            Resolve Task
          </FinalButton>
        )}
        {ticket.canEdit && ticket.status === TicketStatus.Blocked && (
          <FinalButton type="button" kind="primary" onClick={() => toggleBlocked(false)}>
            Unblock
          </FinalButton>
        )}
        {ticket.user && (
          <FinalButton
            kind="outline_black"
            onClick={() => history.push(`/users/${ticket.user!.id}`)}
          >
            View Patient
          </FinalButton>
        )}
        {ticket.canEdit && ticket.status !== TicketStatus.Blocked && (
          <FinalButton kind="danger" onClick={() => toggleBlocked(true)}>
            Mark as Blocked
          </FinalButton>
        )}
      </>
    );

  return (
    <>
      <GenericSmallModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <p className="mb4">
          Are you sure you want to delete this task? This action is irreversible.
        </p>
        <div className="flex justify-center gap-3">
          <FinalButton
            kind="outline_black"
            type="button"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            No, keep task
          </FinalButton>
          <FinalButton type="button" kind="danger" onClick={onClickDelete}>
            Yes, delete task
          </FinalButton>
        </div>
      </GenericSmallModal>
      <FinalButton type="submit" kind="primary">
        Save Changes
      </FinalButton>
      <FinalButton kind="outline_black" onClick={onClickCancel}>
        Cancel
      </FinalButton>
      <FinalButton kind="danger" onClick={() => setIsDeleteModalOpen(true)}>
        Delete Task
      </FinalButton>
    </>
  );
};
