import { StatefulCheckbox } from 'baseui/checkbox';
import { Input } from 'baseui/input';
import { Notification } from 'baseui/notification';
import { StatefulSelect } from 'baseui/select';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MaskedPhoneInput } from '../../Components/EzForm/MaskedInputs';
import { FinalButton } from '../../Components/FinalButton';
import { Textarea } from '../../Components/Form/Input';
import { useCurrentProvider } from '../../Components/Permissions';
import { providerProfileLabels } from '../../constants';
import { overrides } from '../../globalStyles';
import { CareType, useCreateProviderMutation } from '../../graphQL';
import { stripPhoneNumber } from '../../utils';

interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  npi: string;
  classification: string;
  postNominalTitles: string;
  funFact: string;
  careTypes: CareType[];
  geoStates: string;
  phone: string;
  withTwoFactor: boolean;
}

export function NewProvider() {
  const history = useHistory();
  const { featureFlags } = useCurrentProvider();

  const { register, control, errors, handleSubmit } = useForm<FormFields>();
  const [createProvider, { loading, error }] = useCreateProviderMutation();
  const topRef = useRef<HTMLDivElement | null>(null);

  const onSave = handleSubmit(async values => {
    try {
      const res = await createProvider({
        variables: {
          ...values,
          phone: stripPhoneNumber(values.phone),
          geoStates: values.geoStates.split(',').map(i => i.trim()),
          withTwoFactor: Boolean(values.withTwoFactor),
        },
      });
      const id = res.data?.createProvider.id;
      if (id) {
        history.push(`/providers/${id}`);
      }
    } catch (err) {
      topRef.current?.scrollIntoView();
      throw err;
    }
  });

  return (
    <div className="ma5">
      <h1 ref={topRef}>Create New Provider</h1>
      {error && (
        <Notification kind="negative">
          {error.message ?? 'An unexpected error occurred.'}
        </Notification>
      )}
      <Grid>
        <div>
          <FieldName>First Name</FieldName>
          <Input
            inputRef={register({ required: true })}
            name="firstName"
            error={!!errors.firstName}
          />
        </div>
        <div>
          <FieldName>Last Name</FieldName>
          <Input
            inputRef={register({ required: true })}
            name="lastName"
            error={!!errors.lastName}
          />
        </div>
        <div>
          <FieldName>Title</FieldName>
          <Input
            inputRef={register({ required: true })}
            name="classification"
            error={!!errors.classification}
          />
        </div>
        <div>
          <FieldName>Post-Nominal Titles</FieldName>
          <Input
            inputRef={register}
            name="postNominalTitles"
            placeholder="e.g. Ph.D"
            error={!!errors.postNominalTitles}
          />
        </div>
        <div>
          <FieldName>Email</FieldName>
          <Input inputRef={register({ required: true })} name="email" error={!!errors.email} />
        </div>
        <div>
          <FieldName>Phone</FieldName>
          <MaskedPhoneInput
            name="phone"
            control={control}
            error={!!errors.phone}
            rules={{
              required: 'This field is required',
              validate: {
                valid: value => {
                  const val = stripPhoneNumber(value).length >= 10;
                  return val || 'Please enter a valid phone number.';
                },
              },
            }}
          />
          {featureFlags.includes('PROVIDER_2FACTOR_VERIFICATION') && (
            <div className="mt2">
              <FieldName>Require 2-Factor Auth</FieldName>
              <Controller
                name="withTwoFactor"
                control={control}
                render={({ onChange, ...props }) => (
                  <StatefulCheckbox
                    overrides={overrides.checkbox}
                    onChange={({ currentTarget }) => onChange(currentTarget.checked)}
                    {...props}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div>
          <FieldName>Care Type(s)</FieldName>
          <Controller
            name="careTypes"
            control={control}
            rules={{ required: true }}
            render={({ onChange }) => (
              <StatefulSelect
                multi
                clearable={false}
                options={[CareType.Psychiatry, CareType.Therapy].map(c => ({ label: c, id: c }))}
                onChange={({ value }) => onChange(value.map(v => v.id))}
                error={!!errors.careTypes}
              />
            )}
          />
        </div>
        <div>
          <FieldName>States (separate with commas)</FieldName>
          <Input
            inputRef={register({
              required: true,
              validate: (val: string) => val.split(',').every(i => i.trim().length === 2),
            })}
            name="geoStates"
            placeholder="e.g. MI, NY, FL"
            error={!!errors.geoStates}
          />
        </div>
        <div>
          <FieldName>NPI</FieldName>
          <Input inputRef={register({ required: true })} name="npi" error={!!errors.npi} />
        </div>
        <div>
          <FieldName>{providerProfileLabels.about}</FieldName>
          <Textarea ref={register} name="funFact" />
        </div>
      </Grid>
      <div className="mt3">
        <FinalButton onClick={onSave} loading={loading}>
          Submit
        </FinalButton>
      </div>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  width: 750px;
  margin-top: 3em;
`;
const FieldName = styled.div`
  font-weight: bold;
  margin-bottom: 0.5em;
`;
