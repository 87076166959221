import moment from 'moment';
import React from 'react';
import { Permission } from '../../graphQL';
import { useCurrentProvider } from '../Permissions';
import { CancelAppointmentLink } from './CancelAppointmentLink';
import { EditAppointmentLink } from './EditAppointmentLink';
import { ModifyAppointmentLink } from './ModifyAppointmentLink';
import { RescheduleAppointmentLink } from './RescheduleAppointmentLink';
import { AppointmentForLink } from './types';

type Props = {
  appointment: AppointmentForLink;
  refetch: () => void;
};

export const AppointmentControls = ({ appointment, refetch }: Props) => {
  const canCancel = useCanCancelAppointment(appointment);
  const canReschedule =
    moment().isBefore(appointment.endTime) || ['cancelled', 'no show'].includes(appointment.status);

  const { currentProvider, hasPermission } = useCurrentProvider();

  return (
    <div className="flex flex-column gap-1">
      {hasPermission(Permission.AppointmentEdit) &&
        (appointment.provider.id === currentProvider.id ||
          hasPermission(Permission.MantraAdmin)) && (
          <EditAppointmentLink appointment={appointment} onEdit={refetch} />
        )}
      {canCancel && appointment.status === 'upcoming' && (
        <>
          {canReschedule && <RescheduleAppointmentLink appointment={appointment} />}
          <CancelAppointmentLink appointment={appointment} onCancel={refetch} />
        </>
      )}
      {hasPermission(Permission.MantraAdmin) && (
        <ModifyAppointmentLink appointment={appointment} onModified={refetch} />
      )}
    </div>
  );
};

export const useCanCancelAppointment = (appointment: AppointmentForLink) => {
  const { hasPermission, currentProvider, appView } = useCurrentProvider();
  return (
    hasPermission(Permission.AppointmentCancelAll) ||
    (hasPermission(Permission.AppointmentCancel) &&
      (appView === 'oz' ||
        appointment.user.universityCareTeam.some(i => i.id === currentProvider.id)))
  );
};
