import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { KIND, Notification } from 'baseui/notification';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxRHF } from '../../Components/Form';
import { Text } from '../../globalStyles';
import { useAdminAuthenticateMutation, useFinishPasswordResetMutation } from '../../graphQL';
import { setAuthToken } from '../../token';
import { SessionPageWrapper } from './Shared';
import { Form, inputOverrides } from './Style';
import { validPasswordReg } from './utils';

interface FormFields {
  password: string;
  confirmPassword: string;
  acceptTos: boolean;
}

interface Props {
  email: string;
  token: string;
}

export const SetNewPassword = (props: Props) => {
  const formContext = useForm<FormFields>({ defaultValues: { acceptTos: false } });
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [resetPassword, resetPasswordMutation] = useFinishPasswordResetMutation();
  const [login, loginMutation] = useAdminAuthenticateMutation();

  const onSubmit = formContext.handleSubmit(async values => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword)
      return formContext.setError('confirmPassword', {
        type: 'confirm',
        message: 'Confirmation does not match',
      });
    setLoading(true);
    await resetPassword({ variables: { email: props.email, token: props.token, password } });
    const res = await login({ variables: { email: props.email, password } });
    if (res.data?.adminAuthenticate.authToken) {
      setAuthToken(res.data.adminAuthenticate.authToken);
      history.push('/');
    }
  });

  const { register, errors } = formContext;
  return (
    <SessionPageWrapper title="Change Password">
      <FormProvider {...formContext}>
        {(resetPasswordMutation.error || loginMutation.error) && (
          <Notification kind={KIND.negative}>
            An unexpected error occurred. Please try again.
          </Notification>
        )}
        <Form onSubmit={onSubmit}>
          <FormControl caption={errors.password && errors.password.message}>
            <Input
              inputRef={register({
                required: 'This field is required',
                minLength: { value: 10, message: 'Password must be at least 10 characters' },
                pattern: {
                  value: validPasswordReg,
                  message: `Password must contain a special character, number, an uppercase letter, and a lowercase letter.`,
                },
              })}
              type="password"
              name="password"
              error={!!errors.password}
              size="large"
              placeholder="New Password"
              overrides={inputOverrides}
            />
          </FormControl>
          <FormControl caption={errors.confirmPassword && errors.confirmPassword.message}>
            <Input
              inputRef={register({ required: 'This field is required' })}
              name="confirmPassword"
              type="password"
              error={!!errors.confirmPassword}
              size="large"
              placeholder="Confirm New Password"
              overrides={inputOverrides}
            />
          </FormControl>
          <CheckboxRHF
            name="acceptTos"
            rules={{ required: 'You must accept the terms of service' }}
          >
            I accept the{' '}
            <Text.externalLink href="assets/ProviderTermsOfService.pdf" target="_blank">
              Terms of Service
            </Text.externalLink>
          </CheckboxRHF>

          <FinalButton type="submit" className="w-100" loading={loading} kind="primary">
            Change Password
          </FinalButton>
        </Form>
      </FormProvider>
    </SessionPageWrapper>
  );
};
