import { isMantraAdmin, useCurrentProvider } from '../Components/Permissions';
import { useOrganizationsSimpleQuery } from '../graphQL';

export const useOrganizationOptions = () => {
  const { currentProvider } = useCurrentProvider();
  const mantraAdmin = isMantraAdmin(currentProvider);
  const { data, loading } = useOrganizationsSimpleQuery({
    fetchPolicy: 'cache-first',
    skip: !mantraAdmin,
  });
  const mantraAdminOrgs = data?.organizations ?? [];
  return {
    loading,
    orgOpts: (mantraAdmin ? mantraAdminOrgs : currentProvider.organizations).map(o => ({
      id: o.id,
      label: o.name,
    })),
  };
};
