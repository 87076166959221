import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from './Components/ConfigContext';
import { EventsProvider } from './Components/Events/EventsProvider';
import { NavLayout } from './Components/NavLayout';
import { AuthProvider } from './Components/Permissions/AuthWrapper';
import { TimeoutManager } from './Components/TimeoutManager';
import { VideoProvider } from './Components/Video/VideoProvider';
import { useRefreshTokenBeforeExpiration, useVersionChecker } from './Hooks';
import isProduction from './isProduction';
import { addMarkerIO } from './markerIO';
import { VideoCall } from './Pages/VideoCall';
import { VideoCallDismissed } from './Pages/VideoCall/JoinCallBlocked';
import { JoinVideoCall } from './Pages/VideoCall/JoinVideoCall';
import { NoShow } from './Pages/VideoCall/NoShowPage';
import { Providers } from './Providers';
import { AuthenticatedRoutes, PublicRoutes } from './Routes';
import { isLoggedIn } from './utils';

if (!isProduction) {
  void addMarkerIO();
}

const App: React.FC = () => {
  useVersionChecker();
  return (
    <React.StrictMode>
      <Providers>
        <Router>
          <EventsProvider>
            <ConfigProvider>
              <PublicRoutes>
                <AuthenticatedApp />
              </PublicRoutes>
            </ConfigProvider>
          </EventsProvider>
        </Router>
      </Providers>
    </React.StrictMode>
  );
};

const AuthenticatedApp = () => {
  useRefreshTokenBeforeExpiration(1000 * 60 * 6);

  if (!isLoggedIn()) {
    return <Redirect to={{ pathname: '/login', state: { from: window.location.pathname } }} />;
  }

  return (
    <AuthProvider>
      <VideoProvider>
        <TimeoutManager />
        <Switch>
          <Route path="/users/:id/video/:appointmentId" component={VideoCall} />
          <Route path="/join-call/:id" component={JoinVideoCall} />
          <Route path="/call-dismissed" component={VideoCallDismissed} />
          <Route path="/users/:id/noShow" component={NoShow} />
          <NavLayout>
            <AuthenticatedRoutes />
          </NavLayout>
        </Switch>
      </VideoProvider>
    </AuthProvider>
  );
};

export default App;
