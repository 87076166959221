import { Select } from 'baseui/select';
import { first, isEmpty, isString, isUndefined } from 'lodash';
import qs from 'querystring';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { Notification } from '../../Components/Notification';
import { isMantraProvider, McpOnly, useCurrentProvider } from '../../Components/Permissions';
import { WizardWrapper } from '../../Components/Wizard';
import {
  AttendeeDetailsQuery,
  CareType,
  Permission,
  SelectAttendeesQuery,
  useAttendeeDetailsQuery,
  useSelectAttendeesQuery,
} from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { isPsychiatrist, isTherapist } from '../Provider/util';
import { BookingWizardProps } from './types';
import { getSelectableProviders, hasProviderWithId } from './bookingUtils';

export function SelectAttendees({ data: wizardData, nextStep }: BookingWizardProps) {
  const [patientId, setPatientId] = useState<number | undefined>(
    wizardData.patient && wizardData.patient.id
  );
  const [providerId, setProviderId] = useState<number | undefined>(
    wizardData.appointment.provider && wizardData.appointment.provider.id
  );

  const { hasPermission } = useCurrentProvider();
  const { search } = useLocation();

  const parsedQuery = qs.parse(search.replace(/^\?/, ''));
  const initialUserId = isString(parsedQuery.userId) && Number(parsedQuery.userId);
  const initialProviderId = isString(parsedQuery.providerId) && Number(parsedQuery.providerId);

  const { data } = useSelectAttendeesQuery({
    variables: { includeNewPatients: hasPermission(Permission.MantraAdmin) },
  });

  const { data: { patient } = {} } = useAttendeeDetailsQuery({
    variables: { id: patientId! },
    skip: isUndefined(patientId),
  });

  const selectableProviders = (() => {
    if (!data) return [];
    if (!patient) return data.providers;
    return getSelectableProviders(data.providers, patient);
  })();

  useEffect(() => {
    if (!patientId && initialUserId) {
      setPatientId(initialUserId);
    }
  }, [patientId, setPatientId, initialUserId]);

  useEffect(() => {
    // if providerId is set but not in the selectable providers -- reset
    if (
      !isUndefined(providerId) &&
      !isEmpty(selectableProviders) &&
      !hasProviderWithId(providerId, selectableProviders)
    ) {
      return setProviderId(undefined);
    }
    if (!isUndefined(providerId)) {
      return;
    }
    // if there is an initial provider id and that provider is selectable
    if (initialProviderId && hasProviderWithId(initialProviderId, selectableProviders)) {
      return setProviderId(initialProviderId);
    }
    // if the current provider is a mantra provider
    if (data && isMantraProvider(data.currentProvider)) {
      return setProviderId(data.currentProvider.id);
    }
  }, [data, providerId, initialProviderId, selectableProviders, setProviderId]);

  if (!data?.users) {
    return <></>;
  }

  const providers = isUndefined(providerId)
    ? selectableProviders
    : selectableProviders.filter(p => p.id === providerId);

  const showMismatchWarning =
    !isEmpty(providers) && providers.every(p => shouldShowMismatchWarning(patient, p));

  return (
    <WizardWrapper>
      <h2 className="mt4 mb3">Select attendees</h2>
      <h3>Patient</h3>
      <Select
        maxDropdownHeight="24rem"
        options={data.users.map(user => ({
          label: `${getFullName(user)} (${user.customerId})`,
          id: user.id,
        }))}
        value={patientId ? [{ id: patientId }] : []}
        onChange={e => setPatientId(e.option && (e.option.id as number))}
        multi={false}
        clearable={false}
      />
      <h3 className="mt3">Provider</h3>
      <McpOnly>
        <p className="o-50 f6 mb3 lh-copy">
          To promote continuity of care, we recommend choosing a provider who is licensed in the
          state the patient resides in over breaks, such as winter and summer breaks.
        </p>
      </McpOnly>
      <Select
        getOptionLabel={({ option }) =>
          option && (
            <>
              <p className="f5 truncate">{option.label}</p>
              {option?.geoStates && (
                <p className="mb2">
                  <span>Licensure:</span> {option.geoStates.join(', ')}
                </p>
              )}
            </>
          )
        }
        maxDropdownHeight="24rem"
        options={getProviderSelectionOptions(selectableProviders)}
        value={[{ id: providerId }]}
        onChange={({ option }) => setProviderId(option && (option.id as number))}
        multi={false}
        clearable={false}
      />
      {showMismatchWarning && (
        <Notification kind="warning">
          <strong>Warning:</strong>{' '}
          {providers.length > 1
            ? "None of the selected providers include the patient's assigned provider."
            : "Provider selected is not patient's assigned provider."}
        </Notification>
      )}
      <FinalButton
        kind="primary"
        className="w-100 mt4"
        onClick={() =>
          nextStep({
            ...wizardData,
            patient,
            providers,
            appointment: {
              ...wizardData.appointment,
              provider: providers.length === 1 ? first(providers) : undefined,
            },
          })
        }
        disabled={!patient}
      >
        Next: Select Appointment Type
      </FinalButton>
    </WizardWrapper>
  );
}

function shouldShowMismatchWarning(
  patient?: AttendeeDetailsQuery['patient'],
  provider?: SelectAttendeesQuery['providers'][number]
) {
  if (!patient || !provider) return false;
  const wrongPsychiatrist =
    patient.careTypes.includes(CareType.Psychiatry) &&
    isPsychiatrist(provider) &&
    patient.provider &&
    patient.provider.id !== provider.id;
  const wrongTherapist =
    patient.careTypes.includes(CareType.Therapy) &&
    isTherapist(provider) &&
    patient.therapist &&
    patient.therapist.id !== provider.id;
  return !!(wrongPsychiatrist || wrongTherapist);
}

function getProviderSelectionOptions(providers: SelectAttendeesQuery['providers']) {
  const providerOptions = providers.map(p => ({
    label: `${p.name}${p.qualifications.length ? ` (${p.qualifications})` : ''}`,
    id: p.id,
    geoStates: p.geoStates,
  }));
  return [{ label: 'All available providers' }, ...providerOptions];
}
