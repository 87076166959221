import { Avatar } from 'baseui/avatar';
import moment from 'moment';
import React from 'react';
import { LoginAsUser } from '../../Components/Debug/LoginAsUser';
import { QuickEmailLink, TextIcon } from '../../Components/Flair';
import { icons } from '../../Components/Icons';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { isMantraProvider, isMcpUser, useCurrentProvider } from '../../Components/Permissions';
import { User } from '../../graphQL';
import { getFullPreferredName, getUserOrgAbbreviation } from '../../modelUtils/users';
import { useDrilldownContext } from './helpers';
import { Text } from './styles';
import { UnstyledLink } from '../Shared';

export const Profile = () => {
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const { selfie, customerId, organization } = user;

  const name = getFullPreferredName(user, 'p l');
  const url = selfie?.url ?? icons.assetsDefaultPicturePng;

  let logoLinkTo = `/organizations/${organization?.id}/admin?tab=handbook`;
  if (isMcpUser(currentProvider)) {
    logoLinkTo = `/organizations/${organization?.id}/handbook`;
  } else if (isMantraProvider(currentProvider)) {
    logoLinkTo = `/organizations/${organization?.id}/provider?tab=handbook`;
  }

  return (
    <div className="relative flex pv3 ph4 mt2">
      {organization && (
        <UniversityLogo
          organizationId={organization.id}
          right="16px"
          top="8px"
          linkTo={logoLinkTo}
        />
      )}
      <div className="mr3 mt2">
        <Avatar size="60px" src={url} name={name} />
      </div>
      <div>
        <span className="flex items-center">
          <Text.h1 className="mv0 mr2" data-cy="userName">
            {name}
          </Text.h1>
          <QuickEmailLink user={user} />
          <span className="ml2">
            <LoginAsUser userId={user.id} />
          </span>
        </span>
        <Text.bodyGrey>
          {customerId}{' '}
          {user.organizationIdentification && (
            <>
              ({getUserOrgAbbreviation(user)} ID: {user.organizationIdentification})
            </>
          )}
        </Text.bodyGrey>
        <AgeAndPronouns user={user} />
        {organization && (
          <UnstyledLink to={logoLinkTo}>
            <Text.body>
              <TextIcon preset="university">{organization.name}</TextIcon>
            </Text.body>
          </UnstyledLink>
        )}
      </div>
    </div>
  );
};

type AgeAndPronounsUser = Pick<User, 'birthDate' | 'pronouns'>;
export const AgeAndPronouns = ({ user }: { user: AgeAndPronounsUser }) => {
  const { pronouns } = user;

  const age = user.birthDate ? moment().diff(moment(user.birthDate), 'years') : 'unknown';

  return (
    <Text.body className="flex items-center">
      {age} y/o
      {pronouns ? `, ${pronouns}` : ', unspecified pronouns'}
    </Text.body>
  );
};
