import moment from 'moment';
import React from 'react';
import { useCurrentProvider } from '../../Components/Permissions';
import { WizardStep } from '../../Components/Wizard';
import {
  AppView,
  CareType,
  useAdminAppointmentQuery,
  useReferralUserBookingQuery,
  useUserBookingQuery,
} from '../../graphQL';
import { RequireJust } from '../../types';
import {
  canBookIntake,
  getIntakeApptData,
  getSelectableProviders,
  isPatientCaregiver,
} from './bookingUtils';
import { Confirm } from './Confirm';
import { SelectAppointmentType } from './SelectAppointmentType';
import { SelectAttendees } from './SelectAttendees';
import { SelectTime } from './SelectTime';
import { BookingWizardData } from './types';

type HookArgs = {
  userId?: number;
  rescheduleId?: number;
  careType?: CareType;
  outOfPolicy?: boolean;
};

type HookResult = RequireJust<SubHookResult, 'loading'> & {
  steps: WizardStep<BookingWizardData>[];
};

type SubHookArgs<T> = {
  skip?: boolean;
  variables: T;
};

type SubHookResult = {
  loading?: boolean;
  error?: string;
  data?: BookingWizardData;
};

const viewSteps: Record<AppView, (keyof typeof possibleSteps)[]> = {
  oz: ['attendees', 'type', 'dateTimeDisplayAllHours', 'confirm'],
  mcp: ['attendees', 'type', 'dateTime', 'confirm'],
  referral: ['dateTime', 'confirm'],
};

const possibleSteps: Record<string, WizardStep<BookingWizardData>> = {
  attendees: {
    name: 'Attendees',
    render: SelectAttendees,
  },
  type: {
    name: 'Type',
    render: SelectAppointmentType,
  },
  dateTime: {
    name: 'Date/Time',
    render: SelectTime,
  },
  dateTimeDisplayAllHours: {
    name: 'Date/Time',
    render: props => <SelectTime {...props} displayAllHours />,
  },
  confirm: {
    name: 'Confirm',
    render: Confirm,
  },
};

const baseData: BookingWizardData = {
  initialStepIndex: 0,
  providers: [],
  appointment: {},
  didEncounterTimeConflict: false,
};

export const useBookingFlow = ({
  userId,
  rescheduleId,
  careType,
  outOfPolicy,
}: HookArgs): HookResult => {
  const { appView, currentProvider } = useCurrentProvider();
  const isReferral = appView === 'referral';
  const steps = getSteps(appView);

  const { data: userBookingData, loading: userBookingLoading } = useUserBookingQuery({
    skip: !userId || !!rescheduleId || isReferral,
    variables: { userId: userId! },
  });

  const { data: rescheduleData, loading: rescheduleLoading } = useReschedulingFlow({
    skip: !rescheduleId || isReferral,
    variables: { rescheduleId, outOfPolicy },
  });

  const {
    data: simpleBookingData,
    loading: simpleBookingLoading,
    error,
  } = useSimpleBookingFlow({
    skip: !userId || !isReferral,
    variables: { userId, careType },
  });

  if (rescheduleLoading || simpleBookingLoading || userBookingLoading) {
    return { loading: true, steps };
  }

  // Prioritize reschedules
  if (rescheduleData) {
    return { loading: false, data: { ...rescheduleData, initialStepIndex: 2 }, steps };
  }

  // If was a typical user booking (skipped if referralBooking - line 80)
  if (userBookingData) {
    const patient = userBookingData.adminUser;
    const { appointmentTemplates, therapist, provider, careTypes } = patient;
    const getInitialApptData = () => {
      if (isPatientCaregiver(currentProvider, patient)) {
        return {
          providers: [currentProvider],
          appointment: { provider: currentProvider },
          initialStepIndex: 1,
        };
      }
      if (provider && careTypes.includes(CareType.Psychiatry)) {
        return { providers: [provider], appointment: { provider } };
      }
      if (therapist && careTypes.includes(CareType.Therapy)) {
        return { providers: [therapist], appointment: { provider: therapist } };
      }
      return {};
    };

    return {
      loading: false,
      data: {
        ...baseData,
        ...getInitialApptData(),
        patient,
        appointmentTemplates,
      },
      steps,
    };
  }

  // If not a referral, was an unkown booking for a non referral
  return isReferral
    ? { loading: false, data: simpleBookingData, steps, error }
    : { loading: false, data: baseData, steps };
};

type ReschedulingArgs = SubHookArgs<{ rescheduleId?: number; outOfPolicy?: boolean }>;

const useReschedulingFlow = ({ skip, variables }: ReschedulingArgs): SubHookResult => {
  const { rescheduleId, outOfPolicy } = variables;

  const { data: apptData, loading: apptLoading } = useAdminAppointmentQuery({
    variables: { id: rescheduleId! },
    skip: !rescheduleId || skip,
  });

  if (apptLoading) {
    return { loading: true };
  }

  if (!apptData) {
    return { loading: false };
  }

  const reschedulingAppointment = apptData.adminAppointment;
  const { user, provider, appointmentType, careType, endTime, startTime } = reschedulingAppointment;
  const { appointmentTemplates } = user;
  const templateIndex =
    appointmentTemplates.findIndex(
      a => a.appointmentType === appointmentType && a.careType === careType
    ) ?? -1;

  return {
    data: {
      ...baseData,
      reschedulingAppointment: {
        ...reschedulingAppointment,
        outOfPolicy,
      },
      appointmentTemplates,
      patient: user,
      appointment: {
        ...reschedulingAppointment,
        templateIndex,
        duration: moment(endTime).diff(startTime, 'minutes'),
      },
      providers: [provider!],
    },
  };
};

type SimpleBookingArgs = SubHookArgs<{ careType?: CareType; userId?: number }>;

const useSimpleBookingFlow = ({ skip, variables }: SimpleBookingArgs): SubHookResult => {
  const { userId, careType } = variables;

  const { data, loading } = useReferralUserBookingQuery({
    variables: { userId: userId! },
    skip: !userId || skip,
  });

  if (loading) {
    return { loading: true };
  }
  if (!data) {
    return { loading: false };
  }

  const { adminUser: patient } = data;
  const { appointmentTemplates } = patient;

  const providers = getSelectableProviders(data.providers, patient);
  const canBookPsych = canBookIntake(CareType.Psychiatry, patient);
  const canBookTherapy = canBookIntake(CareType.Therapy, patient);
  const psychAppt = getIntakeApptData(CareType.Psychiatry, appointmentTemplates);
  const therapyAppt = getIntakeApptData(CareType.Therapy, appointmentTemplates);

  if (!careType && canBookPsych && psychAppt) {
    return {
      data: {
        ...baseData,
        appointmentTemplates,
        patient,
        appointment: psychAppt,
        providers,
      },
    };
  }
  if (!careType && canBookTherapy && therapyAppt) {
    return {
      data: {
        ...baseData,
        appointmentTemplates,
        patient,
        appointment: therapyAppt,
        providers,
      },
    };
  }
  if (!careType) {
    return { error: 'User has booked all available intake appointments.' };
  }

  const appt = getIntakeApptData(careType, appointmentTemplates);
  const isBookable = canBookIntake(careType, patient);

  if (!isBookable) {
    return { error: 'User has already booked an intake appointment for this care type.' };
  }
  if (!appt) {
    return { error: 'User cannot book an intake appointment for this care type.' };
  }
  return {
    data: {
      ...baseData,
      appointmentTemplates,
      patient,
      appointment: appt,
      providers,
    },
  };
};

const getSteps = (appView: AppView) => viewSteps[appView].map(k => possibleSteps[k]);
