import { get } from 'lodash';
import React from 'react';
import { StyledComponent } from 'styled-components';
import { ColorLookup, colors } from '../../globalStyles';
import SVGs from './_svgs.json';

/**
 * ### WHAT'S GOING ON??? ###
 *
 * This component dynamically loads SVGs so they can be styled using our color presets
 *
 * In order to add icons, you must follow these steps:
 * 1. Add an SVG to the svgs folder in this directory.
 * 2. Make sure that the fill is set to "current" wherever it should match (see copy.svg for example)
 * 3. run "yarn icons" to update the type defs
 *
 * note: SVG must be simple and must color based on fill, svgs that use stroke will need to be handled seperately
 */

export type SVGKey = keyof typeof SVGs;
export type InlineSVGProps = { icon: SVGKey; kind?: ColorLookup } & Pick<
  React.HTMLProps<SVGElement>,
  'alt' | 'className' | 'onClick' | 'style' | 'title' | 'tabIndex' | 'size' | 'width' | 'height'
>;
export const InlineSVG = ({ icon, kind = 'black', size, ...props }: InlineSVGProps) => {
  const { viewBox } = SVGs[icon];

  const Component = svgComponents[icon];
  if (!Component) {
    throw new Error('Invalid icon type given to InlineSVG component.');
  }

  return (
    <Component viewBox={viewBox} fill={get(colors, kind)} width={size} height={size} {...props} />
  );
};

/**
 * Here we import all SVGs from subfolder using webpack SVG loader
 * reference: https://stackoverflow.com/questions/45754739/how-to-import-an-entire-folder-of-svg-images-or-how-to-load-them-dynamically-i
 */
const svgComponents = {} as Record<string, StyledComponent<'svg', any, {}, never>>;
try {
  const reqSvgs = require.context('!@svgr/webpack!./svgs', true, /\.svg$/);
  for (const [key, { file }] of Object.entries(SVGs)) {
    svgComponents[key] = reqSvgs(file).default;
  }
} catch (error) {
  // we need to do this try/catch because jest does not support 'require.context'
  // https://stackoverflow.com/questions/38332094/how-can-i-mock-webpacks-require-context-in-jest
}

export { SVGs };
