import { Set as ImSet } from 'immutable';
import { entries } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useReducer } from 'react';
import { Nullable } from '../../../../../types';
import { TimeZone } from '../../../../../utils';
import { CompleteAllocation, isCompletedAllocation, LocalAllocation, NewAllocation } from './types';

type UpdateArgs<T extends keyof CompleteAllocation> = {
  allocationId: number;
  field: T;
  value: CompleteAllocation[T];
};

type AddArgs = {
  timezone: TimeZone;
};

type DeleteArgs = {
  allocationId: number;
};

export type EditAction =
  | {
      type: 'update';
      args: UpdateArgs<keyof CompleteAllocation>;
    }
  | {
      type: 'add';
      args: AddArgs;
    }
  | {
      type: 'delete';
      args: DeleteArgs;
    };

export type AllocError = {
  msg: string;
  field?: keyof CompleteAllocation;
  conflictingIds?: ImSet<number>;
};

type AvailabilityState = {
  allocations: LocalAllocation[];
  errors: Record<number, Nullable<AllocError>>;
};

const handleUpdate = <T extends keyof CompleteAllocation>(
  state: AvailabilityState,
  { allocationId, field, value }: UpdateArgs<T>
) => {
  const { allocations, errors } = state;
  const updateIdx = allocations.findIndex(i => i.id === allocationId);
  const allocation = allocations[updateIdx];
  const updatedAllocation = { ...allocation, [field]: value };

  let allocError: Nullable<AllocError> = null;

  if (field === 'organizationId') {
    updatedAllocation.childOrganizationIds = [];
  }

// Fee For Service time doesn't apply to admin or timeOff allocation types, so `false` doesn't apply, so whenever we 
// change to this type of allocation, we null out the value of this field
  if (field === 'type' && (value === 'admin' || value === 'timeOff')) {
    updatedAllocation.isFeeForServiceTime = null;
  }

  const overlappingAllocationsIds = isCompletedAllocation(updatedAllocation)
    ? getOverlappingAllocationIds(updatedAllocation, allocations)
    : [];

  const doAllocationsIntersect =
    isCompletedAllocation(updatedAllocation) &&
    updatedAllocation.startTime >= updatedAllocation.endTime;

  if (doAllocationsIntersect) {
    const msg =
      field === 'startTime'
        ? 'Choose a start time earlier than the end time.'
        : 'Choose an end time later than the start time.';
    allocError = { msg, field };
  }
  // has an overlap w other allocations
  else if (overlappingAllocationsIds.length) {
    allocError = {
      msg: 'Time blocks are overlapping.',
      conflictingIds: ImSet(overlappingAllocationsIds),
    };
  }

  const updatedErrors = entries(errors).reduce(
    (acc, [id, err]) => {
      if (!err || allocationId === Number(id)) {
        return acc;
      }
      // clear other errors if they no longer have conflictss
      const conflictingIds = err.conflictingIds?.delete(allocationId);
      return conflictingIds && !conflictingIds.size
        ? acc
        : { ...acc, [id]: { ...err, conflictingIds } };
    },
    { [allocationId]: allocError }
  );

  return {
    errors: updatedErrors,
    // don't update if there's an error
    allocations: [
      ...allocations.slice(0, updateIdx),
      updatedAllocation,
      ...allocations.slice(updateIdx + 1),
    ],
  };
};

const handleAdd = (state: AvailabilityState, { timezone }: AddArgs) => {
  return {
    ...state,
    allocations: [...state.allocations, getInitialAllocation(timezone)],
  };
};

const handleDelete = (state: AvailabilityState, { allocationId }: DeleteArgs) => {
  const { allocations, errors } = state;
  const deleteIdx = allocations.findIndex(i => i.id === allocationId);

  const updatedErrors = entries(errors).reduce((acc, [id, err]) => {
    // clear other errors if they no longer have conflicts
    const conflictingIds = err?.conflictingIds?.delete(allocationId);
    return !conflictingIds?.size ? acc : { ...acc, [id]: { ...err, conflictingIds } };
  }, {} as typeof errors);

  return {
    errors: { ...updatedErrors, [allocationId]: null },
    allocations: [...allocations.slice(0, deleteIdx), ...allocations.slice(deleteIdx + 1)],
  };
};

const editAllocationReducer = (state: AvailabilityState, action: EditAction) => {
  switch (action.type) {
    case 'add': {
      return handleAdd(state, action.args);
    }
    case 'update': {
      return handleUpdate(state, action.args);
    }
    case 'delete': {
      return handleDelete(state, action.args);
    }
    default:
      return state;
  }
};

type HookArgs = {
  timezone: TimeZone;
  blockAllocations: CompleteAllocation[];
};

export const useEditAvailability = ({ timezone, blockAllocations }: HookArgs) => {
  const [availabilityState, dispatch] = useReducer(editAllocationReducer, {
    errors: {},
    allocations: isEmpty(blockAllocations) ? [getInitialAllocation(timezone)] : blockAllocations,
  });

  const { errors, allocations } = availabilityState;

  const hasErrors = Object.values(errors).some(Boolean);
  const canSave =
    !hasErrors &&
    (!allocations.length || allocations.some(isCompletedAllocation)) &&
    Boolean((allocations[0]?.weekly && allocations[0]?.repeatsUntil) || !allocations[0]?.weekly);

  return [dispatch, { localAllocations: allocations, errors, hasErrors, canSave }] as const;
};

// Blank allocation field
const getInitialAllocation = (timezone: TimeZone): NewAllocation => ({
  id: Math.ceil(Math.random() * 100),
  weekly: false,
  type: 'default',
  timezone,
  repeatsUntil: null,
  isFeeForServiceTime: false,
});

export const getOverlappingAllocationIds = (
  updatedAllocation: CompleteAllocation,
  allocations: LocalAllocation[]
) => {
  return allocations
    .filter(isCompletedAllocation)
    .reduce(
      (acc, a) =>
        a.id !== updatedAllocation.id && doAllocationsOverlap(updatedAllocation, a)
          ? [...acc, a.id]
          : acc,
      [] as number[]
    );
};

export const doAllocationsOverlap = (a: CompleteAllocation, b: CompleteAllocation) => {
  return (
    (a.startTime >= b.startTime && a.startTime < b.endTime) ||
    (b.startTime >= a.startTime && b.startTime < a.endTime)
  );
};
