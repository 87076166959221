import { toaster } from 'baseui/toast';
import moment from 'moment-timezone';
import React from 'react';
import { OzOnly } from '../../../Components/Permissions';
import {
  AdminUserQuery,
  CareStatus,
  NextSteps,
  PaymentSource,
  useBeginSponsoredCareReenrollmentMutation,
  useCancelSponsoredCareReenrollmentMutation,
  useCreateCareTransitionMutation,
} from '../../../graphQL';
import { getRemainingSessions } from '../../../utils';
import { careStatusCopy, paymentSourceCopy } from '../copy';
import { Restricted, useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';

interface Props {
  careFlow: AdminUserQuery['adminUser']['careFlows'][number];
}

export function UserCareFlow({ careFlow }: Props) {
  const sessions = getRemainingSessions(careFlow);
  return (
    <Styles.PlanContainer>
      <Text.bodyBold>{careFlow.careType}</Text.bodyBold>
      <Restricted>
        {/* we can assert here since these are only undefined when restricted */}
        <Text.body kind={careFlow.careStatus === CareStatus.Active ? 'success' : 'black'}>
          {careStatusCopy[careFlow.careStatus!]}
        </Text.body>
        <Text.body>{paymentSourceCopy[careFlow.paymentSource!]}</Text.body>
        {sessions && careFlow.paymentSource === PaymentSource.Organization && (
          <Text.body className="i">
            {sessions.remaining} of {sessions.sessionsLimit} visits remaining
          </Text.body>
        )}
        <OzOnly>
          <ConversionState careFlow={careFlow} />
          <ReenrollmentState careFlow={careFlow} />
        </OzOnly>
      </Restricted>
    </Styles.PlanContainer>
  );
}

function ConversionState({ careFlow }: Props) {
  const { user, refetch } = useDrilldownContext();
  const [convertMutation] = useCreateCareTransitionMutation();

  const { careStatus, pendingTransition, paymentSource, careType } = careFlow;
  const convertableStatuses: CareStatus[] = [CareStatus.OnHold, CareStatus.Cancelled];

  const onConvert = async (transitionNextSteps: NextSteps) => {
    try {
      await convertMutation({
        variables: {
          transition: { userId: user.id, nextSteps: transitionNextSteps, careType },
        },
      });
      await refetch();
    } catch {
      toaster.negative('There was a problem converting this patient.', {});
    }
  };

  if (
    convertableStatuses.includes(careStatus!) &&
    paymentSource === PaymentSource.Organization &&
    !pendingTransition
  ) {
    return (
      <Text.linkButtonSmall onClick={() => onConvert(NextSteps.ActiveSelfPay)}>
        Trigger {paymentSourceCopy[PaymentSource.SelfPay]} Task to Patient
      </Text.linkButtonSmall>
    );
  }
  // If we have other types of pending transitions in the future
  // we should handle them with the appropriate copy
  if (pendingTransition && pendingTransition.nextSteps === NextSteps.ActiveSelfPay) {
    return (
      <Text.bodySmallGrey className="i">
        Pending {paymentSourceCopy[PaymentSource.SelfPay]} Conversion
      </Text.bodySmallGrey>
    );
  }
  return null;
}

function ReenrollmentState({ careFlow }: Props) {
  const { user, refetch } = useDrilldownContext();
  const [beginReenrollment] = useBeginSponsoredCareReenrollmentMutation({
    variables: { userId: user.id },
    onCompleted: () => refetch(),
  });
  const [cancelReenrollment] = useCancelSponsoredCareReenrollmentMutation({
    variables: { userId: user.id },
    onCompleted: () => refetch(),
  });

  if (
    careFlow.paymentSource === PaymentSource.Organization ||
    !user.organization?.latestCarePeriod ||
    moment(user.organization.latestCarePeriod.endDate).isBefore(moment())
  ) {
    return null;
  }

  if (user.pendingSponsoredCareReenrollment) {
    return (
      <div>
        <Text.bodySmallGrey className="i di">Re-enrollment pending.</Text.bodySmallGrey>
        <Text.linkButtonSmall kind="danger" className="mr2" onClick={() => cancelReenrollment()}>
          Cancel?
        </Text.linkButtonSmall>
      </div>
    );
  }
  return (
    <Text.linkButtonSmall onClick={() => beginReenrollment()}>
      <span className="mr2">Re-enroll in sponsored care</span>
    </Text.linkButtonSmall>
  );
}
