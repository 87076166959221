import React from 'react';
import { Icon } from '../../Components/Icons';
import { Text } from '../../globalStyles';

export const SessionPageWrapper: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <div className="center" style={{ maxWidth: '325px' }}>
      <div className="flex justify-center mt5 mb4">
        <Icon icon="iconsMantraTransparentSvg" alt="Mantra Health logo" size={34} />
      </div>
      <Text.h1 className="mb4 tc">{title}</Text.h1>
      {children}
    </div>
  );
};
